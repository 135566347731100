import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

const urlPattern =
    'https:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)';

@Component({
    selector: 'app-confirm',
    templateUrl: 'edit-entity-dialog.component.html',
    styles: [
        `
            .mat-dialog-content {
                overflow-wrap: break-word;
                white-space: pre-wrap;
            }
        `
    ]
})
export class YodataEditEntityDialogComponent implements OnInit {
    entityForm: UntypedFormGroup = this.fb.group({});
    isAllowedNewMapping: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<YodataEditEntityDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder
    ) {}

    closeForm(isCancelled: boolean, isCreateYodataContact: boolean = false) {
        // CREATE NEW CONTACT IN YODATA FLOW
        if (isCreateYodataContact) {
            this.dialogRef.close({
                type: this.data.type,
                entity: this.data.entity,
                formValues: {},
                isCreateNewInYodata: isCreateYodataContact
            });
            return;
        }

        if (!isCancelled) {
            this.dialogRef.close();
            return;
        }

        const values = this.entityForm.getRawValue();
        if (!values || !values.agent_office_pod) {
            return;
        }

        if (this.entityForm.invalid) {
            alert('Please fill all required fields');
            return;
        }

        this.dialogRef.close({
            type: this.data.type,
            entity: this.data.entity,
            formValues: values
        });
    }

    ngOnInit() {
        // console.log(this.data);
        let name = '';
        let agentOfficePod = '';
        if (this.data && this.data.type === 'profile') {
            name = this.data.entity.first_name + ' ' + this.data.entity.last_name;
            if (this.data.entity.yodata_mapping) {
                agentOfficePod = this.data.entity.yodata_mapping.pod_uri;
            } else {
                this.isAllowedNewMapping = true;
            }
        } else if (this.data && this.data.type === 'division') {
            name = this.data.entity.title;
            if (this.data.entity.yodata_mapping) {
                agentOfficePod = this.data.entity.yodata_mapping.pod_uri;
            }
        }

        this.entityForm = this.fb.group(
            {
                name: [{value: name, disabled: true}, []],
                agent_office_pod: [agentOfficePod, [Validators.maxLength(500), Validators.pattern(urlPattern)]]
            },
            {updateOn: 'change'}
        );
    }
}

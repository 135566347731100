import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {of, Subject} from 'rxjs';
import {catchError, filter, map, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {IBill, IPaymentGateway, IPaymentMade, IPaymentReceivedMadeRequestObject} from '@cyberco-nodejs/zipi-typings';
import {NotificationsService} from 'angular2-notifications';
import {MatDialog} from '@angular/material/dialog';
import {BankingService} from 'app/services/api/finance/banking.service';
import {GatewayService} from '../../../../profile/services/gateway.service';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {ContactCreateDialogComponent} from '../../../../contacts/contact-dialogs/contact-create-dialog/contact-create-dialog.component';
import {PAYMENT_MODES} from 'app/local-typings';
import {PaymentsMadeService} from '../../../services/payments-made.service';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {CheckPrintConfirmDialogComponent} from '../../../../shared/components/check-print-confirm-dialog/check-print-confirm.dialog';
import {BillsService} from 'app/services/api/finance/bills.service';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {DealFinancialApiService} from '../../../../deals/components/deal/deal-financial.api.service';
import {DealService} from '../../../../../services/deal.service';
import {LedgerAccountSelectorComponent} from '../../../../shared/components/ledger-account-selector/ledger-account-selector.component';
import {Deal} from '../../../../../models/deal';
import {FormGroupArray, FormGroupWithFormControls} from '../../../../../typings/common';
import Decimal from 'decimal.js-light';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';
import {formatToDate} from '../../../../../utilities';

@Component({
    selector: 'app-create-payment',
    templateUrl: 'create-payment-made.component.html',
    styleUrls: ['create-payment-made.component.scss']
})
export class CreatePaymentMadeComponent implements OnInit, OnDestroy {
    @ViewChild('ledgerAccountSelector') public ledgerAccountSelector: LedgerAccountSelectorComponent | undefined;
    @ViewChild('checkLedgerAccountSelector') public checkLedgerAccountSelector:
        | LedgerAccountSelectorComponent
        | undefined;

    private unsubscribe: Subject<void> = new Subject();

    billsArray: FormGroupArray = this.fb.array([]) as FormGroupArray;
    formGroup: FormGroupWithFormControls = this.fb.group({
        money_receiver__contact_fk_id: [null, [Validators.required]],
        paid_date: [new Date(), [Validators.required]],
        paid_by__ledger_account_fk_id: [null, [Validators.required]],
        amount: [null, [Validators.required]],

        payment_mode: [null, [Validators.required]],
        payment_number: [null, []],
        notes: [null, []],
        memo: [null, []],
        check_number: [null, []],
        reference: [null, []]
    }) as FormGroupWithFormControls;
    currencyMaskitoMask = currencyMaskitoOptions;

    gateway: IPaymentGateway | undefined;

    amountReceived: number = 0;
    paymentsAmount: number = 0;
    excessAmount: number = 0;

    paymentModes = PAYMENT_MODES;
    createDisabled: boolean = false;
    isEditFormFullDisabled: boolean = false;
    isPaymentMatched: boolean = false;
    isReconciled: boolean = false;

    billCheckId: number | null = null;
    paymentMadeId: number | null = null;
    paymentMade: IPaymentMade | undefined;

    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    savedContacts: Array<number> = [];

    isDepositRequestPayment: boolean;
    isDepositReleasePayment: boolean;

    relatedDeal: Deal | undefined;

    constructor(
        private fb: UntypedFormBuilder,
        private ntfs: NotificationsService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        public router: Router,
        private bankingService: BankingService,
        private gatewayService: GatewayService,
        private invoicesService: InvoicesService,
        private billsService: BillsService,
        private paymentsMadeService: PaymentsMadeService,
        private dealFinancialApiService: DealFinancialApiService,
        private dealService: DealService
    ) {
        this.isDepositRequestPayment = false;
        this.isDepositReleasePayment = false;
    }

    ngOnInit() {
        this.formGroup.controls.paid_date.patchValue(formatToDate(new Date()));

        this.gatewayService
            .getCompanySystemGateway()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((gateway) => {
                this.gateway = gateway;
            });

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            if (values && values.length > 0) {
                this.formGroup.controls.money_receiver__contact_fk_id.patchValue(values[0].target_id);
            } else {
                this.formGroup.controls.money_receiver__contact_fk_id.patchValue(null);
            }
        });

        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            const id = params['id'];

            if (id) {
                const parentUrl = this.route.snapshot.parent!.url[0].path;

                if (parentUrl === 'payments') {
                    this.paymentMadeId = Number(id);
                    this.initEditPayment();
                } else if (parentUrl === 'bills') {
                    this.billCheckId = Number(id);
                    this.initCreateCheck();
                }
            } else {
                this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((queries) => {
                    if (queries.bill_ids) {
                        this.initPayBills(queries.bill_ids.map((bill_id: number | string) => Number(bill_id)));
                    } else {
                        this.initVendorContactSubscribe();
                    }
                });
            }
        });
        this.formGroup.controls.amount.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => this.amountValidation());
    }

    initVendorContactSubscribe(ids: number[] = []) {
        this.formGroup.controls.money_receiver__contact_fk_id.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((id) => {
                this.billsArray.clear();

                if (!id) {
                    return;
                }

                this.billsService
                    .getBillsForCreditsApply(id)
                    .pipe(
                        tap((bills) =>
                            bills.map((bill: IBill) => {
                                const arrItem = this.fb.group({
                                    bill_id: bill.bill_id,
                                    payment_id: null,
                                    bill_number: bill.bill_number,
                                    due_date: bill.due_date,
                                    payment: 0,
                                    balance: bill.pending_balance
                                });

                                arrItem.controls.payment.valueChanges
                                    .pipe(takeUntil(this.unsubscribe))
                                    .subscribe((value) => this.amountValidation());

                                this.billsArray.push(arrItem);
                            })
                        ),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe();
            });
    }

    initEditPayment() {
        if (this.paymentMadeId) {
            this.paymentsMadeService
                .getPaymentMadeById(this.paymentMadeId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                    (payment: IPaymentMade) => {
                        this.paymentMade = payment;

                        if (this.paymentMade.source__deposit_release_fk_id) {
                            this.getDealAddress();
                        }

                        if (
                            this.paymentMade &&
                            this.paymentMade.related_payments &&
                            this.paymentMade.related_payments.some((pay) => !!pay.deposit_request_fk_id)
                        ) {
                            this.isDepositRequestPayment = true;
                        }

                        if (
                            this.paymentMade &&
                            this.paymentMade.related_payments &&
                            this.paymentMade.related_payments.some((pay) => !!pay.deposit_release_fk_id)
                        ) {
                            this.isDepositReleasePayment = true;
                        }

                        const paid_by__ledger_account_fk_id = payment.paid_by__ledger_account_fk_id;

                        this.isEditFormFullDisabled =
                            payment.check_info && payment.check_info.check_status === 'cleared';

                        if (payment.journal && payment.journal.line_items) {
                            this.isPaymentMatched = !!payment.journal.line_items.find(
                                (t) => !!t.matched__transaction_external_id
                            );
                            this.isReconciled = !!payment.journal.line_items.find((t) => !!t.reconciliation_fk_id);
                        }

                        if (payment.related_payments && payment.related_payments.length !== 0) {
                            for (const rp of payment.related_payments) {
                                if (rp.bill) {
                                    const arrItem = this.fb.group({
                                        bill_id: rp.bill.bill_id,
                                        payment_id: rp.payment_id,
                                        bill_number: rp.bill.bill_number,
                                        due_date: rp.bill.due_date,
                                        payment: [
                                            {
                                                value: rp.amount,
                                                disabled: this.isEditFormFullDisabled
                                            }
                                        ],
                                        balance: rp.bill.pending_balance
                                            ? new Decimal(rp.bill.pending_balance)
                                                  .add(rp.amount)
                                                  .toDecimalPlaces(2)
                                                  .toNumber()
                                            : Number(rp.amount.toFixed(2))
                                    });

                                    arrItem.controls.payment.valueChanges
                                        .pipe(takeUntil(this.unsubscribe))
                                        .subscribe((value) => this.amountValidation());

                                    if (rp.status === 'pending') {
                                        arrItem.disable();
                                    }

                                    this.billsArray.push(arrItem);
                                }
                            }
                        }

                        this.billsService
                            .getBillsForCreditsApply(payment.money_receiver__contact_fk_id)
                            .pipe(
                                tap((bills) =>
                                    bills.map((bill: IBill) => {
                                        const paymentExist = this.billsArray
                                            .getRawValue()
                                            .find((p) => p.bill_id === bill.bill_id);

                                        if (!paymentExist) {
                                            const arrItem = this.fb.group({
                                                bill_id: bill.bill_id,
                                                payment_id: null,
                                                bill_number: bill.bill_number,
                                                due_date: bill.due_date,
                                                payment: [
                                                    {
                                                        value: 0,
                                                        disabled: this.isEditFormFullDisabled
                                                    }
                                                ],
                                                balance: bill.pending_balance
                                            });

                                            arrItem.controls.payment.valueChanges
                                                .pipe(takeUntil(this.unsubscribe))
                                                .subscribe((value) => this.amountValidation());

                                            this.billsArray.push(arrItem);
                                        }
                                    })
                                ),
                                takeUntil(this.unsubscribe)
                            )
                            .subscribe();

                        this.formGroup.patchValue({
                            paid_by__ledger_account_fk_id: paid_by__ledger_account_fk_id,
                            money_receiver__contact_fk_id: payment.money_receiver__contact_fk_id,
                            money_sender__contact_fk_id: null,
                            paid_date: payment.paid_date,
                            amount: payment.amount,
                            payment_mode: payment.payment_mode,
                            payment_number: payment.payment_made_number,
                            reference: payment.reference,
                            notes: payment.notes,
                            check_number: payment.check_info ? payment.check_info.check_number : null,
                            memo: payment.check_info ? payment.check_info.memo : null
                        });

                        this.formGroup.controls.money_receiver__contact_fk_id.disable();
                        this.formGroup.controls.payment_number.setValidators([Validators.required]);
                        this.formGroup.controls.payment_number.updateValueAndValidity();

                        if (this.isEditFormFullDisabled) {
                            this.formGroup.disable();
                            this.formGroup.controls.memo.enable();
                        }

                        if (
                            this.isPaymentMatched ||
                            this.paymentMade.external_transaction_ref ||
                            this.isDepositRequestPayment ||
                            this.isDepositReleasePayment ||
                            payment.is_locked_for_applying ||
                            this.paymentMade.payment_mode === 'deduction'
                        ) {
                            this.formGroup.controls.amount.disable();
                        }

                        if (payment.money_receiver__contact_fk_id) {
                            this.savedContacts = [payment.money_receiver__contact_fk_id];
                        }
                    },
                    (err) => {
                        setTimeout(() => this.router.navigate(['/purchases/payments/']), 1500);
                    }
                );
        }
    }

    initCreateCheck() {
        if (this.billCheckId) {
            this.billsService
                .getBillById(this.billCheckId)
                .pipe(
                    filter((bill) => !!bill),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((bill) => {
                    const arrItem = this.fb.group({
                        bill_id: bill.bill_id,
                        payment_id: null,
                        bill_number: bill.bill_number,
                        due_date: bill.due_date,
                        payment: bill.pending_balance,
                        balance: bill.pending_balance
                    });

                    arrItem.controls.payment.valueChanges
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((value) => this.amountValidation());

                    this.billsArray.push(arrItem);
                    this.formGroup.controls.payment_mode.patchValue('check');
                    this.formGroup.controls.amount.patchValue(bill.pending_balance);
                    this.formGroup.controls.money_receiver__contact_fk_id.patchValue(
                        bill.money_receiver__contact_fk_id
                    );
                    this.formGroup.controls.money_receiver__contact_fk_id.disable();
                    this.formGroup.controls.paid_by__ledger_account_fk_id.setValidators(Validators.required);
                });
        }
    }

    initPayBills(ids: number[]) {
        this.billsService
            .getBillById(ids[0])
            .pipe(
                filter((bill) => !!bill),
                takeUntil(this.unsubscribe)
            )
            .subscribe((firstBill) => {
                this.formGroup.controls.money_receiver__contact_fk_id.patchValue(
                    firstBill.money_receiver__contact_fk_id
                );
                if (firstBill.money_receiver__contact_fk_id) {
                    this.savedContacts = [firstBill.money_receiver__contact_fk_id];

                    this.billsArray.clear();

                    this.billsService
                        .getBillsForCreditsApply(firstBill.money_receiver__contact_fk_id)
                        .pipe(
                            tap((bills) => {
                                let summaryPay = 0;

                                bills.map((bill: IBill) => {
                                    const isSelectedBill = ids.includes(bill.bill_id!);

                                    if (isSelectedBill && bill.pending_balance) {
                                        summaryPay = new Decimal(summaryPay)
                                            .add(bill.pending_balance)
                                            .toDecimalPlaces(2)
                                            .toNumber();
                                    }

                                    const arrItem = this.fb.group({
                                        bill_id: bill.bill_id,
                                        payment_id: null,
                                        bill_number: bill.bill_number,
                                        due_date: bill.due_date,
                                        payment: isSelectedBill ? bill.pending_balance : 0,
                                        balance: bill.pending_balance
                                    });

                                    arrItem.controls.payment.valueChanges
                                        .pipe(takeUntil(this.unsubscribe))
                                        .subscribe((value) => this.amountValidation());

                                    this.billsArray.push(arrItem);
                                });

                                this.formGroup.controls.amount.patchValue(summaryPay);
                            }),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe(() => this.initVendorContactSubscribe());
                }
            });
    }

    getDealAddress() {
        if (this.paymentMade) {
            this.dealFinancialApiService
                .getDealDepositRelease(this.paymentMade.source__deposit_release_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((dealRelease) => {
                    if (dealRelease) {
                        if (dealRelease.deal_fk_id) {
                            this.dealService.getDealBaseView(dealRelease.deal_fk_id).then((deal) => {
                                this.relatedDeal = deal;
                            });
                        }
                    }
                });
        }
    }

    showPaymentMode() {
        if (this.paymentMade && this.paymentMadeId) {
            let paymentModeTitle = '';
            for (const mode of this.paymentModes) {
                if (mode.slug === this.paymentMade.payment_mode) {
                    paymentModeTitle = mode.title;
                }
            }
            return paymentModeTitle;
        }
    }

    amountValidation() {
        const rawValue = this.formGroup.getRawValue();
        const value = Number(cleanCurrencyString(rawValue.amount));
        const billsArray = this.billsArray.getRawValue();

        let sumOfPayments = 0;

        const sumOfPaymentsDecimal: Decimal = billsArray.reduce(
            (acc, bill) => acc.add(Number(cleanCurrencyString(bill.payment))),
            new Decimal(0)
        );
        sumOfPayments = sumOfPaymentsDecimal.toDecimalPlaces(2).toNumber();

        this.amountReceived = value;
        this.paymentsAmount = sumOfPayments;
        this.excessAmount = new Decimal(value).sub(sumOfPayments).toDecimalPlaces(2).toNumber();

        if (value >= sumOfPayments) {
            this.formGroup.controls.amount.setErrors(null);
        } else {
            this.formGroup.controls.amount.setErrors({message: `The total applied payment has exceeded this amount.`});
        }

        this.validatePayments();
    }

    validatePayments() {
        let unappliedAmount = new Decimal(this.amountReceived);
        for (const control of this.billsArray.controls) {
            const group: UntypedFormGroup = control as UntypedFormGroup;
            const payment = Number(cleanCurrencyString(group.controls.payment.value));

            if (payment > group.controls.balance.value) {
                group.controls.payment.setErrors({message: 'Amount cannot exceed the balance.'});
                group.controls.payment.markAllAsTouched();
                this.formGroup.controls.amount.markAllAsTouched();
                unappliedAmount = unappliedAmount.sub(payment);
                continue;
            }

            if (payment > 0 && payment > unappliedAmount.toDecimalPlaces(2).toNumber()) {
                group.controls.payment.setErrors({
                    message: `The total amount of $${this.amountReceived} has been exceeded.`
                });
                group.controls.payment.markAllAsTouched();
                this.formGroup.controls.amount.markAllAsTouched();
                unappliedAmount = unappliedAmount.sub(payment);
                continue;
            }
            group.controls.payment.setErrors(null);
            unappliedAmount = unappliedAmount.sub(payment);
        }
    }

    private validateForm() {
        if (isNaN(this.formGroup.controls.paid_date.value)) {
            this.formGroup.controls.paid_date.setValue(null);
        }
        if (this.billCheckId && this.checkLedgerAccountSelector) {
            this.checkLedgerAccountSelector.markTouched();
        } else if (this.billCheckId && this.ledgerAccountSelector) {
            this.ledgerAccountSelector.markTouched();
        }

        if (this.formGroup.invalid || this.billsArray.invalid) {
            this.formGroup.markAllAsTouched();
            this.billsArray.markAllAsTouched();
            this.ntfs.warn('Information could not be saved. Please resolve the highlighted errors.');
            return false;
        }

        return true;
    }

    payInFull(item: AbstractControl) {
        const itemGroup: UntypedFormGroup = item as UntypedFormGroup;
        if (itemGroup.disabled) {
            return;
        }
        const balance = cleanCurrencyString(itemGroup.controls.balance.value);

        itemGroup.controls.payment.patchValue(balance);
    }

    create() {
        if (!this.validateForm() || this.createDisabled) {
            return;
        }
        const dataRaw = this.formGroup.getRawValue();
        const billsRaw = this.billsArray
            .getRawValue()
            .map((bill) => {
                return {
                    entity_id: bill.bill_id,
                    payment_id: bill.payment_id,
                    amount: Number(cleanCurrencyString(bill.payment)),
                    entity: null,
                    entity_transfer_id: null
                };
            })
            .filter((item) => item.amount > 0);

        this.createDisabled = true;

        const requestObj: IPaymentReceivedMadeRequestObject = {
            money_receiver__contact_fk_id: dataRaw.money_receiver__contact_fk_id,
            money_sender__contact_fk_id: null,
            paid_date: dataRaw.paid_date,
            scheduled_date: null,
            amount: Number(cleanCurrencyString(dataRaw.amount)),
            paid_by__ledger_account_fk_id: dataRaw.paid_by__ledger_account_fk_id,
            paid_by__payment_method_fk_id: null,
            pay_to__ledger_account_fk_id: null,
            pay_to__payment_method_fk_id: null,
            payments: billsRaw,
            is_create_multiple_payments: true,
            payment_mode: dataRaw.payment_mode,
            payment_number: dataRaw.payment_number,
            reference: dataRaw.reference,
            notes: dataRaw.notes,

            matched__transaction_external_id: null,
            check_info: null,
            deposit_release_id: null,
            is_locked_for_applying: false,
            allow_auto_apply: false,
            source__deal_fk_id: null,
            sender_velocity: null,
            restrict_downgrade: false
        };

        if (dataRaw.payment_mode === 'check') {
            requestObj.check_info = {
                memo: dataRaw.memo,
                check_number: dataRaw.check_number,
                print_status: 'not_printed',
                check_status: 'uncleared'
            };
        }

        if (dataRaw.payment_mode === 'check_record') {
            requestObj.check_info = {
                memo: dataRaw.memo,
                check_number: dataRaw.check_number,
                print_status: 'unknown',
                check_status: 'uncleared'
            };
        }

        this.paymentsMadeService
            .createPaymentMade(requestObj)
            .pipe(
                catchError(() => {
                    this.createDisabled = false;
                    return of(null);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((res) => {
                if (res) {
                    this.router.navigate(['/purchases/payments/']);
                }
            });
    }

    editPayment() {
        if (!this.validateForm() || this.createDisabled) {
            return;
        }
        const dataRaw = this.formGroup.getRawValue();
        const billsRaw = this.billsArray
            .getRawValue()
            .map((bill) => {
                return {
                    entity_id: bill.bill_id,
                    payment_id: bill.payment_id,
                    amount: Number(cleanCurrencyString(bill.payment)),
                    entity: null,
                    entity_transfer_id: null
                };
            })
            .filter((item) => item.amount > 0 || item.payment_id);

        this.createDisabled = true;

        const checkInfo =
            this.paymentMade && this.paymentMade.check_info
                ? {
                      check_status: this.paymentMade.check_info.check_status,
                      print_status: this.paymentMade.check_info.print_status,
                      memo: dataRaw.memo,
                      check_number: dataRaw.check_number
                  }
                : null;

        const requestObj: IPaymentReceivedMadeRequestObject = {
            money_receiver__contact_fk_id: this.paymentMade ? this.paymentMade.money_receiver__contact_fk_id : null,
            money_sender__contact_fk_id: null,
            paid_date: dataRaw.paid_date,
            scheduled_date: null,
            amount: Number(cleanCurrencyString(dataRaw.amount)),
            paid_by__ledger_account_fk_id: dataRaw.paid_by__ledger_account_fk_id,
            paid_by__payment_method_fk_id: null,
            pay_to__ledger_account_fk_id: null,
            pay_to__payment_method_fk_id: null,
            payments: billsRaw,
            is_create_multiple_payments: true,

            payment_mode: dataRaw.payment_mode,
            payment_number: dataRaw.payment_number,
            reference: dataRaw.reference,
            notes: dataRaw.notes,

            matched__transaction_external_id: null,
            check_info: checkInfo,
            deposit_release_id: null,
            is_locked_for_applying: false,
            allow_auto_apply: false,
            source__deal_fk_id: null,
            sender_velocity: null,
            restrict_downgrade: false
        };

        if (this.paymentMadeId) {
            this.paymentsMadeService
                .editPaymentMade(this.paymentMadeId, requestObj)
                .pipe(
                    catchError(() => {
                        this.createDisabled = false;
                        return of(null);
                    }),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((res) => {
                    if (res) {
                        this.router.navigate(['/purchases/payments/']);
                    }
                });
        }
    }

    changeMode($event: any) {
        if (!['check', 'check_record'].includes($event)) {
            this.formGroup.controls.check_number.setValue(null);
            this.formGroup.controls.memo.setValue(null);
        }
    }

    saveAndPrint(isNow: boolean) {
        if (!this.validateForm() || this.createDisabled) {
            return;
        }
        const dataRaw = this.formGroup.getRawValue();
        const billsRaw = this.billsArray
            .getRawValue()
            .map((bill) => {
                return {
                    entity_id: bill.bill_id,
                    payment_id: null,
                    amount: Number(cleanCurrencyString(bill.payment)),
                    entity: null,
                    entity_transfer_id: null
                };
            })
            .filter((item) => item.amount > 0);

        this.createDisabled = true;

        const requestObj: IPaymentReceivedMadeRequestObject = {
            money_receiver__contact_fk_id: dataRaw.money_receiver__contact_fk_id,
            money_sender__contact_fk_id: null,
            paid_date: dataRaw.paid_date,
            scheduled_date: null,
            amount: Number(cleanCurrencyString(dataRaw.amount)),
            paid_by__ledger_account_fk_id: dataRaw.paid_by__ledger_account_fk_id,
            paid_by__payment_method_fk_id: null,
            pay_to__ledger_account_fk_id: null,
            pay_to__payment_method_fk_id: null,
            payments: billsRaw,
            is_create_multiple_payments: true,

            payment_mode: dataRaw.payment_mode,
            payment_number: dataRaw.payment_number,
            reference: dataRaw.reference,
            notes: null,

            matched__transaction_external_id: null,
            check_info: {
                memo: dataRaw.memo,
                check_number: dataRaw.check_number,
                print_status: 'not_printed',
                check_status: 'uncleared'
            },
            deposit_release_id: null,
            is_locked_for_applying: false,
            allow_auto_apply: false,
            source__deal_fk_id: null,
            sender_velocity: null,
            restrict_downgrade: false
        };

        this.paymentsMadeService
            .createPaymentMade(requestObj)
            .pipe(
                catchError(() => {
                    this.createDisabled = false;
                    return of(null);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((payment) => {
                if (!!isNow && payment) {
                    this.printCheck(payment);
                } else {
                    return this.router.navigate(['/purchases/payments/']);
                }
            });
    }

    printCheck(payment: IPaymentMade) {
        this.paymentsMadeService
            .downloadCheckPdf(payment.payment_made_id!, {
                check_margins: true,
                show_background: false
            })
            .pipe(
                map((res) => new Blob([new Uint8Array(res.data)], {type: 'application/pdf'})),
                tap((result) => {
                    const blobUrl = URL.createObjectURL(result);
                    const iframe = document.createElement('iframe');
                    iframe.id = 'print-frame';

                    iframe.style.display = 'none';
                    iframe.src = blobUrl;
                    iframe.onload = () => {
                        setTimeout(function () {
                            if (iframe.contentWindow) {
                                iframe.contentWindow.print();
                            }
                        }, 0);
                    };
                    document.body.appendChild(iframe);
                    this.printConfirm(payment);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    printConfirm(payment: IPaymentMade) {
        const dialogRef = this.dialog.open(CheckPrintConfirmDialogComponent, {
            minWidth: 320,
            data: {
                payment_made_id: payment.payment_made_id,
                check_number: payment.check_info.check_number
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.router.navigate(['/purchases/payments/']));
    }

    contactCreate() {
        const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
            autoFocus: false,
            data: {
                category: 'vendor'
            }
        });

        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    isAbleToDelete() {
        if (this.paymentMade) {
            const payments = this.paymentMade.related_payments;
            const areThereUndeletablePayments =
                payments &&
                payments.length > 0 &&
                payments.some(
                    (pay) =>
                        ['completed', 'review', 'pending', 'processing'].includes(pay.status) ||
                        !!pay.deposit_request_fk_id ||
                        !!pay.deposit_release_fk_id
                );

            const isExternalPaySystemMode =
                this.paymentMade.payment_mode &&
                ['zipi_pay', 'zipi_financial', 'authorize'].includes(this.paymentMade.payment_mode);

            if (areThereUndeletablePayments || this.isPaymentMatched || this.isReconciled || isExternalPaySystemMode) {
                return false;
            }
        }
        return true;
    }

    getDeleteTooltip() {
        if (
            this.paymentMade &&
            this.paymentMade.related_payments &&
            this.paymentMade.related_payments.some((pay) => !!pay.deposit_request_fk_id)
        ) {
            return 'Payment Made was created as part of the Deal Deposit and should be deleted from Deal Page.';
        }

        if (
            this.paymentMade &&
            this.paymentMade.related_payments &&
            this.paymentMade.related_payments.some((pay) => !!pay.deposit_release_fk_id)
        ) {
            return 'Payment Made was created as part of the Deal Deposit and should be deleted from Deal Page.';
        }
        if (
            this.paymentMade &&
            this.paymentMade.payment_mode &&
            ['zipi_pay', 'zipi_financial', 'authorize'].includes(this.paymentMade.payment_mode)
        ) {
            return 'Payment Made has reference to external Pay System';
        }
        if (this.isPaymentMatched) {
            return 'Payments that have been matched in the Banking module cannot be deleted. To delete, the matching must first be removed.';
        }
        if (this.paymentMade && !!this.paymentMade.source__deal_fk_id) {
            return 'You cannot modify Payment Made associated with Deal.';
        }

        return !this.isAbleToDelete() ? 'Payment Made has Payment(s) that cannot be deleted' : '';
    }

    deletePayment() {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            data: {
                title: `Confirm deleting Payment Made`,
                message: `Please, confirm deleting`
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((confirmed: boolean) => {
                if (!confirmed) {
                    return;
                }
                if (this.paymentMade && this.paymentMade.payment_made_id) {
                    this.paymentsMadeService
                        .deletePaymentById(this.paymentMade.payment_made_id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((res) => {
                            if (res) {
                                this.router.navigate(['/purchases/payments/']);
                            }
                        });
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

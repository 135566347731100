import {AbstractControl} from '@angular/forms';
import {MaskitoOptions} from '@maskito/core';
import {maskitoNumberOptionsGenerator, maskitoWithPlaceholder} from '@maskito/kit';
import {map} from 'rxjs/operators';

// used by Maskito Kit for negative numbers
const UNICODE_MINUS_SIGN = '\u2212';

// Masks
export const currencyMaskitoOptions: MaskitoOptions = maskitoNumberOptionsGenerator({
    decimalSeparator: '.',
    thousandSeparator: ',',
    precision: 2,
    prefix: '$',
    min: 0
});

export const currencyWithNegativeMaskitoOptions: MaskitoOptions = maskitoNumberOptionsGenerator({
    decimalSeparator: '.',
    thousandSeparator: ',',
    precision: 2,
    prefix: '$'
});

export const numberMaskitoOptions: MaskitoOptions = maskitoNumberOptionsGenerator({
    decimalSeparator: '.',
    thousandSeparator: ',',
    precision: 2,
    min: 0
});
export const numberWithNegativeMaskitoOptions: MaskitoOptions = maskitoNumberOptionsGenerator({
    decimalSeparator: '.',
    thousandSeparator: ',',
    precision: 2
});
export const numberWithNegativeWithoutDecimalMaskitoOptions: MaskitoOptions = maskitoNumberOptionsGenerator({
    thousandSeparator: ','
});

export const percentMaskitoOptions: MaskitoOptions = maskitoNumberOptionsGenerator({
    decimalSeparator: '.',
    thousandSeparator: '',
    precision: 7,
    min: 0,
    max: 100
});

export const einMaskitoOptions = {
    ...maskitoWithPlaceholder('__-_______'),
    mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
} as MaskitoOptions;

export const ssnMaskitoOptions = {
    ...maskitoWithPlaceholder('___-__-____'),
    mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
} as MaskitoOptions;

export const tinMaskitoOptions = {
    ...maskitoWithPlaceholder('__-_______'),
    mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
} as MaskitoOptions;

export const phoneMaskitoOptions = {
    ...maskitoWithPlaceholder('___-___-____'),
    mask: [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
} as MaskitoOptions;

// Processing functions
export function cleanCurrencyString(currency: string | number): string {
    if (currency == null) {
        return '';
    }
    if (typeof currency === 'number') {
        return currency.toString();
    }

    let standardizeMinusSign = currency.replace(UNICODE_MINUS_SIGN, '-');
    let cleanedValue = standardizeMinusSign.replace(/\$|\,/g, '');

    return cleanedValue;
}

export const unmaskCurrencyControlValue = (formControl: AbstractControl) =>
    map((value: string = '') => {
        const cleanedValue: string = cleanCurrencyString(value);
        formControl.patchValue(cleanedValue, {emitEvent: false});
        return cleanedValue;
    });

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Deal} from '../../../../models/deal';
import {GenericFormArray, GenericFormGroup} from '../../../../entites/generic.entity';
import {OpeningPerformanceValuesEntity} from '../../../../entites/opening-performance-values.entity';
import {MultipleTargetsDealsQueryModel} from '../../../deals/components/deal/common/deal.models';

@Component({
    selector: 'app-contact-edit-compliance-opening-performance-values',
    template: `
        <div class="row w-100 compliance-item">
            <div class="col">
                <strong>Commission Calculation Modifiers</strong>
            </div>
        </div>

        <button
            type="button"
            (click)="doAdd()"
            mat-button
            matTooltip="Add opening performance value"
            style="margin-bottom: 10px;"
        >
            <mat-icon class="mr-1">add</mat-icon>
            Modification
        </button>

        <div *ngFor="let item of openingPerformanceValuesFA.controls; index as ii" class="zp-pd-15">
            <app-contact-edit-compliance-opening-performance-values-item
                (delete)="doDelete(ii)"
                [openingValuesFG]="item"
            >
            </app-contact-edit-compliance-opening-performance-values-item>
        </div>
    `,
    styleUrls: ['../contact-edit.component.css']
})
export class OpeningPerformanceValuesComponent implements OnDestroy, OnInit {
    private unsubscribe: Subject<void> = new Subject();

    @Input() openingPerformanceValuesFA: GenericFormArray<OpeningPerformanceValuesEntity> = <
        GenericFormArray<OpeningPerformanceValuesEntity>
    >new GenericFormArray([]);
    DEAL = Deal;

    doDelete(ii: number) {
        this.openingPerformanceValuesFA.removeAt(ii);
    }

    doAdd() {
        this.openingPerformanceValuesFA.push(
            new GenericFormGroup(
                new OpeningPerformanceValuesEntity().setParameters(
                    new MultipleTargetsDealsQueryModel().setStatus(Deal.status_SET.closed)
                )
            )
        );
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnInit(): void {}
}

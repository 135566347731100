import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ElasticsearchService} from '../../services/elasticsearch.service';
import {EventsService} from '../../../../services/events.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {DateRangePickerComponent} from '../../../../components/date-range-picker/date-range-picker.component';
import * as moment from 'moment';
import {NotificationsServiceZipi} from '../../../notifications/notifications.service';

const MIN_SYMBOLS_TO_START_SEARCH = 3;

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeSearch: Subject<void> = new Subject();

    @ViewChild('searchInput', {static: true}) searchInput: ElementRef<HTMLElement> | undefined;
    @ViewChild('dateRangePicker', {static: true}) dateRangePicker: DateRangePickerComponent | undefined;

    globalSearchForm: UntypedFormGroup | undefined;
    searchResults: Array<any> = [];
    totalResults: number | undefined;
    handleDateBetween: any = null;

    modes: Array<string> = [
        'global',
        'deal',
        'contact',
        'division',
        'goal',
        'group',
        'invoice',
        'expense',
        'bill',
        'profile',
        'payment_made',
        'payment_received'
    ];

    noResults: boolean;

    constructor(
        public router: Router,
        private fb: UntypedFormBuilder,
        private elasticsearch: ElasticsearchService,
        private eventsService: EventsService,
        private notificationsService: NotificationsServiceZipi
    ) {
        this.noResults = false;
    }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.globalSearchForm = this.fb.group({
            searchData: ['', []],
            mode: ['global', []]
        });

        const currentSearchData = this.eventsService.getSearchData();

        if (currentSearchData) {
            this.globalSearchForm.get('searchData')!.setValue(currentSearchData);
        }

        // if (this.router.url.includes('/customer-rater-list')) {
        //     this.globalSearchForm.get('mode').setValue('rate_request');
        // }

        if (this.router.url.includes('/contacts')) {
            this.globalSearchForm.get('mode')!.setValue('contact');
        }

        if (this.router.url.includes('/company/directory/groups')) {
            this.globalSearchForm.get('mode')!.setValue('group');
        }

        if (this.router.url.includes('/company/directory/divisions')) {
            this.globalSearchForm.get('mode')!.setValue('division');
        }

        if (this.router.url.includes('/tasks')) {
            this.globalSearchForm.get('mode')!.setValue('goal');
        }

        if (this.router.url.includes('/deals')) {
            this.globalSearchForm.get('mode')!.setValue('deal');
        }

        if (this.router.url.includes('/sales/invoices')) {
            this.globalSearchForm.get('mode')!.setValue('invoice');
        }

        if (this.router.url.includes('/purchases/expenses')) {
            this.globalSearchForm.get('mode')!.setValue('expense');
        }

        if (this.router.url.includes('/purchases/bills')) {
            this.globalSearchForm.get('mode')!.setValue('bill');
        }

        if (this.router.url.includes('/company/directory/users')) {
            this.globalSearchForm.get('mode')!.setValue('profile');
        }

        if (this.router.url.includes('/sales/paymentsreceived')) {
            this.globalSearchForm.get('mode')!.setValue('payment_received');
        }

        if (this.router.url.includes('/purchases/payments')) {
            this.globalSearchForm.get('mode')!.setValue('payment_made');
        }

        this.globalSearchForm
            .get('searchData')!
            .valueChanges.pipe(debounceTime(600), takeUntil(this.unsubscribe))
            .subscribe((data) => {
                if (data && data.length >= MIN_SYMBOLS_TO_START_SEARCH) {
                    this.search();
                }
            });
    }

    changeSearchMode(mode: string) {
        this.searchResults = [];
        this.noResults = false;
        if (typeof this.globalSearchForm !== 'undefined') {
            this.globalSearchForm.get('searchData')!.setValue('');
            this.globalSearchForm.get('mode')!.setValue(mode);
        }
    }

    search() {
        if (
            typeof this.globalSearchForm !== 'undefined' &&
            this.globalSearchForm.get('searchData')!.value &&
            this.globalSearchForm.get('searchData')!.value.trim().length
        ) {
            this.noResults = false;
            this.unsubscribeSearch.next();

            const searchRequest = {
                searchData: this.globalSearchForm.get('searchData')!.value.trim(),
                mode: this.globalSearchForm.get('mode')!.value
            };
            this.elasticsearch
                .searchData(searchRequest)
                .pipe(takeUntil(this.unsubscribeSearch))
                .subscribe((resp) => {
                    if (
                        resp.result &&
                        resp.result.hits.total.value > 0 &&
                        resp.result.hits.hits &&
                        resp.result.hits.hits.length > 0
                    ) {
                        this.totalResults = resp.result.hits.total.value;

                        this.searchResults = resp.result.hits.hits;
                        this.searchInput?.nativeElement.blur();

                        this.searchInput?.nativeElement.focus();
                    } else {
                        this.totalResults = 0;
                        this.searchResults = [];
                        this.noResults = true;
                    }
                });
        }
    }

    searchByDate() {
        // validate
        if (
            !this.handleDateBetween ||
            !this.handleDateBetween ||
            !this.handleDateBetween.dateFrom ||
            !this.handleDateBetween.dateTo
        ) {
            return this.notificationsService.addError('Date From and Date To must be set');
        }
        if (
            moment(this.handleDateBetween.dateFrom, 'YYYYMMDD').diff(
                moment(this.handleDateBetween.dateTo, 'YYYYMMDD')
            ) > 0
        ) {
            return this.notificationsService.addError('Date From must be earlier than Date To');
        }

        this.unsubscribeSearch.next();

        const searchRequest = {
            searchData: this.globalSearchForm?.get('searchData')?.value.trim(),
            mode: this.globalSearchForm?.get('mode')?.value,
            dateRange: {
                from: this.handleDateBetween.dateFrom,
                to: this.handleDateBetween.dateTo
            }
        };
        this.elasticsearch
            .searchData(searchRequest)
            .pipe(takeUntil(this.unsubscribeSearch))
            .subscribe((resp) => {
                if (
                    resp.result &&
                    resp.result.hits.total.value > 0 &&
                    resp.result.hits.hits &&
                    resp.result.hits.hits.length > 0
                ) {
                    this.totalResults = resp.result.hits.total.value;

                    this.searchResults = resp.result.hits.hits;
                    this.searchInput?.nativeElement.blur();

                    this.searchInput?.nativeElement.focus();
                } else {
                    this.totalResults = 0;
                    this.searchResults = [];
                    this.noResults = true;
                }
            });
    }

    handleSearchValue() {
        if (this.searchResults.length === 0) {
            this.search();
        }

        this.eventsService.setSearchData(this.globalSearchForm?.get('searchData')?.value);

        if (typeof this.globalSearchForm !== 'undefined' && this.globalSearchForm.get('mode')?.value !== 'global') {
            switch (this.globalSearchForm.get('mode')?.value) {
                case 'contact':
                    const idsArrayContacts = this.searchResults.map((item) => item._source.raw_data.id);
                    this.eventsService.updateContactsEventValue(idsArrayContacts);
                    this.router.navigate(['/contacts']);
                    break;

                case 'division':
                    const idsArrayDivisions = this.searchResults.map((item) => item._source.raw_data.id);
                    this.eventsService.updateGroupEventValue(idsArrayDivisions);
                    this.router.navigate(['/company/directory/divisions']);
                    break;

                case 'group':
                    const idsArrayGroups = this.searchResults.map((item) => item._source.raw_data.id);
                    this.eventsService.updateGroupEventValue(idsArrayGroups);
                    this.router.navigate(['/company/directory/groups']);
                    break;

                case 'goal':
                    const idsArrayTasks = this.searchResults.map((item) => item._source.raw_data.id);
                    this.eventsService.updateGoalEventValue(idsArrayTasks);
                    this.router.navigate(['/tasks']);
                    break;

                case 'deal':
                    const idsArrayDeals = this.searchResults.map((item) => item._source.raw_data.id);
                    this.eventsService.updateDealEventValue(idsArrayDeals);
                    this.router.navigate(['/deals']);
                    break;

                case 'invoice':
                    this.router.navigate(['/sales/invoices']);
                    break;

                case 'expense':
                    this.router.navigate(['/purchases/expenses']);
                    break;

                case 'bill':
                    this.router.navigate(['/purchases/bills']);
                    break;

                case 'profile':
                    this.router.navigate(['/company/directory/users']);
                    break;

                case 'payment_received':
                    this.router.navigate(['/sales/paymentsreceived']);
                    break;

                case 'payment_made':
                    this.router.navigate(['/purchases/payments']);
                    break;
            }
        }
    }

    searchResultRedirect(item: {[key: string]: any}) {
        this.eventsService.setSearchData(this.globalSearchForm?.get('searchData')?.value);

        switch (item.type) {
            case 'contact':
                // this.eventsService.updateContactsEventValue([item.raw_data.id]);
                this.router.navigate(['/contacts', item.raw_data.id]);
                break;

            case 'contact_person':
                this.router.navigate(['/contacts', item.raw_data.contact_id]);
                break;

            case 'division':
                this.eventsService.updateGroupEventValue([item.raw_data.id]);
                this.router.navigate(['/company/directory/divisions']);
                break;

            case 'group':
                this.eventsService.updateGroupEventValue([item.raw_data.id]);
                this.router.navigate(['/company/directory/groups']);
                break;

            case 'goal':
                this.eventsService.updateGoalEventValue([item.raw_data.id]);
                this.router.navigate(['/tasks']);
                break;

            case 'deal':
                this.eventsService.updateDealEventValue([item.raw_data.id]);
                this.router.navigate([`/deals/${item.raw_data.id}`]);
                break;

            case 'invoice':
                this.router.navigate([`/sales/invoices/${item.raw_data.id}`]);
                break;

            case 'expense':
                this.router.navigate([`/purchases/expenses/${item.raw_data.id}`]);
                break;

            case 'bill':
                this.router.navigate([`/purchases/bills/${item.raw_data.id}`]);
                break;

            case 'profile':
                this.router.navigate(['/company/directory/users', item.raw_data.id]);
                break;

            case 'payment_received':
                this.router.navigate([`/sales/paymentsreceived/${item.raw_data.id}`]);
                break;

            case 'payment_made':
                this.router.navigate([`/purchases/payments/${item.raw_data.id}`]);
                break;
        }
    }

    displayFn(item?: any): string | undefined {
        return '';
    }

    modeToGlobal() {
        this.globalSearchForm?.get('mode')?.setValue('global');
        this.search();
    }

    resetSearch(cleanSearchString = true) {
        if (cleanSearchString) {
            this.globalSearchForm?.get('searchData')?.setValue('');
        }
        this.searchResults = [];
        this.noResults = false;
        this.eventsService.updateContactsEventValue([]);
    }

    ngOnDestroy() {
        this.eventsService.setSearchData(this.globalSearchForm?.get('searchData')?.value);

        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeSearch.next();
        this.unsubscribeSearch.complete();
    }
}

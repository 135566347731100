import * as _ from 'lodash-es';
import * as moment from 'moment';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormControl} from '@angular/forms';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MatDialog} from '@angular/material/dialog';
import {GenericControl, GenericFormGroup} from 'app/entites/generic.entity';
import {Deal} from 'app/models/deal';
import {NotificationsServiceZipi} from '../../../notifications/notifications.service';
import {
    CompensationProfileFilter,
    MultipleTargetsDealsQueryModel
} from '../../../deals/components/deal/common/deal.models';
import {FinancialElementModel} from '../models/financial-element.model';
import {CompensationService} from '../compensation.service';
import {CompensationProfileModel} from '../models/compensation-profile.model';
import {DateRangeModel} from '../models/date-range.model';
import {OverrideDialogComponent} from './override.dialog';
import {map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, of, Subject} from 'rxjs';
import {ICompanyWideState} from 'app/store/company-wide/company-wide.reducer';
import {select, Store} from '@ngrx/store';
import {selectMembershipOrganizations, selectProcessedSOB} from 'app/store/company-wide/company-wide.selectors';
import {FeatureFlagsService} from '../../../feature-flags/feature-flags.service';
import {ChipNode} from '../models/chip-node';

type ExtractGenericClass<Type> = {
    [Property in keyof Type]?: GenericControl<Type[Property]>;
};

@Component({
    selector: 'app-company-compensation-profile',
    styles: [
        `
            .label {
                margin-right: 16px;
            }

            .card-content {
                align-items: center;
                display: flex;
            }

            .section-margin {
                margin-bottom: 0px;
            }

            .changed-profile {
                box-shadow: 0px 0px 5px 1px rgba(0, 0, 255, 0.2);
            }

            .invalid-profile {
                box-shadow: 0px 0px 5px 1px rgba(255, 0, 0, 0.2);
            }

            .edit-indicator {
                color: rgba(0, 0, 0, 0.54);
            }

            ::ng-deep .add-item-menu {
                max-height: 250px;
            }

            .bold-input-placeholder ::ng-deep .mat-form-field-label {
                font-weight: 700;
                color: rgba(0, 0, 0, 0.87);
                font-size: 1.33rem;
                line-height: 24px;
            }
            .mat-card {
                border: 1px lightgrey solid;
            }
        `
    ],
    template: `
        <mat-card [ngClass]="{'invalid-profile': compensationForm.invalid, 'changed-profile': hasChanges()}">
            <div class="section-margin">
                <div class="card-content d-flex flex-column">
                    <div
                        class="d-flex align-items-center justify-content-around w-100"
                        (click)="doEditCompensationProfile($event)"
                        [ngStyle]="createMode === false ? {cursor: 'pointer'} : null"
                        style="padding: 16px"
                    >
                        <button
                            mat-raised-button
                            *ngIf="canOverride"
                            class="mat-primary"
                            (click)="doOverride(compensationForm.getRawValue())"
                            style="margin-right: 8px"
                        >
                            Override
                        </button>
                        <div class="label" *ngIf="compensationForm?.controls?.title?.value">
                            {{ compensationForm?.controls?.title?.value }}
                        </div>
                        <div *ngIf="!compensationForm?.controls?.title?.value" class="d-flex">
                            <div class="label">
                                {{
                                    date_range_type_LABELS[
                                        compensationForm?.controls?.effective_date_range?.controls?.type?.value ||
                                            date_range_type_LABELS.lifetime
                                    ]
                                }}
                            </div>
                            <ng-container
                                *ngIf="
                                    compensationForm?.controls?.effective_date_range?.controls?.type?.value ===
                                    date_range_type_SET.specific_dates
                                "
                            >
                                <div class="label">
                                    {{
                                        moment(
                                            compensationForm?.controls?.effective_date_range?.controls?.date_from?.value
                                        ).format('MMM DD')
                                    }}
                                </div>
                                <div class="label">-</div>
                                <div class="label">
                                    {{
                                        moment(
                                            compensationForm?.controls?.effective_date_range?.controls?.date_till?.value
                                        ).format('MMM DD')
                                    }}
                                </div>
                            </ng-container>
                            <div
                                *ngIf="
                                    compensationForm?.controls?.effective_date_range?.controls?.type?.value ===
                                    date_range_type_SET.days
                                "
                                class="label"
                            >
                                {{ compensationForm?.controls?.effective_date_range?.controls?.days?.value }}
                            </div>
                        </div>
                        <div class="label">
                            <u>{{ target_LABELS[compensationForm?.controls?.target?.value] }}</u>
                        </div>
                        <app-company-compensation-combined-picker
                            [hideControls]="true"
                            [nodesFA]="compensationForm.controls.apply_to!"
                        ></app-company-compensation-combined-picker>

                        <div class="remaining-space"></div>
                        <mat-icon
                            *ngIf="compensationForm?.controls?.type?.value === COMPENSATION_PROFILE.type_set.template"
                            style="color:black; margin-right: 8px"
                            matTooltip="Template Plan"
                            >rule
                        </mat-icon>
                        <mat-icon
                            *ngIf="compensationForm?.controls?.single_profile?.value === false"
                            style="color:black; margin-right: 8px"
                            matTooltip="Multilayer Plan Activated"
                            >layers
                        </mat-icon>
                        <mat-icon
                            *ngIf="compensationForm?.controls?.status?.value === COMPENSATION_PROFILE.status_set.active"
                            style="color:darkgreen; margin-right: 8px"
                            matTooltip="Active"
                            >attach_money
                        </mat-icon>
                        <mat-icon
                            *ngIf="compensationForm?.controls?.status?.value === COMPENSATION_PROFILE.status_set.draft"
                            style="color:darkred; margin-right: 8px"
                            matTooltip="Inactive"
                            >money_off
                        </mat-icon>

                        <div class="edit-indicator">
                            <button class="exp-button" *ngIf="!editMode" [disabled]="disabled" mat-icon-button>
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button class="exp-button" *ngIf="editMode" [disabled]="disabled" mat-icon-button>
                                <mat-icon>check_circle_outline</mat-icon>
                            </button>
                        </div>

                        <div style="display: flex; flex-direction: column; margin-top: -20px; margin-bottom: -20px">
                            <button
                                [disabled]="disabled || isSortBlacked"
                                mat-icon-button
                                *ngIf="show_up"
                                (click)="up.emit()"
                            >
                                <mat-icon id="order_up">arrow_upward</mat-icon>
                            </button>
                            <button
                                [disabled]="disabled || isSortBlacked"
                                mat-icon-button
                                *ngIf="show_down"
                                (click)="down.emit()"
                            >
                                <mat-icon id="order_down">arrow_downward</mat-icon>
                            </button>
                        </div>
                    </div>
                    <mat-divider style="position: unset"></mat-divider>
                    <div *ngIf="editMode" style="flex-direction: column; width:100%; padding: 16px 16px 0 16px;">
                        <div style="display: flex; flex-direction: row;">
                            <div style="display: flex; width: 90%">
                                <mat-form-field
                                    class="bold-input-placeholder"
                                    style="padding-right: 4px; width: 45%; min-width: 150px;"
                                    floatLabel="always"
                                >
                                    <input
                                        matInput
                                        placeholder="Profile Title"
                                        style="padding-right: 30px;"
                                        [formControl]="compensationForm!.controls.title!"
                                    />
                                    <mat-hint
                                        *ngIf="compensationForm?.controls?.public_title?.value"
                                        style="color: black;font-size: 12px;display: flex;"
                                    >
                                        {{ compensationForm?.controls?.public_title?.value }}
                                        <mat-icon
                                            class="ml-1"
                                            style="font-size: 14px;"
                                            matTooltip="This title is visible to ALL Assigned Users (ie Selecting Cap Widget Parameters)."
                                        >
                                            visibility
                                        </mat-icon>
                                    </mat-hint>
                                    <app-edit-title
                                        [titleFormControl]="compensationForm?.controls?.public_title!"
                                    ></app-edit-title>
                                </mat-form-field>
                                <app-company-compensation-combined-picker
                                    style="width: 45%; padding-right: 8px; min-width: 150px;"
                                    [title]="'Assign Profile To:'"
                                    [availableTypes]="['company_group', 'individuals_in_group', 'individual']"
                                    [nodesFA]="compensationForm.controls.apply_to!"
                                ></app-company-compensation-combined-picker>
                                <div
                                    style="display: flex; flex-direction: column; justify-content: center; width: 10%; min-width: 100px;"
                                >
                                    <div style="margin-right: 4px; width:150px">
                                        <button
                                            mat-button
                                            [matMenuTriggerFor]="dateRangeMenu"
                                            style="font-weight: 600; cursor: pointer;"
                                        >
                                            {{
                                                date_range_type_LABELS[
                                                    compensationForm?.controls?.effective_date_range?.controls?.type
                                                        ?.value
                                                ]
                                            }}
                                        </button>

                                        <mat-menu #dateRangeMenu="matMenu" [class]="'add-item-menu'">
                                            <button
                                                mat-menu-item
                                                *ngFor="let type of object_keys(date_range_type_SET)"
                                                (click)="
                                                    compensationForm?.controls?.effective_date_range?.controls?.type?.patchValue(
                                                        type
                                                    )
                                                "
                                            >
                                                {{ date_range_type_LABELS[type] }}
                                            </button>
                                        </mat-menu>
                                    </div>
                                    <div
                                        *ngIf="
                                            compensationForm?.controls?.effective_date_range?.controls?.type?.value ===
                                            date_range_type_SET.specific_dates
                                        "
                                        style="display: flex; flex-direction: column"
                                    >
                                        <mat-form-field style="margin-right: 4px; width:150px">
                                            <input
                                                matInput
                                                [matDatepicker]="date_from"
                                                name="date_from"
                                                [formControl]="
                                                    getFormControlFromGenericGroupDateRangeModel(
                                                        compensationForm!.controls.effective_date_range!.controls,
                                                        'date_from'
                                                    )
                                                "
                                                placeholder="Date From"
                                            />
                                            <mat-datepicker-toggle matSuffix [for]="date_from"></mat-datepicker-toggle>
                                            <mat-datepicker #date_from></mat-datepicker>
                                        </mat-form-field>
                                        <mat-form-field style="margin-right: 4px; width:150px">
                                            <input
                                                matInput
                                                [matDatepicker]="date_till"
                                                name="date_till"
                                                [formControl]="
                                                    getFormControlFromGenericGroupDateRangeModel(
                                                        compensationForm!.controls.effective_date_range!.controls,
                                                        'date_till'
                                                    )
                                                "
                                                placeholder="Date Till"
                                            />
                                            <mat-datepicker-toggle matSuffix [for]="date_till"></mat-datepicker-toggle>
                                            <mat-datepicker #date_till></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div
                                        *ngIf="
                                            compensationForm?.controls?.effective_date_range?.controls?.type?.value ===
                                            date_range_type_SET.days
                                        "
                                        style="display: flex; flex-direction: row"
                                    >
                                        <mat-form-field style="margin-right: 4px; width:150px">
                                            <input
                                                matInput
                                                type="number"
                                                pattern="^[1-9]\\d*$"
                                                name="days"
                                                placeholder="Effective Days"
                                                [formControl]="
                                                    compensationForm!.controls.effective_date_range!.controls.days!
                                                "
                                            />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div
                                style="display: flex; flex-direction:column; align-items: flex-end; width: 10%;min-width: 155px;"
                            >
                                <div
                                    style="display: flex; align-items: center;"
                                    *ngIf="
                                        compensationForm?.controls?.status?.value !==
                                        COMPENSATION_PROFILE.status_set.imported
                                    "
                                >
                                    <span style="margin-right: 8px">Active:</span>
                                    <mat-slide-toggle
                                        *ngIf="
                                            compensationForm?.controls?.status?.value !==
                                            COMPENSATION_PROFILE.status_set.imported
                                        "
                                        [formControl]="statusFC"
                                        (change)="onStatusChange($event)"
                                    ></mat-slide-toggle>
                                    <span
                                        *ngIf="
                                            compensationForm?.controls?.status?.value ===
                                            COMPENSATION_PROFILE.status_set.imported
                                        "
                                    >
                                        Imported
                                    </span>
                                </div>
                                <div
                                    style="display: flex; align-items: center;"
                                    *ngIf="
                                        enforcedEnabledFlag &&
                                        compensationForm?.controls?.type?.value !==
                                            COMPENSATION_PROFILE.type_set.template
                                    "
                                >
                                    <span style="margin-right: 8px">Enforce:</span>
                                    <mat-slide-toggle
                                        [formControl]="compensationForm!.controls.is_enforced!"
                                    ></mat-slide-toggle>
                                    <span
                                        *ngIf="
                                            compensationForm?.controls?.status?.value ===
                                            COMPENSATION_PROFILE.status_set.imported
                                        "
                                    >
                                        Imported
                                    </span>
                                </div>
                                <div
                                    style="display: flex; align-items: center; margin-left: 8px"
                                    [rule]="{company_settings__manage_cross_profile_compensation_elements: true}"
                                    rbac
                                    [denyMethod]="'style'"
                                    [allowStyle]="{display: 'flex'}"
                                    [denyStyle]="{display: 'none'}"
                                >
                                    <span style="margin-right: 8px">Single Profile:</span>
                                    <mat-slide-toggle
                                        [formControl]="compensationForm!.controls.single_profile!"
                                    ></mat-slide-toggle>
                                </div>
                                <div
                                    style="display: flex; align-items: center;"
                                    *ngIf="
                                        compensationForm?.controls?.status?.value ===
                                        COMPENSATION_PROFILE.status_set.imported
                                    "
                                >
                                    <span> Imported </span>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: column; margin-left: 28px">
                            <div style="display: flex; font-weight: bold; align-items: center; font-size: 16px;">
                                <span
                                    [matTooltip]="
                                        'These are the deal that will be affected, select criteria or leave blank to include all'
                                    "
                                    >Triggers</span
                                >

                                <button [disabled]="disabled" mat-icon-button [matMenuTriggerFor]="criteriaMenu">
                                    <mat-icon>add</mat-icon>
                                </button>

                                <mat-menu #criteriaMenu="matMenu" [class]="'add-item-menu'">
                                    <button
                                        mat-menu-item
                                        *ngFor="let filter of filtersToAdd"
                                        (click)="addFilter(filter.name)"
                                    >
                                        {{ filter.placeholder }}
                                    </button>
                                </mat-menu>
                            </div>

                            <div class="w-100 zp-pd-15" style="display: flex; flex-wrap: wrap">
                                <ng-container *ngFor="let filter of existingFilters; index as filterIndex">
                                    <app-deal-filter
                                        style="max-width: 210px;"
                                        *ngIf="filter && filter.name"
                                        [dealFilter]="filter"
                                        [control]="
                                            CompensationProfileFilter.getFilterFormControl(
                                                compensationForm!.controls.deal_filters!.controls,
                                                filter!.name!
                                            )
                                        "
                                        [nodesFA]="
                                            CompensationProfileFilter.getFilterFormArray(
                                                compensationForm!.controls.deal_filters!.controls,
                                                filter!.name!
                                            )!
                                        "
                                        (removeFilter)="removeFilter(filterIndex)"
                                    >
                                    </app-deal-filter>
                                </ng-container>
                            </div>

                            <!--                            <div style="display: flex; flex-direction: row">-->
                            <!--                                <app-company-compensation-filters [label]="'Default Parameters for Conditions'"-->
                            <!--                                                                  [viewType]="'menu'"-->
                            <!--                                                                  [filtersForm]="compensationForm.controls.deal_parameters"></app-company-compensation-filters>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>

            <div
                *ngIf="editMode"
                style="display: flex; font-weight: bold; align-items: center; margin-left: 28px; font-size: 16px; padding: 0 16px;"
            >
                Elements
                <button [disabled]="disabled" mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>add</mat-icon>
                </button>

                <mat-menu #menu="matMenu" [class]="'add-item-menu'">
                    <ng-container *ngFor="let type of object_keys(type_LABELS)">
                        <button
                            mat-menu-item
                            *ngIf="type !== 'compensation_expense' || compensationExpenseEnabledFlag"
                            (click)="doAddPerformanceCompensationProfile(type)"
                            [value]="type"
                        >
                            {{ type_LABELS[type] }}
                            <mat-icon
                                class="material-icons-outlined intradeal-icon"
                                matTooltip="If the element conditions dictate, it will split and calculate applicable financial rules within a single deal."
                                *ngIf="intradealable[type]"
                            >
                                assessment
                            </mat-icon>
                        </button>
                    </ng-container>
                </mat-menu>
            </div>

            <div *ngIf="editMode" style="padding: 0 16px;">
                <div
                    class="section-margin"
                    *ngFor="
                        let financialElementForm of compensationForm?.controls?.financial_elements?.controls;
                        index as pci;
                        last as last;
                        first as first
                    "
                >
                    <app-company-compensation-financial-element
                        [financialElementFG]="financialElementForm"
                        [disabled]="disabled"
                        [isDefaultConditionParameters]="pci === 0"
                        [defaultConditionParameters]="(defaultConditionParametersFG | async)?.getRawValue()"
                        [show_down]="!last"
                        [show_up]="!first"
                        (delete)="doDeletePerformanceCompensationProfile(pci)"
                        (up)="doMovePerformanceCompensationProfileUp(pci)"
                        (down)="doMovePerformanceCompensationProfileDown(pci)"
                    >
                    </app-company-compensation-financial-element>
                </div>
            </div>

            <div
                *ngIf="editMode && compensationForm?.controls?.note?.value"
                class="flex-row"
                style="align-items: center; justify-content: space-between; width: 100%; padding: 0 16px;"
            >
                {{ compensationForm?.controls?.note?.value }}
            </div>

            <div *ngIf="editMode" style="margin-top: 8px; padding: 0 16px 16px;">
                <div class="card-content">
                    <div class="flex-row" style="align-items: center; justify-content: space-between; width: 100%">
                        <div>
                            <button
                                mat-raised-button
                                [disabled]="disabled"
                                class="mat-warn"
                                (click)="doDeleteCompensationProfile()"
                            >
                                Delete
                            </button>
                        </div>
                        <div>
                            <button mat-raised-button class="mr-2" (click)="doCancelEditMode()">Cancel</button>
                            <button mat-raised-button [matMenuTriggerFor]="menu" class="mr-2">
                                Copy
                                <mat-icon>expand_more</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu" [class]="'add-item-menu'">
                                <div
                                    *ngFor="let type of typesAvailableForCreation"
                                    [matTooltipDisabled]="
                                        type !== COMPENSATION_PROFILE.type_set.template || checkCanBeClonedAsTemplate()
                                    "
                                    [matTooltip]="
                                        'Plan can not be cloned as template because 1 or more elements has types Sales Compensation, Royalty or User Royalty and not empty condition or more than 1 rule'
                                    "
                                >
                                    <button
                                        mat-menu-item
                                        [value]="type"
                                        [disabled]="
                                            type === COMPENSATION_PROFILE.type_set.template &&
                                            !checkCanBeClonedAsTemplate()
                                        "
                                        (click)="doCopyCompensationProfile(type)"
                                    >
                                        Copy as {{ typesLabels[type] }}
                                    </button>
                                </div>
                            </mat-menu>
                            <button *ngIf="createMode" mat-raised-button class="mat-primary mr-2" (click)="save.emit()">
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    `
})
export class CompensationProfileComponent implements OnInit, OnDestroy {
    CompensationProfileFilter = CompensationProfileFilter;
    typesAvailableForCreation: string[] = [
        CompensationProfileModel.type_set.default,
        CompensationProfileModel.type_set.template
    ];

    typesLabels = {
        [CompensationProfileModel.type_set.default]: 'Dynamic Plan',
        [CompensationProfileModel.type_set.template]: 'Template'
    };

    private unsubscribe: Subject<void> = new Subject();
    public statusFC = new UntypedFormControl(false);
    public date_range_type_SET = DateRangeModel.type_SET;
    public COMPENSATION_PROFILE = CompensationProfileModel;
    public DEAL = Deal;
    public date_range_type_LABELS: {[key: string]: string} = {
        lifetime: 'Lifetime',
        days: 'Days after Hiring',
        specific_dates: 'Specific Dates'
    };
    public target_LABELS: {[key: string]: string} = {
        company: 'Company',
        profiles_in_company: 'Profiles',
        groups_in_company: 'Groups',
        company_group: 'Group',
        profiles_in_group: 'Profiles',
        profile: 'Profile'
    };
    public status_LABELS: {[key: string]: string} = {
        draft: 'Draft',
        active: 'Active'
    };

    public filter_type_SET: {[key: string]: string} = Deal.type_SET;
    public filter_type_LABELS: {[key: string]: string} = {
        buyer: 'Buyer',
        seller: 'Seller'
    };

    public type_LABELS: {[key: string]: string} = {
        disbursement_instructions: 'CDA Disbursement Text',
        commission_categorization: 'Additional Commission',
        company_income_expense: 'Company Income/Expense',
        company_partner: 'Company Partner',
        incentive: 'Deal Incentive',
        company: 'Deal Income/Expense',
        overhead: 'Deal Overhead',
        referral: 'Deal Referral',
        royalty: 'Deal Royalty',
        disbursement_template: 'Entity Income/Expense',
        user_referral: 'Entity Referral',
        user_royalty: 'Entity Royalty',
        participant: 'Participant',
        agent: 'Sales Compensation',
        compensation_expense: 'Compensation Expense',
        sales: 'Sales Income/Expense',
        taxes: 'Taxes'
    };

    public moment = moment;

    public intradealable: {[key: string]: boolean} = FinancialElementModel.intradealable;
    public availableFilters: CompensationProfileFilter[] = [];
    public filtersToAdd: CompensationProfileFilter[] = [];
    public existingFilters: CompensationProfileFilter[] = [];
    _compensationForm: GenericFormGroup<CompensationProfileModel> = new GenericFormGroup<CompensationProfileModel>(
        new CompensationProfileModel()
    );
    enforcedEnabledFlag = false;
    compensationExpenseEnabledFlag = false;

    @Input() disabled: boolean = false;
    @Input() order: number = 0;
    @Input() editMode: boolean = false;
    @Input() createMode: boolean = false;
    @Input() isSortBlacked: boolean = false;
    @Input() set compensationForm(form: GenericFormGroup<CompensationProfileModel>) {
        this._compensationForm = form;
        const elemToDefault: GenericFormGroup<FinancialElementModel> =
            this._compensationForm.controls.financial_elements!.controls[0];
        if (elemToDefault) {
            this.defaultConditionParametersFG.next(elemToDefault.controls.commonRule!.controls.rule_filters!);
        }
    }
    get compensationForm() {
        return this._compensationForm!;
    }
    @Output() save = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @Output() override = new EventEmitter<CompensationProfileModel>();
    @Output() copyCompensationProfile = new EventEmitter();

    @Input() show_up: boolean = false;
    @Input() show_down: boolean = false;
    @Input() canOverride: boolean = false;

    @Output() up = new EventEmitter();
    @Output() down = new EventEmitter();
    defaultConditionParametersFG: BehaviorSubject<GenericFormGroup<MultipleTargetsDealsQueryModel> | null> =
        new BehaviorSubject<GenericFormGroup<MultipleTargetsDealsQueryModel> | null>(null);
    protected compensationProfileSnapshot: CompensationProfileModel | null = null;

    protected lastValue: MultipleTargetsDealsQueryModel = new MultipleTargetsDealsQueryModel();

    onStatusChange(event: MatSlideToggleChange) {
        if (event.checked) {
            this.compensationForm.controls.status!.patchValue(this.COMPENSATION_PROFILE.status_set.active);
        } else {
            this.compensationForm.controls.status!.patchValue(this.COMPENSATION_PROFILE.status_set.draft);
        }
    }

    doOverride(commProfile: CompensationProfileModel) {
        const dialogRef = this.dialog.open(OverrideDialogComponent);
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.override.emit(commProfile);
                }
            });
    }

    doEditCompensationProfile(event: any) {
        if (this.createMode || event.target.id === 'order_up' || event.target.id === 'order_down') {
            return;
        }
        this.editMode = !this.editMode;
    }

    doCancelEditMode() {
        if (!this.createMode) {
            this.compensationForm.patchValue(this.compensationProfileSnapshot!);
            this.editMode = false;
        } else {
            this.cancel.emit();
        }
    }

    doDeleteCompensationProfile() {
        this.delete.emit();
    }

    doCopyCompensationProfile(type: string) {
        // this.updateOrder();
        this.compensationService.setupCompensationProfileFormValidators(this.compensationForm);
        if (!this.compensationService.isCompensationProfileFormValid(this.compensationForm)) {
            return;
        }
        this.editMode = false;
        this.copyCompensationProfile.emit(type);
    }

    doAddPerformanceCompensationProfile(type: string) {
        const form = new GenericFormGroup(
            new FinancialElementModel().setIsTemplate(
                this.compensationForm.controls.type!.value === CompensationProfileModel.type_set.template
            )
        );
        form.get('type')!.setValue(type);
        form.get('type')!.disable();
        form.controls.sort_order!.patchValue(this.compensationForm.controls.financial_elements!.controls.length + 1, {
            emitEvent: false
        });
        // setting default Condition Parameters values
        if (this.defaultConditionParametersFG.value) {
            const defaultConditionParameters = this.defaultConditionParametersFG.value.getRawValue();
            delete defaultConditionParameters.used_default_values;
            form.controls.commonRule!.controls.rule_filters!.patchValue(defaultConditionParameters);
        }
        if (FinancialElementModel.checkIfElementCanBeUnenforced(form.controls.type!.value)) {
            if (!this.compensationForm.controls.is_enforced!.value) {
                form.controls.commonRule!.controls.is_prorated!.patchValue(false, {emitEvent: true});
                form.controls.commonRule!.controls.is_prorated!.disable();
                form.controls.rules!.controls.forEach((rule) => {
                    rule.controls.disbursement_template!.controls.additional_incomes!.controls.forEach((ftFg) => {
                        ftFg.controls.is_enforced!.patchValue(false, {emitEvent: false});
                    });
                    rule.controls.disbursement_template!.controls.additional_expenses!.controls.forEach((ftFg) => {
                        ftFg.controls.is_enforced!.patchValue(false, {emitEvent: false});
                    });
                });
            }
            form.controls.is_enforced!.patchValue(this.compensationForm.controls.is_enforced!.value, {
                emitEvent: false
            });
        } else {
            form.controls.is_enforced!.disable({emitEvent: false});
        }

        this.compensationForm.controls.financial_elements!.controls.push(form);
    }

    doMovePerformanceCompensationProfileUp(index: number) {
        const element = this.compensationForm.controls.financial_elements!.controls.splice(index, 1).pop();
        this.compensationForm.controls.financial_elements!.controls.splice(index - 1, 0, element!);
        this.updateOrder();
    }

    doMovePerformanceCompensationProfileDown(index: number) {
        const element = this.compensationForm.controls.financial_elements!.controls.splice(index, 1).pop();
        this.compensationForm.controls.financial_elements!.controls.splice(index + 1, 0, element!);
        this.updateOrder();
    }

    doDeletePerformanceCompensationProfile(index: number) {
        this.compensationForm.controls.financial_elements!.controls.splice(index, 1);
    }

    object_keys(obj: {[key: string]: any}): string[] {
        return Object.keys(obj);
    }

    equals(x: any, y: any) {
        if (x === y) {
            return true;
        }
        // if both x and y are null or undefined and exactly the same

        if (!(x instanceof Object) || !(y instanceof Object)) {
            return false;
        }
        // if they are not strictly equal, they both need to be Objects

        // if ( x.constructor !== y.constructor ){
        //     console.log('constructor');
        //     return false;
        // }
        // // they must have the exact same prototype chain, the closest we can do is
        // // test there constructor.

        for (const p in x) {
            if (!x.hasOwnProperty(p)) {
                continue;
            }
            // other properties were tested using x.constructor === y.constructor

            if (!y.hasOwnProperty(p)) {
                return false;
            }
            // allows to compare x[ p ] and y[ p ] when set to undefined

            if (x[p] === y[p]) {
                continue;
            }
            // if they have the same strict value or identity then they are equal

            if (typeof x[p] !== 'object') {
                return false;
            }
            // Numbers, Strings, Functions, Booleans must be strictly equal

            if (!this.equals(x[p], y[p])) {
                return false;
            }
            // Objects and Arrays must be tested recursively
        }

        for (const p in y) {
            if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
                return false;
            }
            // allows x[ p ] to be set to undefined
        }
        return true;
    }

    updateOrder() {
        this.compensationForm.controls.financial_elements!.controls.forEach((element, i, array) => {
            element.controls.sort_order!.patchValue(array.length - i);
        });
    }

    ngOnInit() {
        this.compensationProfileSnapshot = this.compensationForm.getRawValue();
        this.updateOrder();
        this.compensationForm.controls
            .effective_date_range!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((value) => {
                const result = new DateRangeModel();
                switch (value.type) {
                    case DateRangeModel.type_SET.days:
                        result.days = value.days;
                        result.type = DateRangeModel.type_SET.days;
                        result.date_from = new Date();
                        result.date_till = new Date();
                        result.date_till.setDate(result.date_till.getDate() + value.days);
                        break;
                    case DateRangeModel.type_SET.specific_dates:
                        result.days = value.days;
                        result.date_from = new Date(value.date_from);
                        result.date_till = new Date(value.date_till);
                        result.type = DateRangeModel.type_SET.specific_dates;
                        break;
                    case DateRangeModel.type_SET.lifetime:
                        result.days = value.days;
                        result.type = DateRangeModel.type_SET.lifetime;
                        result.date_till = null;
                        result.date_from = null;
                        break;
                    default:
                        break;
                }
                this.compensationForm.controls.effective_date_range!.patchValue(result, {emitEvent: false});
            });

        // this.compensationForm.controls.deal_parameters.valueChanges
        //     .pipe(takeUntil(this.unsubscribe))
        //     .subscribe((newValue: MultipleTargetsDealsQueryModel) => {
        //         this.compensationForm.controls.financial_elements.controls.forEach(financialElementFG => {
        //             if (this.equals(Object.assign(new MultipleTargetsDealsQueryModel(), financialElementFG.controls.commonRule.controls.rule_filters.getRawValue()), this.lastValue)) {
        //                 financialElementFG.controls.commonRule.controls.rule_filters.patchValue(newValue);
        //             }
        //         });
        //         this.compensationProfileSnapshot = this.compensationForm.getRawValue();
        //         this.lastValue = Object.assign(new MultipleTargetsDealsQueryModel(), newValue);
        //     });

        if (this.compensationForm.controls.status!.value === CompensationProfileModel.status_set.active) {
            this.statusFC.patchValue(true, {emitEvent: false});
        }

        if (this.compensationForm.controls.status!.value === CompensationProfileModel.status_set.draft) {
            this.statusFC.patchValue(false, {emitEvent: false});
        }

        combineLatest([
            this.store.pipe(select(selectProcessedSOB)),
            this.store.pipe(select(selectMembershipOrganizations))
        ])
            .pipe(
                map(([sob, orgList]) => {
                    const sourceOfBusinessFilter = this.availableFilters.find(
                        (filter) => filter.name === 'source_of_business'
                    );
                    if (typeof sourceOfBusinessFilter !== 'undefined') {
                        sourceOfBusinessFilter.options = [
                            {
                                title: 'None',
                                value: 'null' // yes, it should be a string to correctly compare in backend
                            }
                        ];
                        sourceOfBusinessFilter.options = sourceOfBusinessFilter.options.concat(
                            sob.sourceList.map((sob) => {
                                return {
                                    title: sob.label!,
                                    value: sob.label
                                };
                            })
                        );
                    }

                    const organisationsFilter = this.availableFilters.find(
                        (filter) => filter.name === 'mls_organisations'
                    );
                    if (typeof organisationsFilter !== 'undefined') {
                        organisationsFilter.options = orgList.map((organisation) => {
                            return {
                                title: organisation.title!,
                                value: organisation.id
                            };
                        });
                    }
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.compensationProfileSnapshot = this.compensationForm.getRawValue();
                this.filtersToAdd = this.availableFilters.slice(); // slice() just to "clone" value
                this.existingFilters = [];
                this.object_keys(this.compensationProfileSnapshot.deal_filters).forEach((filterName: string) => {
                    const foundFilter = this.filtersToAdd.find((filter) => filter.name === filterName);
                    if (
                        typeof foundFilter !== 'undefined' &&
                        // @ts-ignore
                        this.compensationProfileSnapshot.deal_filters[filterName] !== null
                    ) {
                        if (filterName === 'apply_to') {
                            if (this.compensationProfileSnapshot!.deal_filters[filterName].length) {
                                this.filtersToAdd.splice(this.filtersToAdd.indexOf(foundFilter), 1);
                                this.existingFilters.push(foundFilter);
                            }
                        } else {
                            this.filtersToAdd.splice(this.filtersToAdd.indexOf(foundFilter), 1);
                            this.existingFilters.push(foundFilter);
                        }
                        this.existingFilters.sort(CompensationProfileFilter.compareFiltersByOrder);
                    }
                });
            });

        // Detecting elements order changes to get default values for condition parameters
        combineLatest(
            this.compensationForm.controls.financial_elements!.controls.map(
                (feFg) => feFg.controls.sort_order!.valueChanges
            )
        )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((changes) => {
                const elemToDefault: GenericFormGroup<FinancialElementModel> =
                    this._compensationForm!.controls.financial_elements!.controls[0];
                elemToDefault.controls.commonRule!.controls.rule_filters!.controls.used_default_values!.patchValue(
                    false
                );
                if (elemToDefault) {
                    this.defaultConditionParametersFG.next(elemToDefault.controls.commonRule!.controls.rule_filters!);
                }
            });

        // Detecting default Condition Parameters values changes ​​to change all elements that use default values for the condition parameters
        this.defaultConditionParametersFG
            .pipe(
                switchMap((feFg) => (feFg ? feFg.valueChanges.pipe(startWith(feFg.getRawValue())) : of(null))),
                takeUntil(this.unsubscribe)
            )
            .subscribe((defaultConditionParameters) => {
                if (defaultConditionParameters) {
                    delete defaultConditionParameters.used_default_values;
                    this.compensationForm.controls
                        .financial_elements!.controls.filter(
                            (feFg) =>
                                feFg.controls.commonRule!.controls.rule_filters!.controls.used_default_values!.value
                        )
                        .forEach((feFg) =>
                            feFg.controls.commonRule!.controls.rule_filters!.patchValue(defaultConditionParameters, {
                                emitEvent: false
                            })
                        );
                }
            });

        this.compensationForm.controls.financial_elements!.controls.forEach((feFg) => {
            if (!FinancialElementModel.checkIfElementCanBeUnenforced(feFg.controls.type!.value)) {
                feFg.controls.is_enforced!.patchValue(true, {emitEvent: false});
                feFg.controls.is_enforced!.disable({emitEvent: false});
            }
        });

        this.compensationForm.controls
            .is_enforced!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((is_enforced) => {
                this.compensationForm.controls.financial_elements!.controls.forEach((feFg) => {
                    if (
                        FinancialElementModel.checkIfElementCanBeUnenforced(feFg.controls.type!.value) &&
                        !feFg.controls.is_enforced!.disabled
                    ) {
                        feFg.controls.is_enforced!.patchValue(is_enforced);
                        feFg.controls.commonRule!.controls.is_prorated!.patchValue(is_enforced, {emitEvent: true});
                        if (is_enforced) {
                            feFg.controls.commonRule!.controls.is_prorated!.enable();
                        } else {
                            feFg.controls.commonRule!.controls.is_prorated!.disable();
                        }
                        feFg.controls.rules!.controls.forEach((rule) => {
                            rule.controls.disbursement_template!.controls.additional_incomes!.controls.forEach(
                                (ftFg) => {
                                    ftFg.controls.is_enforced!.patchValue(is_enforced, {emitEvent: false});
                                }
                            );
                            rule.controls.disbursement_template!.controls.additional_expenses!.controls.forEach(
                                (ftFg) => {
                                    ftFg.controls.is_enforced!.patchValue(is_enforced, {emitEvent: false});
                                }
                            );
                        });
                    } else {
                        feFg.controls.is_enforced!.disable({emitEvent: false});
                    }
                });
            });
    }

    addFilter(filterName: string | null) {
        const foundFilter = this.filtersToAdd.find((filter) => filter.name === filterName);
        if (typeof foundFilter !== 'undefined') {
            this.filtersToAdd.splice(this.filtersToAdd.indexOf(foundFilter), 1);
            this.existingFilters.push(foundFilter);
            this.existingFilters.sort(CompensationProfileFilter.compareFiltersByOrder);
        }
    }

    removeFilter(filterIndex: number) {
        const filterToDelete = this.existingFilters[filterIndex];
        if (filterToDelete.name !== 'apply_to') {
            this.compensationForm.get('deal_filters')!.get(filterToDelete.name!)!.setValue(null);
        } else {
            const formArray = this.compensationForm.get('deal_filters')!.get(filterToDelete.name) as UntypedFormArray;
            formArray.clear();
        }
        this.filtersToAdd.push(this.existingFilters[filterIndex]);
        this.filtersToAdd.sort(CompensationProfileFilter.compareFiltersByOrder);
        this.existingFilters.splice(filterIndex, 1);
    }

    hasChanges(): boolean {
        if (this.createMode) {
            return false;
        } else if (!this.compensationProfileSnapshot!.id) {
            return true;
        }
        if (this.compensationForm && this.compensationProfileSnapshot) {
            return !_.isEqual(this.compensationForm.getRawValue(), this.compensationProfileSnapshot);
        }
        return false;
    }

    constructor(
        protected compensationService: CompensationService,
        protected dialog: MatDialog,
        protected notificationService: NotificationsServiceZipi,
        private store: Store<ICompanyWideState>,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.availableFilters = CompensationProfileFilter.available_deals_filters_SET.sort(
            CompensationProfileFilter.compareFiltersByOrder
        );

        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.enforcedEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'rules:not_enforced_compensation_profiles'
                );
                this.compensationExpenseEnabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'rules:compensation_expense_element'
                );
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    checkCanBeClonedAsTemplate() {
        return this.compensationForm.controls.financial_elements!.controls.every((financialElementFg) => {
            // @ts-ignore
            if (!FinancialElementModel.intradealable[financialElementFg.controls.type!.value]) {
                return true;
            }
            return (
                !financialElementFg.controls.commonRule!.controls.type!.value &&
                !financialElementFg.controls.commonRule!.controls.operator!.value &&
                financialElementFg.controls.rules!.controls.length <= 1
            );
        });
    }

    getFormControlFromGenericGroupDateRangeModel(
        controls: {[Property in keyof DateRangeModel]?: GenericControl<DateRangeModel[Property]>},
        controlName: keyof DateRangeModel
    ): UntypedFormControl {
        return controls[controlName] as UntypedFormControl;
    }
}

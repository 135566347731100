// import {EditDealCommunicationComponent} from '../common/communication.component';
import {ChangeDetectorRef, Component, EventEmitter, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonListOfFieldsDialogComponent} from '../common/common-list-of-fields-dialog/common-list-of-fields-dialog.component';
import {NotificationsServiceZipi} from '../../../../notifications/notifications.service';
import {UntypedFormControl, Validators} from '@angular/forms';
import {Client} from '../../../../../models/client';
import {DealService} from '../../../../../services/deal.service';
import {Deal, DEAL_SYSTEM_STATUS} from '../../../../../models/deal';
import {SourceOfBusiness} from '../../../../../models/source-of-business';
import {SalesEntity} from '../../../../../models/sales-entity';
import {GenericEntity, GenericFormGroup} from '../../../../../entites/generic.entity';
import {ListItem} from '../../../../../models/calculation';
import {merge as observableMerge, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {INotificationModel, MessagesService} from '../../../../messages/messages.service';
import {UserNotificationService} from '../../../../user-notification/user-notification.service';
import {NotificationsService as Angular2Notifications} from 'angular2-notifications';
import type {Notification as Angular2Notification} from 'angular2-notifications';

import {DealProcessingService} from '../deal-processing.service';
import {Title} from '@angular/platform-browser';
import {CompensationProfileModel} from '../../../../account-info/compensation/models/compensation-profile.model';
import {CompensationServiceApi} from '../../../../account-info/compensation/compensation.service';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {ConfirmComponent} from '../../../../../layouts/confirm/confirm.component';
import {DisbursementText} from '../../../../../models/disbursement-text';
import {FetchProducts} from '../../../../finance/store/finance.actions';
import {select, Store} from '@ngrx/store';
import {selectProducts} from '../../../../finance/store/finance.selectors';
import {IProduct} from '@cyberco-nodejs/zipi-typings';
import {DeleteDealsWarningComponent} from './index/delete-deals-warning/delete-deals-warning.component';
import {UiModsApi} from '../../../../ui-mods/api/ui-mods.api';
import {UiModsSource} from '../../../../ui-mods/ui-mods.source';
import {ComponentCanDeactivate} from '../../../../../services/resolvers/pending-changes-guard.service';
import {UpdateSOB} from '../../../../../store/company-wide/company-wide.actions';
import {DealSystemNote} from '../../../../../models/deal-system-note';
import {AlertComponent} from '../../../../../layouts/alert/alert.component';
import {RuleModel} from '../../../../account-info/compensation/models/rule.model';
import {FinancialElementModel} from '../../../../account-info/compensation/models/financial-element.model';
import {SnapshotAction} from '@angular/fire/compat/database/interfaces';
import {ContactPartLink} from '../../../../../models/contact-part-link';
import {DealDisbursementComponent} from '../common/disbursement.component';
import {FeatureFlagsService} from '../../../../feature-flags/feature-flags.service';
import * as Sentry from '@sentry/angular-ivy';
import {DealEventsService} from '../common/deal-events/deal-events.service';

@Component({
    selector: 'app-edit-deal',
    styleUrls: ['../common/edit-deal.component.scss'],
    templateUrl: 'edit-deal.component.html'
})
export class EditDealComponent implements ComponentCanDeactivate, OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    // @ViewChild('communication') communication: EditDealCommunicationComponent = new EditDealCommunicationComponent();

    pdfOutdated: boolean = false;

    dealFormGroup: GenericFormGroup<Deal> = new GenericFormGroup(new Deal(), 'change');
    // updated_at = +new Date();
    sourcesOfBusiness: SourceOfBusiness[] = [];
    isDealConnectedToSkyslope: boolean = false;
    public dealIdInUrl: string | null = null;
    public componentReady: boolean = false;
    canCreateDualDeal: boolean = true;
    recalculate: any;
    isProcessBarClosed: boolean;
    isSuccessBarClosed: boolean;

    saveDisabled: boolean = false;
    showRefreshButton: boolean = false;
    overrideBtnDisabled: boolean = true;
    payoutsBtnDisabled: boolean = true;
    isDealOverridden: boolean = false;
    isDealLoaded: boolean = false;

    isSidebarOpen: boolean = false;
    currentSidebarTab: string | null = '';
    salesEntities: SalesEntity[] = [];
    financialElementsBySalesEntity: {[index: number]: FinancialElementModel[]} = {};
    public financialElementsBySalesEntityFC: UntypedFormControl = new UntypedFormControl();
    isPreviousNameMatched: boolean = true;

    product$: Observable<IProduct[]> = new Observable<IProduct[]>();

    openDeposit: boolean = false;
    showDepositButtonNeed: boolean = true;

    DEAL = Deal;

    showDismissWarningEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

    dealSnapshot: Deal = new Deal();
    afterDealSaving$: Subject<string> = new Subject<string>();
    checkCommissionPayer$: Subject<void> = new Subject<void>();
    deleting: boolean = false;
    isNewEditDealPageAllowed: boolean = false;
    showDealSelfTestingInterface: boolean = false;
    showSideCountAndSalesVolumeFlag: boolean = false;

    unsavedDealStatus: 'draft' | 'open' | undefined;

    @ViewChild('disbursement') disbursement: DealDisbursementComponent | undefined;

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        // do not allow to save approved deal
        if (this.dealFormGroup.controls.disbursement_approved!.value || this.deleting) {
            return true;
        }
        // before leaving page system compare snapshot and current deal on existing any changes
        // if (this.dealSnapshot) {
        //     return this.dealProcessing.isDealObjectsEqual(this.dealSnapshot, this.dealFormGroup.getRawValue());
        // }
        return !this.dealFormGroup.dirty;
    }

    saveChanges() {
        // this method is needed for guard CanDeactivate
        this.save(false, true);
    }

    setSalesEntities(doRefresh: boolean = true) {
        if (this.dealFormGroup.controls.id!.value) {
            const deal = this.dealFormGroup.getRawValue();

            // this.salesEntities = deal.sales_entities.slice();
            this.salesEntities = deal.sales_entities.filter((se) => se.role === SalesEntity.role_SET.agent);

            const primarySE = this.salesEntities.find((el) => el.is_primary === true);
            if (primarySE) {
                const currentSE = this.salesEntities.find((el) => el.id === primarySE.id);
                if (currentSE) {
                    this.isDealOverridden = !!currentSE.overriden__compensation_profile_fk_id;
                }
            }

            this.salesEntities.forEach((salesEntity) => {
                this.financialElementsBySalesEntity[salesEntity.id!] = salesEntity.applied;
            });

            // do apply-splits
            if (doRefresh) {
                this.doRefresh(null, true);
            }
            // this.dealService.applySplitsToDeal(deal)
            //     .then(modified => {
            //         modified.sales_entities.forEach(salesEntity => {
            //             this.financialElementsBySalesEntity[salesEntity.id] = salesEntity.applied;
            //         });
            //     });
        }
    }

    doRefresh(event: void | null, doNotRefresh: boolean = false) {
        if (
            (this.dealFormGroup.controls.system_status!.value === DEAL_SYSTEM_STATUS.open ||
                this.dealFormGroup.controls.system_status!.value === DEAL_SYSTEM_STATUS.approved) &&
            this.dealFormGroup.controls.id!.value &&
            this.componentReady
        ) {
            if (!this.dealProcessing.isDealFormValid()) {
                return;
            }

            this.dealProcessing.syncFinancialTransfers();
            const deal = this.dealFormGroup.getRawValue();
            deal.flat_fee_commissions = this.dealFormGroup.controls.flat_fee_commissions!.getEnabledValues();
            // we do not run recalculations if there empty close_of_escrow
            if (!deal.close_of_escrow || !deal.type) {
                return;
            }

            this.dealFormGroup.disable({emitEvent: false});

            // reset applied if disbursement is not approved
            let sales_entities: any[] = deal.sales_entities;
            // we need this because when disbursement approved, applySplits will return empty arrays for Applied property of the Sales Entity
            // but we need to display applied compensation profiles in Override component
            if (!deal.disbursement_approved) {
                sales_entities = deal.sales_entities.map((se) => ({...se, applied: []}));
            }

            this.overrideBtnDisabled = true;
            this.dealFormGroup.controls.disbursement_text_template!.reset(new DisbursementText(), {emitEvent: false});
            this.dealService
                .applySplitsToDeal({...deal, sales_entities} as any)
                .then((modified) => {
                    // remove duplicate conditions in applied element rules
                    modified.sales_entities = (modified.sales_entities as any).map((se: SalesEntity) => ({
                        ...se,
                        applied: se.applied.map((finEl) => ({
                            ...finEl,
                            element: {
                                ...finEl.element,
                                rules: finEl.element.rules.map((rule: RuleModel) => ({
                                    ...rule,
                                    and_conditions:
                                        // if type, value, operator are identical - remove first condition
                                        rule.and_conditions[0].type === rule.type &&
                                        rule.and_conditions[0].value === rule.value &&
                                        rule.and_conditions[0].operator === rule.operator
                                            ? rule.and_conditions.slice(1)
                                            : rule.and_conditions
                                }))
                            }
                        }))
                    }));

                    this.overrideBtnDisabled = false;
                    modified.sales_entities.forEach((salesEntity) => {
                        this.financialElementsBySalesEntity[salesEntity.id!] = salesEntity.applied;
                    });

                    this.financialElementsBySalesEntityFC.patchValue(this.financialElementsBySalesEntity);

                    this.showRefreshButton = false;
                    this.changeDetector.detectChanges();
                    if (!modified.disbursement_approved) {
                        this.dealFormGroup.enable({emitEvent: false});
                    }
                    this.updateCommissionCategorization(modified);

                    if (modified.disbursement_text_template) {
                        this.dealFormGroup.controls.disbursement_text_template!.patchValue(
                            modified.disbursement_text_template
                        );
                        this.dealFormGroup.controls.disbursement_text_template!.disable({emitEvent: false});
                    }

                    if (!doNotRefresh) {
                        this.dealFormGroup.controls.company_calculation!.patchValue(modified.company_calculation, {
                            emitEvent: false
                        });
                        this.dealFormGroup.controls.sales_entities!.patchValue([], {emitEvent: false});
                        this.dealFormGroup.controls.sales_entities!.patchValue(modified.sales_entities, {
                            emitEvent: false
                        });
                        this.dealFormGroup.controls.financial_transfers!.patchValue([], {emitEvent: false});
                        this.dealFormGroup.controls.financial_transfers!.patchValue(modified.financial_transfers, {
                            emitEvent: false
                        });
                        this.dealFormGroup.updateValueAndValidity({emitEvent: true});
                    }
                    this.dealProcessing.syncFinancialTransfers();
                    this.dealFormGroup.controls.sales_entities!.controls.forEach((salesEntityFG) => {
                        if (salesEntityFG.controls.is_primary!.value) {
                            salesEntityFG.controls.participant_split!.controls.percent!.disable({emitEvent: false});
                            salesEntityFG.controls.participant_split!.controls.value_type!.disable({emitEvent: false});
                        }
                        salesEntityFG.controls.contact!.controls.disbursement_instructions!.disable({emitEvent: false});
                    });
                    this.dealFormGroup.controls.system_notes!.patchValue(modified.system_notes, {emitEvent: false});
                    this.checkSystemNotes();
                    this.dealFormGroup.controls.self_testing!.patchValue(modified.self_testing);
                })
                .catch((err) => {
                    console.error('ERROR', err);

                    this.dealFormGroup.enable({emitEvent: false});
                });
        }
    }

    constructor(
        public dealService: DealService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        protected notificationService: NotificationsServiceZipi,
        private dealEventsService: DealEventsService,
        protected ng2Notifications: Angular2Notifications,
        public router: Router,
        protected messagesService: MessagesService,
        private userNtfs: UserNotificationService,
        public dealProcessing: DealProcessingService,
        protected titleService: Title,
        protected compensationServiceApi: CompensationServiceApi,
        private store: Store<any>,
        protected uiModsApi: UiModsApi,
        protected uiModsSource: UiModsSource,
        private changeDetector: ChangeDetectorRef,
        private featureFlagsService: FeatureFlagsService
    ) {
        this.dealProcessing.setupDeal(new Deal());
        this.dealFormGroup = this.dealProcessing.dealFG;
        this.financialElementsBySalesEntity = this.dealProcessing.financialElementsBySalesEntity;
        this.financialElementsBySalesEntityFC = this.dealProcessing.financialElementsBySalesEntityFC;

        observableMerge(
            this.dealFormGroup.controls.apply_rules!.valueChanges,
            this.dealFormGroup.controls.close_of_escrow!.valueChanges,
            this.dealFormGroup.controls.sales_price!.valueChanges,
            this.dealFormGroup.controls.sales_price_adjustment!.valueChanges,
            this.dealFormGroup.controls.type!.valueChanges,
            this.dealFormGroup.controls.status!.valueChanges,
            this.dealFormGroup.controls.income_commission_value!.valueChanges,
            this.dealFormGroup.controls.income_flat_commission_value!.valueChanges,
            this.dealFormGroup.controls.income_commission_type!.valueChanges,
            this.dealFormGroup.controls.referral_fee_value!.valueChanges,
            this.dealFormGroup.controls.referral_fee_type!.valueChanges,
            this.dealFormGroup.controls.company_calculation!.valueChanges,
            this.dealFormGroup.controls.financial_transfers!.valueChanges
        )
            .pipe(
                takeUntil(this.unsubscribe)
                // debounce(() => timer(5000))
            )
            .subscribe((changes) => {
                // console.log(changes);
                this.showRefreshButton = true;
                this.pdfOutdated = true;
                this.changeDetector.detectChanges();
            });

        this.dealFormGroup.controls
            .sales_entities!.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((salesEntites) => {
                this.showRefreshButton = true;
                this.pdfOutdated = true;
                this.changeDetector.detectChanges();
                // update select at override menu
                this.setSalesEntities(false);
            });

        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isNewEditDealPageAllowed = this.featureFlagsService.isFeatureEnabled('deals:new_edit_deal_page');
                this.showDealSelfTestingInterface =
                    this.featureFlagsService.isFeatureEnabled('deals:self_testing_interface');
                this.showSideCountAndSalesVolumeFlag = this.featureFlagsService.isFeatureEnabled(
                    'deals:show_side_count_and_sales_volume'
                );
            });

        this.isProcessBarClosed = true;
        this.isSuccessBarClosed = true;
    }

    updateCommissionCategorization(modified: Deal) {
        this.dealFormGroup.controls.income_flat_commission_value!.patchValue(modified.income_flat_commission_value, {
            emitEvent: false
        });
        this.dealFormGroup.controls.commission_categorization_template!.patchValue(
            modified.commission_categorization_template,
            {emitEvent: false}
        );
        if (modified.commission_categorization_template.money_list.length >= 0) {
            this.dealFormGroup.controls.flat_fee_commissions!.controls = new Array<GenericFormGroup<ListItem>>().concat(
                modified.commission_categorization_template.money_list
                    .map(
                        (item) =>
                            new GenericFormGroup<ListItem>(
                                {
                                    name: item.label,
                                    value: item.amount
                                },
                                'change'
                            )
                    )
                    .map((control) => {
                        control.disable({emitEvent: false});
                        return control;
                    }),
                modified.flat_fee_commissions.map((item) => new GenericFormGroup<ListItem>(item))
            );
        }
        this.dealFormGroup.controls.flat_fee_commissions!.updateValueAndValidity();
    }

    private _initDealEvents() {
        const eventThreads: {
            threadType: string;
            currentStage: string;
            currentNotification: Angular2Notification;
            events: object[];
        }[] = [];

        this.dealEventsService
            .watchDealEvents(Number(this.dealIdInUrl))
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((event: any) => {
                switch (event.eventType) {
                    case 'syncWithSkyslopeRequested':
                        switch (event.meta.sync_stage) {
                            case 'initiated': {
                                let n = this.ng2Notifications.info('Refresh', 'Refresh in progress, please wait.', {
                                    timeOut: 0
                                });
                                eventThreads.push({
                                    threadType: 'syncWithSkyslopeRequested',
                                    currentStage: event.meta.sync_stage,
                                    currentNotification: n,
                                    events: [event]
                                });
                                break;
                            }
                            case 'started': {
                                // commented out because QA requested to avoid showing an extra step. Just "start" and "end" are needed for now.
                                // let targetThread = eventThreads.find(et => et.threadType==='syncWithSkyslopeRequested' && et.currentStage==='initiated');
                                // let n;
                                // if (targetThread) {
                                //     this.ng2Notifications.remove(targetThread.currentNotification.id);
                                //     n = this.ng2Notifications.info('Refresh', 'Started...', {timeOut: 0});
                                //     targetThread.currentNotification = n;
                                //     targetThread.currentStage = event.meta.sync_stage;
                                //     targetThread.events.push(event);
                                // } else {
                                //     n = this.ng2Notifications.info('Refresh', 'Started...', {timeOut: 0});
                                //     eventThreads.push({
                                //         threadType: 'syncWithSkyslopeRequested',
                                //         currentStage: event.meta.sync_stage,
                                //         currentNotification: n,
                                //         events: [event]
                                //     });
                                // }

                                break;
                            }
                            case 'finished': {
                                let targetThread = eventThreads.find(
                                    (et) =>
                                        et.threadType === 'syncWithSkyslopeRequested' && et.currentStage === 'started'
                                );

                                if (!targetThread) {
                                    targetThread = eventThreads.find(
                                        (et) =>
                                            et.threadType === 'syncWithSkyslopeRequested' &&
                                            et.currentStage === 'initiated'
                                    );
                                }

                                let n;
                                if (targetThread) {
                                    this.ng2Notifications.remove(targetThread.currentNotification.id);
                                }
                                if (event.meta.status === 'success') {
                                    n = this.ng2Notifications.success(
                                        'Refresh',
                                        'Refresh is complete. Please reload the page.',
                                        {timeOut: 0}
                                    );

                                    // AntonV: we don't want to refresh dealForm anymore.
                                    // Primary reason is that it works incorrectly in current Edit deal page.
                                    // And we will revisit it as part of the new Edit deal page.
                                    // this.initDeal();
                                } else if (event.meta.status === 'error') {
                                    n = this.ng2Notifications.error('Refresh', 'Refresh failed, try again.');
                                } else {
                                    n = this.ng2Notifications.info(
                                        'Refresh',
                                        event.eventType + ' : ' + event.meta.sync_stage,
                                        {timeOut: 0}
                                    );
                                }

                                if (targetThread) {
                                    targetThread.currentNotification = n;
                                    targetThread.currentStage = event.meta.sync_stage;
                                    targetThread.events.push(event);
                                } else {
                                    eventThreads.push({
                                        threadType: 'syncWithSkyslopeRequested',
                                        currentStage: event.meta.sync_stage,
                                        currentNotification: n,
                                        events: [event]
                                    });
                                }

                                break;
                            }
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
            });
    }

    async ngOnInit() {
        // const ST_EditDeal = Sentry.startTransaction({ name: "T_EditDeal" });
        this.dealIdInUrl = this.route.snapshot.paramMap.get('id');

        // const SS_ngOnInit = ST_EditDeal.startChild({
        //     op: 'S_ngOnInit',
        //     data: {
        //         deal_id: this.dealIdInUrl
        //     },
        //     description: `Execute ngOnInit for Edit Deal page`,
        // });

        this.dealProcessing.saveButtonStateChange.pipe(takeUntil(this.unsubscribe)).subscribe((state) => {
            if (state === 'enabled') {
                this.saveDisabled = false;
            } else if (state === 'disabled') {
                this.saveDisabled = true;
            }
        });

        this.store.dispatch(new FetchProducts());
        this.product$ = this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe));

        if (!this.dealIdInUrl) {
            this.router.navigate(['/default-page']);
            return;
        }

        await this.initDeal();
        this._initDealEvents();

        // by default all badges are disabled, to enable badges for this deal we need to run this request
        // after all badges list was updated it needs to do this request AGAIN
        this.uiModsSource.uiMods.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.uiModsApi.loadUiModsByDeal(Number(this.dealIdInUrl)).then((res) => {
                if (res && res.length) {
                    for (const uiModInstance of res) {
                        if (
                            uiModInstance.uiMod &&
                            uiModInstance.uiMod.addon &&
                            uiModInstance.uiMod.addon.slug === 'skyslope_integration'
                        ) {
                            this.isDealConnectedToSkyslope = true;
                            break;
                        }
                    }
                    this.uiModsSource.updateDealBadgesInList(
                        res.map((badge) => this.uiModsSource.buildUiModDealBadgeInList(badge, false))
                    );
                }
            });
        });

        this.dealSnapshot = this.dealFormGroup.getRawValue();
        // ST_EditDeal.setTag('is_deal_approved_in_t', this.dealSnapshot.disbursement_approved);
        // SS_ngOnInit.setTag('is_deal_approved', this.dealSnapshot.disbursement_approved);

        // Workaround for the deal changes detection. We receive financial transfers with amount = 0 from the api,
        // but at the ui after apply splits such transfers will have amount = null.
        // Can be removed after fixing described issue.
        this.dealSnapshot.financial_transfers = this.dealSnapshot.financial_transfers.map((transfer) => {
            transfer.amount = transfer.amount !== 0 ? transfer.amount : null;
            return transfer;
        });
        // After apply splits 0 is transformed to null
        // Need investigating why it is happened
        this.dealSnapshot.sales_entities = this.dealSnapshot.sales_entities.map((se) => {
            se.payout.amount = se.payout.amount !== 0 ? se.payout.amount : null;
            return se;
        });

        // auto-build deal name and watch changes
        observableMerge(
            this.dealFormGroup.controls.address!.valueChanges,
            this.dealFormGroup.controls.client_type!.valueChanges,
            this.dealFormGroup.controls.deal_participants!.valueChanges
        )
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((changes) => {
                if (this.isPreviousNameMatched) {
                    const address = this.dealFormGroup.controls.address!.value
                        ? this.dealFormGroup.controls.address!.value
                        : '';
                    const client = this._createParticipantsForDealName();

                    this.dealFormGroup.controls.name!.patchValue((address + (client ? ` ${client}` : '')).trim());
                }
            });
        this.dealFormGroup.controls.name!.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((changes) => {
            this._checkIfDealNameMatchedRule();
        });

        if (this.dealFormGroup.controls.disbursement_approved?.value) {
            this.disableDealFieldsIfApproved(this.dealFormGroup.controls.disbursement_approved?.value);
        }
        // SS_ngOnInit.setStatus('ok');
        // SS_ngOnInit.finish();
        // ST_EditDeal.setStatus('ok');
        // ST_EditDeal.finish();
    }

    disableDealFieldsIfApproved(approved: boolean) {
        if (approved) {
            Object.keys(this.dealFormGroup.controls).forEach((v) => {
                if (!['external_deal_url', 'image_url', 'name'].includes(v)) {
                    // @ts-ignore
                    this.dealFormGroup.controls[v]?.disable({emitEvent: false});
                }
            });
            this.dealFormGroup.disable({emitEvent: false});

            // enable some fields
            if (this.dealFormGroup.controls.name?.disabled) {
                this.dealFormGroup.controls.name?.enable();
            }
            if (this.dealFormGroup.controls.image_url?.disabled) {
                this.dealFormGroup.controls.image_url?.enable();
            }
            if (this.dealFormGroup.controls.external_deal_url?.disabled) {
                this.dealFormGroup.controls.external_deal_url?.enable();
            }
        }
    }

    fillDealForm(existingDeal: Deal, setComponentReady = false) {
        if (!existingDeal) {
            return;
        }

        // set timestamp of filling recent data
        // this.updated_at = +new Date();

        // AntonV idea: seems not needed.
        // this.dealProcessing.dealFG.controls.financial_transfers!.patchValue([], {emitEvent: false});
        // this.dealProcessing.dealFG.controls.sales_entities!.patchValue([], {emitEvent: false});
        this.dealProcessing.dealFG.patchValue(existingDeal, {emitEvent: false});
        this.dealProcessing.syncFinancialTransfers();

        if (setComponentReady) {
            // these operations require full deal object, so we will performm them only when
            // have entire deal data
            this.updateCommissionCategorization(existingDeal);
        }

        this.dealProcessing.syncFinancialTransfers();

        if (setComponentReady) {
            this.componentReady = true;
        }

        if (
            existingDeal.system_status === DEAL_SYSTEM_STATUS.open ||
            existingDeal.system_status === DEAL_SYSTEM_STATUS.approved
        ) {
            Deal.required_fields.forEach((requiredField) => {
                if (this.dealFormGroup.get(requiredField)) {
                    this.dealFormGroup.get(requiredField)!.setValidators(Validators.required);
                }
            });
        }

        this.dealProcessing.dealFG.updateValueAndValidity();
    }

    async initDeal() {
        let existingDeal: Deal | null = null;

        if (
            this.dealIdInUrl &&
            this.dealService.dealCache &&
            this.dealService.dealCache.value &&
            this.dealService.dealCache.value.id === Number(this.dealIdInUrl)
        ) {
            // So we have deal object in cache and want do pre-render
            existingDeal = this.dealService.dealCache.value;
            this.fillDealForm(existingDeal);

            // clear cache
            this.dealService.dealCache.next(null);

            // lazy loading deal from back-end
            existingDeal = await this.dealService.getOne(Number(this.dealIdInUrl));
        } else {
            // there no cache, so we have to fetch deal from back-end
            existingDeal = await this.dealService.getOne(Number(this.dealIdInUrl));
        }

        if (existingDeal) {
            this.isDealLoaded = true;
            this.payoutsBtnDisabled = false;
            this.overrideBtnDisabled = false;

            if (existingDeal.is_historical_imported) {
                this.router.navigate([`/deals/${existingDeal.id}`]);
                return;
            }

            this.fillDealForm(existingDeal, true);

            if (existingDeal.address) {
                this.titleService.setTitle(`${existingDeal.address} | Zipi`);
            }
            this.dealFormGroup.controls.custom_deal_fields!.patchValue(existingDeal.custom_deal_fields);

            this.setSalesEntities(false);

            this.checkSystemNotes();

            // compare if deal name is matched 'creating deal name' rule
            this._checkIfDealNameMatchedRule();
        }
    }

    closeBar() {
        this.isProcessBarClosed = true;
    }

    closeSuccessBar() {
        this.isSuccessBarClosed = true;
    }

    delayedCloseSuccessBar() {
        setTimeout(() => this.closeSuccessBar(), 5000);
    }

    private prepareDeal(): Deal {
        const deal: Deal = this.dealFormGroup.getRawValue();
        deal.flat_fee_commissions = this.dealFormGroup.controls.flat_fee_commissions!.getEnabledValues();
        return deal;
    }

    changeUnsavedStatus($event: 'draft' | 'open') {
        this.unsavedDealStatus = $event;
    }

    save(shouldStayOnPage: boolean = false, justNeedReload: boolean = false, eventType: string = '') {
        // validate close of escrow
        if (this.dealFormGroup.getRawValue().close_of_escrow == null) {
            return this.notificationService.addError('Closing Date cannot be empty.');
        }

        // update some deals fields of approved deal
        if (this.dealFormGroup.controls.disbursement_approved?.value) {
            return this.saveApprovedDeal(shouldStayOnPage);
        }

        // trim tne name
        if (this.dealFormGroup.controls.name!.value) {
            this.dealFormGroup.controls.name!.patchValue(this.dealFormGroup.controls.name!.value.trim());
        }

        // set required fields for transfers - product
        this.dealProcessing.setValidatorsToTransfers(false);
        // check required fields
        if (!this.dealProcessing.isDealFormValid() || this.saveDisabled) {
            // open all Disbursement Cards if transfers are invalid
            if (this.dealFormGroup.controls.financial_transfers?.invalid) {
                this.disbursement!.dealExpenses!.panel!.open();
                this.disbursement!.companyExpenses!.panel!.open();
                this.disbursement!.salesEntities!.accordion!.openAll();
            }

            return false;
        }
        // check if draft deal has closed/processed status
        if (
            this.dealFormGroup.controls.system_status!.value === DEAL_SYSTEM_STATUS.draft &&
            this.unsavedDealStatus === DEAL_SYSTEM_STATUS.draft &&
            this.dealFormGroup.controls.status!.value &&
            (this.dealFormGroup.controls.status!.value === Deal.status_SET['closed'] ||
                this.dealFormGroup.controls.status!.value === Deal.status_SET['processed'])
        ) {
            this.notificationService.addError("Status of Draft Deal should not be 'Closed' or 'Processed'");
            return;
        }

        const deal = this.prepareDeal();
        const promises = [];

        if (deal.system_status === DEAL_SYSTEM_STATUS.draft && this.unsavedDealStatus === DEAL_SYSTEM_STATUS.open) {
            deal.system_status = DEAL_SYSTEM_STATUS.open;
        }

        if (deal.id) {
            promises.push(this.dealService.update(deal.id, deal));
        }
        this.dealProcessing.saveButtonStateChange.next('disabled');

        return Promise.all(promises)
            .then((results: any) => {
                const result = results[0];

                if (result.id) {
                    deal.id = result.id;
                }
            })
            .then(async () => {
                this.dealProcessing.saveButtonStateChange.next('enabled');
                this.dealSnapshot = this.dealFormGroup.getRawValue();

                if (eventType) {
                    this.afterDealSaving$.next(eventType);
                    return;
                }
                if (justNeedReload) {
                    // const savedDeal = await this.dealService.getOne(Number(this.dealIdInUrl));
                    // this.fillDealForm(savedDeal, true);
                    return;
                }
                if (shouldStayOnPage) {
                    this.dealFormGroup.markAsPristine();
                    return this.router.navigate([`/deals/edit/${deal.id}`], {fragment: (+new Date()).toString(32)});
                }

                return this.router.navigate(['/deals', deal.id]);
            })
            .catch(() => {
                this.dealProcessing.saveButtonStateChange.next('enabled');
            });
    }

    addClient() {
        this.dealFormGroup.controls.clients!.push(new GenericFormGroup(new Client(), 'change'));
    }

    openDialog() {
        const dialogRef = this.dialog.open(CommonListOfFieldsDialogComponent, {
            minWidth: 500,
            data: {
                type: 'SourceOfBusiness',
                list: this.sourcesOfBusiness
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((list) => {
                if (!list) {
                    // dialog was just closed, no need to save anything
                    return;
                }

                this.store.dispatch(
                    new UpdateSOB(
                        list.reduce((acc: {label: string}[], cur: {label: string}) => {
                            acc.push({label: cur.label});
                            return acc;
                        }, [])
                    )
                );
            });
    }

    openSideBar(tab: string) {
        if (this.isSidebarOpen && this.currentSidebarTab !== tab) {
            this.currentSidebarTab = tab;
        } else if (this.isSidebarOpen && this.currentSidebarTab === tab) {
            this.isSidebarOpen = false;
        } else if (!this.isSidebarOpen) {
            this.currentSidebarTab = tab;
            this.isSidebarOpen = true;
        }
    }

    closeSideBar() {
        this.currentSidebarTab = null;
    }

    closeSideBarMust() {
        this.currentSidebarTab = null;
        this.isSidebarOpen = false;
    }

    async doSaveOverriddenCompensation(
        event: {salesEntity: SalesEntity; compensationForm: GenericFormGroup<CompensationProfileModel>}[]
    ) {
        const compensationProfilesToCreate: {salesEntityId: number; compensationProfile: CompensationProfileModel}[] =
            [];
        const compensationProfilesToUpdate: {
            id: number;
            financial_elements: FinancialElementModel[];
            status: string;
        }[] = [];
        event.forEach((eventData) => {
            const commProfile = eventData.compensationForm.getRawValue();
            const currentSalesEntity = eventData.salesEntity;

            // SAVE
            if (!currentSalesEntity.overriden__compensation_profile_fk_id) {
                if (currentSalesEntity.type === SalesEntity.type_SET.company_group) {
                    commProfile.apply_to = [
                        new ChipNode()
                            .setLabel(currentSalesEntity.contact.display_name!)
                            .setTargetId(currentSalesEntity.company_group.id!)
                            .setType(ChipNode.type_SET.company_group)
                    ];
                } else if (currentSalesEntity.type === SalesEntity.type_SET.profile) {
                    commProfile.apply_to = [
                        new ChipNode()
                            .setLabel(currentSalesEntity.contact.display_name!)
                            .setTargetId(currentSalesEntity.profile.id)
                            .setType(ChipNode.type_SET.individual)
                    ];
                } else {
                    console.error('Unknown type of Sales Entity');
                    return;
                }

                commProfile.type = CompensationProfileModel.type_set.override;
                commProfile.status = CompensationProfileModel.status_set.active;
                commProfile.sort_order = CompensationProfileModel.override_sort_order_set;
                commProfile.is_enforced = true;

                const dealId = this.dealFormGroup.controls.id!.value;
                commProfile.deal_filters.apply_to_deals.push(
                    new ChipNode().setLabel(`Deal #${dealId}`).setTargetId(dealId).setType(ChipNode.type_SET.deal)
                );

                // all id must be null
                // commProfile.financial_elements = (commProfile.financial_elements as any)
                commProfile.financial_elements = commProfile.financial_elements.map((el) => {
                    return GenericEntity.FABRIC(FinancialElementModel).hydrate(el).setId(null);
                });

                compensationProfilesToCreate.push({
                    salesEntityId: currentSalesEntity.id!,
                    compensationProfile: commProfile
                });
            } else {
                // UPDATE
                // TODO: Alex: need to update only changed compensation profiles
                const updateObj = {
                    id: currentSalesEntity.overriden__compensation_profile_fk_id,
                    financial_elements: commProfile.financial_elements,
                    status: CompensationProfileModel.status_set.active,
                    type: CompensationProfileModel.type_set.override
                };

                commProfile.id = currentSalesEntity.overriden__compensation_profile_fk_id;

                compensationProfilesToUpdate.push(updateObj);
            }
        });

        if (compensationProfilesToCreate.length) {
            await this.compensationServiceApi
                .batchCreateOverriddenCompensationProfile(compensationProfilesToCreate)
                .then((newCompensationProfiles) => {
                    newCompensationProfiles.forEach(
                        (newCompensationProfile: {
                            salesEntityId: number;
                            compensationProfile: CompensationProfileModel;
                        }) => {
                            // update overriden__compensation_profile_fk_id in salesEntities list
                            // this.salesEntities = (this.salesEntities as any)
                            this.salesEntities = this.salesEntities.map((el) => {
                                if (el.id === newCompensationProfile.salesEntityId) {
                                    return GenericEntity.FABRIC(SalesEntity)
                                        .hydrate(el)
                                        .setOverridenCompensationProfileId(
                                            newCompensationProfile.compensationProfile.id
                                        );
                                } else {
                                    return el;
                                }
                            });

                            // update overriden__compensation_profile_fk_id in dealFormGroup form
                            this.dealFormGroup.controls.sales_entities!.controls.forEach((element) => {
                                if (element.value.id === newCompensationProfile.salesEntityId) {
                                    element.controls.overriden__compensation_profile_fk_id!.patchValue(
                                        newCompensationProfile.compensationProfile.id
                                    );
                                }
                            });
                        }
                    );
                });
        }
        if (compensationProfilesToUpdate.length) {
            await this.compensationServiceApi.putCompensationProfiles({profilesToUpdate: compensationProfilesToUpdate});
        }

        this.closeSideBarMust();
        // do apply-splits
        this.doRefresh(null);
    }

    doCloseOverriddenCompensation() {
        this.closeSideBarMust();
    }

    doDeleteOverriddenCompensation(
        event: {salesEntity: SalesEntity; compensationForm: GenericFormGroup<CompensationProfileModel>}[]
    ) {
        if (
            event.every(
                (eventData) => eventData.salesEntity && eventData.salesEntity.overriden__compensation_profile_fk_id
            )
        ) {
            const confirmDialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: 320,
                minHeight: 160,
                data: {
                    title: `Confirm Rules Override`,
                    message: `Please, confirm removal all Custom Rules`
                }
            });

            confirmDialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((confirmed: boolean) => {
                    if (!confirmed) {
                        return;
                    }

                    this.compensationServiceApi
                        .batchDeleteOverriddenCompensationProfile(
                            event.map((eventData) => {
                                return {
                                    salesEntityId: eventData.salesEntity.id,
                                    compensationProfile: eventData.compensationForm.getRawValue()
                                };
                            })
                        )
                        .then(() => {
                            // do apply-splits
                            this.doRefresh(null);
                            this.closeSideBarMust();

                            // update salesEntities list
                            // this.salesEntities = (this.salesEntities as any)
                            this.salesEntities = this.salesEntities.map((el) => {
                                if (event.map((eventData) => eventData.salesEntity.id).includes(el.id)) {
                                    return GenericEntity.FABRIC(SalesEntity)
                                        .hydrate(el)
                                        .setOverridenCompensationProfileId(null);
                                } else {
                                    return el;
                                }
                                // ? ({...el, overriden__compensation_profile_fk_id: null})
                                // : el
                            });
                            // update overriden__compensation_profile_fk_id in dealFormGroup form
                            this.dealFormGroup.controls.sales_entities!.controls.forEach((element) => {
                                if (event.map((eventData) => eventData.salesEntity.id).includes(element.value.id)) {
                                    element.controls.overriden__compensation_profile_fk_id!.patchValue(null);
                                }
                            });
                        });
                });
        }
    }

    openDepositFunc($event: boolean) {
        this.openDeposit = $event;
    }

    closeDepositFunc($event: boolean) {
        this.openDeposit = !$event;
    }

    reloadDeaL() {
        this.initDeal();
    }

    onOverrideChange(event: {isOverridden: boolean}) {
        this.isDealOverridden = event.isOverridden;
    }

    deleteDeal() {
        const dialogRef = this.dialog.open(DeleteDealsWarningComponent, {
            data: {
                dualDealExists: !!this.dealFormGroup.controls.dual_deal_id!.value
            }
        });
        const dealId = Number(this.dealIdInUrl);

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.deleting = true;
                    const dealsToDelete = [dealId];
                    if (result === 'both') {
                        dealsToDelete.push(this.dealFormGroup.controls.dual_deal_id!.value);
                    }
                    this.dealService.bulkDelete(dealsToDelete).then((response) => {
                        this.router.navigate(['/deals']);
                    });
                }
            });
    }

    public object_keys(obj: object) {
        return Object.keys(obj);
    }

    public async updateDealStatus(status: string) {
        // update only status
        await this.dealService.updateDealStatus(this.dealFormGroup.controls.id!.value, {status});

        this.dealFormGroup.controls.status!.setValue(status);
        this.dealFormGroup.updateValueAndValidity();
    }

    private _checkIfDealNameMatchedRule(): void {
        const address = this.dealFormGroup.controls.address!.value ? this.dealFormGroup.controls.address!.value : '';
        const client = this._createParticipantsForDealName();

        this.isPreviousNameMatched =
            this.dealFormGroup.controls.name!.value === (address + (client ? ` ${client}` : '')).trim();
    }
    private _createParticipantsForDealName(): string {
        const client_type = this.dealFormGroup.controls.client_type!.value;
        const participants = this.dealFormGroup.controls.deal_participants!.value;
        let client;
        if (client_type === this.DEAL.deal_client_type_SET.buyer) {
            client = participants.buyers?.map((b: ContactPartLink) => `(${b.link_title})`).join(', ');
        } else if (client_type === this.DEAL.deal_client_type_SET.seller) {
            client = participants.sellers?.map((b: ContactPartLink) => `(${b.link_title})`).join(', ');
        }

        return client;
    }

    checkSystemNotes() {
        if (this.dealFormGroup.controls.system_notes!.controls.length > 0) {
            const notesToShow = this.dealFormGroup.controls
                .system_notes!.controls.filter((note) => note.controls.is_dismissed?.value === false)
                .sort((noteA, noteB) => {
                    if (
                        noteA.controls.system_key?.value ===
                            DealSystemNote.system_keys_SET.background_recalculation_failed &&
                        noteB.controls.system_key?.value === DealSystemNote.system_keys_SET.duplicated_transfers
                    ) {
                        return -1;
                    }
                    if (
                        noteA.controls.system_key?.value === DealSystemNote.system_keys_SET.duplicated_transfers &&
                        noteB.controls.system_key?.value ===
                            DealSystemNote.system_keys_SET.background_recalculation_failed
                    ) {
                        return 1;
                    }
                    return 0;
                });
            if (notesToShow.length > 0) {
                this.showSystemNotesDialog(notesToShow);
            }
        }
    }

    showSystemNotesDialog(notesFG: GenericFormGroup<DealSystemNote>[]) {
        const noteFG = notesFG.shift();
        if (noteFG) {
            let dialogConfig;
            switch (noteFG.controls.system_key?.value) {
                case DealSystemNote.system_keys_SET.duplicated_transfers:
                    dialogConfig = {
                        minWidth: 320,
                        minHeight: 160,
                        data: {
                            title: `Duplicate Transactions`,
                            hideCancel: true,
                            buttonOkMessage: `Ok`,
                            messages: [
                                `SkySlope Books has detected transactions within this Deal that appear to be duplicated.`,
                                `Please confirm each of the items listed in the Deal Disbursement section prior to Approval.`
                            ]
                        }
                    };
                    break;
                case DealSystemNote.system_keys_SET.background_recalculation_failed:
                    dialogConfig = {
                        minWidth: 320,
                        minHeight: 160,
                        data: {
                            title: `Deal Calculation Failure`,
                            hideCancel: true,
                            hideCheckboxShowMessageAgain: true,
                            buttonOkMessage: `Ok`,
                            messages: [
                                `Deal currently contains a calculation failure, which may be impacting the values displayed.`,
                                `Please try to re-save the Deal.`,
                                `If additional assistance is required, please contact our Technical Support Team.`
                            ]
                        }
                    };
                    break;
            }
            const confirmDialogRef = this.dialog.open(AlertComponent, dialogConfig);

            confirmDialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((isDismissed: boolean) => {
                    if (isDismissed) {
                        this.dealService
                            .dismissSystemNote(this.dealFormGroup.controls.id!.value, {
                                ids: noteFG.controls.affected_entities_ids?.value,
                                system_key: noteFG.controls.system_key?.value
                            })
                            .then((result) => {
                                this.dealFormGroup.controls.system_notes!.patchValue(result, {emitEvent: false});
                            });
                    }
                    if (notesFG.length > 0) {
                        this.showSystemNotesDialog(notesFG);
                    }
                });
        }
    }

    saveApprovedDeal(shouldStayOnPage: boolean = false) {
        if (!this.dealIdInUrl) {
            return;
        }

        const dealData = {
            external_deal_url: this.dealFormGroup.controls.external_deal_url?.value,
            image_url: this.dealFormGroup.controls.image_url?.value,
            name: this.dealFormGroup.controls.name?.value,
            notes: this.dealFormGroup.controls.notes?.value
        };

        this.dealService.saveApprovedDeal(this.dealIdInUrl, dealData).then((result) => {
            if (shouldStayOnPage) {
                this.dealFormGroup.markAsPristine();
                return this.router.navigate([`/deals/edit/${this.dealIdInUrl}`], {
                    fragment: (+new Date()).toString(32)
                });
            }

            return this.router.navigate(['/deals', this.dealIdInUrl]);
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.dealEventsService.unwatchDealEvents(Number(this.dealIdInUrl));
        this.showDismissWarningEventEmitter.complete();
        this.afterDealSaving$.complete();
        this.checkCommissionPayer$.complete();
    }
}

import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {GenericFormArray, GenericFormGroup} from '../../../../../../entites/generic.entity';
import {
    YodataReportModel,
    YodataReportParticipantModel,
    YodataReportTransactionEntryModel
} from './yodata-report.model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FormGroupArrayWithGroupAsControl, FormGroupWithGroupAsControl} from '../../../../../../typings/common';

const urlPattern =
    'https:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)';

@Component({
    selector: 'app-yodata-report-dialog',
    templateUrl: 'yodata-report-dialog.component.html',
    styles: [
        `
            .mat-dialog-content {
                overflow-wrap: break-word;
                white-space: pre-wrap;
            }
        `
    ]
})
export class YodataReportDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    // entityForm: GenericFormGroup<YodataReportModel> = new GenericFormGroup(new YodataReportModel());
    entityForm: FormGroupWithGroupAsControl = this.fb.group(new YodataReportModel()) as FormGroupWithGroupAsControl;
    // transactionEntryArray: GenericFormArray<YodataReportTransactionEntryModel> = new GenericFormArray([]);
    // participantsArray: GenericFormArray<YodataReportParticipantModel> = new GenericFormArray([]);
    // salesEntitiesArrayCtrl: GenericFormArray<YodataReportTransactionEntryModel> = new GenericFormArray([]);
    // participantsArrayCtrl: GenericFormArray<YodataReportParticipantModel> = new GenericFormArray([]);
    salesEntitiesArrayCtrl: FormGroupArrayWithGroupAsControl = this.fb.array([]) as FormGroupArrayWithGroupAsControl;
    participantsArrayCtrl: FormGroupArrayWithGroupAsControl = this.fb.array([]) as FormGroupArrayWithGroupAsControl;

    SUPER_TYPES = {SALE: 'SALE', MISC: 'MISC'};
    SUPER_TYPES_LABELS: {[key: string]: string} = {SALE: 'Sale', MISC: 'Miscellaneous'};
    superType: string | null = null;
    divisionTitle: string | null = null;
    side: 'listing' | 'buyer' | null = null;
    readyToInit = false;

    constructor(
        public dialogRef: MatDialogRef<YodataReportDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            DIVISION_TITLE: string;
            TRANSACTION_SUPER_TYPE: string;
            report: any;
            uiModInstanceId: number;
            dealId: number;
        },
        private fb: UntypedFormBuilder
    ) {}

    closeForm(isCancelled: boolean) {
        if (!isCancelled) {
            this.dialogRef.close();
            return;
        }

        // VALIDATE
        if (this.entityForm.invalid) {
            alert('Please fill all required fields');
            return;
        }

        const values = this.entityForm.getRawValue();
        // console.log(values, 'values');

        // closeDate
        // Required when transaction is closed. If status is Closed, must contain the contract settlement date when the property changed hands.
        // The date must be a valid date later than or equal to the purchaseContractDate and on or prior to the current date. If status is Open or Canceled, must be empty.
        // **Included for Sales Transactions Only
        if (this.superType === this.SUPER_TYPES.SALE) {
            if (!values.data) {
                return;
            }
            if (values.data.object.transactionStatus === 'ClosedTransactionStatus') {
                if (!values.data.object.closeDate) {
                    alert('close date required when transaction is closed');
                    return;
                }
            }
        }

        this.dialogRef.close({
            // type: this.data.type, // no need this
            // entity: this.data.entity, // no need this
            formValues: values
        });
    }

    ngOnInit() {
        this.superType = this.data.TRANSACTION_SUPER_TYPE; // this is for conditional controls (fields)
        this.divisionTitle = this.data.DIVISION_TITLE;
        try {
            this.side =
                this.data.report.data.object.transactionEntry[0].recipient.roleName === 'ListingAgent'
                    ? 'listing'
                    : 'buyer';
        } catch (e) {}

        this.readyToInit = true;

        this.initForm();
        this.entityForm.patchValue(this.data.report);

        // RESET salesEntities and participants arrays to prevent patch duplicate
        this.salesEntitiesArrayCtrl.patchValue([]);
        this.participantsArrayCtrl.patchValue([]);

        if (Array.isArray(this.data.report.data.object.transactionEntry)) {
            this.data.report.data.object.transactionEntry.forEach((el: YodataReportTransactionEntryModel) => {
                this.addTransactionEntry(el);

                // if primary agent - subscribe on POD URI changes and patch every participant affiliation POD URI
                if (el._IS_PRIMARY === true) {
                    const participantsCtrl = (this.entityForm.controls.data as any).controls.object.controls.participant
                        .controls;
                    (this.entityForm.controls.data as any).controls.object.controls.transactionEntry.controls.forEach(
                        (ell: GenericFormGroup<YodataReportTransactionEntryModel>) => {
                            if (ell.controls._IS_PRIMARY && ell.controls._IS_PRIMARY.value === true) {
                                if (
                                    Array.isArray(this.data.report.data.object.participant) &&
                                    this.data.report.data.object.participant.length
                                ) {
                                    (ell.controls.recipient as any).controls.id.valueChanges
                                        .pipe(takeUntil(this.unsubscribe))
                                        .subscribe((x: string) => {
                                            participantsCtrl.forEach(
                                                (participantCtrl: GenericFormGroup<YodataReportParticipantModel>) => {
                                                    if (!participantCtrl.controls.affiliation) {
                                                        return;
                                                    }
                                                    participantCtrl.controls.affiliation.patchValue(x);
                                                }
                                            );
                                        });
                                }
                            }
                        }
                    );
                }
            });
        }
        if (Array.isArray(this.data.report.data.object.participant)) {
            this.data.report.data.object.participant.forEach((el: YodataReportParticipantModel) =>
                this.addParticipant(el)
            );
        }
    }

    initForm() {
        // this.salesEntitiesArrayCtrl = this.fb.array([]);
        // this.participantsArrayCtrl = this.fb.array([]);
        // Validators.required
        this.entityForm = this.fb.group(
            {
                topic: ['', [Validators.maxLength(500)]],
                recipient: ['', [Validators.maxLength(500), Validators.pattern(urlPattern)]],
                source: ['', [Validators.maxLength(500), Validators.pattern(urlPattern)]],
                data: this.fb.group({
                    type: ['', [Validators.required]],
                    // instrument: this.fb.group({
                    //     name: ['', [Validators.required]],
                    //     type: ['', [Validators.required]],
                    // }),
                    object: this.fb.group({
                        additionalProperty: this.fb.group({
                            batchId: ['', [Validators.required]],
                            transactionSequence: [null, [Validators.required]],
                            unimprovedLandFlag: [false, []]
                        }),
                        // commissionDate: ['', []], for miscelanneous only
                        // closeDate: ['', []], for sales only
                        // purchaseContractDate: ['', []], for sales
                        reportingOffice: ['', [Validators.required]],
                        // listingOffice: [null, []], // only for sale
                        // buyerOffice: [null, []], // only for sale
                        totalSalesProductionGCI: this.fb.group({
                            currency: ['', [Validators.required]],
                            type: ['', [Validators.required]],
                            value: [{value: null, disabled: true}, [Validators.required]]
                        }),
                        totalSalesProductionGCIDeduction: this.fb.group({
                            currency: ['', [Validators.required]],
                            type: ['', [Validators.required]],
                            value: [null, [Validators.required]]
                        }),
                        closePrice: this.fb.group({
                            currency: ['', [Validators.required]],
                            type: ['', [Validators.required]],
                            value: [{value: null, disabled: true}, [Validators.required]]
                        }),
                        identifier: this.fb.group({
                            bmsTransactionId: [
                                {value: '', disabled: true},
                                [Validators.required, Validators.maxLength(10)]
                            ]
                        }),
                        // ADDRESS
                        object: this.fb.group({
                            type: ['', [Validators.required]], // "RealEstateProperty"
                            propertyType: ['', [Validators.required]] // 'RESI' : 'COMS'

                            // FIELDS ONLY FOR SALE transaction:
                            // addressCountry: ['', [Validators.required]],
                            // addressLocality: ['', [Validators.required]], // City
                            // addressRegion: ['', [Validators.required]], // State
                            // postalCode: ['', [Validators.required]],
                            // streetAddress: ['', [Validators.required]],
                        }),

                        transactionEntry: this.salesEntitiesArrayCtrl,
                        participant: this.participantsArrayCtrl,

                        transactionStatus: ['', [Validators.required]],
                        transactionType: [{value: '', disabled: true}, [Validators.required]],
                        type: ['', [Validators.required]]
                    })
                })

                // name: [{value: name, disabled: true}, []],
                // agent_office_pod: ['', [Validators.maxLength(500)]],
            },
            {updateOn: 'change'}
        ) as FormGroupWithGroupAsControl;

        // this.transactionEntryArray = this.entityForm.controls.data.controls.object.controls.transactionEntry;
        // this.participantsArray = this.entityForm.controls.data.controls.object.controls.participant.controls;

        // console.log(this.entityForm.controls.data['controls'], 'this.entityForm.controls.data[\'controls\']');

        // conditional fields
        if (this.superType === this.SUPER_TYPES.SALE) {
            const transactionObjectGroup = this.entityForm.controls.data!.get('object');
            const transactionObjectObjectGroup = (this.entityForm.controls.data as any).controls.object.get('object');

            // listing buyer offices
            // since we have only one side - it should depend on deal.client_type
            (transactionObjectGroup as any).addControl(
                'listingOffice',
                this.fb.control(null, this.side === 'listing' ? [Validators.required] : [])
            );
            (transactionObjectGroup as any).addControl(
                'buyerOffice',
                this.fb.control(null, this.side === 'buyer' ? [Validators.required] : [])
            );

            // ADDRESS fields
            (transactionObjectObjectGroup as any).addControl('addressCountry', this.fb.control(null));
            (transactionObjectObjectGroup as any).addControl('addressLocality', this.fb.control(null));
            (transactionObjectObjectGroup as any).addControl('addressRegion', this.fb.control(null));
            (transactionObjectObjectGroup as any).addControl('postalCode', this.fb.control(null));
            (transactionObjectObjectGroup as any).addControl('streetAddress', this.fb.control(null));

            (transactionObjectGroup as any).addControl('closeDate', this.fb.control(null));
            (transactionObjectGroup as any).addControl('purchaseContractDate', this.fb.control(null));
        } else if (this.superType === this.SUPER_TYPES.MISC) {
            const transactionObjectGroup = this.entityForm.controls.data!.get('object');

            // commissionDate
            (transactionObjectGroup as any).addControl('commissionDate', this.fb.control('', [Validators.required]));
        }
    }

    addParticipant(p: YodataReportParticipantModel): UntypedFormGroup {
        const participantGroup = this.fb.group({
            type: ['', [Validators.minLength(2), Validators.maxLength(40)]],
            // roleName: ['', []], // conditional field
            // position: [null, [Validators.min(1), Validators.max(9)]], // conditional field
            givenName: ['', [Validators.minLength(2), Validators.maxLength(40)]],
            familyName: ['', [Validators.minLength(2), Validators.maxLength(40)]],
            additionalName: ['', [Validators.maxLength(40)]],
            email: ['', [Validators.maxLength(70)]],
            telephone: ['', [Validators.maxLength(70)]],
            affiliation: [
                {value: '', disabled: true},
                [Validators.required, Validators.maxLength(500), Validators.pattern(urlPattern)]
            ]
        });

        if (this.superType === this.SUPER_TYPES.SALE) {
            participantGroup.addControl('roleName', this.fb.control(''));
            participantGroup.addControl('position', this.fb.control(null));
        }

        if (p) {
            participantGroup.patchValue(p);
        }

        (<UntypedFormArray>this.participantsArrayCtrl).push(participantGroup);
        return participantGroup;
    }

    addTransactionEntry(p: YodataReportTransactionEntryModel): UntypedFormGroup {
        // (agentGroup as any).addControl('salesProductionUnit', this.fb.control(null));
        // //     (agentGroup as any).recipient.controls.addControl('roleName', this.fb.control(null));

        const transactionEntryGroup = this.fb.group({
            _IS_PRIMARY: [null],
            SE_NAME: [''],
            recipient: this.fb.group({
                id: ['', Validators.pattern(urlPattern)],
                // roleName: ['', []], // custom field only for sales transactions
                type: ['', []],
                identifier: this.fb.group({
                    bmsAgentId: [{value: '', disabled: true}, [Validators.maxLength(12)]]
                })
            }),
            salesProductionGCI: this.fb.group({
                currency: ['', []],
                type: ['', []],
                value: [null, [Validators.required]]
            }),
            // salesProductionUnit: [null, []], // conditional field - only if sale
            type: ['', [Validators.minLength(2), Validators.maxLength(40)]]
        });

        if (this.superType === this.SUPER_TYPES.SALE) {
            transactionEntryGroup.addControl('salesProductionUnit', this.fb.control(null, [Validators.max(1)]));
            (transactionEntryGroup.get('recipient') as any).addControl('roleName', this.fb.control(''));
        }

        if (p) {
            transactionEntryGroup.patchValue(p);
        }

        // (<FormArray>this.entityForm.controls.data['controls'].object.controls.get('contact_persons')).push(transactionEntryGroup);
        (<UntypedFormArray>this.salesEntitiesArrayCtrl).push(transactionEntryGroup);
        return transactionEntryGroup;
    }

    get reportingOfficeValue() {
        // @ts-ignore
        return this.entityForm.controls.data.controls.object['controls'].reportingOffice.value;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

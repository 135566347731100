import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {ProcessRemainingPayoutsByMoneyTransferEftDialogComponent} from './components/process-remaining-payouts/money-transfer-eft-dialog/process-remaining-payouts-by-money-transfer-eft-dialog.component';
import {ProcessRemainingPayoutsBillCreateOnlyDialogComponent} from './components/process-remaining-payouts/bill-create-only-dialog/process-remaining-payouts-bill-create-only-dialog.component';
import {ProcessRemainingPayoutsRecordPaymentDialogComponent} from './components/process-remaining-payouts/record-payment-dialog/process-remaining-payouts-record-payment-dialog.component';
import {ProcessRemainingPayoutsCheckOnlyDialogComponent} from './components/process-remaining-payouts/check-only-dialog/process-remaining-payouts-check-only-dialog.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../shared/shared.module';
import {RemainingPayoutsApiService} from './services/remaining-payouts.api-service';
import {RemainingPayoutsDataService} from './services/remaining-payouts.data-service';
import {RecordClosingPayoutDialogComponent} from './components/process-closing-payouts/record-closing-payout-dialog/record-closing-payout-dialog.component';
import {ClosingPayoutsApiService} from './services/closing-payouts.api-service';
import {SummaryPayoutsApiService} from '@app/modules/deals/modules/common-deals-module/services/summary-payouts.api-service';
import {PostingDealDialogComponent} from '@app/modules/deals/modules/common-deals-module/components/process-summary-payouts/posting-deal-dialog/posting-deal-dialog.component';

@NgModule({
    declarations: [
        ProcessRemainingPayoutsByMoneyTransferEftDialogComponent,
        ProcessRemainingPayoutsBillCreateOnlyDialogComponent,
        ProcessRemainingPayoutsRecordPaymentDialogComponent,
        ProcessRemainingPayoutsCheckOnlyDialogComponent,
        RecordClosingPayoutDialogComponent,
        PostingDealDialogComponent
    ],
    imports: [CommonModule, SharedModule],
    exports: [
        ProcessRemainingPayoutsByMoneyTransferEftDialogComponent,
        ProcessRemainingPayoutsBillCreateOnlyDialogComponent,
        ProcessRemainingPayoutsRecordPaymentDialogComponent,
        ProcessRemainingPayoutsCheckOnlyDialogComponent,
        RecordClosingPayoutDialogComponent,
        PostingDealDialogComponent,

        CommonModule,
        SharedModule
    ],
    providers: [
        RemainingPayoutsApiService,
        RemainingPayoutsDataService,
        ClosingPayoutsApiService,
        SummaryPayoutsApiService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonDealsModule {}

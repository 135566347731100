import {Injectable} from '@angular/core';
import {ServiceRequester} from '../service.requester';
import {Observable} from 'rxjs';
import {
    IContact,
    IContactInvite,
    IContactInviteParams,
    IContactNote,
    IProfile,
    IContactLocationTable,
    IContactPerson,
    IZfTransferPreference
} from '../../../typings';
import {IContactShare, IContactPayrollHistory} from '@cyberco-nodejs/zipi-typings';
import {Profile} from '../../models/profile';

@Injectable()
export class ShipperContactsService {
    private url = '/contacts';

    constructor(public requester: ServiceRequester) {}

    // @todo: we have to remove this method from system. because it fetch ALL contacts. which is bad idea.
    getContactsList(contactIds: number[] = []): Observable<IContact[]> {
        // temporary hack to let it work for companies with many contacts;
        let targetURL = this.url + '/lite';
        if (contactIds.length > 0) {
            targetURL = this.url;
        }

        return this.requester.makeMsCall$(targetURL, 'GET', 'shipp', {contact_ids: contactIds});
    }

    getContactById(contactId: number): Observable<IContact> {
        return this.requester.makeMsCall$(this.url + `/${contactId}`, 'GET', 'shipp');
    }

    getContactPayrollHistory(contactPayrollId: number): Observable<IContactPayrollHistory[]> {
        return this.requester.makeMsCall$(this.url + `/payroll-history/${contactPayrollId}`, 'GET', 'shipp');
    }

    getDecryptedTin(contactId: number, contactPayrollId: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + `/${contactId}/payroll/${contactPayrollId}/tin`, 'GET', 'shipp');
    }

    encryptTin(contactId: number, contactPayrollId: number, tin: string): Observable<any> {
        return this.requester.makeMsCall$(this.url + `/${contactId}/payroll/${contactPayrollId}/tin`, 'PUT', 'shipp', {
            tin
        });
    }

    getContactInMyCompanyByPartnerCompanyId(partnerCompanyId: number): Observable<IContact> {
        return this.requester.makeMsCall$(this.url + `/partner/${partnerCompanyId}`, 'GET', 'shipp');
    }

    /**
     * Get contacts for entity-picker
     */
    getContactsById(contactIds: number[]): Observable<IContact[]> {
        return this.requester.makeMsCall$(this.url + `/get-by-ids`, 'GET', 'shipp', {ids: contactIds});
    }

    getContactPersonsById(contactPersonsIds: number[]): Observable<
        {
            id: number;
            label: string;
            permissions: string | null;
            company_fk_id: number;
            contact_persons:
                | {
                      contact_company_name: string;
                      contact_person_id: number;
                      contact_person_name: string;
                      contact_person_type: string;
                  }[]
                | null;
        }[]
    > {
        return this.requester.makeMsCall$(this.url + `/person/get-by-ids`, 'GET', 'shipp', {ids: contactPersonsIds});
    }

    getVendorContacts(scrollData: {limit: number; offset: number}): Observable<
        {
            contact_id: number;
            company_name: string;
            display_name: string;
            contact_persons: IContactPerson[];
            contact_locations: IContactLocationTable[];
        }[]
    > {
        return this.requester.makeMsCall$(this.url + `/get-vendor`, 'GET', 'shipp', scrollData);
    }

    getRelatedToProfileContact(profile_id: number): Observable<IContact> {
        return this.requester.makeMsCall$('/public' + this.url + '/profile/' + profile_id, 'GET', 'shipp');
    }

    showContact(contactId: number): Observable<IContact> {
        return this.requester.makeMsCall$(this.url + `/${contactId}`, 'GET', 'shipp');
    }

    getContactPartnerCompanyZipiFinancialSettingsByContactId(contactId: number): Observable<{
        target_contact_id: string;
        customer_ref: string;
        target_contact_metatypes: Array<string>;
        feeds_automation: boolean;
        cards_acquiring: boolean;
    }> {
        return this.requester.makeMsCall$(this.url + `/${contactId}/partner-zipi-fin-settings`, 'GET', 'shipp');
    }

    getContactTransferPreference(
        contactId: number
    ): Observable<{incoming: IZfTransferPreference; outgoing: IZfTransferPreference}> {
        return this.requester.makeMsCall$(this.url + `/${contactId}/transfer-preference`, 'GET', 'shipp');
    }

    saveContactTransferPreference(
        contactId: number,
        data: {velocity: string; type: 'when_sending' | 'when_receiving'; auto_downgrade: boolean}
    ): Observable<any> {
        return this.requester.makeMsCall$(this.url + `/${contactId}/transfer-preference`, 'POST', 'shipp', data);
    }

    removeContactTransferPreference(
        contactId: number,
        data: {type: 'when_sending' | 'when_receiving'}
    ): Observable<any> {
        return this.requester.makeMsCall$(this.url + `/${contactId}/transfer-preference`, 'DELETE', 'shipp', data);
    }

    createContact(data: IContact): Observable<IContact> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', data);
    }

    updateContactById(contactId: number, data: IContact): Observable<IContact> {
        return this.requester.makeMsCall$(this.url + `/${contactId}`, 'PUT', 'shipp', data);
    }

    updateProfileContactPersonById(profileId: number, data: IProfile | Profile): Observable<IContact> {
        return this.requester.makeMsCall$(this.url + `/${profileId}/profile`, 'PUT', 'shipp', data);
    }

    deleteContact(contactId: number, force: boolean = false): Observable<boolean> {
        let url = this.url + `/${contactId}`;
        if (force) {
            url = url + '?force=true';
        }
        return this.requester.makeMsCall$(url, 'DELETE', 'shipp');
    }

    getContactsInvitesList(): Observable<IContactInvite[]> {
        return this.requester.makeMsCall$(this.url + '/invites', 'GET', 'shipp');
    }

    createContactInvite(contactId: number, data: IContactInviteParams): Observable<IContactInvite> {
        return this.requester.makeMsCall$(this.url + `/${contactId}/invite`, 'POST', 'shipp', data);
    }

    cancelContactInvite(contactInviteId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `/invites/${contactInviteId}/`, 'DELETE', 'shipp');
    }

    bulkContactCreate(data: IContact[]): Observable<IContact[]> {
        return this.requester.makeMsCall$(this.url + '/bulk-create/', 'POST', 'shipp', data);
    }

    getContactShares(contactId: number): Observable<IContactShare[]> {
        return this.requester.makeMsCall$(`${this.url}/${contactId}/shares`, 'GET', 'shipp');
    }

    updateContactShares(contactId: number, shares: IContactShare[]): Observable<IContactShare[]> {
        return this.requester.makeMsCall$(`${this.url}/${contactId}/shares`, 'PUT', 'shipp', shares);
    }

    transferOwnership(
        contactId: number,
        data: {owner_type: 'profile' | 'group' | 'company'; owner_id: number}
    ): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.url}/${contactId}/transfer-ownership`, 'PUT', 'shipp', data);
    }

    createContactNote(contactId: number, data: IContactNote): Observable<boolean> {
        return this.requester.makeMsCall$(`${this.url}/${contactId}/contact-note`, 'POST', 'shipp', data);
    }

    getContactNotes(contactId: number): Observable<IContactNote[]> {
        return this.requester.makeMsCall$(`${this.url}/${contactId}/contact-note`, 'GET', 'shipp');
    }

    updateContactNoteById(contactId: number, data: IContactNote): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `/${contactId}/contact-note`, 'PUT', 'shipp', data);
    }

    deleteContactNoteById(contactId: number, noteId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `/${contactId}/contact-note/` + noteId, 'DELETE', 'shipp');
    }

    isDeletableContact(contactId: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + `/${contactId}/isdeletable`, 'GET', 'shipp');
    }

    isDeletableDivisionContact(contactId: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + `/${contactId}/division-contact/isdeletable`, 'GET', 'shipp');
    }

    getContactsListWithDelegatedAndParams(
        data: {
            offset?: number;
            limit: number;
            order?: {};
            types?: string[];
            filters?: string[];
            context_contact_id?: number;
        },
        context_contact_id?: number
    ) {
        let url = this.url + `/contacts-with-delegated-and-params`;
        if (context_contact_id) {
            url += `?context_contact_id=${context_contact_id}`;
        }
        return this.requester.makeMsCall$(url, 'POST', 'shipp', data);
    }

    mergeContacts(data: {primaryContactId: number; notPrimaryContactIds: [number]}): Observable<any> {
        return this.requester.makeMsCall$(`/users-and-licences/merge-contacts`, 'POST', 'zipi', data);
    }

    unmergeContacts(data: {contactId: number}): Observable<any> {
        return this.requester.makeMsCall$(`/users-and-licences/unmerge-contacts`, 'POST', 'zipi', data);
    }

    getMergedContacts(contactId: number): Observable<any> {
        return this.requester.makeMsCall$(this.url + `/${contactId}/merged-contacts`, 'GET', 'shipp');
    }
}

import {Component, OnDestroy} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {AvailableProfilesSource} from '../services/sources/available-profiles.source';
import {Profile} from '../models/profile';
import {CurrentProfileSource} from '../services/sources/current-profile.source';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-select-profile',
    styles: [
        `
            .subscription-inactive-card {
                margin-top: 20vh;
                width: 50vw;
                margin-left: 25vw;
                /*display: flex;*/
                /*flex-direction: row;*/
            }
            .error-content {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        `
    ],
    template: `
        <mat-card class="subscription-inactive-card">
            <mat-card-content class="error-content">
                <mat-card-title> Select Profile </mat-card-title>
                <div>
                    Action you tried to perform impossible for Global View Mode. Please select one of your profiles to
                    proceed.
                    <br /><br />
                </div>
            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-button class="mat-primary" [matMenuTriggerFor]="appMenu">
                    {{ currProfileTitle }}
                </button>
                <mat-menu #appMenu="matMenu" xPosition="before">
                    <ng-container *ngFor="let profile of availableProfiles">
                        <div
                            class="twoLineMenuItem"
                            mat-menu-item
                            *ngIf="profile?.company"
                            (click)="setCurrentProfile(profile)"
                        >
                            <div class="bigMenuSubItem">
                                <span>{{ profile?.company?.title }}</span>
                                <mat-icon
                                    *ngIf="!isSubscriptionActive(profile?.company?.subscription_status)"
                                    style="font-size: 14px; width: 14px; height: 14px; margin-right: 3px; color: #FF5722;"
                                    >warning</mat-icon
                                >
                            </div>
                            <br />
                            <div class="smallMenuSubItem">
                                <span>{{ profile?.user?.email }}</span>
                            </div>
                        </div>
                        <div
                            mat-menu-item
                            *ngIf="isGlobalViewVisible && !profile.company"
                            (click)="setCurrentProfile(profile)"
                        >
                            Global View
                        </div>
                    </ng-container>
                </mat-menu>
            </mat-card-actions>
        </mat-card>
    `
})
export class SelectProfileComponent implements OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    public availableProfiles: Profile[] = [];
    public currentProfile: Profile | null = null;
    public isGlobalViewVisible: boolean = false;
    public currProfileTitle: string = 'Select Profile';

    constructor(
        public authService: AuthService,
        protected router: Router,
        protected currentProfileSource: CurrentProfileSource,
        protected availableProfilesSource: AvailableProfilesSource
    ) {
        this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((currProfile) => {
            this.currentProfile = currProfile;
            // if (currProfile && currProfile.company && currProfile.company.title) {
            //     this.currProfileTitle = currProfile.company.title;
            // } else {
            //     this.currProfileTitle = 'Global View';
            // }
        });
        this.availableProfilesSource.availableProfiles.pipe(takeUntil(this.unsubscribe)).subscribe((profilesList) => {
            this.availableProfiles = profilesList.sort((a: Profile, b: Profile) => {
                if (a.type === 'global' && b.type !== 'global') {
                    return -1;
                }
                if (a.type !== 'global' && b.type === 'global') {
                    return 1;
                }
                if (a.company && a.company.title && b.company && b.company.title && a.company.title > b.company.title) {
                    return 1;
                } else {
                    return -1;
                }
            });
            if (this.availableProfiles.length < 2) {
                console.log('need logout!');
                // this.authService.logout();
            }
            this.isGlobalViewVisible = false;
        });
    }

    isSubscriptionActive(subscriptionStatus: string | null | undefined) {
        if (typeof subscriptionStatus === 'string') {
            return ['live', 'trial'].indexOf(subscriptionStatus) > -1;
        } else {
            return false;
        }
    }

    setCurrentProfile(profile: Profile) {
        const storedRoute = localStorage.getItem('user_route');
        console.log(storedRoute);
        if (storedRoute) {
            this.authService.loadProfile(profile.id as number).then(() => {
                console.log(storedRoute);
                window.location.href = storedRoute;
            });
        } else {
            this.router.navigate(['/']).then(() => {
                this.authService.loadProfile(profile.id as number);
            });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

import {SourceOfBusiness} from './source-of-business';
import {Group} from './group';
import {
    ICompany,
    ICompanyBillingSettings,
    ICompanyLocation,
    ICompanySettings,
    ICustomDealField,
    IParticipantSubTypes,
    IProfile
} from '@cyberco-nodejs/zipi-typings/companies.typings';
import {IGroup, IUser} from '@cyberco-nodejs/zipi-typings';
import {SubType} from './sub-type';
import {SubSource} from './sub-source';

export class Company implements ICompany {
    company_id?: number;
    id?: number;
    main__contact_fk_id?: number;
    access_plan: string = '';
    business_relationship_type?: string = '';
    skyslope_subscriber_id?: string = '';
    title: string | null = null;
    is_external_groups_allowed: boolean = true;
    zoho_subscription: Array<any> = [];
    parent_company?: ICompany;
    company_group?: IGroup;
    subscription_status: string | null = null;
    subscription_plan: string | null = null;
    // @ts-ignore
    presets: {
        enableBulkAction: boolean;
        sources?: SourceOfBusiness[];
        sub_sources?: SubSource[];
        sub_types?: SubType[];
        capable_metrics_by_product_ids?: number[];
    };

    company_group_fk_id?: number;
    plan?: 'free' | 'collaborator' | 'basic' | 'premium' | 'enterprise';
    settings?: ICompanySettings;
    users?: IUser[];
    profiles?: IProfile[];
    vision?: string;
    industry?: string;
    company_values?: string[];
    billing_account_fk_id?: number;
    zoho_subscription_id?: number;
    group_fk_id?: number;
    parent__company_fk_id?: number;
    subcompanies?: ICompany[];
    clients?: ICompany[];
    '3pls'?: ICompany[];
    locations?: ICompanyLocation[];
    company_settings?: ICompanySettings;
    custom_deal_fields?: ICustomDealField[];
    participant_sub_types: IParticipantSubTypes[] = [];
    // @ts-ignore
    company_billing_settings: ICompanyBillingSettings;
    status: string | null = null;
    permissions: {[key: string]: boolean} | null = null;
}

export interface ICompanyCalculationSettings {
    capable_metrics_by_product_ids?: number[];
}

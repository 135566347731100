<section class="bg-white">
    <!------- Header ------->
    <header class="d-flex align-items-center px-3 list-header">
        <h3 class="m-0 mr-1 d-flex align-items-end">Bills</h3>

        <button
            rbac
            [rule]="{purchases__manage_bills: true}"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'block'}"
            [denyStyle]="{display: 'none'}"
            mat-stroked-button
            class="ml-3"
            [matMenuTriggerFor]="menu"
            color="warn"
            *ngIf="selection.selected.length"
        >
            Bulk Action ({{ isAllSelected ? 'All' : selection.selected.length }})
        </button>
        <mat-menu
            #menu="matMenu"
            rbac
            [rule]="{purchases__manage_bills: true}"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'block'}"
            [denyStyle]="{display: 'none'}"
        >
            <button mat-menu-item (click)="bulkDelete()">Delete</button>
            <button mat-menu-item *ngIf="checkSelectedHaveSameVendorAndPayable()" (click)="bulkPay()">Pay Bills</button>
        </mat-menu>
        <i class="sp-spacer"></i>

        <app-date-range-picker
            class="mr-2 mt-2"
            [floatLabel]="'never'"
            (click)="$event.stopPropagation()"
            (dateEmitter)="setRange($event)"
            [maxDate]="range.controls.end_date.value"
            [minDate]="range.controls.start_date.value"
        ></app-date-range-picker>

        <app-company-compensation-combined-picker
            class="mr-2 mt-2"
            style="width: 180px"
            [availableTypes]="['contact']"
            [floatLabel]="'never'"
            [title]="'Vendor Name'"
            [only_compensation_groups]="false"
            [singleSelected]="true"
            [bold_style]="false"
            [nodesFA]="moneySenderCtrlArr"
            [initialContactIds]="contactIds"
        >
        </app-company-compensation-combined-picker>

        <mat-slide-toggle class="mr-3" [formControl]="excludeDealBillsControl">
            Exclude Bills from Deals
        </mat-slide-toggle>

        <mat-form-field class="mt-2" [floatLabel]="'never'">
            <mat-label>Filter</mat-label>
            <mat-select [formControl]="statusFilter" multiple>
                <mat-optgroup [label]="'Bill Status'">
                    <mat-option
                        *ngFor="let status of getBillStatuses()"
                        [value]="status"
                        (onSelectionChange)="changeStatusFilter($event)"
                    >
                        {{ getBillStatusValue(status) | fromSnakeCase }}
                    </mat-option>
                </mat-optgroup>
                <mat-optgroup [label]="'Payment Status'">
                    <mat-option
                        *ngFor="let status of getPaymentStatuses()"
                        [value]="status"
                        (onSelectionChange)="changeStatusFilter($event)"
                    >
                        {{ getPaymentStatusValue(status) | fromSnakeCase }}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>

        <button
            class="ml-2"
            rbac
            [rule]="{purchases__manage_bills: true}"
            [denyMethod]="'style'"
            [allowStyle]="{display: 'block'}"
            [denyStyle]="{display: 'none'}"
            type="button"
            mat-raised-button
            color="primary"
            routerLink="/purchases/bills/create"
        >
            <mat-icon>add</mat-icon>
            Create
        </button>
    </header>

    <!------- List of bills ------->
    <div class="table-background">
        <div *ngIf="isMasterToggleChecked && selection.selected.length !== scrollData.total" class="select-all-banner">
            All {{ selection.selected.length }} transactions on this page are selected.&nbsp;
            <a class="select-all-link" (click)="selectAll()">Select all {{ scrollData.total }} transactions?</a>
        </div>
        <div *ngIf="isAllSelected" class="select-all-banner">
            All {{ scrollData.total }} transactions are selected.&nbsp;
            <a class="select-all-link" (click)="clearSelection()">Clear selections?</a>
        </div>

        <table
            mat-table
            [dataSource]="dataSource"
            [ngClass]="{'d-none': dataSource.data.length === 0}"
            matSort
            matSortDisableClear
            matSortActive="{{ scrollData.sort_column }}"
            [matSortDirection]="scrollData.sort_direction"
            (matSortChange)="changeSort($event)"
            class="w-100"
        >
            <ng-container matColumnDef="checkbox">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="w-5"
                    [ngStyle]="
                        isAllSelected || (isMasterToggleChecked && selection.selected.length !== scrollData.total)
                            ? {top: '47px'}
                            : {top: '0'}
                    "
                >
                    <!--                    <mat-checkbox #bulkSelect-->
                    <!--                                  class="checkbox-area"-->
                    <!--                                  [ngClass]="{'checked': isAllSelected}"-->
                    <!--                                  (change)="$event ? masterToggle() : null"-->
                    <!--                                  [checked]="isAllSelected || isEntirePageSelected()">-->
                    <!--                    </mat-checkbox>-->
                </th>
                <td mat-cell *matCellDef="let element" class="w-5" (click)="$event.stopPropagation()">
                    <mat-checkbox
                        (change)="toggleBulkCheckbox($event, element.bill_id)"
                        [value]="element.bill_id"
                        [checked]="selection.isSelected(element.bill_id)"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="invoice_date">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="w-10 pl-1"
                    [ngStyle]="
                        isAllSelected || (isMasterToggleChecked && selection.selected.length !== scrollData.total)
                            ? {top: '47px'}
                            : {top: '0'}
                    "
                >
                    Bill Date
                </th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.invoice_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="bill_number">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="w-10 pl-1"
                    [ngStyle]="
                        isAllSelected || (isMasterToggleChecked && selection.selected.length !== scrollData.total)
                            ? {top: '47px'}
                            : {top: '0'}
                    "
                >
                    Bill#
                </th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.bill_number || 'N/A' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="receiver">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="w-10 pl-1"
                    [ngStyle]="
                        isAllSelected || (isMasterToggleChecked && selection.selected.length !== scrollData.total)
                            ? {top: '47px'}
                            : {top: '0'}
                    "
                >
                    Vendor Name
                </th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <span *ngIf="element.receiver" [ngClass]="element.contactIsHidden ? 'hidden-contact' : ''">
                        {{ element.receiver }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="connected_invoice_number">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="w-10 pl-1"
                    [ngStyle]="
                        isAllSelected || (isMasterToggleChecked && selection.selected.length !== scrollData.total)
                            ? {top: '47px'}
                            : {top: '0'}
                    "
                >
                    Reference
                </th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.connected_invoice_number || 'N/A' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="summary_status">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="w-10 pl-1"
                    [ngStyle]="
                        isAllSelected || (isMasterToggleChecked && selection.selected.length !== scrollData.total)
                            ? {top: '47px'}
                            : {top: '0'}
                    "
                >
                    Status
                </th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    <ng-container *ngIf="element.summary_status">
                        <span [ngStyle]="{color: statusColor[element.summary_status]}">
                            {{ element.summary_status | fromSnakeCase }}
                        </span>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="due_date">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="w-10 pl-1"
                    [ngStyle]="
                        isAllSelected || (isMasterToggleChecked && selection.selected.length !== scrollData.total)
                            ? {top: '47px'}
                            : {top: '0'}
                    "
                >
                    Due Date
                </th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.due_date | dateFromNumber | date: 'mediumDate' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status_of_payment">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="w-10 pl-1"
                    [ngStyle]="
                        isAllSelected || (isMasterToggleChecked && selection.selected.length !== scrollData.total)
                            ? {top: '47px'}
                            : {top: '0'}
                    "
                >
                    Payments Status
                </th>
                <td mat-cell *matCellDef="let element" class="w-10 px-1">
                    {{ element.status_of_payment | fromSnakeCase }}
                </td>
            </ng-container>

            <ng-container matColumnDef="total_amount">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="w-10 table-header-align-right"
                    [ngStyle]="
                        isAllSelected || (isMasterToggleChecked && selection.selected.length !== scrollData.total)
                            ? {top: '47px'}
                            : {top: '0'}
                    "
                >
                    Total Amount
                </th>
                <td mat-cell *matCellDef="let element" class="w-10 px-3 text-right">
                    {{ element.total_amount | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="balance">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="w-10 table-header-align-right"
                    [ngStyle]="
                        isAllSelected || (isMasterToggleChecked && selection.selected.length !== scrollData.total)
                            ? {top: '47px'}
                            : {top: '0'}
                    "
                >
                    Balance
                </th>
                <td mat-cell *matCellDef="let element" class="w-10 px-3 text-right">
                    {{ element.balance | currency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="w-5 text-right"
                    [ngStyle]="
                        isAllSelected || (isMasterToggleChecked && selection.selected.length !== scrollData.total)
                            ? {top: '47px'}
                            : {top: '0'}
                    "
                >
                    Action
                </th>
                <td mat-cell *matCellDef="let element" class="w-5 text-right">
                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button
                            mat-menu-item
                            rbac
                            [rule]="{purchases__manage_bills: true}"
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'block'}"
                            [denyStyle]="{display: 'none'}"
                            [disabled]="
                                ['write_off', 'void', 'rejected'].includes(element.summary_status) ||
                                (element.creator__company_fk_id !== currentCompanyId &&
                                    element.owner__company_fk_id !== currentCompanyId)
                            "
                            (click)="editBill(element)"
                        >
                            Edit
                        </button>
                        <button
                            mat-menu-item
                            rbac
                            [rule]="{purchases__manage_bills: true}"
                            [denyMethod]="'style'"
                            [allowStyle]="{display: 'block'}"
                            [denyStyle]="{display: 'none'}"
                            [disabled]="
                                !['draft', 'open', 'sent', 'overdue'].includes(element.summary_status) ||
                                !element.connected__invoice_fk_id ||
                                (element?.payments && element?.payments.length > 0)
                            "
                            (click)="disconnectBill(element)"
                        >
                            Disconnect
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="row.id" class="zp-table-row"></tr>
        </table>

        <!------- Paginator ------->
        <div class="custom-paginator-background"></div>
        <mat-paginator
            class="custom-paginator"
            [length]="scrollData.total"
            [pageSize]="scrollData.limit"
            [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
        <!------- No values ------->
        <div *ngIf="dataSource.data && dataSource.data.length === 0" class="text-muted m-3 text-center no-items">
            There are no bills.
        </div>
    </div>
</section>

import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import type {IReport, IReportSetting, ITag} from '@cyberco-nodejs/zipi-typings';
import {TagsService} from '../../../../../../services/api/tags.service';
import {
    timePeriodObj,
    transactionTypeList,
    listOfPropertyClass,
    listOfTransactionStatus,
    listOfDealApproval
} from '../../../../constants';
import {getNextActivePanel, isLastPanelItem} from '../../helper';

type RankAgentPerfBy =
    | 'first_name'
    | 'last_name'
    | 'total_count'
    | 'sum_volume'
    | 'company_split'
    | 'compensation_split_sum'
    | 'agent_net'
    | 'avg_commission_percent';

type AgentPerfReportSettings = Pick<
    IReportSetting,
    | 'time_period'
    | 'time_period_start'
    | 'time_period_end'
    | 'transaction_type'
    | 'property_class'
    | 'transaction_status'
    | 'deal_approval'
    | 'tags'
    | 'entities'
    | 'divisions'
> & {
    rank_by: RankAgentPerfBy;
};

@Component({
    selector: 'app-report-edit-bar-agent-performance',
    templateUrl: './agent-performance.component.html',
    styleUrls: ['./agent-performance.component.scss']
})
export class AgentPerformanceBarComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Output() action: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Input() report: IReport | null = null;

    public reportSettings: Partial<AgentPerfReportSettings> | null = null;
    private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);

    public allTags: ITag[] = [];
    public transactionTypeList = transactionTypeList;
    public listOfPropertyClass = listOfPropertyClass;
    public listOfTransactionStatus = listOfTransactionStatus;
    public listOfDealApproval = listOfDealApproval;
    public listOfRankBy: Array<{value: RankAgentPerfBy; title: string}> = [
        {value: 'first_name', title: 'First Name'},
        {value: 'last_name', title: 'Last Name'},
        {value: 'total_count', title: 'Total Count'},
        {value: 'sum_volume', title: 'Agent Volume'},
        {value: 'compensation_split_sum', title: 'Agent Split'},
        {value: 'agent_net', title: 'Agent Net'},
        {value: 'company_split', title: 'Company Split'},
        {value: 'avg_commission_percent', title: 'Avg Comm %'}
    ];

    public isShowNext: boolean = true;
    public activePanel: keyof AgentPerfReportSettings = 'time_period';
    public readonly reportOptionPanels: Array<{value: keyof AgentPerfReportSettings; title: string}> = [
        {value: 'time_period', title: 'Time Period'},
        {value: 'transaction_type', title: 'Transaction Type'},
        {value: 'property_class', title: 'Property Class'},
        {value: 'transaction_status', title: 'Status'},
        {value: 'deal_approval', title: 'Deal Approval'},
        {value: 'tags', title: 'Report Tagging'},
        {value: 'entities', title: 'Customers'},
        {value: 'divisions', title: 'Offices'},
        {value: 'rank_by', title: 'Rank By'}
    ];

    private defaultReportSettings: Partial<AgentPerfReportSettings> = {
        time_period: timePeriodObj.this_year.value,
        transaction_type: ['buyer', 'seller'],
        property_class: ['residential', 'commercial'],
        transaction_status: ['opportunity', 'active', 'pending', 'processed'],
        deal_approval: ['approved', 'unapproved'],
        rank_by: 'total_count'
    };

    constructor(private tagsService: TagsService) {}

    ngOnInit() {
        this.initData();
        this.initSubscription();
    }

    initData() {
        if (this.report) {
            this.reportSettings = {...this.defaultReportSettings, ...this.report.settings};
        }
    }

    initSubscription() {
        this.tagsService
            .getCompanyTags()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.allTags = data.result;
            });
    }

    onSelectOptions(event: {value: keyof AgentPerfReportSettings}) {
        this.activePanel = event.value;
        this.isShowNext = !isLastPanelItem(this.activePanel, this.reportOptionPanels);
    }

    onValueChanges<P extends keyof AgentPerfReportSettings>(property: P, data: AgentPerfReportSettings[P]) {
        switch (property) {
            case 'time_period':
                const timerPeriodProps = data as unknown as Partial<AgentPerfReportSettings>;
                this.reportSettings = {
                    ...this.reportSettings,
                    ...timerPeriodProps
                };
                break;
            default:
                if (this.reportSettings) {
                    this.reportSettings[property] = data;
                }
        }
    }

    onAction(event: string) {
        switch (event) {
            case 'save': // fallthrough
            case 'run':
                this.action.emit({type: event, data: this.reportSettings});
                break;
            case 'cancel': // fallthrough
            case 'delete':
                this.action.emit({type: event, data: null});
                break;
            case 'next':
                const nextData = getNextActivePanel(this.activePanel, this.reportOptionPanels);
                if (nextData.name === '') break;
                this.activePanel = nextData.name;
                this.isShowNext = !nextData.isLast;
                break;
        }
    }

    ngOnDestroy() {
        this.action.complete();
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }
}

<div *ngIf="locationCtrl" [formGroup]="locationCtrl">
    <div class="row sp-location sp-location__edit">
        <div class="col-8">
            <mat-form-field class="w-100">
                <input
                    type="text"
                    matInput
                    formControlName="full_street"
                    placeholder="Street Address"
                    [errorStateMatcher]="errMatcher"
                    required
                />
                <mat-error align="end" *ngIf="locationCtrl.get('street_address')!.invalid">required</mat-error>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field class="w-100">
                <input type="text" matInput formControlName="unit_number" placeholder="Unit" />
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field class="w-100">
                <input
                    type="text"
                    matInput
                    formControlName="city"
                    placeholder="City"
                    [errorStateMatcher]="errMatcher"
                    required
                />
                <mat-error align="end" *ngIf="locationCtrl.get('city')!.invalid">required</mat-error>
            </mat-form-field>
        </div>

        <div class="col-2">
            <mat-form-field class="w-100">
                <input
                    type="text"
                    matInput
                    formControlName="state"
                    placeholder="State"
                    [errorStateMatcher]="errMatcher"
                    required
                />
                <mat-error align="end" *ngIf="locationCtrl.get('state')!.invalid">required</mat-error>
            </mat-form-field>
        </div>

        <div class="col-2">
            <mat-form-field class="w-100">
                <input
                    type="text"
                    matInput
                    formControlName="zip"
                    placeholder="Zip"
                    [errorStateMatcher]="errMatcher"
                    required
                />
                <mat-error align="end" *ngIf="locationCtrl.get('zip')!.invalid">required</mat-error>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field class="w-100">
                <input
                    type="text"
                    matInput
                    formControlName="country"
                    placeholder="Country"
                    [errorStateMatcher]="errMatcher"
                    required
                />
                <mat-error align="end" *ngIf="locationCtrl.get('country')!.invalid">required</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="zp-btn-group" *ngIf="isAbleToEdit">
        <button
            type="button"
            *ngIf="isEditingMode === false"
            mat-icon-button
            (click)="editLocation()"
            class="sp-location-remove"
        >
            <mat-icon class="sp-edit-icon">edit</mat-icon>
        </button>

        <button
            type="button"
            *ngIf="removeLocation"
            mat-icon-button
            (click)="removeLocation()"
            class="sp-location-remove"
        >
            <mat-icon class="sp-delete-icon">delete</mat-icon>
        </button>
    </div>
</div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {InvoicePublicService} from '../../../services/invoice-public.service';
import {ICompany, IContact, IInvoice, IPaymentMethod, IProfile} from '@cyberco-nodejs/zipi-typings';
import {AuthService} from '../../../../../services/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {isEqual} from 'lodash-es';
import {Store} from '@ngrx/store';
import {IContactInvitesState} from '../../../../contact-invites/store/contact-invites.reducers';
import {PayInvoiceDialogComponent} from '../../invoice/pay-invoice-dialog/pay-invoice-dialog.component';
import {PaymentTypeSelectionDialogComponent} from '../dialogs/payment-type-selection-dialog/payment-type-selection-dialog.component';
import {PayViaAuthorizeDialogComponent} from '../dialogs/pay-via-authorize-dialog/pay-via-authorize-dialog.component';
import {NotificationsServiceZipi} from '../../../../notifications/notifications.service';
import {EnterToPortalComponent} from '../enter-to-portal/enter-to-portal.component';
import {ZipiFinancialIframeDialogComponent} from '@app/modules/account-info/company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-financial-iframe-dialog/zipi-financial-iframe-dialog.component';
import {IZipiFinancialIframeDialogData} from '@app/typings/zipi-financial-iframe';
import {SendingPaymentLoaderDialogComponent} from '@app/modules/finance/components/common/sending-payment-loader-dialog/sending-payment-loader-dialog.component';

const win: any = window;
const Payload: any = win.Payload;

@Component({
    selector: 'app-invoice-public-page-v2',
    templateUrl: './invoice-public-page-v2.component.html',
    styleUrls: ['./invoice-public-page-v2.component.scss']
})
export class InvoicePublicPageV2Component implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    invoiceHash: string | null = null;
    invoice: IInvoice | undefined;

    zipiFinCustomerRef: string | null = null;

    currentContact: IContact | undefined;
    currentProfile: IProfile | undefined;
    existUserCompanies: ICompany[] = [];
    loggedUserCompanies: ICompany[] = [];

    canViewInApp: boolean = false;

    invoicePaid: boolean;

    invoicePayloadBankMethod: IPaymentMethod | null = null;
    invoicePayloadCardMethod: IPaymentMethod | null = null;
    invoiceAuthorizeNetMethod: IPaymentMethod | null = null;

    constructor(
        protected activatedRoute: ActivatedRoute,
        private invoicePublicService: InvoicePublicService,
        protected authService: AuthService,
        public dialog: MatDialog,
        protected store: Store<IContactInvitesState>,
        protected notificationServiceZipi: NotificationsServiceZipi,
        private router: Router
    ) {
        this.invoicePaid = false;
    }

    isEqual: (o1: any, o2: any) => boolean = isEqual;

    async ngOnInit() {
        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            this.invoiceHash = params['invoice_hash'];

            if (this.invoiceHash) {
                this.invoicePublicService
                    .getInfoForPublicInvoicePageByInvoiceHash(this.invoiceHash)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((response) => {
                        this.invoice = response.invoice;
                        this.currentProfile = response.profile;
                        this.zipiFinCustomerRef = response.zipi_fin_customer_ref;
                        if (this.invoice.summary_status === 'paid') {
                            this.invoicePaid = true;
                            return;
                        }

                        if (
                            this.currentProfile &&
                            this.currentProfile.email &&
                            this.currentProfile.email !== this.invoice.money_sender_email
                        ) {
                            this.invoicePublicService
                                .checkExistCompanies(this.currentProfile.email)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((userCompanies) => {
                                    this.loggedUserCompanies = userCompanies;
                                });
                        }
                        this.loadContact();
                        this.loadMethods(this.invoice);
                    });
            }
        });
    }

    loadContact() {
        if (this.invoice && this.invoice.money_sender__contact_fk_id) {
            this.invoicePublicService
                .getPublicContact(this.invoice.money_sender__contact_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((contact) => {
                    this.currentContact = contact;
                    this.checkPermissions();
                });
        }
    }

    loadMethods(invoice: IInvoice) {
        if (invoice.pay_to_bank__payment_method_fk_id) {
            this.invoicePublicService
                .getInvoiceMethod(invoice.pay_to_bank__payment_method_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((bankMethod) => {
                    if (
                        bankMethod &&
                        bankMethod.payment_gateway &&
                        bankMethod.payment_gateway.driver_type === 'payload'
                    ) {
                        this.invoicePayloadBankMethod = bankMethod;
                    }
                });
        }
        if (invoice.pay_to_card__payment_method_fk_id) {
            this.invoicePublicService
                .getInvoiceMethod(invoice.pay_to_card__payment_method_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((cardMethod) => {
                    if (
                        cardMethod &&
                        cardMethod.payment_gateway &&
                        cardMethod.payment_gateway.driver_type === 'payload'
                    ) {
                        this.invoicePayloadCardMethod = cardMethod;
                    } else if (
                        cardMethod &&
                        cardMethod.payment_gateway &&
                        cardMethod.payment_gateway.type === 'authorize_net_merchant'
                    ) {
                        this.invoiceAuthorizeNetMethod = cardMethod;
                    }
                });
        }
    }

    checkPermissions() {
        this.canViewInApp =
            !!this.currentProfile &&
            this.currentProfile.company!.id !== this.invoice!.owner__company_fk_id &&
            this.currentContact!.partner__company_fk_id === this.currentProfile.company!.id &&
            this.currentProfile.company!.id === this.invoice!.money_sender__company_fk_id;
    }

    openInPortal() {
        if (this.invoice && this.invoice.money_sender_email) {
            this.invoicePublicService
                .checkExistCompanies(this.invoice.money_sender_email)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((userCompanies) => {
                    this.existUserCompanies = userCompanies;
                    const dialogRef = this.dialog.open(EnterToPortalComponent, {
                        width: '600px',
                        data: {
                            companies: this.existUserCompanies,
                            loggedCompanies: this.loggedUserCompanies,
                            currentContact: this.currentContact,
                            currentProfile: this.currentProfile,
                            invoice: this.invoice,
                            invoiceHash: this.invoiceHash
                        }
                    });

                    dialogRef
                        .afterClosed()
                        .pipe(
                            filter((pn) => !!pn),
                            takeUntil(this.unsubscribe)
                        )
                        .subscribe((ok) => {
                            if (ok) {
                            }
                        });
                });
        }
    }

    viewInApp() {
        if (this.invoice) {
            if (this.invoice.connected__bill_fk_id) {
                this.router.navigate([`/purchases/bills/${this.invoice.connected__bill_fk_id}`]);
            } else {
                this.router.navigate([`/purchases/sourcedocuments/approve/${this.invoice.invoice_id}`]);
            }
        }
    }

    payInvoice() {
        if (this.invoicePayloadBankMethod && (this.invoicePayloadCardMethod || this.invoiceAuthorizeNetMethod)) {
            this.openSelectionPopup();
        } else if (
            !this.invoiceAuthorizeNetMethod &&
            (this.invoicePayloadBankMethod || this.invoicePayloadCardMethod)
        ) {
            this.prepareToOpenPayloadForm(this.invoicePayloadBankMethod ? 'bank' : 'card');
        } else if (
            !this.invoicePayloadBankMethod &&
            !this.invoicePayloadCardMethod &&
            !!this.invoiceAuthorizeNetMethod
        ) {
            this.payViaAuthorize();
        } else {
            this.notificationServiceZipi.addInfo('There is no way to pay this Invoice.');
        }
    }

    openSelectionPopup() {
        const dialogRef = this.dialog.open<PaymentTypeSelectionDialogComponent>(PaymentTypeSelectionDialogComponent, {
            minWidth: '600px',
            maxHeight: '80vh',
            disableClose: true,
            data: {}
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result: 'bank' | 'card' | null) => {
                if (result) {
                    if (result === 'bank') {
                        this.prepareToOpenPayloadForm('bank');
                    } else if (result === 'card') {
                        if (this.invoiceAuthorizeNetMethod) {
                            this.payViaAuthorize();
                        } else {
                            this.prepareToOpenPayloadForm('card');
                        }
                    }
                }
            });
    }

    prepareToOpenPayloadForm(payloadPayType: 'bank' | 'card') {
        if (this.currentContact) {
            if (
                !this.currentContact.contact_payment_gateways ||
                this.currentContact.contact_payment_gateways.length === 0 ||
                !this.currentContact.contact_payment_gateways.some((cpg) => cpg.driver_type === 'payload')
            ) {
                this.setupZipiFinancialForContact('contact_gateway_only', 'payload', payloadPayType);
            } else {
                this.payViaPayload(payloadPayType);
            }
        }
    }

    async payViaPayload(type: 'bank' | 'card') {
        if (this.currentContact) {
            const currentMethod: IPaymentMethod | null =
                type === 'bank' ? this.invoicePayloadBankMethod : this.invoicePayloadCardMethod;
            if (
                this.invoice &&
                this.invoice.invoice_id &&
                currentMethod &&
                currentMethod &&
                currentMethod.payment_method_id
            ) {
                const settings = await firstValueFrom(
                    this.invoicePublicService.getPayloadSettings(
                        currentMethod.payment_method_id,
                        this.invoice.invoice_id
                    )
                );
                if (!settings.token) {
                    console.error('Cannot get settings to initialize payment popup.');
                    return;
                }
                Payload(settings.token);

                const checkout = new Payload.Checkout({
                    card_payments: type === 'card',
                    bank_account_payments: type === 'bank'
                })
                    .on('loaded', (evt: any) => {})
                    .on('authorized', (evt: any) => {})
                    .on('processed', (evt: {transaction_id: string; type: 'processed'}) => {})
                    .on('success', (evt: {transaction_id: string; type: 'success'}) => {
                        this.createPayloadCoPayment(evt.transaction_id, type);
                    })
                    .on('declined', (evt: any) => {})
                    .on('closed', (evt: any) => {});
            }
        }
    }

    payViaAuthorize() {
        if (this.invoice && this.currentContact && this.invoiceAuthorizeNetMethod) {
            const dialogRef = this.dialog.open<
                PayViaAuthorizeDialogComponent,
                {title: string; contact: IContact; method: IPaymentMethod; invoice: IInvoice}
            >(PayViaAuthorizeDialogComponent, {
                minWidth: '600px',
                maxHeight: '80vh',
                data: {
                    title: 'Pay Invoice',
                    invoice: this.invoice,
                    contact: this.currentContact,
                    method: this.invoiceAuthorizeNetMethod
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    this.ngOnInit();
                });
        }
    }

    createPayloadCoPayment(payloadTransactionId: string, type: 'bank' | 'card') {
        const payloadCoPaymentObj = {
            transaction_id: payloadTransactionId,
            invoice_id: this.invoice!.invoice_id,
            pay_to_payment_method_id: null,
            type: type
        };

        if (type === 'bank' && this.invoicePayloadBankMethod && this.invoicePayloadBankMethod.payment_method_id) {
            payloadCoPaymentObj.pay_to_payment_method_id = this.invoicePayloadBankMethod.payment_method_id as any;
        } else if (
            type === 'card' &&
            this.invoicePayloadCardMethod &&
            this.invoicePayloadCardMethod.payment_method_id
        ) {
            payloadCoPaymentObj.pay_to_payment_method_id = this.invoicePayloadCardMethod.payment_method_id as any;
        }

        const dialogRef = this.dialog.open<SendingPaymentLoaderDialogComponent, {driver: 'payload_co' | 'authorize'}>(
            SendingPaymentLoaderDialogComponent,
            {
                minWidth: '500px',
                maxHeight: '80vh',
                disableClose: true,
                data: {
                    driver: 'payload_co'
                }
            }
        );

        this.invoicePublicService
            .publicPayloadCoPay(payloadCoPaymentObj)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                dialogRef.close();
                this.ngOnInit();
            });
    }

    chargeCustomer() {
        if (this.invoice) {
            const dialogRef = this.dialog.open<
                PayInvoiceDialogComponent,
                {
                    invoice: IInvoice;
                    access_method: 'public' | 'internal';
                    title: string;
                    current_profile: IProfile | null;
                }
            >(PayInvoiceDialogComponent, {
                minWidth: '600px',
                maxHeight: '80vh',
                data: {
                    invoice: this.invoice,
                    access_method: 'public',
                    title: 'Pay Invoice',
                    current_profile: null
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((result) => {
                    this.ngOnInit();
                });
        }
    }

    async setupZipiFinancialForContact(
        methodType: 'contact_gateway_only',
        driverType: 'payload',
        payloadPayType: 'bank' | 'card'
    ) {
        let mainPerson = null;
        if (
            this.currentContact &&
            this.currentContact.contact_persons &&
            this.currentContact.contact_persons.length > 0
        ) {
            mainPerson = this.currentContact.contact_persons.find((person) => person.type === 'main_person');
        }
        const contactData = {
            company_name: this.currentContact?.company_name ? this.currentContact?.company_name : '',
            email: mainPerson && mainPerson.email ? mainPerson.email : '',
            first_name: mainPerson && mainPerson.first_name ? mainPerson.first_name : '',
            last_name: mainPerson && mainPerson.last_name ? mainPerson.last_name : '',
            nick_name: mainPerson && mainPerson.preferred_name ? mainPerson.preferred_name : ''
        };
        const dialogRef = this.dialog.open<ZipiFinancialIframeDialogComponent, IZipiFinancialIframeDialogData>(
            ZipiFinancialIframeDialogComponent,
            {
                disableClose: true,
                maxHeight: '80vh',
                width: '650px',
                panelClass: 'custom-dialog-container',
                data: {
                    contactId: this.currentContact?.contact_id ? this.currentContact?.contact_id : null,
                    gateway: null,
                    ownGateway: null,
                    driverType: driverType,
                    paymentMethod: null,
                    methodType: methodType,
                    accessMethod: 'public',
                    isUniversal: true,
                    customerId: this.zipiFinCustomerRef,
                    prefillData: contactData,
                    creationSource: 'current_company',
                    storeAccountMethod: 'required'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result.isError) {
                    this.notificationServiceZipi.addError(result.message);
                }
                if (result.isClosed) {
                    return;
                }
                this.loadContact();
                this.payViaPayload(payloadPayType);
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

import {Component, OnInit, OnDestroy, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {firstValueFrom, Subject} from 'rxjs';
import {IAccountingLog, ILedgerAccount, IPayment, IPaymentMade} from '@cyberco-nodejs/zipi-typings';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {NotificationsService} from 'angular2-notifications';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {SessionService} from 'app/services/session.service';
import {PaymentsMadeService} from '../../../services/payments-made.service';
import {PAYMENT_CREDITS_STATUS_COLOR, PAYMENT_MODES_MAP} from 'app/local-typings';
import {LedgerAccountService} from '../../../../../services/api/finance/ledger-accounts.service';
import {DealFinancialApiService} from '../../../../deals/components/deal/deal-financial.api.service';
import {DealService} from '../../../../../services/deal.service';
import {Deal} from '../../../../../models/deal';
import {ConfirmComponent} from '../../../../../layouts/confirm/confirm.component';
import {PrintChecksDialogComponent} from '../print-checks-dialog/print-checks-dialog.component';
import {IPaymentMadeListItem} from '../typings';
import {FeatureFlagsService} from '@app/modules/feature-flags/feature-flags.service';
import moment from 'moment-timezone';
@Component({
    selector: 'app-payment-made-page',
    templateUrl: 'payment-made-page.component.html',
    styleUrls: ['payment-made-page.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PaymentMadePageComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    companyTitle: string | null = null;
    payment: (IPaymentMade & {related_payments: Array<IPayment & {applied: number; pending: number}>}) | undefined;
    dataSource: MatTableDataSource<IPayment>;
    dataConnectedSource: MatTableDataSource<IPayment>;
    displayedRelatedColumns = ['payment_date', 'invoice_id', 'source_document_title', 'status', 'amount'];

    relatedDeals: Deal[] = [];

    showPayments: boolean;
    blobUrl: Blob | null = null;
    paymentId: number | null = null;

    paymentModesMap = PAYMENT_MODES_MAP;
    statusColor = PAYMENT_CREDITS_STATUS_COLOR;
    showHistory: boolean;
    showRefundedPayments: boolean;

    appliedToInvoices: number = 0;
    pendingForInvoices: number = 0;
    pendingBalance: number = 0;
    unapplied: number = 0;
    pendingBalanceForUnapplied: number = 0;
    completedRefunds: number = 0;

    currentPaidThroughAccount: ILedgerAccount | undefined;
    additionalInfoLoaded: boolean;

    paymentMadeLogs: IAccountingLog[] = [];
    paymentMadeOldLogs: Array<any> = [];
    logsLoaded: boolean = false;

    isVoidAllowed: boolean = false;

    // Feature flags
    isPayStubPrintAvailable: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private sessionService: SessionService,
        private ntfs: NotificationsService,
        private paymentsMadeService: PaymentsMadeService,
        public dialog: MatDialog,
        public router: Router,
        private ledgerAccountService: LedgerAccountService,
        private dealFinancialApiService: DealFinancialApiService,
        private dealService: DealService,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.dataSource = new MatTableDataSource<IPayment>([]);
        this.dataConnectedSource = new MatTableDataSource<IPayment>([]);
        this.showPayments = false;
        this.showHistory = false;
        this.showRefundedPayments = false;
        this.additionalInfoLoaded = false;
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isPayStubPrintAvailable = this.featureFlagsService.isFeatureEnabled('purchases:pay_stub');
            });
    }

    openCustomizeDialog() {
        if (
            this.payment &&
            this.payment.payment_mode === 'check' &&
            this.payment.check_info &&
            this.payment.check_info.print_status === 'not_printed'
        ) {
            const paymentForPrint: IPaymentMadeListItem = this.payment as any;
            if (this.payment && this.payment.money_receiver_contact) {
                paymentForPrint['vendor_name'] = this.payment.money_receiver_contact.display_name as any;
            } else {
                paymentForPrint['vendor_name'] = '';
            }
            const dialogRef = this.dialog.open(PrintChecksDialogComponent, {
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                maxWidth: '100%',
                panelClass: 'no-padding-dialog',
                data: [paymentForPrint]
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    this.initPreview();
                });
        } else {
            this.ntfs.error(`This check has already been printed.`);
        }
    }

    initPreview() {
        this.companyTitle = this.sessionService.profile!.company!.title;
        this.blobUrl = null;

        this.route.paramMap
            .pipe(
                map((pm) => {
                    const stringId: string | null = pm.get('id');
                    return Number(stringId);
                }),
                filter((maybeId) => !isNaN(maybeId)),
                takeUntil(this.unsubscribe)
            )
            .subscribe((id) => {
                this.paymentId = id;

                this.paymentsMadeService
                    .getPaymentMadeById(id)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(
                        (payment: IPaymentMade) => {
                            this.payment = payment as IPaymentMade & {
                                related_payments: Array<IPayment & {applied: number; pending: number}>;
                            };
                            this.loadLedgerAccount();
                            this.getDealsAddresses();
                            this.checkAbilityToVoidRefund();

                            if (this.payment.refunded_payments_made && this.payment.refunded_payments_made.length > 0) {
                                let payments: Array<IPayment | IPaymentMade> = [];
                                this.payment.refunded_payments_made.forEach((refundedPayment) => {
                                    if (
                                        refundedPayment.related_payments &&
                                        refundedPayment.related_payments.length > 0
                                    ) {
                                        payments = payments.concat(refundedPayment.related_payments);
                                    } else {
                                        payments.push(refundedPayment);
                                    }
                                });
                                payments.sort((a, b) => {
                                    const dateA: string = a.created_at as unknown as string;
                                    const dateB: string = a.created_at as unknown as string;
                                    if (+new Date(dateA) > +new Date(dateB)) {
                                        return 1;
                                    }
                                    if (+new Date(dateA) < +new Date(dateB)) {
                                        return -1;
                                    }
                                    return 0;
                                });
                                this.paymentMadeOldLogs = payments;
                            }

                            if (payment.related_payments) {
                                this.dataSource.data = payment.related_payments;
                            }
                            if (
                                payment.connected_payment_received &&
                                payment.connected_payment_received.related_payments
                            ) {
                                this.dataConnectedSource.data = payment.connected_payment_received.related_payments;
                            }
                            this.calculateAmounts();
                        },
                        (err) => {
                            setTimeout(() => this.router.navigate(['/purchases/payments/']), 1500);
                        }
                    );
            });
    }

    checkAbilityToVoidRefund() {
        if (this.payment && this.payment.created_at) {
            // hours UTC when PayloadCo creates and sends instructions for banks
            const payloadCoDeadlineHours = 23;

            let payloadCoDeadlineTime = null;
            const hoursOfPaymentCreation = moment(this.payment.created_at as any)
                .utc()
                .hours();
            if (hoursOfPaymentCreation < payloadCoDeadlineHours) {
                // we have time to void till 23:00 UTC
                payloadCoDeadlineTime = moment(this.payment.created_at as any)
                    .utc()
                    .set('hour', payloadCoDeadlineHours)
                    .set('minute', 0)
                    .set('second', 0);
            } else {
                // we have time to void till the next day 23:00 UTC
                payloadCoDeadlineTime = moment(this.payment.created_at as any)
                    .utc()
                    .add(1, 'days')
                    .set('hour', payloadCoDeadlineHours)
                    .set('minute', 0)
                    .set('second', 0);
            }
            const now = moment().utc();

            this.isVoidAllowed = now.isBefore(payloadCoDeadlineTime);
        }
    }

    loadLedgerAccount() {
        if (this.payment && this.payment.paid_by__ledger_account_fk_id) {
            this.ledgerAccountService
                .getLedgerAccountById(this.payment.paid_by__ledger_account_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((ledgerAccount: ILedgerAccount) => {
                    if (ledgerAccount && ledgerAccount.ledger_account_id) {
                        this.currentPaidThroughAccount = ledgerAccount;
                    }
                    this.additionalInfoLoaded = true;
                });
        } else {
            this.additionalInfoLoaded = true;
        }
    }

    async getDealsAddresses() {
        if (this.payment) {
            const uniqueDealIds: Set<number> = new Set();
            if (this.payment.source__deposit_release_fk_id) {
                const dealRelease = await firstValueFrom(
                    this.dealFinancialApiService.getDealDepositRelease(this.payment.source__deposit_release_fk_id)
                );
                if (dealRelease && dealRelease.deal_fk_id) {
                    uniqueDealIds.add(dealRelease.deal_fk_id);
                }
            }
            if (this.payment.source__deal_fk_id) {
                uniqueDealIds.add(this.payment.source__deal_fk_id);
            }
            if (this.payment.related_payments) {
                for (const relatedPayment of this.payment.related_payments) {
                    if (relatedPayment.bill && relatedPayment.bill.items && relatedPayment.bill.items.length > 0) {
                        for (const billItem of relatedPayment.bill.items) {
                            if (billItem.connected__deal_fk_id) {
                                uniqueDealIds.add(billItem.connected__deal_fk_id);
                            }
                        }
                    }
                }
            }

            if (uniqueDealIds.size > 0) {
                this.relatedDeals = [];
                for (const dealId of uniqueDealIds) {
                    this.dealService.getDealBaseView(dealId).then((deal) => {
                        this.relatedDeals.push(deal);
                    });
                }
            }
        }
    }

    calculateAmounts() {
        let billRefunds: IPayment[] = [];
        const excessRefunds: IPaymentMade[] = [];
        if (this.payment && this.payment.refunded_payments_made && this.payment.refunded_payments_made.length > 0) {
            for (const refundPayment of this.payment.refunded_payments_made) {
                if (refundPayment.summary_status === 'completed') {
                    this.completedRefunds += refundPayment.amount;
                }
                if (refundPayment.related_payments && refundPayment.related_payments.length > 0) {
                    billRefunds = billRefunds.concat(refundPayment.related_payments);
                } else {
                    excessRefunds.push(refundPayment);
                }
            }
        }

        if (this.payment && this.payment.related_payments && this.payment.related_payments.length > 0) {
            for (const payment of this.payment.related_payments as unknown as Array<
                IPayment & {applied: number; pending: number}
            >) {
                let applied = 0;
                let appliedPending = 0;
                let refunded = 0;
                let refundedPending = 0;

                if (payment.bill_fk_id) {
                    if (payment.status === 'completed') {
                        applied = payment.amount;
                    }
                    if (['processing', 'scheduled'].includes(payment.status)) {
                        appliedPending = payment.amount;
                    }
                    if (payment.refunded_amount > 0) {
                        const refundPayment = billRefunds.find(
                            (ref) => ref.refund_source__payment_fk_id === payment.payment_id
                        );
                        if (refundPayment) {
                            if (refundPayment.status === 'completed') {
                                refunded = refundPayment.amount;
                            }
                            if (refundPayment.status === 'processing') {
                                refundedPending = refundPayment.amount;
                            }
                        }
                    }
                }
                payment['applied'] = applied - refunded;
                payment['pending'] = payment['applied'] + (appliedPending - refundedPending);

                this.appliedToInvoices += applied - refunded;
                this.pendingForInvoices += appliedPending - refundedPending;
            }

            this.pendingBalance = this.appliedToInvoices + this.pendingForInvoices;

            const excessPendingRefundAmount = excessRefunds.reduce(
                (acc, pay) => (pay.summary_status === 'processing' ? pay.amount + acc : acc),
                0
            );
            const excessCompletedRefundAmount = excessRefunds.reduce(
                (acc, pay) => (pay.summary_status === 'completed' ? pay.amount + acc : acc),
                0
            );
            const completedAmount = this.payment.related_payments.reduce(
                (acc, pay) => (pay.status === 'completed' ? pay.amount + acc : acc),
                0
            );

            this.unapplied = this.payment.amount - completedAmount - excessCompletedRefundAmount;
            this.pendingBalanceForUnapplied = this.unapplied - excessPendingRefundAmount;
        }
    }

    loadHistory() {
        this.logsLoaded = false;
        this.showRefundedPayments = !this.showRefundedPayments;
        if (this.showHistory && this.payment && this.payment.payment_made_id) {
            this.paymentsMadeService
                .getPaymentMadeHistory(this.payment.payment_made_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((logs) => {
                    this.paymentMadeLogs = logs;
                    this.logsLoaded = true;
                });
        }
    }

    redirectToSource(sourceInvoiceId: number) {
        this.router.navigate([`/purchases/sourcedocuments/approve/${sourceInvoiceId}`]);
    }

    recheckPaymentStatus(paymentMadeId: number) {
        this.paymentsMadeService
            .recheckPaymentMadeStatusById(paymentMadeId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((message) => {
                if (message) {
                    this.ntfs.info(`External Status`, message);
                    this.initPreview();
                }
            });
    }

    cancelPayment() {
        const dialogRef = this.dialog.open(ConfirmComponent, {
            data: {
                title: `Cancel Scheduled Payment`,
                message: `Please confirm that you would like to cancel this payment.`,
                buttonCancelMessage: 'Dismiss',
                buttonOkMessage: 'Confirm'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((confirmed: boolean) => {
                if (!confirmed) {
                    return;
                }
                if (this.payment && this.payment.payment_made_id) {
                    this.paymentsMadeService
                        .cancelPaymentMade(this.payment.payment_made_id)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((message) => {
                            if (message) {
                                this.router.navigate(['/purchases/payments/']);
                            }
                        });
                }
            });
    }

    printPayStub() {
        if (this.payment && this.payment.payment_made_id) {
            this.paymentsMadeService
                .createPayStub(this.payment.payment_made_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response.result && response.result.error) {
                        this.ntfs.error(response.result.error);
                        return;
                    }

                    const blob = new Blob([new Uint8Array(response.result.data)], {type: 'application/pdf'});
                    const blobUrl = URL.createObjectURL(blob);
                    const iframe = document.createElement('iframe');
                    iframe.id = 'print-frame';

                    iframe.style.display = 'none';
                    iframe.src = blobUrl;
                    iframe.onload = () => {
                        setTimeout(() => {
                            if (iframe.contentWindow) {
                                iframe.contentWindow.print();
                            }
                        }, 0);
                    };
                    document.body.appendChild(iframe);
                });
        }
    }

    voidPayment() {
        if (this.payment && this.payment.external_transaction_ref && this.payment.payment_made_id) {
            this.paymentsMadeService
                .voidPaymentMade(this.payment.payment_made_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((message) => {
                    if (message) {
                        this.ntfs.info(`The voiding process has begun.`);
                        this.initPreview();
                    }
                });
        }
    }

    ngOnInit() {
        this.initPreview();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from 'app/services/session.service';
import {IPayment, IPaymentMade, IProfile} from '@cyberco-nodejs/zipi-typings';
import {NotificationsService} from 'angular2-notifications';
import {PaymentsMadeService} from '../../../services/payments-made.service';
import {PAGE_SIZE_OPTIONS, PAYMENT_CREDITS_STATUS_COLOR, PAYMENT_MODES_MAP} from 'app/local-typings';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {ProfilesService} from 'app/services/profiles.service';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import {MatOptionSelectionChange} from '@angular/material/core';
import {UntypedFormControl} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {IScrollData} from 'app/models/scroll-data';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FeatureFlagsService} from 'app/modules/feature-flags/feature-flags.service';
import {PrintChecksDialogComponent} from '../print-checks-dialog/print-checks-dialog.component';
import {ChecksLedgerErrorPopupComponent} from '../checks-ledger-error-popup/checks-ledger-error-popup.component';

@Component({
    selector: 'app-payments-made-list',
    templateUrl: 'payments-made-list.component.html',
    styleUrls: ['payments-made-list.component.scss', '../../../../../../assets/infinite-scroll-table.scss']
})
export class PaymentsMadeListComponent implements OnInit, AfterViewInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    selection: SelectionModel<number> = new SelectionModel<number>(true, []);

    paymentModesMap = PAYMENT_MODES_MAP;
    statusColor = PAYMENT_CREDITS_STATUS_COLOR;
    statusTypes = [
        'all',
        'processing',
        'completed',
        'scheduled',
        'cleared',
        'uncleared',
        'printed',
        'not_printed',
        'expired',
        'declined',
        'reversed',
        'canceled',
        'review',
        'pending',
        'error'
    ];
    statusFilter: UntypedFormControl = new UntypedFormControl([]);

    scrollData: IScrollData = {
        offset: 0,
        limit: 50,
        sort_column: 'paid_date',
        sort_direction: 'asc',
        filter: null,
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<IPaymentMade>;
    displayedColumns = [
        'checkbox',
        'paid_date',
        'status',
        'vendor_name',
        'payment_made_number',
        'reference',
        'check_number',
        'payment_mode',
        'amount_applied',
        'amount',
        'action'
    ];

    isSelectedForBulk: boolean;

    // Feature flags
    isPayStubPrintAvailable: boolean = false;
    batchCheckPrintingEnabled: boolean = false;

    constructor(
        protected currentProfileSource: CurrentProfileSource,
        public sessionService: SessionService,
        private profileService: ProfilesService,
        private paymentsMadeService: PaymentsMadeService,
        public router: Router,
        private route: ActivatedRoute,
        private ntfs: NotificationsService,
        public dialog: MatDialog,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.dataSource = new MatTableDataSource<IPaymentMade>([]);
        this.isSelectedForBulk = false;
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.batchCheckPrintingEnabled = this.featureFlagsService.isFeatureEnabled(
                    'purchases:batch_check_printing'
                );
                this.isPayStubPrintAvailable = this.featureFlagsService.isFeatureEnabled('purchases:pay_stub');
            });
    }

    ngOnInit() {
        if (this.sessionService.profile && this.sessionService.profile.settings) {
            this.scrollData.sort_direction = this.sessionService.profile.settings.sort_payments_made_direction;
            this.scrollData.sort_column = this.sessionService.profile.settings.sort_payments_made_column;

            this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
                if (params['company_id']) {
                    const companyId = Number(atob(params['company_id']));
                    const currentProfile: IProfile = this.sessionService.profile as unknown as IProfile;
                    this.redirect(currentProfile, companyId);
                }
            });

            if (this.paginator) {
                this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                    this.scrollData.limit = data.pageSize;
                    this.scrollData.offset = data.pageSize * data.pageIndex;

                    this.nextBatch();
                });
            }
        }
    }

    ngAfterViewInit() {
        this.nextBatch();
    }

    async redirect(currentProfile: IProfile, companyId: number) {
        if (currentProfile.company && currentProfile.company.company_id === companyId) {
            await this.router.navigate(['/purchases/payments']);
        } else {
            await this.router.navigate(['/']);
        }
    }

    nextBatch() {
        // cancel previous batch requests
        this.unsubscribeBatch.next();

        if (this.statusFilter.value && !this.statusFilter.value.includes('all')) {
            this.scrollData.filter = this.statusFilter.value;
        } else {
            this.scrollData.filter = '';
        }

        this.paymentsMadeService
            .getPaymentsMade(this.scrollData)
            .pipe(
                tap((data) => {
                    this.dataSource.data = data.result;
                    this.scrollData.total = data._meta.total;
                }),
                takeUntil(this.unsubscribeBatch)
            )
            .subscribe();
    }

    paymentCancel(payment: IPayment) {
        // this.paymentsReceivedService.cancelPayment(payment.payment_id)
        //     .pipe(takeUntil(this.unsubscribe))
        //     .subscribe(response => {
        //         this.ngOnInit();
        //     });
    }

    recheckPaymentStatus() {
        this.paymentsMadeService
            .recheckCompanyMadePaymentsStatuses()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isStatusesWasChanged) => {
                if (isStatusesWasChanged) {
                    this.ngOnInit();
                } else {
                    this.ntfs.warn(`Statuses have not changed.`);
                }
            });
    }

    resetData() {
        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;
        this.scrollData.total = 0;

        this.nextBatch();
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        if (this.sessionService.profile && this.sessionService.profile.settings) {
            this.sessionService.profile.settings.sort_payments_made_direction = this.scrollData.sort_direction;
            this.sessionService.profile.settings.sort_payments_made_column = this.scrollData.sort_column;
            const id = Number(this.sessionService.profile.id);
            const settings = this.sessionService.profile.settings;
            this.profileService
                .updateProfileSettings(id, settings)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response) {
                        this.currentProfileSource.triggers.changeRequesterProfile.next(response.result);
                    }
                });

            this.resetData();
        }
    }

    toggleBulkCheckbox($event: any, id: number) {
        if ($event) {
            this.selection.toggle(id as never); // dirty hack
        }
        this.isSelectedForBulk = this.selection.selected.length !== 0;
    }

    bulkDelete() {
        const paymentMadeIdsForBulk = this.selection.selected;
        if (paymentMadeIdsForBulk.length > 0) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: 320,
                data: {
                    title: 'Deleting Payments Made',
                    message: `${paymentMadeIdsForBulk.length} Payments Made will be deleted.`,
                    messages: [
                        `If there is a payment related to the invoice or related journal has matched or reconciled transaction, it will not be deleted.`,
                        `You must first remove payments from the Payment Made, unmatch and unreconcile related transaction.`
                    ]
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((ok) => {
                    if (ok) {
                        this.paymentsMadeService
                            .bulkDeletePaymentsMade(paymentMadeIdsForBulk)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((res) => {
                                this.selection.clear();
                                this.isSelectedForBulk = false;
                                this.dataSource.data = [];
                                this.resetData();
                                const successfulDeleted = res.deleted_payments
                                    ? `<br>${res.deleted_payments} Deleted`
                                    : '';
                                const notDeleted = res.not_deleted_payments
                                    ? `<br>${res.not_deleted_payments} Not Deleted`
                                    : '';

                                this.ntfs.success(`Payments:${successfulDeleted}${notDeleted}`);
                            });
                    }
                });
        }
    }

    changeStatusFilter(event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            const {value, selected} = event.source;
            let statusFilter = this.statusFilter.value;

            if (value === 'all') {
                statusFilter = selected ? this.statusTypes : [];
            } else if (value !== 'all') {
                if (statusFilter.includes('all') && !selected) {
                    statusFilter = statusFilter.filter((f: string) => f !== 'all' && f !== value);
                } else {
                    statusFilter = this.statusTypes.filter(
                        (f) => (f !== value && statusFilter.includes(f)) || (f === value && selected)
                    );
                }
            }

            // update values
            this.statusFilter.setValue(statusFilter);
            this.resetData();
        }
    }

    getPaymentMadeStatus(payment: IPaymentMade) {
        if (payment.payment_mode === 'check' && payment.check_info) {
            if (payment.check_info.print_status === 'not_printed') {
                return payment.check_info.print_status;
            }

            if (payment.check_info.print_status === 'printed') {
                return payment.check_info.check_status;
            }
        } else {
            return payment.summary_status;
        }
    }

    openCustomizeDialog() {
        // Find any non-printable checks that were selected. Throw an error if there are any.
        const nonPrintableCheckPayments = this.dataSource.data.filter((p) =>
            p.payment_made_id
                ? this.selection.selected.includes(p.payment_made_id) &&
                  p.payment_mode === 'check' &&
                  (this.getPaymentMadeStatus(p) === 'completed' || this.getPaymentMadeStatus(p) === 'cleared')
                : false
        );

        if (nonPrintableCheckPayments.length > 0) {
            this.ntfs.error(`Completed and Cleared checks can not be printed`);
            return;
        }

        const checksToPrint = this.dataSource.data.filter((paymentMade) =>
            paymentMade.payment_made_id
                ? this.selection.selected.includes(paymentMade.payment_made_id) &&
                  paymentMade.payment_mode === 'check' &&
                  (this.getPaymentMadeStatus(paymentMade) === 'not_printed' ||
                      this.getPaymentMadeStatus(paymentMade) === 'uncleared')
                : false
        );

        // Check for mixed statuses among checks selected
        if (checksToPrint.length > 1) {
            const firstStatus = this.getPaymentMadeStatus(checksToPrint[0]);
            const hasMixedStatuses = checksToPrint.some(
                (payment) => this.getPaymentMadeStatus(payment) !== firstStatus
            );

            if (hasMixedStatuses) {
                this.ntfs.error(
                    `Some of your selections are unable to be processed together. Please ensure all of your selected items are in the same status`
                );
                return;
            }
        }

        if (checksToPrint.length > 0) {
            const checksLedgerIds = checksToPrint.reduce((ledgerIdsArr: number[], currentCheck: IPaymentMade) => {
                if (!ledgerIdsArr.includes(currentCheck.paid_by__ledger_account_fk_id)) {
                    ledgerIdsArr.push(currentCheck.paid_by__ledger_account_fk_id);
                }
                return ledgerIdsArr;
            }, []);

            if (checksLedgerIds.length > 1) {
                this.dialog.open(ChecksLedgerErrorPopupComponent, {
                    panelClass: 'checks-ledger-error-dialog'
                });
                return;
            }

            const dialogRef = this.dialog.open(PrintChecksDialogComponent, {
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                maxWidth: '100%',
                panelClass: 'no-padding-dialog',
                data: checksToPrint
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    this.selection.clear();
                    this.isSelectedForBulk = false;
                    this.dataSource.data = [];
                    this.resetData();
                });
        } else {
            this.ntfs.error(`Not printed checks were not selected`);
        }
    }

    downloadPayStub() {
        const paymentMadeIds = this.selection.selected;
        if (paymentMadeIds.length > 0) {
            this.paymentsMadeService
                .orderPayStubGeneration(paymentMadeIds)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response) {
                        this.selection.clear();
                        this.ntfs.success(
                            `PayStub${paymentMadeIds.length > 1 ? 's' : ''} created.`,
                            ` It can be found and opened in the top notifications bell when complete.`
                        );
                    }
                });
        }
    }

    printPayStub(paymentMade: IPaymentMade) {
        if (paymentMade && paymentMade.payment_made_id) {
            this.paymentsMadeService
                .createPayStub(paymentMade.payment_made_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response.result && response.result.error) {
                        this.ntfs.error(response.result.error);
                        return;
                    }

                    const blob = new Blob([new Uint8Array(response.result.data)], {type: 'application/pdf'});
                    const blobUrl = URL.createObjectURL(blob);
                    const iframe = document.createElement('iframe');
                    iframe.id = 'print-frame';

                    iframe.style.display = 'none';
                    iframe.src = blobUrl;
                    iframe.onload = () => {
                        setTimeout(() => {
                            if (iframe.contentWindow) {
                                iframe.contentWindow.print();
                            }
                        }, 0);
                    };
                    document.body.appendChild(iframe);
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}

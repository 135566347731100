import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {Subject} from 'rxjs';
import {UntypedFormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';
import {GenericFormArray, GenericFormGroup} from '../../../../entites/generic.entity';
import {Router} from '@angular/router';
import {NotificationsService} from 'angular2-notifications';
import {filter, takeUntil} from 'rxjs/operators';
import {RbacService} from '../../../rbac/rbac.service';
import {IScrollData} from '../../../../models/scroll-data';
import {UsersAndLicencesApiService} from '../../users-licenses/users-and-licences.api.service';
import {MatDialog} from '@angular/material/dialog';
import {MarketplaceApi} from '../marketplace.api';
import {Group} from '../../../../models/group';
import {CurrentProfileSource} from '../../../../services/sources/current-profile.source';
import {GroupApi} from '../../../../services/api/group.api';
import {DashAddonApi, DashCompanySettingsResponse} from './dash-addon.api';
import {FormGroupWithFormControls, FormGroupWithArrayAsControl} from '../../../../typings/common';

@Component({
    selector: 'app-dash-addon-settings',
    template: `
        <div class="container my-4">
            <h1 class="my-3">Dash (Realogy) settings</h1>
            <section class="mb-4">
                <h4 class="d-flex align-items-center">
                    <span>Dash (Realogy) Company ID</span>
                    <mat-icon class="text-muted ml-1" [matTooltip]="selectCompanyIdTooltipText">help</mat-icon>
                </h4>
                <p class="text-muted mb-0" style="font-size: 14px;">Example: de305d54-75b4-431b-adb2-eb6b9e546014</p>
                <form [formGroup]="companyIdForm" class="row">
                    <div class="col-8">
                        <mat-form-field class="w-100">
                            <mat-placeholder>Dash Company ID</mat-placeholder>
                            <input matInput [disabled]="true" required type="text" formControlName="dash_company_id" />
                        </mat-form-field>
                    </div>
                    <div class="col-2 d-flex align-items-center">
                        <button
                            mat-raised-button
                            [disabled]="isLoading"
                            class="ml-2"
                            color="primary"
                            (click)="updateDashCompanyId($event)"
                            tabindex="1"
                        >
                            Save
                        </button>
                    </div>
                </form>
                <div class="row" *ngIf="!errorOnFetchCompanyText && dashCompanyName">
                    <div class="col">
                        <span>Company Name:</span>
                        <span class="font-weight-bold"> {{ dashCompanyName }}</span>
                    </div>
                </div>
                <div class="row" *ngIf="errorOnFetchCompanyText">
                    <div class="col-8" style="color: red;">
                        {{ errorOnFetchCompanyText }}
                    </div>
                    <div class="col-2">
                        <button
                            mat-raised-button
                            type="button"
                            [disabled]="isLoading"
                            class="ml-2"
                            color="primary"
                            (click)="retry()"
                            tabindex="1"
                        >
                            Retry
                        </button>
                    </div>
                </div>
            </section>
            <section>
                <h4>Offices to Divisions</h4>
                <form [formGroup]="divisionsToOfficesForm">
                    <ng-container formArrayName="divisions_offices_mapping">
                        <div
                            *ngFor="
                                let officeDivisionSet of divisionsToOfficesForm.controls.divisions_offices_mapping
                                    .controls;
                                let index = index
                            "
                            [formGroupName]="index"
                            class="row w-100"
                        >
                            <!--<span>{{officeDivisionSet.value | json}}</span>-->
                            <mat-form-field class="col-4">
                                <mat-select
                                    [disabled]="true"
                                    placeholder="Dash (Realogy) Office"
                                    formControlName="dash_office_guid"
                                >
                                    <mat-option *ngFor="let office of dashOffices" [value]="office.officeGuid">
                                        {{ office.officeName }} ({{ office.primaryEmailAddress }})
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="col-4">
                                <mat-select placeholder="SkySlope Books Division" formControlName="zipi_divison_id">
                                    <mat-option *ngFor="let zipiDivision of zipiDivisions" [value]="zipiDivision.id">
                                        {{ zipiDivision.title }} ({{ zipiDivision.id }})
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <div *ngIf="dashOffices.length" class="row pb-4">
                        <button
                            mat-raised-button
                            style="margin-left: 15px;"
                            [disabled]="isLoading"
                            color="primary"
                            (click)="updateOfficesDivisionsMapping($event)"
                            tabindex="1"
                        >
                            Save mapping
                        </button>
                    </div>
                </form>
            </section>
        </div>
    `,
    styleUrls: []
})
export class DashAddonSettingsComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    companyIdForm: FormGroupWithFormControls = this.fb.group(
        {
            // https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
            dash_company_id: [
                null,
                [
                    Validators.required,
                    Validators.pattern(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i)
                ]
            ]
        },
        {updateOn: 'change'}
    ) as FormGroupWithFormControls;

    officesToDivisionsArray = this.fb.array([]);

    // divisionsToOfficesForm: GenericFormGroup<{
    //     divisions_offices_mapping: GenericFormArray<{dash_office_guid: string, zipi_divison_id: number}>
    // }> = this.fb.group({
    //     divisions_offices_mapping: this.officesToDivisionsArray,
    // }, { updateOn: 'change' });
    divisionsToOfficesForm: FormGroupWithArrayAsControl = this.fb.group(
        {
            divisions_offices_mapping: this.officesToDivisionsArray
        },
        {updateOn: 'change'}
    ) as FormGroupWithArrayAsControl;

    zipiDivisions: Group[] = [];
    dashCompanyName: string | null = null;
    dashOffices: Array<{
        officeGuid: string;
        officeName: string;
        officeId: string;
        primaryEmailAddress: string;
    }> = [];

    selectCompanyIdTooltipText =
        'This attribute represents the GUID used for the Company ID in Realogy system. This will be always of the format 32 hexadecimal digits separated by hyphens. DataType: GUID (format: 00000000-0000-0000-0000-000000000000) Example: 13a7337c-45e3-4eb4-a2b2-ee489b56ec8e';
    isLoading = false;
    isCompanySettingsLoaded = false; // used to identify if GET company-settings request already done or not
    // dashCompanySettings = null;
    dashCompanyId = null; // stored as company-settings in spanner database
    errorOnFetchCompanyText: string | null = null;

    constructor(
        public router: Router,
        // private companyGatewayService: CompanyGatewayService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        protected rbacService: RbacService,
        protected usersAndLicencesApiService: UsersAndLicencesApiService,
        public dialog: MatDialog,
        public marketplaceApi: MarketplaceApi,
        protected currentProfileSource: CurrentProfileSource,
        public groupApi: GroupApi,
        public dashAddonApi: DashAddonApi
    ) {}

    ngOnInit() {
        this.rbacService.isAllowed({marketplace__manage_dash: true}).then((can) => {
            if (!can) {
                this.router.navigate(['/']);
            }
        });

        this.isLoading = true;
        this.dashAddonApi.loadDashAddonCompanySettings().then((res) => {
            this.handleSettings(res);

            // todo - add divisions list to company-settings request
            this.isLoading = false;
            this.marketplaceApi.getDivisionsList().then((list: Group[]) => {
                this.zipiDivisions = list;
            });
        });
    }

    retry() {
        this.isLoading = true;
        this.dashAddonApi.loadDashAddonCompanySettings().then((res) => {
            this.handleSettings(res);
            this.isLoading = false;
        });
    }

    handleSettings(res: DashCompanySettingsResponse) {
        this.isCompanySettingsLoaded = true;
        // this.dashCompanySettings = res.dash_company_settings;
        if (res.dash_company_settings && res.dash_company_settings.dash_company_id) {
            this.companyIdForm.controls.dash_company_id!.patchValue(res.dash_company_settings.dash_company_id);
        }

        this.errorOnFetchCompanyText = res.error_on_fetch_company;

        if (res.dash_company_with_offices) {
            this.dashCompanyName = res.dash_company_with_offices.companyName;
            this.dashOffices = res.dash_company_with_offices.offices;

            res.dash_company_with_offices.offices.forEach((dashOffice) => {
                if (res.dash_company_settings && Array.isArray(res.dash_company_settings.divisions_offices_mapping)) {
                    const foundMapping = res.dash_company_settings.divisions_offices_mapping.find(
                        (m) => m.dash_office_guid === dashOffice.officeGuid
                    );
                    if (foundMapping) {
                        const newFG = new GenericFormGroup(foundMapping);
                        this.officesToDivisionsArray.push(newFG);
                    } else {
                        const newFG = new GenericFormGroup({
                            dash_office_guid: dashOffice.officeGuid,
                            zipi_divison_id: null
                        });
                        this.officesToDivisionsArray.push(newFG);
                    }
                } else {
                    const newFG = new GenericFormGroup({
                        dash_office_guid: dashOffice.officeGuid,
                        zipi_divison_id: null
                    });
                    this.officesToDivisionsArray.push(newFG);
                }
            });
        }
    }

    updateDashCompanyId(ev: Event): void {
        ev.preventDefault();
        if (this.companyIdForm.invalid) {
            return;
        }

        const data = this.companyIdForm.getRawValue();

        this.isLoading = true;
        this.dashAddonApi
            .updateDashAddonCompanySettings(data)
            .then((res) => {
                this.dashAddonApi.loadDashAddonCompanySettings().then((response) => {
                    this.handleSettings(response);
                    this.isLoading = false;
                });
            })
            .catch(() => {
                this.isLoading = false;
            });
    }

    updateOfficesDivisionsMapping(ev: Event): void {
        ev.preventDefault();
        if (this.divisionsToOfficesForm.invalid) {
            return;
        }

        const data = this.divisionsToOfficesForm.getRawValue();

        // console.log(data, 'updateOfficesDivisionsMapping rawvalue');

        this.isLoading = true;
        this.dashAddonApi
            .updateDashAddonCompanySettings(data)
            .then((res) => {
                this.isLoading = false;
            })
            .catch(() => {
                this.isLoading = false;
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

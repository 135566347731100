import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Sort} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';

import type {AgentStatementData} from '../statement-page/statement-page.component';

export const MAX_CHECKBOXES = 50;

@Component({
    selector: 'app-invoice-statement-table',
    templateUrl: 'statement-table.component.html',
    styleUrls: ['../../../../../../assets/infinite-scroll-table.scss']
})
export class StatementTableComponent {
    @Input() dataSource: MatTableDataSource<AgentStatementData>;
    @Input() selection = new SelectionModel<number>(true, []);

    @Output() sort = new EventEmitter<Sort>();
    @Output() rowSelection = new EventEmitter<{id: number; checked: boolean}>();
    @Output() rowClick = new EventEmitter<AgentStatementData>();

    displayedColumns: Array<keyof AgentStatementData | 'checkbox'> = [
        'checkbox',
        'contact_name',
        'prev_balance_due',
        'total_amount',
        'payments_amount',
        'credit_notes',
        'last_sent',
        'balance_due'
    ];

    constructor() {
        this.dataSource = new MatTableDataSource<any>([]);
    }

    changeSort(sort: Sort) {
        this.sort.emit(sort);
    }

    onCheckboxChange(id: number, isChecked: boolean) {
        if (isChecked) {
            if (this.selection.selected.length < MAX_CHECKBOXES) {
                this.selection.select(id);
            }
        } else {
            this.selection.deselect(id);
        }
    }

    isCheckBoxDisabled(contactId: number): boolean {
        return !this.selection.isSelected(contactId) && this.selection.selected.length >= MAX_CHECKBOXES;
    }

    handleClickRow(row: AgentStatementData) {
        this.rowClick.emit(row);
    }
}

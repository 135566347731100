import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild} from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import {IBill} from '@cyberco-nodejs/zipi-typings';
import {takeUntil, tap, filter} from 'rxjs/operators';
import {ProfilesService} from 'app/services/profiles.service';
import {BillsService} from 'app/services/api/finance/bills.service';
import {MatDialog} from '@angular/material/dialog';
import {SessionService} from 'app/services/session.service';
import {CurrentProfileSource} from 'app/services/sources/current-profile.source';
import {BILL_INVOICE_STATUS_COLOR, PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationsService} from 'angular2-notifications';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {SelectionModel} from '@angular/cdk/collections';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatOptionSelectionChange} from '@angular/material/core';
import {GenericFormArray} from 'app/entites/generic.entity';
import {ChipNode} from '../../../../account-info/compensation/models/chip-node';
import {IScrollData} from 'app/models/scroll-data';
import {MatPaginator} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-bills',
    templateUrl: './bills.component.html',
    styleUrls: ['./bills.component.scss', '../../../../../../assets/infinite-scroll-table.scss']
})
export class BillsComponent implements OnInit, AfterViewInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    private unsubscribeBatch: Subject<void> = new Subject();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;

    selection: SelectionModel<number> = new SelectionModel<number>(true, []);

    contactIds: Array<number> = [];

    statusTypes = [
        'all',
        'open',
        'sent',
        'overdue',
        'partial_payment',
        'paid',
        'write_off',
        'void',
        'rejected',
        'draft'
    ];
    paymentTypes = ['all', 'unpaid', 'processing', 'pending', 'partially_paid', 'paid', 'need_confirm'];
    statusFilter: UntypedFormControl = new UntypedFormControl([]);
    excludeDealBillsControl: UntypedFormControl = new UntypedFormControl(false);
    moneySenderCtrlArr: GenericFormArray<ChipNode> = new GenericFormArray<ChipNode>([]);
    range: UntypedFormGroup = this.fb.group({
        start_date: [null, []],
        end_date: [null, []]
    });

    statusColor = BILL_INVOICE_STATUS_COLOR;

    currentCompanyId: number | null = null;
    isLoading: boolean = false;
    scrollData: IScrollData = {
        offset: 0,
        limit: 50,
        sort_column: 'bill_number',
        sort_direction: 'asc',
        summary_status_filter: '',
        payment_status_filter: '',
        exclude_bills_from_deals: false,
        money_receiver_id: '',
        start_date: '',
        end_date: '',
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;

    dataSource: MatTableDataSource<IBill>;
    displayedColumns = [
        'checkbox',
        'invoice_date',
        'bill_number',
        'receiver',
        'connected_invoice_number',
        'summary_status',
        'due_date',
        'status_of_payment',
        'total_amount',
        'balance',
        'action'
    ];

    isAllSelected: boolean = false;
    isMasterToggleChecked: boolean = false;

    constructor(
        private billsService: BillsService,
        private ntfs: NotificationsService,
        public dialog: MatDialog,
        public sessionService: SessionService,
        public currentProfileSource: CurrentProfileSource,
        public profileService: ProfilesService,
        public router: Router,
        public fb: UntypedFormBuilder,
        protected route: ActivatedRoute
    ) {
        this.dataSource = new MatTableDataSource<IBill>([]);
    }

    async ngOnInit() {
        const routeQueryParams = await firstValueFrom(this.route.queryParams);
        if (routeQueryParams) {
            if (routeQueryParams.hasOwnProperty('contact_id')) {
                this.contactIds = [Number(routeQueryParams.contact_id)];
                this.scrollData.money_receiver_id = routeQueryParams.contact_id;
            }
            if (routeQueryParams.hasOwnProperty('status')) {
                if (routeQueryParams.status === 'overdue') {
                    this.statusFilter.setValue([`billstat_${routeQueryParams.status}`]);
                }
                if (routeQueryParams.status === 'current') {
                    this.statusFilter.setValue(['billstat_sent', 'billstat_open', 'billstat_partial_payment']);
                }
            }
        }
        if (this.sessionService.profile) {
            if (this.sessionService.profile.company && this.sessionService.profile.company.id) {
                this.currentCompanyId = this.sessionService.profile.company.id;
            }
            if (this.sessionService.profile.settings) {
                this.scrollData.sort_direction = this.sessionService.profile.settings.sort_bills_direction;
                this.scrollData.sort_column = this.sessionService.profile.settings.sort_bills_column;
            }
        }

        this.excludeDealBillsControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            this.scrollData.exclude_bills_from_deals = value;
            this.resetData();
        });

        this.moneySenderCtrlArr.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values: Array<any>) => {
            this.scrollData.money_receiver_id = values && values.length ? values[0].target_id : '';
            this.resetData();
        });

        this.range.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((values) => {
            this.scrollData.start_date = values.start_date || '';
            this.scrollData.end_date = values.end_date || '';
            this.resetData();
        });

        if (this.paginator) {
            this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
                this.scrollData.limit = data.pageSize;
                this.scrollData.offset = data.pageSize * data.pageIndex;

                this.nextBatch();
            });
        }
    }

    ngAfterViewInit() {
        this.isAllSelected = false;
        this.isMasterToggleChecked = false;
        setTimeout(() => this.nextBatch(), 0);
    }

    getBillStatuses() {
        return this.statusTypes.map((s) => `billstat_${s}`);
    }

    getPaymentStatuses() {
        return this.paymentTypes.map((s) => `paystat_${s}`);
    }

    getBillStatusValue(value: string) {
        return value.replace('billstat_', '');
    }

    getPaymentStatusValue(value: string) {
        return value.replace('paystat_', '');
    }

    changeStatusFilter(event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            const {value, selected} = event.source;
            const statusFilter = this.statusFilter.value;

            const invoicePrefix = 'billstat_';
            const paymentPrefix = 'paystat_';

            let iStatuses = statusFilter.filter((s: string) => s.indexOf('billstat_') !== -1);
            let pStatuses = statusFilter.filter((s: string) => s.indexOf('paystat_') !== -1);

            if (value.indexOf(invoicePrefix) !== -1) {
                if (value === 'billstat_all') {
                    iStatuses = selected ? this.getBillStatuses() : [];
                } else if (value !== 'billstat_all') {
                    if (iStatuses.includes('billstat_all') && !selected) {
                        iStatuses = iStatuses.filter((f: string) => f !== 'billstat_all' && f !== value);
                    } else {
                        iStatuses = this.getBillStatuses().filter(
                            (f) => (f !== value && iStatuses.includes(f)) || (f === value && selected)
                        );
                    }
                }
            }
            if (value.indexOf(paymentPrefix) !== -1) {
                if (value === 'paystat_all') {
                    pStatuses = selected ? this.getPaymentStatuses() : [];
                } else if (value !== 'paystat_all') {
                    if (pStatuses.includes('paystat_all') && !selected) {
                        pStatuses = pStatuses.filter((f: string) => f !== 'paystat_all' && f !== value);
                    } else {
                        pStatuses = this.getPaymentStatuses().filter(
                            (f) => (f !== value && pStatuses.includes(f)) || (f === value && selected)
                        );
                    }
                }
            }

            // update values
            this.statusFilter.setValue(iStatuses.concat(pStatuses));
            this.resetData();
        }
    }

    nextBatch() {
        // cancel previous batch requests
        this.unsubscribeBatch.next();
        this.isLoading = true;

        this.getScrollData();

        this.billsService
            .getBills(this.scrollData)
            .pipe(
                tap((data) => {
                    this.dataSource.data = data.result;

                    if (this.isAllSelected) {
                        this.selection.select(data.result.map((b) => b.bill_id) as never); // dirty hack
                    }

                    this.scrollData.total = data._meta.total;
                    this.isLoading = false;
                }),
                takeUntil(this.unsubscribeBatch)
            )
            .subscribe();
    }

    getScrollData() {
        const summaryStatusFilter: Array<string> = [];
        const paymentStatusFilter: Array<string> = [];

        this.statusFilter.value.forEach((status: string) => {
            if (status.indexOf('billstat_') !== -1) {
                summaryStatusFilter.push(status.replace('billstat_', ''));
            } else if (status.indexOf('paystat_') !== -1) {
                paymentStatusFilter.push(status.replace('paystat_', ''));
            }
        });

        if (summaryStatusFilter && !summaryStatusFilter.includes('all')) {
            this.scrollData.summary_status_filter = summaryStatusFilter;
        } else {
            this.scrollData.summary_status_filter = [];
        }

        if (paymentStatusFilter && !paymentStatusFilter.includes('all')) {
            this.scrollData.payment_status_filter = paymentStatusFilter;
        } else {
            this.scrollData.payment_status_filter = [];
        }
    }

    editBill(bill: IBill) {
        this.router.navigate([`purchases/bills/edit/${bill.bill_id}`]);
    }

    disconnectBill(bill: IBill) {
        if (bill.bill_id) {
            this.billsService
                .disconnectBill(bill.bill_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    if (result) {
                        this.ntfs.success('Bill successfully disconnected');
                    }
                    // update values
                    this.resetData();
                    this.nextBatch();
                });
        }
    }

    resetData() {
        this.paginator!.pageIndex = 0;
        this.scrollData.offset = 0;
        this.scrollData.total = 0;

        this.selection.clear();
        this.isAllSelected = false;
        this.isMasterToggleChecked = false;

        this.nextBatch();
    }

    changeSort(sort: Sort) {
        if (this.scrollData.sort_column === sort.active) {
            // change direction
            this.scrollData.sort_direction = sort.direction;
        } else {
            // change column
            this.scrollData.sort_column = sort.active;
            // change direction
            this.scrollData.sort_direction = sort.direction;
        }

        if (this.sessionService.profile && this.sessionService.profile.settings) {
            this.sessionService.profile.settings.sort_bills_direction = this.scrollData.sort_direction;
            this.sessionService.profile.settings.sort_bills_column = this.scrollData.sort_column;
            const id = Number(this.sessionService.profile.id);
            const settings = this.sessionService.profile.settings;
            this.profileService
                .updateProfileSettings(id, settings)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((response) => {
                    if (response) {
                        this.currentProfileSource.triggers.changeRequesterProfile.next(response.result);
                    }
                });

            this.resetData();
        }
    }

    toggleBulkCheckbox($event: any, id: number) {
        if ($event) {
            this.selection.toggle(id as never);

            this.isAllSelected = false;
            this.isMasterToggleChecked = false;
        }
    }

    isEntirePageSelected() {
        const selectedIds = this.selection.selected;
        const ids = this.dataSource.data.map((tr) => tr.bill_id);

        return !!ids.length && ids.every((r) => selectedIds.indexOf(r as never) >= 0);
    }

    masterToggle() {
        this.isAllSelected = false;

        if (this.isEntirePageSelected()) {
            this.isMasterToggleChecked = false;
            this.selection.clear();
        } else {
            this.isMasterToggleChecked = true;

            this.dataSource.data.forEach((row) => {
                if (row.bill_id && !this.selection.selected.includes(row.bill_id as never)) {
                    this.selection.select(row.bill_id as never);
                }
            });
        }
    }

    selectAll() {
        this.isAllSelected = true;
        this.isMasterToggleChecked = true;
        this.dataSource.data.forEach((row) => {
            if (row.bill_id && !this.selection.selected.includes(row.bill_id as never)) {
                this.selection.select(row.bill_id as never);
            }
        });
    }

    clearSelection() {
        this.isAllSelected = false;
        this.isMasterToggleChecked = false;
        this.selection.clear();
    }

    checkSelectedHaveSameVendorAndPayable() {
        if (this.selection.hasValue()) {
            const selectedBills = this.dataSource.data.filter((b) =>
                this.selection.selected.includes(b.bill_id as never)
            );

            const vendorId = selectedBills[0].money_receiver__contact_fk_id;
            return selectedBills.every(
                (sb) =>
                    sb.money_receiver__contact_fk_id === vendorId &&
                    !sb.source__company_fk_id &&
                    sb.pending_balance &&
                    sb.pending_balance > 0 &&
                    sb.status_of_invoice !== 'draft' &&
                    sb.status_of_payment !== 'paid'
            );
        }

        return false;
    }

    bulkDelete() {
        const billIdsForBulk = this.selection.selected;

        if (billIdsForBulk.length > 0) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: 320,
                data: {
                    title: 'Deleting Bills',
                    message:
                        // we do not allow to use bulkDeleteAllBills
                        // this.isAllSelected || this.isEntirePageSelected()  ?
                        // `All Bills will be deleted.` :
                        `${billIdsForBulk.length} Bill${billIdsForBulk.length > 1 ? 's' : ''} will be deleted.`,
                    messages: [
                        `If there is payment in the Bill or related journal has matched or reconciled transaction, it will not be deleted.`,
                        `You must first remove payments from the Bill, unmatch and unreconcile related transaction.`
                    ]
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((pn) => !!pn),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((ok) => {
                    if (ok) {
                        // we do not allow to use bulkDeleteAllBills
                        // if (this.isAllSelected || this.isEntirePageSelected()) {
                        //     this.getScrollData();
                        //     this.billsService.bulkDeleteAllBills(this.scrollData)
                        //         .pipe(takeUntil(this.unsubscribe))
                        //         .subscribe((res) => {
                        //             this.selection.clear();
                        //
                        //             this.resetData();
                        //
                        //             if (res.deleted_bills && !res.not_deleted_bills) {
                        //                 this.ntfs.success(`Bills:<br>${res.deleted_bills > 1 ? 'Bills have' : 'Bill has'} been successfully deleted`);
                        //             } else if (res.deleted_bills && res.not_deleted_bills) {
                        //                 this.ntfs.warn('Bills:<br>1 or more of your bills could not be deleted. They are referenced by one or more of the following: a deal, a payment, a transaction');
                        //             } else if (!res.deleted_bills && res.not_deleted_bills) {
                        //                 this.ntfs.error(`Bills:<br>${res.not_deleted_bills > 1 ? 'These bills' : 'This bill'} cannot be deleted. ${res.not_deleted_bills > 1 ? 'They are' : 'It is'} referenced by one or more of the following: a deal, a payment, a transaction`);
                        //             }
                        //         });
                        // } else {
                        this.getScrollData();
                        this.billsService
                            .bulkDeleteBills(billIdsForBulk)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((res) => {
                                this.selection.clear();

                                this.resetData();

                                if (res.deleted_bills && !res.not_deleted_bills) {
                                    this.ntfs.success(
                                        `Bills:<br>${res.deleted_bills > 1 ? 'Bills have' : 'Bill has'} been successfully deleted`
                                    );
                                } else if (res.deleted_bills && res.not_deleted_bills) {
                                    this.ntfs.warn(
                                        'Bills:<br>1 or more of your bills could not be deleted. They are referenced by one or more of the following: a deal, a payment, a transaction'
                                    );
                                } else if (!res.deleted_bills && res.not_deleted_bills) {
                                    this.ntfs.error(
                                        `Bills:<br>${res.not_deleted_bills > 1 ? 'These bills' : 'This bill'} cannot be deleted. ${res.not_deleted_bills > 1 ? 'They are' : 'It is'} referenced by one or more of the following: a deal, a payment, a transaction`
                                    );
                                }
                            });
                        // }
                    }
                });
        }
    }

    bulkPay() {
        const ids = this.selection.selected;
        this.router.navigate(['purchases/payments/create'], {queryParams: {bill_ids: ids}});
    }

    setRange(range: {dateFrom: string; dateTo: string}) {
        this.range.controls.start_date.patchValue(range.dateFrom);
        this.range.controls.end_date.patchValue(range.dateTo);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribeBatch.next();
        this.unsubscribeBatch.complete();
    }
}

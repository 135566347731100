import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../services/service.requester';
import {IMessage} from '../../../typings';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {Observable, Subscription} from 'rxjs';
import {SessionService} from '../../services/session.service';
import {NotificationsService} from 'angular2-notifications';
import {environment} from '../../../environments/environment';

export interface INotificationModel<T> {
    notification_id: number;
    profile_fk_id: number;
    company_fk_id: number;
    category: string;
    metadata: {
        addon_id: number | null;
        addon_title: string | null;
        addon_url: string | null;
    };
    payload: T;
    message: string;
    status: string;
    action: string;
    link: string;
    seen: boolean;
    seen_by: Object[];
    flagged_by: Object[];
    seen_by_sense: string;
}

@Injectable()
export class MessagesService {
    public messNotifications = null;
    public asyncNotifications: any = null;
    // public asyncNotificationsCounter;
    public sub: Subscription | null = null;

    protected ignoreQrNotificationsId: number | null = null;

    // path: string;
    pathAsyncNotify: string | null = null;
    // spinnerNeeded = null;

    constructor(
        protected requester: ServiceRequester,
        protected fireDb: AngularFireDatabase,
        protected notificationsService: NotificationsService,
        protected sessionService: SessionService
    ) {
        // this.path = `/messages-notifications/${(<any>environment).namespace}/${this.sessionService.user.firebase_id}`;
        if (this.sessionService.user && this.sessionService.profile) {
            this.pathAsyncNotify = `/async-notifications/${(<any>environment).namespace}/${this.sessionService.user.firebase_id}/${this.sessionService.profile.company_fk_id}`;
        }
    }

    /**
     * Run initialize to connect to FireDB ref to receiving notifications.
     */
    public initialize() {
        if (!this.sessionService.user || !this.sessionService.user.firebase_id || !this.pathAsyncNotify) {
            return;
        }

        // console.log('initialize');
        // this.messNotifications = this.fireDb
        //     .list(this.path, (ref) => {
        //         return ref.limitToLast(5).orderByChild('timestamp');
        //     })
        //     .valueChanges();

        this.asyncNotifications = this.fireDb
            .list(this.pathAsyncNotify, (ref) => {
                return ref.limitToLast(50).orderByChild('timestamp');
            })
            .snapshotChanges();

        // this.asyncNotificationsCounter = this.fireDb
        //     .list(this.pathAsyncNotify, (ref) => {
        //         return ref.orderByChild('seen').equalTo(false);
        //     })
        //     .snapshotChanges();
        //
        // this.spinnerNeeded = this.fireDb
        //     .list(this.pathAsyncNotify, (ref) => {
        //         return ref.orderByChild('status').equalTo('start');
        //     })
        //     .snapshotChanges();

        // this.sub = this.messNotifications.subscribe((list) => {
        //     const item = list.pop();
        //     const now = Date.now();
        //
        //     if (!item || item.quote_request_id === this.ignoreQrNotificationsId || (now - item.timestamp) > 5000) {
        //         return;
        //     }
        //
        //     this.showNotification(item);
        // });
    }

    /**
     * Call disconnect to unsubscribe from auth guarded FireDB reference.
     */
    public disconnect() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    public ignoreQr(qrId: number) {
        this.ignoreQrNotificationsId = qrId;
    }

    public resetIgnoredQr(qrId: number) {
        this.ignoreQrNotificationsId = null;
    }

    // showNotification(item: IMessageNotification) {
    //     // if carrier
    //     let link = `/#/truckload/${item.quote_request_id}`;
    //
    //     // if shipper
    //     // if (this.sessionService.profile.company.type === 'shipper') {
    //     // TODO: this is teporary fix to avoid compiler error
    //     if (true) {
    //         link = `/#/truckload/${item.quote_request_id}?company=${item.sender_company_id}`;
    //     }
    //
    //     this.notificationsService.html(
    //         `<p><b>${item.sender_email}</b> said:</p>
    //                 <p>${item.text}</p>
    //                 <p><a class="message-link" href="${link}">Show dialog</a></p>
    //               `,
    //         // 'info',
    //     );
    // }

    // updateNotifications(notificationsToMark) {
    //     if (!this.sessionService.user.firebase_id) { return; }
    //
    //     // const pathAsyncNotify = '/async-notifications/' + this.sessionService.user.firebase_id;
    //
    //     notificationsToMark.forEach(itemToMark => {
    //         this.fireDb.list(this.pathAsyncNotify).update(itemToMark.ref, itemToMark.value);
    //     });
    // }

    // updateUnreadNotifications() {
    //     if (!this.sessionService.user.firebase_id) { return; }
    //
    //     // const pathAsyncNotify = '/async-notifications/' + this.sessionService.user.firebase_id;
    //
    //         this.fireDb.list(this.pathAsyncNotify).update();
    //     // notificationsToMark.forEach(itemToMark => {
    //     //     // this.fireDb.list(this.pathAsyncNotify).update(itemToMark.ref, itemToMark.value);
    //     //     // this.fireDb.object(itemToMark.ref).update({seen: itemToMark.seen});
    //     // });
    // }

    // // deprecated
    // getAsyncNotificationsMore(limit: number, endAt: string) {
    //     return this.fireDb.list(this.pathAsyncNotify)
    //         .query.limitToLast(limit).endAt(endAt).orderByChild('timestamp').once('value');
    // }
    // // deprecated
    // getAsyncNotifications(limit: number) {
    //     return this.fireDb.list(this.pathAsyncNotify)
    //         .query.limitToLast(limit).orderByChild('timestamp').once('value');
    // }

    getMessages(qrId: number, carrierCompanyId: number): Observable<IMessage[]> {
        return this.requester.makeMsCall$(
            `/comments/quote-requests/${qrId}/carriers/${carrierCompanyId}/messages`,
            'GET',
            'shipp'
        );
    }

    sendMessage(loadId: number, carrierCompanyId: number, text: string): Observable<IMessage> {
        return this.requester.makeMsCall$(
            `/comments/quote-requests/${loadId}/carriers/${carrierCompanyId}/messages`,
            'POST',
            'shipp',
            {
                text
            }
        );
    }

    getCustomerReminders(loadId: number, customerCompanyId: number, sharedCarrierContactId: number): Observable<any> {
        return this.requester.makeMsCall$(
            `/comments/load/${loadId}/customer/${customerCompanyId}/remind/${sharedCarrierContactId}`,
            'GET',
            'shipp'
        );
    }

    getAsyncNotificationsCount(): Promise<{count: number; isSpinnerNeeded: boolean}> {
        return this.requester.makeMsCall(`/notification/counter`, 'GET', {}, {}, true);
    }

    getAsyncNotificationsSpanner(limit: number, offset: number = 0, filter: string = 'all'): Promise<any> {
        return this.requester.makeMsCall(
            `/notification?limit=${limit}&offset=${offset}&filter=${filter}`,
            'GET',
            {},
            {},
            true
        );
    }

    getAsyncNotification(notificationId: number): Promise<any> {
        return this.requester.makeMsCall(`/notification/${notificationId}`, 'GET', {}, {}, true);
    }

    markAllAsyncNotificationsRead() {
        return this.requester.makeMsCall(`/notification/mark-all-read`, 'PUT', {}, {}, true);
    }

    updateAsyncNotification(notificationId: number, data: any) {
        return this.requester.makeMsCall(`/notification/${notificationId}`, 'PUT', data, {}, true);
    }
}

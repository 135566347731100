import {Injectable} from '@angular/core';
import {ServiceRequester} from '@app/services/service.requester';
import {IMetric} from '@app/typings/calculation-settings.typings';
import {Subject} from 'rxjs';

type MetricsData = {data: IMetric[]; meta: {total: number}};

@Injectable()
export class CalculationSettingsService {
    public metrics: IMetric[] = [];
    constructor(private requester: ServiceRequester) {}

    async getMetricsFromApi(params: any = {}): Promise<MetricsData> {
        return await this.requester.makeMsCall(`/metrics/`, 'GET', params);
    }

    async getAllMetrics(needRefresh: boolean = false): Promise<IMetric[]> {
        if (!needRefresh) {
            return Promise.resolve(this.metrics);
        }
        return this.getMetricsFromApi().then((metricsData) => {
            this.metrics = metricsData.data;
            return this.metrics;
        });
    }

    async createMetric(metric: IMetric): Promise<IMetric> {
        return await this.requester.makeMsCall(`/metrics/`, 'POST', metric);
    }

    async updateMetric(metric: IMetric): Promise<IMetric> {
        return await this.requester.makeMsCall(`/metrics/${metric.metric_id}`, 'PUT', metric);
    }

    async deleteMetric(metric: IMetric): Promise<IMetric> {
        return await this.requester.makeMsCall(`/metrics/${metric.metric_id}`, 'DELETE', metric);
    }
}

import {Component, Input} from '@angular/core';
import {IReportsPreviewData, IReportsPreviewResultData} from '../../../../store/reports.reducer';

@Component({
    selector: 'reports-preview-cap',
    templateUrl: './cap.component.html',
    styleUrls: ['./cap.component.scss']
})
export class CapPreviewComponent {
    @Input() page: number = 0;
    @Input() perPage: number = 25;

    @Input()
    /**
     * Set report data
     */
    set reportData(data: any) {
        this._reportData = data;
    }

    /**
     * Get report data
     */
    get reportData() {
        return this._reportData;
    }

    _reportData: IReportsPreviewResultData[] | [] = [];

    constructor() {}
}

import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
// import {Sort} from '@angular/material/sort';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {PAGE_SIZE_OPTIONS} from 'app/local-typings';
import {Subject} from 'rxjs';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NotificationsService} from 'angular2-notifications';
import {filter, takeUntil} from 'rxjs/operators';
import {RbacService} from '../../../rbac/rbac.service';
import {IScrollData} from '../../../../models/scroll-data';
import {UsersAndLicencesApiService} from '../../users-licenses/users-and-licences.api.service';
import {MatDialog} from '@angular/material/dialog';
// import {YodataEditEntityDialogComponent} from './edit-entity-dialog/edit-entity-dialog.component';
import {MarketplaceApi} from '../marketplace.api';
import {Group} from '../../../../models/group';
import {CurrentProfileSource} from '../../../../services/sources/current-profile.source';
import {GroupApi} from '../../../../services/api/group.api';
import {Profile} from '../../../../models/profile';
import {YodataSettingsTableComponent} from './yodata-settings-table.component';
import {YodataAddonApi} from './yodata-addon.api';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

@Component({
    selector: 'app-yodata-settings',
    templateUrl: './yodata-settings.component.html',
    styleUrls: ['./yodata-settings.component.scss']
})
export class YodataSettingsComponent implements OnInit, OnDestroy {
    @ViewChild('yodataProfilesTableComponent', {static: false}) yodataProfilesTableComponent:
        | YodataSettingsTableComponent
        | undefined;
    private unsubscribe: Subject<void> = new Subject();
    // private unsubscribeBatch: Subject<void> = new Subject();
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator = new MatPaginator(
        new MatPaginatorIntl(),
        ChangeDetectorRef.prototype
    );

    yodataSettingsForm: UntypedFormGroup = this.fb.group({
        realEstateOrganizationId: [
            null,
            [Validators.required, Validators.minLength(5), Validators.maxLength(8), this.alphanumericValidator]
        ],
        isProfilesCreate: [false, []]
    });
    yodataSettingsPending = true;

    // customFieldsForm: FormGroup;
    importAgentsPodsForm: UntypedFormGroup = this.fb.group({
        createProfiles: [false, []],
        overrideExisting: [false, []]
    });

    selectedIndex = 0;

    scrollData: IScrollData = {
        offset: 0,
        limit: 25,
        sort_column: 'first_name',
        sort_direction: 'desc',
        summary_status_filter: undefined,
        payment_status_filter: undefined,
        total: 0
    };
    pageSizeOptions = PAGE_SIZE_OPTIONS;
    dataSourceDivisions: MatTableDataSource<Group | any> = new MatTableDataSource<Group>([]);
    displayedColumns = ['first_name', 'last_name', 'email', 'yodata_mapping', 'action'];
    displayedColumnsDivisions = ['title', 'email', 'yodata_mapping', 'action'];
    isLoading = false;
    divisionsTabWasAlreadyLoaded = false;
    INDEX_OF_DIVISIONS = 1;
    currentProfile: Profile | null = null;
    importPending = false;
    isImportPodsAlertHidden = true;

    // for alert import
    organizationName = '';
    mappingsViewModeUIreport: string[] = [];
    mappingsEditModeUIreport: string[] = [];
    mappingsWarningsUIreport: string[] = [];

    yodataCompanyId = '';
    yodataCompanyName = '';
    isAutocreateProfiles = false;
    isCompanySettingsExisting = false;
    autocreateProfilesTooltip =
        'If new profile (agent) created in your organization in Yodata it will be also created as profile in SkySlope Books with no access if this profile has same email and does not exist in SkySlope Books';
    isPreviewModeDone = false;
    profilesAutocreateToggleDisabled = false;

    // used to notify child component (profiles table) to refresh profiles list after import
    eventsSubject: Subject<void> = new Subject<void>();

    constructor(
        public router: Router,
        // private companyGatewayService: CompanyGatewayService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        protected rbacService: RbacService,
        protected usersAndLicencesApiService: UsersAndLicencesApiService,
        public dialog: MatDialog,
        public marketplaceApi: MarketplaceApi,
        protected currentProfileSource: CurrentProfileSource,
        public groupApi: GroupApi,
        public yodataAddonApi: YodataAddonApi
    ) {}

    ngOnInit() {
        this.rbacService.isAllowed({marketplace__manage_yodata: true}).then((can) => {
            if (!can) {
                this.router.navigate(['/']);
            }
        });

        if (!this.divisionsTabWasAlreadyLoaded) {
            this.divisionsTabWasAlreadyLoaded = true;

            this.currentProfileSource.changeProfileEvent
                .pipe(
                    filter((profile) => profile.company !== null),
                    takeUntil(this.unsubscribe)
                )
                .subscribe((profile) => {
                    this.currentProfile = profile;
                    this.marketplaceApi.getDivisionsList().then((list: Group[]) => {
                        this.dataSourceDivisions.data = list;
                    });
                });
        }

        this.yodataAddonApi
            .loadYodataAddonCompanySettings()
            .then((res) => {
                this.yodataSettingsPending = false;
                if (!res) {
                    return;
                }

                this.yodataCompanyId = res.yodata_company_id;
                this.yodataCompanyName = res.yodata_company_name;
                this.isAutocreateProfiles = res.is_create_profiles;
                this.isCompanySettingsExisting = true;
            })
            .catch((err) => {
                this.yodataSettingsPending = false;
            });
    }

    refreshProfilesListInChildComponent() {
        this.eventsSubject.next();
    }

    closePodsAlert() {
        if (!this.isImportPodsAlertHidden) {
            this.isImportPodsAlertHidden = true;

            // reset variables
            this.organizationName = '';
            this.mappingsViewModeUIreport = [];
            this.mappingsEditModeUIreport = [];
            this.mappingsWarningsUIreport = [];
        }
    }

    updateProfilesPods(meta: {
        mappingsToCreate: any[];
        mappingsToUpdate: any[];
        updatedMappings: any[];
        createdMappings: any[];

        mappingsViewModeUIreport: string[];
        mappingsEditModeUIreport: string[];
    }) {
        if (this.yodataProfilesTableComponent) {
            this.yodataProfilesTableComponent.patchCompanyProfilesListAfterImport(meta);
        }
    }

    updateOfficesPods(meta: {
        mappingsToCreate: any[];
        mappingsToUpdate: any[];
        updatedMappings: any[];
        createdMappings: any[];

        mappingsViewModeUIreport: string[];
        mappingsEditModeUIreport: string[];
    }) {
        const createdOfficesMappings = meta.createdMappings.filter((m) => !!m.company_group_fk_id);
        const updatedOfficesMappings = meta.updatedMappings;

        if (createdOfficesMappings.length || updatedOfficesMappings.length) {
            this.dataSourceDivisions.data = this.dataSourceDivisions.data.map((el) => {
                const createdMapping = createdOfficesMappings.find((m) => m.company_group_fk_id === el.id);
                const updatedMapping = updatedOfficesMappings.find(
                    (m) =>
                        el.yodata_pod_mapping && el.yodata_pod_mapping.yodata_pod_mapping_id === m.yodata_pod_mapping_id
                );
                if (!createdMapping && !updatedMapping) {
                    return el;
                }

                if (createdMapping) {
                    return {...el, yodata_pod_mapping: createdMapping};
                } else if (updatedMapping) {
                    return {...el, yodata_pod_mapping: {...el.yodata_pod_mapping, pod_uri: updatedMapping.pod_uri}};
                }
                return el;
            });
        }
    }

    async saveCompanySettings(ev: MouseEvent) {
        ev.preventDefault();
        if (!this.yodataSettingsForm.valid) {
            return;
        }

        const values: {realEstateOrganizationId: string; isProfilesCreate: boolean} =
            this.yodataSettingsForm.getRawValue();

        this.yodataSettingsPending = true;
        this.yodataAddonApi
            .createYodataAddonCompanySettings(values)
            .then((res) => {
                this.yodataCompanyId = res.newSettings.yodata_company_id;
                this.yodataCompanyName = res.newSettings.yodata_company_name;
                this.isAutocreateProfiles = res.newSettings.is_create_profiles;
                this.isCompanySettingsExisting = true;
                this.yodataSettingsPending = false;
            })
            .catch((err) => {
                console.log(err);
                this.yodataSettingsPending = false;
            });
    }

    async previewImportYodataPods(ev: MouseEvent) {
        ev.preventDefault();
        if (!this.isCompanySettingsExisting) {
            return;
        }
        // if (!this.importAgentsPodsForm.valid) { return; }

        this.importPending = true;
        // RUN IMPORT IN PREVIEW MODE
        await this.marketplaceApi
            .importYodataAgentsPodsAction(
                true,
                {
                    createProfiles: true
                },
                false
            )
            .then((res) => {
                this.importPending = false;
                this.isPreviewModeDone = true;

                try {
                    this.organizationName = res.yodataAgentsRes.meta.orgName;
                    this.mappingsViewModeUIreport = res.meta.mappingsViewModeUIreport;
                    this.mappingsEditModeUIreport = res.meta.mappingsEditModeUIreport;
                    this.mappingsWarningsUIreport = res.meta.mappingsWarningsUIreport;

                    this.isImportPodsAlertHidden = false;
                } catch (errrr) {
                    this.closePodsAlert();
                }
            });
    }

    async runImportYodataPods(ev: MouseEvent) {
        ev.preventDefault();
        if (!this.isCompanySettingsExisting) {
            return;
        }
        if (!this.importAgentsPodsForm.valid) {
            return;
        }

        const values = this.importAgentsPodsForm.getRawValue();

        this.closePodsAlert(); // reset alert
        this.importPending = true;
        await this.marketplaceApi
            .importYodataAgentsPodsAction(
                values.overrideExisting,
                {
                    createProfiles: values.createProfiles
                },
                true
            )
            .then((res) => {
                this.importPending = false;
                this.isPreviewModeDone = false;

                try {
                    this.organizationName = res.yodataAgentsRes.meta.orgName;
                    this.mappingsViewModeUIreport = res.meta.mappingsViewModeUIreport;
                    this.mappingsEditModeUIreport = res.meta.mappingsEditModeUIreport;
                    this.mappingsWarningsUIreport = res.meta.mappingsWarningsUIreport;

                    this.isImportPodsAlertHidden = false;

                    this.updateProfilesPods(res.meta);
                    this.updateOfficesPods(res.meta);

                    // if new profiles were created - update list of profiles
                    if (res.meta.addedNewProfiles) {
                        this.refreshProfilesListInChildComponent();
                    }
                } catch (errrr) {
                    this.closePodsAlert();
                }
            })
            .catch(() => {
                this.importPending = false;
                this.isPreviewModeDone = false;
            });
    }

    alphanumericValidator(control: AbstractControl): ValidationErrors | null {
        // only a-z and 0-9 allowed
        const forbidden = /[^0-9a-zA-Z]+/g.test(control.value);
        return forbidden ? {forbiddenSymbols: {value: control.value}} : null;
    }

    selectedTabChanged(ev: {index: number}) {
        // do not request divisions until we go to Divisions tab
        if (ev.index === this.INDEX_OF_DIVISIONS && !this.divisionsTabWasAlreadyLoaded) {
            this.divisionsTabWasAlreadyLoaded = true;

            // this.currentProfileSource.changeProfileEvent
            //     .pipe(filter(profile => profile.company !== null), takeUntil(this.unsubscribe))
            //     .subscribe(profile => {
            //         this.currentProfile = profile;
            this.marketplaceApi.getDivisionsList().then((list: Group[]) => {
                this.dataSourceDivisions.data = list;
            });
            // });
        }
    }

    handleAutocreateToggleChange(ev: MatSlideToggleChange) {
        this.yodataSettingsPending = true;
        this.yodataAddonApi
            .patchYodataAddonCompanySettings({isProfilesCreate: ev.checked})
            .then(() => {
                this.yodataSettingsPending = false;
            })
            .catch((err) => {
                console.log(err);
                this.yodataSettingsPending = false;
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

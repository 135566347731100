import {Component, Input} from '@angular/core';
import {CompanyGoalsFormGroup, FocusAreaFormGroup, GoalFormGroup} from './model';

@Component({
    selector: 'app-company-goals',
    styles: [
        `
            mat-card-header {
                display: block;
                text-align: center;
                font-size: 20px;
                margin: 5%;
                color: #676161;
            }

            button {
                padding-left: 5px;
                padding-right: 5px;
                margin: 20px auto;
            }

            .container {
                display: flex;
                flex-direction: column;
            }

            button {
                height: 40px;
                margin: 4px;
            }

            .mat-icon-button {
                width: auto;
            }
        `
    ],
    template: `
        <mat-card style="box-shadow: none;">
            <mat-card-header> </mat-card-header>
            <mat-card-content class="container" *ngIf="formGroup">
                <ng-container *ngFor="let focus_area of formGroup.controls.focus_areas.controls; index as i">
                    <div>
                        <h2>{{ focus_area.controls.title.value }}</h2>
                    </div>
                    <div style="width:100%">
                        <div
                            style="display: flex; width:100%"
                            *ngFor="let enumerable of focus_area.controls.enumerable.controls; index as ei"
                        >
                            <app-goal-progress
                                *ngIf="enumerable.value.type === 'progress'"
                                style="width:100%"
                                [goal]="enumerable"
                                [index]="ei"
                            ></app-goal-progress>
                            <app-goal-general
                                *ngIf="enumerable.value.type === 'general'"
                                style="width:100%"
                                [goal]="enumerable"
                                [index]="ei"
                            ></app-goal-general>
                            <app-goal-mandatory
                                [goal]="enumerable"
                                (deleteGoal)="deleteGoal(focus_area, ei)"
                            ></app-goal-mandatory>
                        </div>
                        <app-goal-create [focus_area]="focus_area"></app-goal-create>
                    </div>
                </ng-container>
            </mat-card-content>
        </mat-card>
    `
})
export class CompanyGoalsComponent {
    @Input() formGroup: CompanyGoalsFormGroup | undefined;

    setMandatoryType(goal: GoalFormGroup, mandatoryType: string) {
        goal.controls.mandatory_type.patchValue(mandatoryType);
    }

    addProgressGoal(focusArea: FocusAreaFormGroup, dynamic: string = 'increase') {
        const goalFormGroup = new GoalFormGroup();
        goalFormGroup.patchValue({
            dynamic: dynamic,
            type: 'progress'
        });

        focusArea.controls.enumerable.push(goalFormGroup);
    }

    addGeneralGoal(focusArea: FocusAreaFormGroup, dynamic: string = '') {
        const goalFormGroup = new GoalFormGroup();
        goalFormGroup.patchValue({type: 'general'});
        focusArea.controls.enumerable.push(goalFormGroup);
    }

    addGoal(focusArea: FocusAreaFormGroup) {
        focusArea.controls.enumerable.push(new GoalFormGroup());
    }

    deleteGoal(focusArea: FocusAreaFormGroup, goalIndex: number) {
        focusArea.controls.enumerable.controls.splice(goalIndex, 1);
    }

    splitGoal() {
        console.log('SPLIT GOAL');
    }

    makeGeneralGoal(goal: GoalFormGroup) {
        goal.controls.type.patchValue('general');
    }

    makeProgressGoal(goal: GoalFormGroup) {
        goal.controls.type.patchValue('progress');
    }

    createProject() {
        console.log('CREATE PROJECT');
    }
}

<div>
    <mat-card class="preview-body">
        <div>
            <table mat-table [dataSource]="dataSource" class="w-100">
                <ng-container matColumnDef="name">
                    <th mat-header-cell class="header-cell" *matHeaderCellDef>
                        <span class="header-cell-icon-align">Contact</span>
                    </th>
                    <td mat-cell class="cell" *matCellDef="let element">
                        <div class="icon-align">
                            <mat-icon
                                *ngIf="!element.contact_payroll_fk_id"
                                matTooltip="Missing tax record details"
                                style="color: red"
                                >error</mat-icon
                            >
                            <mat-icon *ngIf="element.contact_payroll_fk_id"></mat-icon>
                            <a
                                style="color: #0056b3; text-decoration: underline"
                                (click)="
                                    openContactPayrollDialog(
                                        element.contact_fk_id,
                                        element.contact_payroll_fk_id,
                                        element.contact_fk_id
                                    )
                                "
                            >
                                {{ element.display_name }}
                            </a>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="nonEmployeeCompensation">
                    <th mat-header-cell class="header-cell" *matHeaderCellDef>Box 1</th>
                    <td mat-cell class="cell" *matCellDef="let element">
                        <mat-form-field>
                            <input
                                matInput
                                [defaultValue]="element.other_income"
                                (change)="
                                    onCompensationChange($event, element.contact_payroll_fk_id, element.contact_fk_id)
                                "
                                [disabled]="element.contact_payroll_fk_id === null ? true : null"
                                [maskito]="currencyMaskitoMask"
                            />
                        </mat-form-field>
                    </td>
                </ng-container>

                <ng-container matColumnDef="submissionType">
                    <th mat-header-cell class="header-cell" *matHeaderCellDef>Submission Type</th>
                    <td mat-cell class="cell" *matCellDef="let element">
                        <mat-form-field>
                            <mat-select
                                [value]="element.submission_type"
                                (selectionChange)="
                                    onSubmissionTypeChange(
                                        $event.value,
                                        element.contact_payroll_fk_id,
                                        element.contact_fk_id
                                    )
                                "
                                [disabled]="element.contact_payroll_fk_id === null ? true : null"
                            >
                                <mat-option value="initial">Initial</mat-option>
                                <mat-option value="corrected">Corrected</mat-option>
                                <mat-option value="void">Void</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator
                [length]="scrollData.total"
                [pageSize]="scrollData.limit"
                [pageSizeOptions]="pageSizeOptions"
                [showFirstLastButtons]="true"
            >
            </mat-paginator>
        </div>
    </mat-card>
</div>

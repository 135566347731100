<!------- Amount ------->
<mat-form-field
    class="transfer-field amount full-width"
    [hideRequiredMarker]="hideRequiredMarker"
    *ngIf="currentInputType === 'numeric' && (!isPercent || !allowPercents)"
>
    <input matInput placeholder="Amount" [formControl]="valueFC" [maskito]="currencyMaskitoMask" />
    <mat-error *ngIf="valueFC.value === 0 || valueFC.value === '0'; else valueEmpty">Invalid amount</mat-error>
    <ng-template #valueEmpty><mat-error>Required field</mat-error></ng-template>
    <button
        *ngIf="wildcardList.length"
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Switch value type to wildcard 1"
        (click)="changeType('wildcard')"
    >
        <mat-icon>recent_actors</mat-icon>
    </button>
</mat-form-field>

<!------- Percent ------->
<div
    class="transfer-field amount full-width fake-input"
    *ngIf="currentInputType === 'numeric' && isPercent && allowPercents"
    [ngStyle]="!editPercent ? {} : {display: 'none'}"
    (click)="togglePercentFC()"
>
    {{ percentFC.value ? (percentFC.value | number: '1.0-2') : 0 }}%
</div>
<mat-form-field
    class="transfer-field amount full-width"
    *ngIf="currentInputType === 'numeric' && isPercent && allowPercents"
    [ngStyle]="editPercent ? {} : {display: 'none'}"
>
    <input
        matInput
        placeholder="Percent"
        [formControl]="percentFC"
        #inputPercent
        [autofocus]
        [maskito]="percentMaskitoMask"
        (blur)="editPercent = !editPercent"
    />
    <button
        *ngIf="wildcardList.length"
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Switch value type to wildcard 2"
        (click)="changeType('wildcard')"
    >
        <mat-icon>recent_actors</mat-icon>
    </button>
</mat-form-field>

<!------- Disbursement Instructions ------->
<mat-form-field class="full-width spaced w-100" *ngIf="currentInputType === 'text'">
    <input matInput placeholder="Disbursement Instructions" [formControl]="valueFC" />
    <button
        *ngIf="wildcardList.length"
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Switch value type to wildcard 2"
        (click)="changeType('wildcard')"
    >
        <mat-icon>recent_actors</mat-icon>
    </button>
</mat-form-field>

<!------- Select wildcard ------->
<mat-form-field class="full-width" *ngIf="currentInputType === 'wildcard'">
    <mat-select placeholder="Select wildcard" [formControl]="valueWildcardIdFC">
        <mat-option *ngFor="let wildcard of this.wildcardList" [value]="wildcard.wildcard_id">{{
            wildcard.title
        }}</mat-option>
    </mat-select>
    <button
        *ngIf="wildcardList.length"
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Switch value type to text"
        (click)="changeType(wildcardType)"
    >
        <mat-icon>text_fields</mat-icon>
    </button>
</mat-form-field>

import {merge as observableMerge, Observable, Subject} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Profile} from '../../models/profile';
import {ContactType} from '../../models/contact-type';
import {Router} from '@angular/router';
import {AppInitSource} from './app-init.source';
import {ContactClass} from '../../models/contact-class';

@Injectable()
export class CurrentProfileSource {
    public companyTags = new Subject<any>();
    public contactTypes = new Subject<ContactType[]>();
    public contactClasses = new Subject<ContactClass[]>();

    public triggers = {
        changeProfile: new Subject<Profile>(),
        updateProfileEvent: new Subject<Partial<Profile>>(),
        changeRequesterProfile: new Subject<Profile | null>()
    };

    private events = {
        updateProfileEvent: <Observable<Profile>>observableMerge(this.triggers.updateProfileEvent),
        changeProfileEvent: <Observable<Profile>>observableMerge(this.triggers.changeProfile),
        changeRequesterProfileEvent: <Observable<Profile>>(
            observableMerge(this.triggers.changeProfile, this.triggers.changeRequesterProfile)
        )
    };

    private currentProfile: Profile | null = null;
    private _requesterProfile: Profile | null = null;
    private superAdminUserEmail: string | null = null;
    private zipiZohoToken: string | null = null;

    public get changeProfileEvent(): Observable<Profile> {
        if (this.currentProfile) {
            return this.events.changeProfileEvent.pipe(startWith(this.currentProfile));
        }
        return this.events.changeProfileEvent;
    }

    public get updateProfileEvent(): Observable<Profile> {
        if (this.currentProfile) {
            return this.events.updateProfileEvent.pipe(startWith(this.currentProfile));
        }
        return this.events.updateProfileEvent;
    }

    public get changeRequesterProfileEvent() {
        if (this._requesterProfile) {
            return this.events.changeRequesterProfileEvent.pipe(startWith(this._requesterProfile));
        }
        return this.events.changeRequesterProfileEvent;
    }

    constructor(
        protected router: Router,
        protected appInitSource: AppInitSource
    ) {
        this.appInitSource.appInit.subscribe((appInitResponse) => {
            this.companyTags.next(appInitResponse.companyTags);
            this.currentProfile = appInitResponse.currentProfile;
            this.superAdminUserEmail = appInitResponse.superAdminUserEmail;
            this.zipiZohoToken = appInitResponse.zipiZohoToken;
            this._requesterProfile = appInitResponse.currentProfile;

            if (appInitResponse.currentProfile) {
                this.afterChangeProfile(appInitResponse.currentProfile, {
                    availableProfiles: appInitResponse.availableProfiles,
                    superAdminUserEmail: this.superAdminUserEmail,
                    zipiZohoToken: this.zipiZohoToken
                });
            }

            this.triggers.changeProfile.next(this.currentProfile!);
        });

        // reset categories and classes list on company change
        this.changeProfileEvent.subscribe((profile) => {
            this.contactTypes.next([]);
            this.contactClasses.next([]);
        });
    }

    public changeRequesterProfile(profile: Profile) {
        this._requesterProfile = profile;

        this.triggers.changeRequesterProfile.next(profile);
    }

    public restoreRequesterProfile() {
        this.triggers.changeRequesterProfile.next(this.currentProfile);
    }

    protected afterChangeProfile(
        profile: Profile,
        otherOptions: {
            superAdminUserEmail: string | null;
            zipiZohoToken: string | null;
            availableProfiles: Profile[];
        }
    ) {
        if (profile.user && profile.company) {
            // @ts-ignore
            window.dataLayer = window.dataLayer || [];
            // @ts-ignore
            window.dataLayer.push({
                event: 'zipiUserChangedProfile',
                zipiUserId: profile.user.id,
                zipiFirebaseId: profile.user.firebase_id,
                zipiOktaUid: profile.user.okta_uid,
                zipiUserEmail: profile.user.email,
                zipiUserFirstName: profile.user.first_name,
                zipiUserLastName: profile.user.last_name,
                zipiUserFullName: `${profile.first_name} ${profile.last_name}`, // deprecated
                zipiUserCompanyId: profile.company.id, // deprecated
                zipiUserCompanyName: profile.company.title, // deprecated
                zipiProfileCompanyId: profile.company.id,
                zipiProfileCompanyName: profile.company.title,
                zipiProfileCompanySkyslopeSubId: profile.company.skyslope_subscriber_id,
                zipiAdminEmail: otherOptions.superAdminUserEmail,
                zipiZohoToken: otherOptions.zipiZohoToken,
                zipiIsHelpButtonNeeded: profile.user.is_help_button_shown,
                zipiAvailableCompanies: otherOptions.availableProfiles
                    .map((p) => {
                        if (!p.company) return null;

                        return {
                            company_id: p.company.id,
                            skyslope_subscriber_id: p.company.skyslope_subscriber_id,
                            title: p.company.title,
                            access_plan: p.company.access_plan,
                            business_relationship_type: p.company.business_relationship_type
                        };
                    })
                    .filter((company) => !!company)
            });
        }
    }
}

import {GenericEntity} from '../../../../entites/generic.entity';
import {RuleModel} from './rule.model';

export class FinancialElementModel extends GenericEntity {
    id: number | null = null;
    type: string | null = null;
    title: string = '';
    public_title: string | null = null;
    rules: RuleModel[] = [];
    commonRule: RuleModel = new RuleModel();
    is_enforced: boolean = true;
    is_template: boolean = false;
    provides: string = 'compensation_plan';
    sort_order: number = 0;
    parent__compensation_profile_fk_id: number | null = null;

    setType(type: string) {
        this.type = type;
        return this;
    }

    setTitle(title: string) {
        this.title = title;
        return this;
    }

    addRule(rule: RuleModel) {
        this.rules.push(rule);
        return this;
    }

    setOrder(sort_order: number) {
        this.sort_order = sort_order;
        return this;
    }

    setIsTemplate(isTemplate: boolean) {
        this.is_template = isTemplate;
        return this;
    }

    setId(id: number | null) {
        this.id = id;
        return this;
    }

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce((acc, curr) => {
                    // @ts-ignore
                    acc[curr] = curr;
                    return acc;
                }, {}),
                build: {
                    rules: (val: object[], fabric: string) => val.map((item) => RuleModel.hydrate(item))
                }
            },
            GenericEntity.helpers
        );
    }

    static get type_set(): {[key: string]: string} {
        return {
            agent: 'agent',
            company: 'company',
            company_income_expense: 'company_income_expense',
            team: 'team',
            sales: 'sales',
            sales_individual: 'sales_individual',
            royalty: 'royalty',
            user_royalty: 'user_royalty',
            user_referral: 'user_referral',
            expenses: 'expenses',
            referral: 'referral',
            taxes: 'taxes',
            disbursement_template: 'disbursement_template',
            overhead: 'overhead',
            incentive: 'incentive',
            participant: 'participant',
            company_partner: 'company_partner',
            commission_categorization: 'commission_categorization',
            disbursement_instructions: 'disbursement_instructions',
            company_split_individual: 'company_split_individual',
            compensation_expense: 'compensation_expense'
        };
    }

    static get type_LABELS(): {[key: string]: string} {
        return {
            disbursement_instructions: 'CDA Disbursement Text',
            commission_categorization: 'Additional Commission',
            company_income_expense: 'Company Income/Expense',
            company_partner: 'Company Partner',
            incentive: 'Deal Incentive',
            company: 'Deal Income/Expense',
            overhead: 'Deal Overhead',
            referral: 'Deal Referral',
            royalty: 'Deal Royalty',
            disbursement_template: 'Entity Income/Expense',
            user_referral: 'Entity Referral',
            user_royalty: 'Entity Royalty',
            participant: 'Participant',
            agent: 'Sales Compensation',
            compensation_expense: 'Compensation Expense',
            sales: 'Sales Income/Expense',
            taxes: 'Taxes'
        };
    }

    static get provides_set(): {[key: string]: string} {
        return {
            split_plan: 'split_plan',
            compensation_plan: 'compensation_plan',
            company_split: 'company_split',
            disbursement_template: 'disbursement_template',
            participant: 'participant',
            overhead: 'overhead',
            commission_categorization: 'commission_categorization',
            user_royalty_plan: 'user_royalty_plan',
            royalty_plan: 'royalty_plan',
            disbursement_instructions: 'disbursement_instructions'
        };
    }

    /**
     *
     * @param type {string}
     * @returns {string}
     */
    static providesByType(type: string) {
        switch (type) {
            case this.type_set.overhead:
            case this.type_set.incentive:
                return this.provides_set.overhead;
            case this.type_set.disbursement_instructions:
                return this.provides_set.disbursement_instructions;
            case this.type_set.participant:
            case this.type_set.company_partner:
                return this.provides_set.participant;
            case this.type_set.royalty:
            case this.type_set.user_royalty:
            // return this.provides_set.user_royalty_plan;
            case this.type_set.agent:
            // return this.provides_set.compensation_plan;
            case this.type_set.company:
            case this.type_set.company_income_expense:
            case this.type_set.sales:
            case this.type_set.disbursement_template:
            case this.type_set.user_referral:
                return this.provides_set.company_split;
            case this.type_set.commission_categorization:
                return this.provides_set.commission_categorization;
            default:
                return this.provides_set.split_plan;
        }
    }

    static get intradealable(): {[key: string]: boolean} {
        return {
            commission_categorization: false,
            company_partner: false,
            incentive: false,
            overhead: false,
            royalty: true,
            disbursement_template: false,
            user_referral: false,
            user_royalty: true,
            company_income_expense: false,
            company: false,
            participant: false,
            agent: true,
            sales: false,
            taxes: false
        };
    }

    static checkIfElementCanBeUnenforced(elementType: string): boolean {
        return ![
            FinancialElementModel.type_set.disbursement_instructions,
            FinancialElementModel.type_set.commission_categorization,
            FinancialElementModel.type_set.incentive,
            FinancialElementModel.type_set.overhead,
            FinancialElementModel.type_set.taxes,
            FinancialElementModel.type_set.participant
        ].includes(elementType);
    }
}

<div class="d-flex">
    <h4>Payment Options</h4>
    <span class="sp-spacer"></span>
    <mat-icon class="sp-cursor-pointer" (click)="close()">close</mat-icon>
</div>

<mat-dialog-content>
    <mat-tab-group
        class="pay-tabs"
        style="width: 560px"
        [formGroup]="formGroup"
        mat-align-tabs="start"
        [selectedIndex]="tabIndex"
    >
        <mat-tab>
            <div
                [ngClass]="
                    receiveOnlyLoading ||
                    !zipiFinancialGateways ||
                    zipiFinancialGateways.length === 0 ||
                    ((!contactZipiFinancialReceiveGateway ||
                        !contactZipiFinancialReceiveGateway.payment_methods ||
                        contactZipiFinancialReceiveGateway.payment_methods.length === 0) &&
                        (!contactZipiFinancialPayloadCoReceiveGateway ||
                            !contactZipiFinancialPayloadCoReceiveGateway.payment_methods ||
                            contactZipiFinancialPayloadCoReceiveGateway.payment_methods.length === 0))
                        ? 'option-disabled'
                        : 'sp-cursor-pointer'
                "
                class="list-item align-items-center sp-cursor-pointer d-flex mx-1 mt-3 _track_po_zipi_pay_receive_only"
                style="min-height: 50px"
                (click)="tabSlug = 'zipi_fin_receive_only'; changeTab()"
                *ngIf="isMoneyTransfersFeatureFlagEnabled"
            >
                <div class="icon-rectangle">
                    <mat-icon class="material-symbols-outlined">step</mat-icon>
                </div>
                <div class="w-60 text-wrap">
                    <div class="list-item-title" style="font-size: 14px">Direct Deposit</div>
                    <div style="font-size: 12px">Send payment directly to the recipient’s bank account</div>
                    <!--                    we need to keep errors logic-->
                    <!--                    <div style="color: red; font-size: 14px" *ngIf="!receiveOnlyLoading">-->
                    <!--                        <div *ngIf="!zipiFinancialGateways || zipiFinancialGateways.length === 0">-->
                    <!--                            To use "Money Transfers (EFT)" you must activate Money Transfers Service-->
                    <!--                        </div>-->
                    <!--                        <div *ngIf="!contactZipiFinancialReceiveGateway">-->
                    <!--                            You cannot pay this Bill by "Money Transfers (EFT)".-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
                <div class="w-20 d-flex justify-content-end">
                    <a
                        *ngIf="
                            !receiveOnlyLoading &&
                            isMuhneeAvailable &&
                            !isPayloadCoAvailable &&
                            (!contactZipiFinancialReceiveGateway ||
                                !contactZipiFinancialReceiveGateway.payment_methods ||
                                contactZipiFinancialReceiveGateway.payment_methods.length === 0) &&
                            (!contactZipiFinancialPayloadCoReceiveGateway ||
                                !contactZipiFinancialPayloadCoReceiveGateway.payment_methods ||
                                contactZipiFinancialPayloadCoReceiveGateway.payment_methods.length === 0) &&
                            !!zipiFinancialGateways &&
                            zipiFinancialGateways.length > 0
                        "
                        href="javascript:"
                        (click)="$event.stopPropagation(); setupZipiFinancialForContact('contact_bank', 'muhnee')"
                        style="font-size: 12px"
                    >
                        Add Bank
                    </a>
                    <a
                        *ngIf="
                            !receiveOnlyLoading &&
                            isPayloadCoAvailable &&
                            (!contactZipiFinancialReceiveGateway ||
                                !contactZipiFinancialReceiveGateway.payment_methods ||
                                contactZipiFinancialReceiveGateway.payment_methods.length === 0) &&
                            (!contactZipiFinancialPayloadCoReceiveGateway ||
                                !contactZipiFinancialPayloadCoReceiveGateway.payment_methods ||
                                contactZipiFinancialPayloadCoReceiveGateway.payment_methods.length === 0) &&
                            !!zipiFinancialGateways &&
                            zipiFinancialGateways.length > 0
                        "
                        href="javascript:"
                        (click)="$event.stopPropagation(); setupZipiFinancialForContact('contact_bank', 'payload')"
                        style="font-size: 12px"
                    >
                        Add Bank
                    </a>
                    <mat-icon
                        *ngIf="
                            !receiveOnlyLoading &&
                            zipiFinancialGateways &&
                            zipiFinancialGateways.length > 0 &&
                            ((contactZipiFinancialReceiveGateway &&
                                contactZipiFinancialReceiveGateway.payment_methods &&
                                contactZipiFinancialReceiveGateway.payment_methods.length > 0) ||
                                (contactZipiFinancialPayloadCoReceiveGateway &&
                                    contactZipiFinancialPayloadCoReceiveGateway.payment_methods &&
                                    contactZipiFinancialPayloadCoReceiveGateway.payment_methods.length > 0))
                        "
                        class="icon-chevron-right"
                        >chevron_right</mat-icon
                    >
                </div>
            </div>

            <div
                [ngClass]="
                    zipiLoading ||
                    !zipiFinancialGateways ||
                    zipiFinancialGateways.length === 0 ||
                    !isZipiFinancialRequested ||
                    (isZipiFinancialRequested && !isRequestedZipiFinancialActive)
                        ? 'option-disabled'
                        : 'sp-cursor-pointer'
                "
                class="list-item align-items-center d-flex mx-1 mt-3 _track_po_zipi_pay_bank_transfer"
                style="min-height: 50px"
                (click)="tabSlug = 'zipi_financial'; changeTab()"
                *ngIf="isMoneyTransfersFeatureFlagEnabled"
            >
                <div class="icon-rectangle">
                    <mat-icon>account_balance</mat-icon>
                </div>
                <div class="w-60 text-wrap">
                    <div class="list-item-title" style="font-size: 14px">Financial Transfer</div>
                    <div style="font-size: 12px">Send payment to the recipient’s SkySlope Books Financial account</div>
                    <!--                    we need to keep errors logic-->
                    <!--                    <div style="color: red; font-size: 14px" *ngIf="!zipiLoading">-->
                    <!--                        <div *ngIf="!zipiFinancialGateways || zipiFinancialGateways.length === 0">-->
                    <!--                            To use "Money Transfers (EFT)" you must activate Money Transfers Service.-->
                    <!--                        </div>-->
                    <!--                        <div *ngIf="!isZipiFinancialRequested">-->
                    <!--                            You cannot pay this Bill by "Money Transfers (EFT)".-->
                    <!--                        </div>-->
                    <!--                        <div *ngIf="isZipiFinancialRequested && !isRequestedZipiFinancialActive">-->
                    <!--                            The Receiving Account is not Active.-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
                <div class="w-20 d-flex justify-content-end">
                    <mat-icon
                        *ngIf="
                            !zipiLoading &&
                            zipiFinancialGateways &&
                            zipiFinancialGateways.length > 0 &&
                            isZipiFinancialRequested &&
                            isRequestedZipiFinancialActive
                        "
                        class="icon-chevron-right"
                        >chevron_right</mat-icon
                    >
                </div>
            </div>

            <div
                [ngClass]="
                    zipiLoading ||
                    !zipiPayCardGateway ||
                    !invoiceCardMethod ||
                    !isZipiFinancialCardRequested ||
                    !isRequestedZipiFinancialCardActive
                        ? 'option-disabled'
                        : 'sp-cursor-pointer'
                "
                class="list-item align-items-center d-flex mx-1 mt-3 _track_po_zipi_pay_card_payment"
                style="min-height: 50px"
                (click)="tabSlug = 'zipi_financial_card'; changeTab()"
                *ngIf="isMoneyTransfersFeatureFlagEnabled"
            >
                <div class="icon-rectangle">
                    <mat-icon class="material-icons-outlined">account_balance_wallet</mat-icon>
                </div>
                <div class="w-60 text-wrap">
                    <div class="list-item-title" style="font-size: 14px">Card on File</div>
                    <div style="font-size: 12px">Use a credit card on file in SkySlope Books to make a payment</div>
                    <!--                    we need to keep errors logic-->
                    <!--                    <div style="color: red; font-size: 14px" *ngIf="!zipiLoading">-->
                    <!--                        <div *ngIf="!zipiPayCardGateway">-->
                    <!--                            To use "Credit Cards" you must activate Money Transfers Service.-->
                    <!--                        </div>-->
                    <!--                        <div *ngIf="!invoiceCardMethod || !isZipiFinancialCardRequested || !isRequestedZipiFinancialCardActive">-->
                    <!--                            You cannot pay this Bill by "Credit Card".-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
                <div class="w-20 d-flex justify-content-end">
                    <!--                    <a href="javascript:" style="font-size: 12px; pointer-events: none; color: #7E7E7E">Add Credit Card</a>-->
                    <!--                    <mat-icon class="icon-chevron-right">chevron_right</mat-icon>-->
                </div>
            </div>

            <div
                [ngClass]="
                    authLoading || !authGateways || authGateways.length === 0 || !isAuthRequested
                        ? 'option-disabled'
                        : 'sp-cursor-pointer'
                "
                class="list-item align-items-center d-flex mx-1 mt-3 _track_po_authorizenet"
                style="min-height: 50px"
                (click)="tabSlug = 'authorize'; changeTab()"
            >
                <div class="icon-rectangle">
                    <mat-icon>credit_card</mat-icon>
                </div>
                <div class="w-60 text-wrap">
                    <div class="list-item-title" style="font-size: 14px">One-time Payment</div>
                    <div style="font-size: 12px">Enter card information with Authorize.Net for a one-time payment</div>
                    <!--                    we need to keep errors logic-->
                    <!--                    <div style="color: red; font-size: 14px" *ngIf="!authLoading">-->
                    <!--                        <div *ngIf="!authGateways || authGateways.length === 0">-->
                    <!--                            You have no stored credit cards.-->
                    <!--                            <a class="ml-2" href="#"-->
                    <!--                                    (click)="$event.preventDefault(); addCard()">-->
                    <!--                                Add Credit Card-->
                    <!--                            </a>-->
                    <!--                        </div>-->
                    <!--                        <div *ngIf="!isAuthRequested">-->
                    <!--                            You cannot pay this bill by Credit Card.-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
                <div class="w-20 d-flex justify-content-end">
                    <a
                        *ngIf="!authLoading && (!authGateways || authGateways.length === 0)"
                        href="javascript:"
                        (click)="$event.preventDefault(); addCard()"
                        style="font-size: 12px"
                        >Add Credit Card</a
                    >
                    <mat-icon
                        *ngIf="!authLoading && authGateways && authGateways.length > 0 && isAuthRequested"
                        class="icon-chevron-right"
                        >chevron_right</mat-icon
                    >
                </div>
            </div>

            <div
                class="list-item align-items-center sp-cursor-pointer d-flex mx-1 mt-3 _track_po_print_check"
                style="min-height: 50px"
                (click)="tabSlug = 'check'; changeTab()"
            >
                <div class="icon-rectangle">
                    <mat-icon class="material-icons-outlined">print</mat-icon>
                </div>
                <div class="w-60 text-wrap">
                    <div class="list-item-title" style="font-size: 14px">Print Check</div>
                    <div style="font-size: 12px">Generate and print a check for payment</div>
                </div>
                <div class="w-20 d-flex justify-content-end">
                    <mat-icon class="icon-chevron-right">chevron_right</mat-icon>
                </div>
            </div>

            <div
                class="list-item align-items-center sp-cursor-pointer d-flex mx-1 mt-3 mb-1 _track_po_record_payment"
                style="min-height: 50px"
                (click)="tabSlug = 'record'; changeTab()"
            >
                <div class="icon-rectangle">
                    <mat-icon>receipt_long</mat-icon>
                </div>
                <div class="w-60 text-wrap">
                    <div class="list-item-title" style="font-size: 14px">Record Payment</div>
                    <div style="font-size: 12px">Make a record of a payment made outside of SkySlope Books</div>
                </div>
                <div class="w-20 d-flex justify-content-end">
                    <mat-icon class="icon-chevron-right">chevron_right</mat-icon>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Financial Transfer" style="width: 50px" *ngIf="isMoneyTransfersFeatureFlagEnabled">
            <div>
                <h4>Financial Transfer</h4>
                <div
                    class="d-flex"
                    style="margin: 15px; color: red"
                    *ngIf="
                        !isZipiFinancialRequested ||
                        !zipiFinancialGateways ||
                        zipiFinancialGateways.length === 0 ||
                        !isRequestedZipiFinancialActive
                    "
                >
                    Money Transfers (EFT) is not available.
                </div>
                <mat-form-field
                    class="w-48 mr-2"
                    *ngIf="
                        isZipiFinancialRequested &&
                        zipiFinancialGateways &&
                        zipiFinancialGateways.length > 0 &&
                        isRequestedZipiFinancialActive
                    "
                >
                    <mat-select [placeholder]="'Payment Method'" formControlName="paid_by__payment_method_fk_id">
                        <ng-container *ngFor="let gateway of zipiFinancialGateways">
                            <mat-optgroup
                                *ngIf="payToZipiFinancialMethod.payment_gateway.type === gateway.type"
                                [label]="gateway.title"
                            >
                                <ng-container *ngFor="let method of gateway.payment_methods">
                                    <mat-option
                                        *ngIf="method.type !== 'zipi_financial_credit_card'"
                                        [value]="method.payment_method_id"
                                        [disabled]="
                                            gateway.status !== 'active' ||
                                            method.status !== 'active' ||
                                            !method.related__ledger_account_fk_id
                                        "
                                        (onSelectionChange)="methodChanged($event, gateway)"
                                    >
                                        <span>{{ method.title }}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-optgroup>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="w-48">
                    <input
                        matInput
                        formControlName="amount"
                        placeholder="Amount"
                        [maskito]="currencyMaskitoMask"
                        (keyup)="validateAmount($event)"
                    />
                </mat-form-field>
            </div>
            <!--Velocity V2-->
            <div
                *ngIf="
                    formGroup &&
                    formGroup.controls.paid_by__payment_method_fk_id &&
                    formGroup.controls.paid_by__payment_method_fk_id.value &&
                    isVelocityFeatureFlagEnabled
                "
            >
                <div class="d-flex" style="min-height: 66px">
                    <div class="w-48" *ngIf="formGroup && formGroup.controls.sender_velocity">
                        <mat-form-field class="w-100">
                            <mat-select
                                [disableOptionCentering]="true"
                                [placeholder]="'Service Level'"
                                formControlName="sender_velocity"
                            >
                                <ng-container *ngFor="let type of senderVelocityTypes">
                                    <mat-option
                                        [value]="type"
                                        [disabled]="
                                            +unmaskCurrencyString(formGroup.controls.amount.value) >= 100000 &&
                                            type === 'instant'
                                        "
                                    >
                                        {{ type | fromSnakeCase }}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <!--                <div class="ml-2" *ngIf="formGroup && formGroup.controls.sender_velocity && formGroup.controls.sender_velocity.value === 'instant' && selectedMethodType !== 'balance'">-->
                <!--                    <mat-checkbox style="margin-top: 10px" formControlName="restrict_downgrade">Restrict auto-downgrade when sending</mat-checkbox>-->
                <!--                </div>-->
            </div>

            <div
                class="d-flex mr-2 align-items-center"
                style="min-height: 65.5px"
                [formGroup]="formGroup"
                *ngIf="isScheduledPaymentFeatureFlagEnabled"
            >
                <div class="d-flex w-50">
                    <mat-slide-toggle (change)="changePayMode($event)">Schedule Payment</mat-slide-toggle>
                </div>
                <div class="w-50" *ngIf="isScheduleEnabled">
                    <app-date-picker
                        [inputDisabled]="true"
                        [minDate]="minScheduleDate"
                        [maxDate]="maxScheduleDate"
                        [placeholder]="'Pay Date'"
                        [dateControl]="formGroup.controls.scheduled_date"
                    >
                    </app-date-picker>
                </div>
            </div>

            <div class="" [formGroup]="formGroup">
                <mat-form-field class="w-100">
                    <textarea matInput type="text" formControlName="note" placeholder="Reference (optional)">
                    </textarea>
                </mat-form-field>
            </div>
        </mat-tab>

        <mat-tab label="Card on File" style="width: 50px">
            <div>
                <div
                    class="d-flex"
                    style="margin: 15px; color: red"
                    *ngIf="!invoiceCardMethod || !isZipiFinancialCardRequested || !isRequestedZipiFinancialCardActive"
                >
                    Card on File is not available.
                </div>
                <mat-form-field
                    class="w-48 mr-2"
                    *ngIf="isZipiFinancialCardRequested && zipiPayCardGateway && isRequestedZipiFinancialCardActive"
                >
                    <mat-select [placeholder]="'Payment Method'" formControlName="paid_by__payment_method_fk_id">
                        <ng-container *ngFor="let method of zipiPayCardGateway.payment_methods">
                            <mat-option
                                *ngIf="method.type === 'zipi_financial_credit_card'"
                                [value]="method.payment_method_id"
                                [disabled]="zipiPayCardGateway.status !== 'active' || method.status !== 'active'"
                            >
                                <span>{{ method.title }}</span>
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="w-48">
                    <input
                        matInput
                        formControlName="amount"
                        placeholder="Amount"
                        [maskito]="currencyMaskitoMask"
                        (keyup)="validateAmount($event)"
                    />
                </mat-form-field>
            </div>

            <div
                class="d-flex mr-2 align-items-center"
                style="min-height: 65.5px"
                [formGroup]="formGroup"
                *ngIf="isScheduledPaymentFeatureFlagEnabled"
            >
                <div class="d-flex w-50">
                    <mat-slide-toggle (change)="changePayMode($event)">Schedule Payment</mat-slide-toggle>
                </div>
                <div class="w-50" *ngIf="isScheduleEnabled">
                    <app-date-picker
                        [inputDisabled]="true"
                        [minDate]="minScheduleDate"
                        [maxDate]="maxScheduleDate"
                        [placeholder]="'Pay Date'"
                        [dateControl]="formGroup.controls.scheduled_date"
                    >
                    </app-date-picker>
                </div>
            </div>

            <div class="" [formGroup]="formGroup">
                <mat-form-field class="w-100">
                    <textarea matInput type="text" formControlName="note" placeholder="Reference (optional)">
                    </textarea>
                </mat-form-field>
            </div>
        </mat-tab>

        <mat-tab label="Authorize.Net">
            <div *ngIf="isAuthRequested">
                <h4 class="d-flex align-items-end">
                    <img
                        style="width: 100px; margin-right: 5px"
                        src="https://storage.googleapis.com/zipi-static/addons/authnet/logos/authnet%20360x140.png"
                    />
                    Authorize.Net
                </h4>
                <div
                    class="d-flex"
                    style="margin: 15px; color: red"
                    *ngIf="!isAuthRequested || !authGateways || authGateways.length === 0"
                >
                    Authorize.Net is not available.
                </div>

                <mat-form-field class="w-48 mr-2" *ngIf="isAuthRequested && authGateways && authGateways.length > 0">
                    <mat-select [placeholder]="'Payment Method'" formControlName="paid_by__payment_method_fk_id">
                        <mat-option [value]="null" class="add-card-option" (click)="addCard()">
                            <mat-icon>add_circle_outline</mat-icon>
                            <span>Add Payment Method</span>
                        </mat-option>

                        <!--<mat-option [value]="null">None</mat-option>-->

                        <ng-container *ngFor="let gateway of authGateways">
                            <div *ngFor="let method of gateway.payment_methods">
                                <mat-option
                                    *ngIf="method.is_available_for_payment"
                                    [value]="method.payment_method_id"
                                    [disabled]="
                                        method.status !== 'active' ||
                                        !method.related__ledger_account_fk_id ||
                                        gateway.status !== 'active'
                                    "
                                >
                                    <div class="d-flex">
                                        <div class="w-90">{{ method?.title }}</div>
                                        <span class="sp-spacer"></span>
                                        <div class="w-10" *ngIf="!method.related__ledger_account_fk_id">
                                            <mat-icon
                                                [matTooltip]="
                                                    'Need to finish setup. You can do this on the Contact Edit Page.'
                                                "
                                                >info</mat-icon
                                            >
                                        </div>
                                    </div>
                                </mat-option>
                            </div>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="w-48">
                    <input
                        matInput
                        formControlName="amount"
                        placeholder="Amount"
                        [maskito]="currencyMaskitoMask"
                        (keyup)="validateAmount($event)"
                    />
                </mat-form-field>
            </div>

            <div class="" [formGroup]="formGroup">
                <mat-form-field class="w-100">
                    <textarea matInput type="text" formControlName="note" placeholder="Reference (optional)">
                    </textarea>
                </mat-form-field>
            </div>
        </mat-tab>

        <mat-tab label="Check">
            <div>
                <h4>Print Check</h4>
                <div class="d-flex mr-2" [formGroup]="formGroup">
                    <mat-form-field class="w-50 mr-2">
                        <input
                            matInput
                            formControlName="amount"
                            placeholder="Amount"
                            [maskito]="currencyMaskitoMask"
                            (keyup)="validateAmount($event)"
                        />
                    </mat-form-field>
                    <div class="w-50">
                        <app-date-picker [placeholder]="'Payment Date'" [dateControl]="formGroup.controls.paid_date">
                        </app-date-picker>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="w-50 mr-2">
                        <app-ledger-account-selector
                            class="w-100"
                            #ledgerAccountPicker2
                            [ledgerAccountControl]="formGroup.controls.paid_by__ledger_account_fk_id"
                            [placeholder]="'Paid Through'"
                        >
                        </app-ledger-account-selector>
                    </div>
                    <mat-form-field class="w-50">
                        <input matInput type="text" formControlName="check_number" placeholder="Check#" />
                    </mat-form-field>
                </div>

                <mat-form-field class="w-100">
                    <textarea matInput type="text" formControlName="memo" placeholder="Memo"> </textarea>
                </mat-form-field>
            </div>

            <div class="" [formGroup]="formGroup">
                <mat-form-field class="w-100">
                    <textarea matInput type="text" formControlName="note" placeholder="Reference (optional)">
                    </textarea>
                </mat-form-field>
            </div>
        </mat-tab>

        <mat-tab label="Record">
            <h4>Record Payment</h4>
            <div class="d-flex mr-2" [formGroup]="formGroup">
                <mat-form-field class="w-50 mr-2">
                    <input
                        matInput
                        formControlName="amount"
                        placeholder="Amount"
                        [maskito]="currencyMaskitoMask"
                        (keyup)="validateAmount($event)"
                    />
                </mat-form-field>
                <div class="w-50">
                    <app-date-picker [placeholder]="'Payment Date'" [dateControl]="formGroup.controls.paid_date">
                    </app-date-picker>
                </div>
            </div>

            <div class="d-flex">
                <mat-form-field class="w-50 mr-2">
                    <mat-select formControlName="payment_mode">
                        <ng-container *ngFor="let mode of paymentModes">
                            <mat-option [value]="mode.slug" *ngIf="mode.is_selectable">
                                <span>{{ mode.title }}</span>
                            </mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-placeholder>Payment Mode</mat-placeholder>
                </mat-form-field>

                <div class="w-50">
                    <app-ledger-account-selector
                        #ledgerAccountPicker1
                        class="w-100"
                        [ledgerAccountControl]="formGroup.controls.paid_by__ledger_account_fk_id"
                        [placeholder]="'Paid Through'"
                    >
                    </app-ledger-account-selector>
                </div>
            </div>

            <div
                [formGroup]="formGroup"
                *ngIf="
                    formGroup.controls.payment_mode &&
                    formGroup.controls.payment_mode.value &&
                    formGroup.controls.payment_mode.value === 'check_record'
                "
            >
                <div class="w-50">
                    <mat-form-field class="w-90">
                        <input matInput autocomplete="off" type="search" formControlName="check_number" />
                        <mat-label>Check Number</mat-label>
                    </mat-form-field>
                </div>
                <div
                    class="w-100"
                    *ngIf="
                        formGroup.controls.payment_mode &&
                        formGroup.controls.payment_mode.value &&
                        formGroup.controls.payment_mode.value === 'check_record'
                    "
                >
                    <mat-form-field class="w-100">
                        <textarea matInput type="text" formControlName="memo"> </textarea>
                        <mat-label>Memo</mat-label>
                    </mat-form-field>
                </div>
            </div>

            <div class="" [formGroup]="formGroup">
                <mat-form-field class="w-100">
                    <textarea matInput type="text" formControlName="note" placeholder="Reference (optional)">
                    </textarea>
                </mat-form-field>
            </div>
        </mat-tab>

        <mat-tab label="Direct Deposit">
            <h4>
                Direct Deposit
                {{
                    isPayloadCoFeatureFlagEnabled && selectedDriver === 'payload'
                        ? '(by Payload.co)'
                        : isPayloadCoFeatureFlagEnabled && selectedDriver !== 'payload' && selectedDriver !== null
                          ? '(by ZilMoney)'
                          : ''
                }}
            </h4>
            <div
                class="d-flex"
                *ngIf="
                    zipiFinancialGateways &&
                    zipiFinancialGateways.length > 0 &&
                    (contactZipiFinancialReceiveGateway || contactZipiFinancialPayloadCoReceiveGateway)
                "
            >
                <mat-form-field class="w-50 mr-2" *ngIf="zipiFinancialGateways && zipiFinancialGateways.length > 0">
                    <mat-select [placeholder]="'Pay From Account'" formControlName="paid_by__payment_method_fk_id">
                        <mat-optgroup *ngFor="let gateway of zipiFinancialGateways" [label]="gateway.title">
                            <ng-container *ngFor="let method of gateway.payment_methods">
                                <mat-option
                                    (onSelectionChange)="methodChanged($event, gateway)"
                                    *ngIf="method.type !== 'zipi_financial_credit_card'"
                                    [value]="method.payment_method_id"
                                    [disabled]="method.status !== 'active' || !method.related__ledger_account_fk_id"
                                >
                                    <span>{{ method.title }}</span>
                                </mat-option>
                            </ng-container>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="w-50 mr-2">
                    <mat-select formControlName="pay_to__payment_method_fk_id">
                        <ng-container *ngIf="contactZipiFinancialReceiveGateway && selectedDriver !== 'payload'">
                            <ng-container *ngFor="let method of contactZipiFinancialReceiveGateway.payment_methods">
                                <mat-option [value]="method.payment_method_id">
                                    <span>{{ method.title }}</span>
                                </mat-option>
                            </ng-container>
                        </ng-container>
                        <ng-container
                            *ngIf="contactZipiFinancialPayloadCoReceiveGateway && selectedDriver === 'payload'"
                        >
                            <ng-container
                                *ngFor="let method of contactZipiFinancialPayloadCoReceiveGateway.payment_methods"
                            >
                                <mat-option [value]="method.payment_method_id">
                                    <span>{{ method.title }}</span>
                                </mat-option>
                            </ng-container>
                        </ng-container>
                    </mat-select>
                    <mat-placeholder>Pay To Account</mat-placeholder>
                </mat-form-field>
            </div>

            <div class="d-flex mr-2" [formGroup]="formGroup">
                <mat-form-field class="w-50 mr-2">
                    <input
                        matInput
                        formControlName="amount"
                        placeholder="Amount"
                        [maskito]="currencyMaskitoMask"
                        (keyup)="validateAmount($event)"
                    />
                </mat-form-field>
            </div>

            <div class="" [formGroup]="formGroup">
                <mat-form-field class="w-100">
                    <textarea matInput type="text" formControlName="note" placeholder="Reference (optional)">
                    </textarea>
                </mat-form-field>
            </div>
        </mat-tab>
    </mat-tab-group>

    <div class="d-flex mb-2">
        <button mat-raised-button class="" *ngIf="tabIndex !== 0" (click)="tabSlug = 'menu'; changeTab()">Back</button>
        <span class="sp-spacer"></span>
        <button mat-raised-button class="mr-2" color="primary" *ngIf="tabIndex !== 0" (click)="pay()" tabindex="1">
            Pay
        </button>
    </div>
</mat-dialog-content>

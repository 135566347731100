import {Component, Inject, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {Subject, firstValueFrom} from 'rxjs';
import {takeUntil, filter, map, tap} from 'rxjs/operators';
import {IContactPayroll, IGooglePlaceObject, IContact} from '@cyberco-nodejs/zipi-typings';
import {extractLocationComponents} from '../../store/contact.utilities';
import {NotificationsService} from 'angular2-notifications';
import * as moment from 'moment';
import {ShipperContactsService} from 'app/services/api/shipper.contacts.service';
import {einMaskitoOptions, ssnMaskitoOptions, unmaskCurrencyControlValue} from '../../../../utilities/maskito';

interface IDialogData {
    type: string;
    payroll: IContactPayroll;
    contact: IContact;
}

@Component({
    selector: 'app-add-contact-payroll-1099-dialog',
    templateUrl: 'add-contact-payroll-1099-dialog.component.html',
    styleUrls: ['add-contact-payroll-1099-dialog.component.css']
})
export class AddContactPayroll1099DialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();
    formGroup: UntypedFormGroup;
    locationPickerCtrl = new UntypedFormControl();
    tinTypeList: Array<{title: string; value: string}> = [
        {title: 'EIN', value: 'ein'},
        {title: 'SSN', value: 'ssn'}
    ];
    public activeTinTypeMask = einMaskitoOptions;

    constructor(
        public dialogRef: MatDialogRef<AddContactPayroll1099DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData,
        private fb: UntypedFormBuilder,
        private changeDetector: ChangeDetectorRef,
        private ntfs: NotificationsService,
        private contactsService: ShipperContactsService
    ) {
        this.formGroup = this.fb.group({
            contact_payroll_id: null,
            name: ['', [Validators.required]],
            type: this.data.type ? this.data.type : '1099_misc',
            tin_type: ['ein', Validators.required],
            // @ts-ignore
            start_date: [moment(new Date()).format('YYYYMMDD'), [Validators.required]],
            filing_status: null,
            withholding_amount: null,
            extra_withholding_amount: null,
            other_income: null,
            deductions: null,
            label: '',
            abbr: '',
            unit_number: '',
            street_number: '',
            street_address: '',
            full_street: '',
            zip: '',
            city: '',
            state: '',
            country: '',
            tin_edit_mode: [true],
            existed_tin: [{value: null, disabled: true}],
            tin: [null],
            isEdit: false
        });
    }

    async ngOnInit() {
        this.formGroup
            .get('tin_type')!
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((val) => {
                this.activeTinTypeMask = val === 'ein' ? einMaskitoOptions : ssnMaskitoOptions;
            });

        // edit
        if (this.data.payroll) {
            this.formGroup.get('isEdit')!.patchValue(true);
            const decryptedTin = await firstValueFrom(
                this.contactsService.getDecryptedTin(
                    Number(this.data.contact.contact_id),
                    Number(this.data.payroll.contact_payroll_id)
                )
            );

            this.formGroup.patchValue(this.data.payroll);
            this.formGroup.patchValue({
                tin_edit_mode: false,
                existed_tin: this.getTinWithMask(this.data.payroll),
                tin: decryptedTin.tin
            });
        } else {
            // create
            this.formGroup.get('name')!.patchValue(this.data.contact.display_name);
            if (this.data.contact.contact_locations && this.data.contact.contact_locations.length) {
                this.formGroup.patchValue(this.data.contact.contact_locations[0]);
            }
        }

        this.locationPickerCtrl.valueChanges
            .pipe(
                filter((l) => l),
                map((lcn: IGooglePlaceObject) => extractLocationComponents(lcn)),
                tap((location) => this.formGroup.patchValue(location)),
                tap(() => this.changeDetector.detectChanges()),
                tap(() => this.locationPickerCtrl.reset(null)),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.formGroup
            .get('withholding_amount')!
            .valueChanges.pipe(
                unmaskCurrencyControlValue(this.formGroup.get('withholding_amount')!),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.formGroup
            .get('extra_withholding_amount')!
            .valueChanges.pipe(
                unmaskCurrencyControlValue(this.formGroup.get('extra_withholding_amount')!),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.formGroup
            .get('other_income')!
            .valueChanges.pipe(
                unmaskCurrencyControlValue(this.formGroup.get('other_income')!),
                takeUntil(this.unsubscribe)
            )
            .subscribe();

        this.formGroup
            .get('deductions')!
            .valueChanges.pipe(
                unmaskCurrencyControlValue(this.formGroup.get('deductions')!),
                takeUntil(this.unsubscribe)
            )
            .subscribe();
    }

    editTin() {
        this.formGroup.controls.tin_edit_mode.patchValue(!this.formGroup.controls.tin_edit_mode.value);
        if (this.formGroup.controls.tin_edit_mode) {
            this.formGroup.controls.tin.setValidators([
                Validators.required,
                Validators.pattern(`(\\d{3}-\\d{2}-\\d{4})?(\\d{2}-\\d{7})?([0-9]{9})?`)
            ]);
            this.formGroup.controls.tin.updateValueAndValidity();
        } else {
            this.formGroup.controls.tin.clearValidators();
            this.formGroup.controls.tin.updateValueAndValidity();
        }
    }

    getTinWithMask(payroll: IContactPayroll) {
        if (!payroll) {
            return '';
        }

        if (payroll.tin_type === 'ssn') {
            return 'XXX-XX-XXXX';
        } else if (payroll.tin_type === 'ein') {
            return 'XX-XXXXXXX';
        } else {
            return 'XXXXXXXXX';
        }
    }

    async submitForm() {
        let payrolls = this.data.contact.contact_payrolls;
        if (payrolls && this.data.payroll) {
            payrolls = payrolls.filter(
                (payroll: IContactPayroll) => payroll.contact_payroll_id !== this.data.payroll.contact_payroll_id
            );
        }
        let isTinExist = false;

        const start_date = this.formGroup.get('start_date') as UntypedFormControl;
        if (this.formGroup.controls.tin_edit_mode.value) {
            const tinNoDashes = this.formGroup.controls.tin?.value?.replace(/[^0-9]/g, '') ?? '';
            if (this.formGroup.controls.tin.value && tinNoDashes.length === 9) {
                this.formGroup.controls.tin.setValue(tinNoDashes);
                const exisitingTin: Number[] = [];
                await Promise.all(
                    (payrolls || []).map(async (payroll: IContactPayroll) => {
                        const decryptedTin = await firstValueFrom(
                            this.contactsService.getDecryptedTin(
                                Number(this.data.contact.contact_id),
                                Number(payroll.contact_payroll_id)
                            )
                        );
                        exisitingTin.push(decryptedTin.tin);
                    })
                );
                isTinExist = exisitingTin.includes(this.formGroup.controls.tin.value);
            } else {
                this.ntfs.warn('TIN is not valid');
            }
        }

        const isDateExist =
            payrolls && start_date
                ? payrolls.some(
                      (payroll: IContactPayroll) =>
                          payroll.type === this.data.type && Number(start_date.value) === Number(payroll.start_date)
                  )
                : false;
        if (this.formGroup.invalid) {
            this.ntfs.warn('Form is not valid');
        } else if (isTinExist) {
            this.ntfs.warn('A record with this tax id already exists. Please edit the existing record');
        } else if (isDateExist) {
            this.ntfs.warn('A record with this date already exists');
        } else {
            const formValue = Object.assign({}, this.formGroup.value);
            this.dialogRef.close(formValue);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

import {IContactPartLink} from '@cyberco-nodejs/zipi-typings';
import {FormControl} from '@angular/forms';
import {GenericFormArray, GenericFormGroup} from '@app/entites/generic.entity';
import {ChipNode} from '@app/modules/account-info/compensation/models/chip-node';

export type MetricType =
    | 'aggregate_count_of_deals'
    | 'aggregate_sales_price'
    | 'sum_of_gross_commission'
    | 'aggregate_agent_split'
    | 'aggregate_company_split'
    | 'aggregate_royalty_split'
    | 'aggregate_user_royalty_split'
    | 'aggregate_side_count'
    | 'aggregate_sales_volume'
    | 'aggregate_transfers_amount';

export const METRIC_TYPES: {[keys in MetricType]: {key: MetricType; label: string}} = {
    aggregate_count_of_deals: {key: 'aggregate_count_of_deals', label: 'Aggregate Count of Deals'},
    aggregate_sales_price: {key: 'aggregate_sales_price', label: 'Aggregate Sales Price'},
    sum_of_gross_commission: {key: 'sum_of_gross_commission', label: 'Aggregate Gross Commission'},
    aggregate_agent_split: {key: 'aggregate_agent_split', label: 'Aggregate Sales Entity Split'},
    aggregate_company_split: {key: 'aggregate_company_split', label: 'Aggregate Company Split'},
    aggregate_royalty_split: {key: 'aggregate_royalty_split', label: 'Aggregate Deal Royalty Split'},
    aggregate_user_royalty_split: {key: 'aggregate_user_royalty_split', label: 'Aggregate User Royalty Split'},
    aggregate_side_count: {key: 'aggregate_side_count', label: 'Aggregate Count of Deals (Based On # Allocation)'},
    aggregate_sales_volume: {key: 'aggregate_sales_volume', label: 'Aggregate Sales Price (Based On % Allocation)'},
    aggregate_transfers_amount: {key: 'aggregate_transfers_amount', label: 'Aggregate Transfers Amounts'}
};

export const METRIC_TYPES_ARRAY = Object.values(METRIC_TYPES);

export type ResetHistoryDateType = 'context_agent_anniversary' | 'specific_date';

export const RESET_HISTORY_DATE_TYPES: {[keys in ResetHistoryDateType]: {key: ResetHistoryDateType; label: string}} = {
    context_agent_anniversary: {key: 'context_agent_anniversary', label: 'Context Agent Anniversary Date'},
    specific_date: {key: 'specific_date', label: 'Specific Date'}
};
export const RESET_HISTORY_DATE_TYPES_ARRAY = Object.values(RESET_HISTORY_DATE_TYPES);

export type ResetHistoryPeriod = 'annually' | 'monthly' | 'weekly' | 'daily';

export const RESET_HISTORY_PERIODS: {[keys in ResetHistoryPeriod]: {key: ResetHistoryPeriod; label: string}} = {
    annually: {key: 'annually', label: 'Annually'},
    monthly: {key: 'monthly', label: 'Monthly'},
    weekly: {key: 'weekly', label: 'Weekly'},
    daily: {key: 'daily', label: 'Daily'}
};
export const RESET_HISTORY_PERIODS_ARRAY = Object.values(RESET_HISTORY_PERIODS);

export type ContextAgentRelation =
    | 'context_agent_is_sender'
    | 'context_agent_is_receiver'
    | 'context_agent_is_initiator';

export const CONTEXT_AGENT_RELATIONS: {[keys in ContextAgentRelation]: {key: ContextAgentRelation; label: string}} = {
    context_agent_is_sender: {key: 'context_agent_is_sender', label: 'Context Agent is Sender'},
    context_agent_is_receiver: {key: 'context_agent_is_receiver', label: 'Context Agent is Receiver'},
    context_agent_is_initiator: {key: 'context_agent_is_initiator', label: 'Context Agent is Initiator'}
};
export const CONTEXT_AGENT_RELATIONS_ARRAY = Object.values(CONTEXT_AGENT_RELATIONS);

export interface IMetricTransferFilter {
    context_agent_relation: ContextAgentRelation | null;
    product_id: number | null;
    sender: IContactPartLink | null;
    receiver: IContactPartLink | null;
    sender_wildcard_id: number | null;
    receiver_wildcard_id: number | null;
}

export interface IMetricDealFilter {
    is_enabled: boolean;
    close_of_escrow_from: string | null;
    close_of_escrow_till: string | null;
    types: string[];
    source_of_business: string[];
    property_classes: string[];
    mls_organisations: number[];
}

export interface IMetricAdvancedSettings {
    reset_history__is_enabled: boolean;
    reset_history__reset_date_type: string | null;
    reset_history__reset_date: string | null;
    reset_history__reset_after_period: string | null;
    group_capping__is_enabled: boolean;
    group_capping__groups_ids: number[];
}

export interface IMetric {
    metric_id?: number;
    title: string | null;
    type: MetricType;
    transfers_filters: IMetricTransferFilter[];
    deals_filters: IMetricDealFilter;
    advanced_settings: IMetricAdvancedSettings;
}

export interface IMetricTransferFilterFG {
    context_agent_relation: FormControl<string | null>;
    product_id: FormControl<number | null>;
    sender: GenericFormArray<GenericFormGroup<ChipNode>>;
    receiver: GenericFormArray<GenericFormGroup<ChipNode>>;
}

export interface IMetricDealsFiltersFG {
    is_enabled: FormControl<boolean | null>;
    close_of_escrow_from: FormControl<Date | null>;
    close_of_escrow_till: FormControl<Date | null>;
    types: FormControl<string[] | null>;
    source_of_business: FormControl<string[] | null>;
    property_classes: FormControl<string[] | null>;
    mls_organisations: FormControl<number[] | null>;
}

export interface IMetricAdvancedSettingsFG {
    reset_history__is_enabled: FormControl<boolean | null>;
    reset_history__reset_date_type: FormControl<string | null>;
    reset_history__reset_date: FormControl<Date | null>;
    reset_history__reset_after_period: FormControl<string | null>;
    group_capping__is_enabled: FormControl<boolean | null>;
    group_capping__groups_ids: FormControl<number[] | null>;
}

export interface IMetricGeneralFG {
    title: FormControl<string | null>;
    type: FormControl<string | null>;
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {GenericEntity, GenericFormArray, GenericFormGroup} from '../../../../entites/generic.entity';
import {CompensationProfileModel} from '../models/compensation-profile.model';
import {BehaviorSubject, merge as observableMerge, Subject} from 'rxjs';
import {startWith, switchMap, takeUntil} from 'rxjs/operators';
import {CompensationService} from '../compensation.service';
import * as _ from 'lodash-es';
import {ConfirmComponent} from '../../../../layouts/confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {IContactsState} from '../../../contacts/store/contacts.reducer';
import {selectProducts} from '../../../finance/store/finance.selectors';
import {IProduct} from '@cyberco-nodejs/zipi-typings';

const sortBy = {
    alpha_asc: (a: CompensationProfileModel, b: CompensationProfileModel) => {
        if (a.title! > b.title!) {
            return 1;
        } else if (a.title! < b.title!) {
            return -1;
        } else {
            return 0;
        }
    },
    order_desc: (a: CompensationProfileModel, b: CompensationProfileModel) => {
        if (a.sort_order < b.sort_order) {
            return 1;
        } else if (a.sort_order > b.sort_order) {
            return -1;
        } else {
            return 0;
        }
    }
};

@Component({
    selector: 'app-tab-1-profiles',
    styles: [
        `
            .header-less-tabs.mat-tab-group .mat-tab-header {
                display: none;
            }
        `
    ],
    template: `
        <app-company-compensation-toolbar
            #toolbar
            (tabChanged)="tabChanged.emit({tabId: $event.tabId})"
            [drafts]="includeDrafts"
            [sortBy]="sortBy"
        ></app-company-compensation-toolbar>
        <div class="header-less-tabs">
            <div *ngIf="toolbar.show === 0">
                <app-tab-1-all
                    (update)="updateCompensationProfilesList()"
                    [compensationProfilesList]="compensationProfilesList"
                    [includeDrafts]="includeDrafts"
                    [sortBy]="sortBy"
                ></app-tab-1-all>
            </div>
            <div *ngIf="toolbar.show === 1">
                <app-tab-2-groups
                    [unfilteredCompensationProfilesList]="unfilteredCompensationProfilesList"
                    [includeDrafts]="includeDrafts"
                    [sortBy]="sortBy"
                ></app-tab-2-groups>
            </div>
            <div *ngIf="toolbar.show === 2">
                <app-tab-3-entites [includeDrafts]="includeDrafts" [sortBy]="sortBy"></app-tab-3-entites>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None
})
export class Tab1ProfilesComponent implements OnInit, OnDestroy {
    @Input() onSaveProfiles: EventEmitter<void> | undefined;
    @Input() hasChanges: boolean | undefined;
    @Output() tabChanged: EventEmitter<{tabId: number}> = new EventEmitter<{tabId: number}>();
    @Output() form: EventEmitter<any> = new EventEmitter<any>();
    private unsubscribe: Subject<void> = new Subject();
    private products: IProduct[] = [];

    public includeDrafts: UntypedFormControl = new UntypedFormControl(true);
    public sortBy: UntypedFormControl = new UntypedFormControl('order_desc');
    public compensationProfilesList: GenericFormArray<CompensationProfileModel> =
        new GenericFormArray<CompensationProfileModel>([]);
    public compensationProfilesListSnapshot: CompensationProfileModel[] = [];

    unfilteredCompensationProfilesList: BehaviorSubject<CompensationProfileModel[]> = new BehaviorSubject<
        CompensationProfileModel[]
    >([]);
    filteredCompensationProfilesList: BehaviorSubject<CompensationProfileModel[]> = new BehaviorSubject<
        CompensationProfileModel[]
    >([]);

    updateCompensationProfilesList() {
        return this.compensationService.api
            .getCompensationProfiles()
            .then((list) =>
                list.map((item: CompensationProfileModel) =>
                    GenericEntity.FABRIC(CompensationProfileModel).hydrate(item)
                )
            )
            .then((mappedList) => {
                const sortedList = _.reverse(_.sortBy(mappedList, ['sort_order']));

                const cpArray: {cpId: number; sort_order: number}[] = [];
                sortedList.forEach((cp, inx) => {
                    if (cp.sort_order !== sortedList.length - inx) {
                        cpArray.push({
                            cpId: cp.id,
                            sort_order: sortedList.length - inx
                        });
                    }
                });

                if (cpArray.length > 0) {
                    this.compensationService.api
                        .putBulkCompensationProfileOrderWithoutRecalculate(cpArray)
                        .then((res) => {
                            this.updateCompensationProfilesList();
                        });
                }

                return this.unfilteredCompensationProfilesList.next(sortedList);
            });
    }

    constructor(
        private store: Store<IContactsState>,
        protected compensationService: CompensationService,
        protected dialog: MatDialog
    ) {}

    ngOnInit() {
        this.onSaveProfiles!.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.compensationProfilesList.controls.forEach((compensationFormFG) => {
                this.compensationService.setupCompensationProfileFormValidators(compensationFormFG);
                if (!this.compensationService.isCompensationProfileFormValid(compensationFormFG)) {
                    compensationFormFG.setErrors({incorrect: true});
                }
            });

            const isCompensationProfilesValid = this.compensationProfilesList.controls.every((compensationFormFG) => {
                return !compensationFormFG.invalid;
            });

            if (!isCompensationProfilesValid) {
                return;
            }

            const allProfiles = this.compensationProfilesList.getRawValue();
            // Profiles that has id was updated
            const profilesToUpdate = allProfiles.filter((profileToUpdate) => {
                if (!profileToUpdate.id) {
                    return false;
                }
                const profilePreviousState = this.compensationProfilesListSnapshot.find(
                    (profileSnapshot) => profileSnapshot.id === profileToUpdate.id
                );
                if (typeof profilePreviousState !== 'undefined') {
                    return !_.isEqual(profileToUpdate, profilePreviousState);
                }
                return false;
            });
            // Profiles that exist in snapshot and doesn't exist in the current profiles list was removed
            const profilesToDelete = this.compensationProfilesListSnapshot.filter(
                (profile) => !allProfiles.find((profileToUpdate) => profileToUpdate.id === profile.id)
            );
            // Profiles that exist in the current profiles list and doesn't exist in the snapshot was added
            const profilesToCreate = allProfiles.filter(
                (profile) =>
                    !this.compensationProfilesListSnapshot.find((profileToUpdate) => profileToUpdate.id === profile.id)
            );
            this.compensationService
                .updateCompensationProfiles({
                    profilesToUpdate,
                    profilesToDelete,
                    profilesToCreate
                })
                .then((res) => {
                    if (typeof res.success !== 'undefined' && !res.success) {
                        const productsInUse = this.products.filter((product) =>
                            res.products_in_use?.includes(product.product_id!)
                        );
                        this.dialog.open(ConfirmComponent, {
                            maxWidth: 600,
                            data: {
                                title: 'Compensation Profile(s) cannot be updated or created',
                                messages: [
                                    `Compensation Profile(s) cannot be updated or created because some of them conatains product${productsInUse.length > 1 ? 's' : ''} ${productsInUse
                                        .map((product) => product.name)
                                        .join(
                                            ', '
                                        )} in Conditions which ${productsInUse.length > 1 ? 'are' : 'is'} not allowed to be used as Capable Metrics by Company calculation settings.`,
                                    '',
                                    'Please, refresh the page and try again'
                                ],
                                hideCancel: true
                            }
                        });
                    } else {
                        this.updateCompensationProfilesList().then(() => {
                            this.compensationProfilesListSnapshot = this.compensationProfilesList.getRawValue();

                            this.form.emit({
                                currentForm: this.compensationProfilesList,
                                startedValues: this.compensationProfilesListSnapshot
                            });
                        });
                    }
                });
        });

        this.filteredCompensationProfilesList.pipe(takeUntil(this.unsubscribe)).subscribe((next) => {
            this.compensationProfilesList = new GenericFormArray(
                next.map((item) => {
                    const compensationProfileFG = new GenericFormGroup(item);
                    compensationProfileFG.controls.financial_elements!.controls.map((financialElement) => {
                        financialElement.get('type')!.disable();
                        return financialElement;
                    });
                    return compensationProfileFG;
                })
            );
            this.compensationProfilesListSnapshot = this.compensationProfilesList.getRawValue();

            this.form.emit({
                currentForm: this.compensationProfilesList,
                startedValues: this.compensationProfilesListSnapshot
            });
        });

        observableMerge(
            this.unfilteredCompensationProfilesList,
            this.includeDrafts.valueChanges,
            this.sortBy.valueChanges
        )
            .pipe(startWith(true), takeUntil(this.unsubscribe))
            .subscribe((next) => {
                const list = this.unfilteredCompensationProfilesList.value
                    // @ts-ignore
                    .sort(sortBy[this.sortBy.value])
                    .filter((item) => {
                        if (this.includeDrafts.value) {
                            return true;
                        } else {
                            return item.status !== CompensationProfileModel.status_set.draft;
                        }
                    });
                this.filteredCompensationProfilesList.next(list);
            });

        this.store.pipe(select(selectProducts), takeUntil(this.unsubscribe)).subscribe((data) => {
            this.products = data;
        });

        this.updateCompensationProfilesList();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

import {merge as observableMerge, Observable, Subject} from 'rxjs';

import {startWith} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AppInitSource} from '../../services/sources/app-init.source';
import {ServiceRequester} from '../../services/service.requester';
import {FocusAreaEntity} from './entites/focus-area.entity';
import {GoalInstanceEntity} from './entites/goal-instance.entity';
import {GenericEntity} from '../../entites/generic.entity';
import {GoalValueEntity} from './entites/goal-value.entity';
import * as moment from 'moment';
import {CurrentProfileSource} from '../../services/sources/current-profile.source';

export class LoadProgressResponseEntity extends GenericEntity {
    focus_areas: FocusAreaEntity[] = [];
    instances_by_focus_area: {[id: number]: GoalInstanceEntity[]} | null = null;

    constructor() {
        super();
    }

    static get helpers() {
        return Object.assign(
            {
                props: Object.getOwnPropertyNames(new this()).reduce(
                    (acc, curr) => {
                        acc[curr] = curr;
                        return acc;
                    },
                    {} as {[key: string]: any}
                ),
                build: {
                    focus_areas: (val: FocusAreaEntity[], fabric: any) =>
                        val.map((item) => FocusAreaEntity.fabric[fabric](item)),
                    instances_by_focus_area: (val: {[id: number]: GoalInstanceEntity[]}, fabric: any) => {
                        return Object.keys(val).reduce(
                            (acc: {[key: string]: any}, index: any) => {
                                // @ts-ignore: cannot define type 'fabric' value
                                acc[index] = val[index].map((item) => GoalInstanceEntity.fabric[fabric](item));
                                return acc;
                            },
                            {} as {[key: string]: any}
                        );
                    }
                }
            },
            GenericEntity.helpers
        );
    }

    static get fabric() {
        return Object.assign(GenericEntity.fabric, {
            hydrate: (
                input: object,
                output: LoadProgressResponseEntity = new this(),
                props: {[prop: string]: string} = this.helpers.props,
                transform: {[id: string]: (val: any, fabric: any) => any} = this.helpers.build
            ): LoadProgressResponseEntity => {
                // @ts-ignore: might be null if input is null
                return <LoadProgressResponseEntity>GenericEntity.fabric.hydrate(input, output, props, transform);
            },
            dehydrate: (
                input: object | LoadProgressResponseEntity,
                output: object = {},
                props: {[prop: string]: string} = this.helpers.props,
                transform: {[id: string]: (val: any, fabric: any) => any} = this.helpers.build
            ): object => {
                // @ts-ignore: might be null if input is null
                return <object>GenericEntity.fabric.dehydrate(input, output, props, transform);
            }
        });
    }
}

@Injectable()
export class ProgressSource {
    private triggers = {
        load: new Subject<LoadProgressResponseEntity>(),
        focusAreas: new Subject<FocusAreaEntity[]>(),
        instancesAssoc: new Subject<{[id: number]: GoalInstanceEntity[]} | null>()
    };

    public events = {
        onLoad: <Observable<LoadProgressResponseEntity>>observableMerge(this.triggers.load),
        onFocusAreasChange: <Observable<FocusAreaEntity[]>>observableMerge(this.triggers.focusAreas),
        onInstancesAssocChange: <Observable<{[id: number]: GoalInstanceEntity[]}>>(
            observableMerge(this.triggers.instancesAssoc)
        )
    };

    private currentProgressResponse: LoadProgressResponseEntity | null = null;
    private currentFocusAreas: FocusAreaEntity[] = [];
    private currentInstancesAssoc: {[id: number]: GoalInstanceEntity[]} | null = null;

    public setLoadedProgress(addons: LoadProgressResponseEntity) {
        this.triggers.load.next(addons);
    }

    public get onLoad(): Observable<LoadProgressResponseEntity> {
        if (this.currentProgressResponse) {
            return this.events.onLoad.pipe(startWith(this.currentProgressResponse));
        }
        return this.events.onLoad;
    }

    public get onFocusAreasChange(): Observable<FocusAreaEntity[]> {
        if (this.currentFocusAreas) {
            return this.events.onFocusAreasChange.pipe(startWith(this.currentFocusAreas));
        }
        return this.events.onFocusAreasChange;
    }

    public get onInstancesAssocChange(): Observable<{[id: number]: GoalInstanceEntity[]}> {
        if (this.currentInstancesAssoc) {
            return this.events.onInstancesAssocChange.pipe(startWith(this.currentInstancesAssoc));
        }
        return this.events.onInstancesAssocChange;
    }

    constructor() {
        this.events.onLoad.subscribe((last: LoadProgressResponseEntity) => {
            // console.log(last);
            this.currentProgressResponse = last;
            this.triggers.focusAreas.next(last.focus_areas);
            this.triggers.instancesAssoc.next(last.instances_by_focus_area);
        });

        this.events.onFocusAreasChange.subscribe((last) => {
            this.currentFocusAreas = last;
        });

        this.events.onInstancesAssocChange.subscribe((last) => {
            this.currentInstancesAssoc = last;
        });
    }
}

@Injectable()
export class ProgressApi {
    constructor(
        public requester: ServiceRequester,
        public source: ProgressSource
    ) {}

    load(): Promise<LoadProgressResponseEntity> {
        return this.requester.makeMsCall('/progress-page/load', 'GET').then((progress) => {
            const resp = LoadProgressResponseEntity.fabric.hydrate(progress);
            this.source.setLoadedProgress(resp);
            return resp;
        });
    }

    loadWeek(instanceId: number, startDate: string): Promise<any> {
        return this.requester.makeMsCall('/progress-page/instance/' + instanceId + '/week/' + startDate, 'GET');
        // .then((progress) => {
        //     const resp = LoadProgressResponseEntity.fabric.hydrate(progress);
        //     // this.source.setLoadedProgress(resp);
        //     return resp;
        // })
    }

    saveWeek(): Promise<LoadProgressResponseEntity> {
        return this.requester.makeMsCall('/progress-page/week', 'PUT');
        // .then((progress) => {
        //     const resp = LoadProgressResponseEntity.fabric.hydrate(progress);
        //     // this.source.setLoadedProgress(resp);
        //     return resp;
        // })
    }

    saveValue(instanceId: number, date: string, value: number): Promise<any> {
        return this.requester.makeMsCall('/progress-page/instance/' + instanceId + '/day/' + date, 'POST', {
            value: value
        });
    }
}

@Injectable()
export class ProgressService {
    constructor(
        public api: ProgressApi,
        public source: ProgressSource,
        protected currentProfileSource: CurrentProfileSource
    ) {}

    loadWeek(instance: GoalInstanceEntity, startDate: Date): Promise<{values: GoalValueEntity[]}> {
        return this.api.loadWeek(instance.id as number, moment(startDate).format('YYYY-MM-DD'));
        // return Promise.resolve([new GoalValueEntity()]);
    }

    saveValue(instance: GoalInstanceEntity, date: Date, value: number): Promise<any> {
        return this.api.saveValue(instance.id as number, moment(date).format('YYYY-MM-DD'), value);
    }

    saveWeek(instance: GoalInstanceEntity, startDate: Date): Promise<GoalValueEntity[]> {
        return Promise.resolve([new GoalValueEntity()]);
    }
}

import {Component, OnInit} from '@angular/core';
// import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
//
// import {SessionService} from '../../../../services/session.service';
// import {MessagesService} from '../../../messages/messages.service';
// import {ProfilesService} from '../../../../services/profiles.service';
import {IFranchiseState} from '../../store/franchise.reducer';
import {GetFranchiseRequestsPendingAction} from '../../store/franchise.actions';
// import {FranchiseRequestService} from '../../../../services/franchise-request.service';
// import {SaveFranchiseRequestPendingAction} from '../../store/franchise.actions';

@Component({
    selector: 'app-franchise-index',
    template: `
        <div class="franchise-page">
            <app-parent-child [kind]="'parent'"></app-parent-child>
            <app-parent-child [kind]="'child'"></app-parent-child>
        </div>
    `,
    styles: [
        `
            .franchise-page {
                height: 100%;
                background-color: #fff;
            }
        `
    ]
})
export class IndexComponent implements OnInit {
    constructor(
        // protected router: Router,
        // protected sessionService: SessionService,
        // protected messagesService: MessagesService,
        // protected profilesService: ProfilesService,
        // protected franchiseSrv: FranchiseRequestService,
        private store: Store<IFranchiseState>
    ) {}

    ngOnInit() {
        this.store.dispatch(new GetFranchiseRequestsPendingAction(null));
    }

    // send() {
    //     // this.franchiseSrv.createFranchiseRequest(this.franchiseObject)
    //     //     .subscribe(a => { console.log(a, 'a'); });
    // }
}

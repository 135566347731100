import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';

interface IPayoutEvent {
    financial_transfer_id: number;
    financial_transfer_ids?: {[key: number]: number};
    status: 'locked' | 'completed' | 'failed' | 'reverted' | 'un_posted' | 'open';
    message: string;
    type: 'remaining' | 'escrow' | 'all';
    connected_entity_id: number;
    connected_entity_number: string;
    pending_balance: number;
    additional_entities?: {[key: number]: {[key: number]: {type: 'bill' | 'invoice'; entity: any}}};
}

@Injectable()
export class DealPayoutsSource {
    public payoutEvents = new Subject<IPayoutEvent>();

    constructor() {}
}

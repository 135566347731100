import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {firstValueFrom, Subject} from 'rxjs';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../../utilities/maskito';
import {IContact, IInvoice, IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {convertNumberToMaskedStringWithRequireDecimals} from '../../../../../../utilities';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {IPublicAuthorizePaymentObj} from '../../../../../../typings/public-invoice';
import {InvoicePublicService} from '../../../../services/invoice-public.service';
import {NotificationsServiceZipi} from '../../../../../notifications/notifications.service';

const win: {[key: string]: any} = window;
const Accept = win.Accept;

@Component({
    selector: 'app-pay-via-authorize-dialog',
    templateUrl: './pay-via-authorize-dialog.component.html',
    styleUrls: ['./pay-via-authorize-dialog.component.scss']
})
export class PayViaAuthorizeDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    title: string | null = null;
    invoice: IInvoice | null = null;
    moneySenderContact: IContact | null = null;
    authMerchantMethod: IPaymentMethod | null = null;

    currencyMaskitoMask = currencyMaskitoOptions;

    formGroup: UntypedFormGroup;
    newCardGroup: UntypedFormGroup;

    countryList: Array<{[key: string]: any}> = [
        {label: 'Belgium', slug: 'Belgium'},
        {label: 'Canada', slug: 'Canada'},
        {label: 'France', slug: 'France'},
        {label: 'Germany', slug: 'Germany'},
        {label: 'Mexico', slug: 'Mexico'},
        {label: 'USA', slug: 'USA'}
    ];

    createNewAuthCardMode: boolean = false;

    isContinueButtonDisabled: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<PayViaAuthorizeDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {title: string; contact: IContact; method: IPaymentMethod; invoice: IInvoice},
        private invoicePublicService: InvoicePublicService,
        protected notificationServiceZipi: NotificationsServiceZipi
    ) {
        this.formGroup = this.fb.group({
            note: ['', []],
            amount: [convertNumberToMaskedStringWithRequireDecimals(0), []],
            paid_by__payment_method_fk_id: [null, [Validators.required]],
            unsaved_paid_by_payment_method_ref: [null, []]
            // sender_velocity: [null, []],
            // restrict_downgrade: [false, []],
        });

        this.newCardGroup = this.fb.group({
            cardNumber: ['', Validators.required],
            expMonth: ['', Validators.required],
            expYear: ['', Validators.required],
            cardCode: ['', Validators.required],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            address: ['', Validators.required],
            city: ['', Validators.required],
            zipCode: [null, Validators.required],
            state: ['', Validators.required],
            country: ['', Validators.required],
            use_as_default: [false, []]
        });
    }

    ngOnInit() {
        this.title = this.data.title;
        this.invoice = this.data.invoice;
        this.moneySenderContact = this.data.contact;
        this.authMerchantMethod = this.data.method;
    }

    validateAmount($event: any) {
        const inputValue = Number(cleanCurrencyString($event.target.value));
        let availableAmount = 0;
        if (this.invoice && this.invoice.pending_balance) {
            availableAmount = this.invoice.pending_balance;
        }

        if (availableAmount < inputValue || inputValue < 0.01) {
            this.formGroup.controls.amount.setValidators([Validators.email]);
            this.formGroup.controls.amount.updateValueAndValidity();
            this.formGroup.controls.amount.markAsTouched();
        } else {
            this.formGroup.controls.amount.clearValidators();
            this.formGroup.controls.amount.updateValueAndValidity();
        }
    }

    chargeInvoice() {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            return;
        }

        const data = this.formGroup.getRawValue();
        data.amount = Number(cleanCurrencyString(data.amount));

        if (this.invoice && this.invoice.invoice_id) {
            this.isContinueButtonDisabled = true;
            this.invoicePublicService
                .payInvoice(this.invoice.invoice_id, data)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    this.isContinueButtonDisabled = false;
                    this.dialogRef.close(result);
                });
        }
    }

    async chargeNewCard() {
        if (this.newCardGroup.invalid) {
            this.newCardGroup.markAllAsTouched();
            return;
        }

        if (this.authMerchantMethod && this.authMerchantMethod.payment_gateway) {
            this.isContinueButtonDisabled = true;

            let successResponse = null;

            const gateway = this.authMerchantMethod.payment_gateway;
            if (!gateway || !gateway.payment_gateway_id) {
                return null;
            }
            const publicKey = await this.getPublicKey(gateway.payment_gateway_id);

            const authData: {[key: string]: any} = {};
            authData['clientKey'] = publicKey;
            authData['apiLoginID'] = gateway.settings.api_login;

            const cardInfo = this.newCardGroup.getRawValue();

            const cardData: {[key: string]: any} = {};
            cardData['cardNumber'] = cardInfo.cardNumber;
            cardData['month'] = cardInfo.expMonth;
            cardData['year'] = cardInfo.expYear;
            cardData['cardCode'] = cardInfo.cardCode;

            const billingData: {[key: string]: any} = {};
            billingData['firstName'] = cardInfo.firstName;
            billingData['lastName'] = cardInfo.lastName;
            billingData['address'] = cardInfo.address;
            billingData['city'] = cardInfo.city;
            billingData['zipCode'] = cardInfo.zipCode;
            billingData['state'] = cardInfo.state;
            billingData['country'] = cardInfo.country;
            billingData['use_as_default'] = cardInfo.use_as_default;

            const secureData: {[key: string]: any} = {};
            secureData['authData'] = authData;
            secureData['cardData'] = cardData;

            const cardDataForSaving = {
                card_number: cardInfo.cardNumber,
                month: cardInfo.expMonth,
                year: cardInfo.expYear,
                cardCode: cardInfo.cardCode,
                zipCode: cardInfo.zipCode
            };

            const cardBillingDataForSaving = {
                firstName: cardInfo.firstName,
                lastName: cardInfo.lastName,
                address: cardInfo.address,
                city: cardInfo.city,
                zipCode: cardInfo.zipCode,
                state: cardInfo.state,
                country: cardInfo.country
            };

            Accept.dispatchData(secureData, (response: any) => {
                if (response.messages.resultCode === 'Error') {
                    let i = 0;
                    while (i < response.messages.message.length) {
                        this.notificationServiceZipi.addError(response.messages.message[i].text);
                        console.log(response.messages.message[i].code + ': ' + response.messages.message[i].text);
                        i = i + 1;
                        if (i > 100) {
                            break;
                        }
                    }
                } else {
                    successResponse = response;
                    if (this.authMerchantMethod && this.authMerchantMethod.payment_method_id) {
                        let gatewayId = null;
                        let payToPaymentId = this.authMerchantMethod.payment_method_id;

                        const paymentObject: IPublicAuthorizePaymentObj = {
                            gatewayId: gatewayId,
                            payToPaymentId: payToPaymentId,
                            opaqueData: successResponse.opaqueData,
                            title: this.cardTitleBuild(),
                            billingData: billingData,
                            invoiceId: this.invoice && this.invoice.invoice_id ? this.invoice.invoice_id : null,
                            amount: null,
                            expirationDate: this.expirationDateBuild()
                        };

                        if (paymentObject.billingData.use_as_default) {
                            this.invoicePublicService
                                .publicAuthorizePayWithSave(paymentObject)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((result) => {
                                    this.isContinueButtonDisabled = false;
                                    this.dialogRef.close(result);
                                });
                        } else {
                            this.invoicePublicService
                                .publicAuthorizeJustPay(paymentObject)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((result) => {
                                    this.isContinueButtonDisabled = false;
                                    this.dialogRef.close(result);
                                });
                        }
                    }
                }
            });
        }
    }

    cardTitleBuild() {
        return 'XXXX XXXX XXXX ' + this.newCardGroup.getRawValue().cardNumber.slice(-4);
    }

    expirationDateBuild() {
        return `${this.newCardGroup.getRawValue().expMonth}/${this.newCardGroup.getRawValue().expYear}`;
    }

    async getPublicKey(gatewayId: number) {
        return firstValueFrom(this.invoicePublicService.getPublicKey(gatewayId));
    }

    close(value: string | null) {
        this.dialogRef.close(value);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

import {Component, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {BaseWidgetComponent} from '../base.widget.component';
import {GenericFormArray} from '../../../../../../entites/generic.entity';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {IProfile} from '@cyberco-nodejs/zipi-typings/index';
import {Widget} from '../../../../../../models/widget';
import {Profile} from '../../../../../../models/profile';
import {ConditionEntity} from '../../../../../../models/condition.entity';

@Component({
    selector: 'app-cap-commission-structure-widget',
    templateUrl: './cap-commission-structure.widget.component.html',
    styleUrls: ['../widgets.scss', './cap-commission-structure.widget.component.scss']
})
export class CapCommissionStructureWidgetComponent extends BaseWidgetComponent implements OnDestroy, OnChanges {
    profileControl = new GenericFormArray<any>([], null);

    itemPluralMapping = {
        deal: {
            '=1': '1 deal',
            other: '# deals'
        }
    };

    private unsubscribeSub: Subject<void> = new Subject();
    isEntityPickerValid: boolean = true;
    isFinancialElementPickerValid: boolean = true;

    public isDataExist = false;
    public isLoaded = false;
    public idFormName = 'cap-structure-form-' + this.randomNum;
    public currentProfile: Profile | null = null;
    public isAllowToDisplay: boolean = true;

    /**
     * On init
     */
    async onInit() {
        this.profileControl.valueChanges.pipe(takeUntil(this.unsubscribeSub)).subscribe((val) => {
            if (val && val.length) {
                const value = val[0];
                this.widget!.settings.entities = val;
                switch (value.type) {
                    case 'individual':
                        this.widget!.settings.profile_id = value.target_id;
                        this.widget!.settings.division_id = null;
                        this.isEntityPickerValid = true;
                        break;
                    case 'company_group':
                        this.widget!.settings.division_id = value.target_id;
                        this.widget!.settings.profile_id = null;
                        this.isEntityPickerValid = true;
                        break;
                    default:
                        this.widget!.settings.profile_id = null;
                        this.widget!.settings.profile_id = null;
                }
            } else {
                this.widget!.settings.profile_id = null;
                this.widget!.settings.division_id = null;
            }
        });
        if (this.widget!.settings && this.widget!.settings.entities) {
            this.profileControl.patchValue(this.widget!.settings.entities);
            this.profileControl.setValue(this.widget!.settings.entities);
        }
        await this.checkPermissionForEntityProfile();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.customizeVisibility && !changes.customizeVisibility.currentValue && this.configPanelVisible) {
            this.hideConfigPanel();
        }
    }

    /**
     * Process data
     * @param widget
     */
    processData(widget: Widget) {
        this.data = widget.data;

        if (widget.na || !widget.data || widget.data.widget_na) {
            this.isDataExist = false;
        } else {
            this.isDataExist = true;
            this.data.commissionTitle = this.prepareWidgetTitle();

            if (this.data.widget_data.current_rule) {
                this.data.isCurrentRulePresent = true;
                if (
                    this.data.widget_data.current_rule.type === ConditionEntity.type_SET.total_product_income ||
                    this.data.widget_data.current_rule.type === ConditionEntity.type_SET.total_product_expense
                ) {
                    let prop: string = '';
                    switch (this.data.widget_data.current_rule.type) {
                        case ConditionEntity.type_SET.total_product_income:
                            prop = 'total_income';
                            break;
                        case ConditionEntity.type_SET.total_product_expense:
                            prop = 'total_expense';
                            break;
                    }
                    this.data.currentValue =
                        this.data.widget_data.current_history_instance.total_product_values[
                            this.data.widget_data.current_rule.product_fk_id
                        ][prop!];
                } else {
                    this.data.currentValue =
                        this.data.widget_data.current_history_instance[this.data.widget_data.current_rule.type];
                }
                this.data.goalValue = this.data.widget_data.current_rule.value;
            } else {
                this.data.isCurrentRulePresent = false;
            }
        }
        this.isLoaded = true;
    }

    /**
     * Prepare widget title
     */
    prepareWidgetTitle() {
        if (!this.isDataExist) {
            return '';
        }

        const titleArr = [];
        const widgetData = this.data.widget_data;
        if (widgetData.compensation_plan_public_title) {
            titleArr.push(widgetData.compensation_plan_public_title);
        } else if (widgetData.compensation_plan_title) {
            titleArr.push(widgetData.compensation_plan_title);
        } else {
            titleArr.push('Profile');
        }

        if (widgetData.financial_element_public_title) {
            titleArr.push(widgetData.financial_element_public_title);
        } else if (widgetData.financial_element_title) {
            titleArr.push(widgetData.financial_element_title);
        } else {
            titleArr.push('Element');
        }

        if (widgetData.current_rule.public_title) {
            titleArr.push(widgetData.current_rule.public_title);
        } else if (widgetData.current_rule.title) {
            titleArr.push(widgetData.current_rule.title);
        } else {
            titleArr.push('Rule');
        }

        return titleArr.join(' / ');
    }

    /**
     * On product change
     * @param value
     */
    onCompensationProfileChange(value: any) {
        this.widget!.settings.financial_element_id = value[0];
        if (value && value.length) {
            this.isFinancialElementPickerValid = true;
        }
    }

    /**
     * Save widget config
     * @param data
     */
    saveWidgetConfig(data: any) {
        if (!this.widget!.settings.financial_element_id) {
            this.isFinancialElementPickerValid = false;
        }
        if (!this.widget!.settings.profile_id && !this.widget!.settings.division_id) {
            this.isEntityPickerValid = false;
        }

        if (!this.isEntityPickerValid || !this.isFinancialElementPickerValid) {
            return false;
        }

        super.saveConfig(data);
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.unsubscribeSub.next();
        this.unsubscribeSub.complete();
        super.ngOnDestroy();
    }

    /**
     * Check permission for entity profile
     */
    async checkPermissionForEntityProfile() {
        if (this.sessionService.profile) {
            this.currentProfile = await this.authService.getCurrentProfile();
            const isAllowPermission = await this.rbacService.isAllowed({company_settings__manage_compensation: true});
            if (!isAllowPermission) {
                const value = [
                    {
                        company_fk_id: this.currentProfile.company_fk_id,
                        label: `${this.currentProfile.first_name} ${this.currentProfile.last_name}`,
                        target_id: this.currentProfile.id,
                        type: 'individual'
                    }
                ];
                this.profileControl.patchValue(value);
                this.profileControl.setValue(value);

                // if current profile doesn't has permission 'company_settings__manage_compensation',
                // it can see only information regarding himself
                if (this.widget!.settings.profile_id !== this.currentProfile.id) {
                    this.isAllowToDisplay = false;
                }
            }
        }
    }
}

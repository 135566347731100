import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IContact, IInvoice, IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {filter, takeUntil} from 'rxjs/operators';
import {InvoicePublicService} from '../../../services/invoice-public.service';
import {NotificationsServiceZipi} from '../../../../notifications/notifications.service';
import {FeatureFlagsService} from '../../../../feature-flags/feature-flags.service';
import {ZipiFinancialIframeDialogComponent} from '../../../../account-info/company-gateway/company-gateway-edit/zipi-finacial/dialogs/zipi-financial-iframe-dialog/zipi-financial-iframe-dialog.component';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {IPublicAuthorizePaymentObj} from '../../../../../typings/public-invoice';
import {IZipiFinancialIframeDialogData} from '../../../../../typings/zipi-financial-iframe';
import {CompanyGatewayService} from '../../../../../services/api/finance/company-gateway.service';
import {SENDER_VELOCITY_TYPES} from '../../../../../local-typings';
import {SessionService} from '../../../../../services/session.service';
import {PaymentMethodsService} from '../../../../profile/services/payment-methods.service';
import {cleanCurrencyString, currencyMaskitoOptions} from '../../../../../utilities/maskito';
import {convertNumberToMaskedStringWithRequireDecimals} from '../../../../../utilities';

const win: {[key: string]: any} = window;
const Accept = win.Accept;

@Component({
    selector: 'app-charge-customer-dialog',
    templateUrl: './charge-customer-dialog.component.html',
    styleUrls: ['./charge-customer-dialog.component.scss']
})
export class ChargeCustomerDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    title: string = 'Charge Customer';
    formGroup: UntypedFormGroup;
    newCardGroup: UntypedFormGroup;

    contactOwnerZipiFinancialSettings: {target_contact_id: number; customer_ref: string} | undefined;
    senderVelocityTypes = SENDER_VELOCITY_TYPES;

    invoice: IInvoice | undefined;

    currencyMaskitoMask = currencyMaskitoOptions;

    invoiceMethods: IPaymentMethod[] = [];
    authMerchantGateway: IPaymentGateway | undefined;

    contactAuthGateway: IPaymentGateway | null = null;
    contactZipiFinCardGateway: IPaymentGateway | null = null;
    contactZipiFinBankGateways: IPaymentGateway[] | null = null;

    moneySenderContact: IContact | undefined;

    isTppCreditCardFeatureFlagEnabled: boolean = false;
    isVelocityFeatureFlagEnabled: boolean = false;

    createNewAuthCardMode: boolean = false;

    isContactLoad: boolean = false;
    isContinueButtonDisabled: boolean = false;
    isAbleToPayByPayloadCoCard: boolean = false;
    isAbleToPayByZipiFinancialBank: boolean = false;

    countryList: Array<{[key: string]: any}> = [
        {label: 'Belgium', slug: 'Belgium'},
        {label: 'Canada', slug: 'Canada'},
        {label: 'France', slug: 'France'},
        {label: 'Germany', slug: 'Germany'},
        {label: 'Mexico', slug: 'Mexico'},
        {label: 'USA', slug: 'USA'}
    ];

    constructor(
        private fb: UntypedFormBuilder,
        private invoicesService: InvoicesService,
        protected notificationServiceZipi: NotificationsServiceZipi,
        private invoicePublicService: InvoicePublicService,
        private companyGatewayService: CompanyGatewayService,
        public dialogRef: MatDialogRef<ChargeCustomerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {invoice: IInvoice; access_method: 'public' | 'internal'; title: string},
        protected featureFlagsService: FeatureFlagsService,
        public dialog: MatDialog,
        public sessionService: SessionService,
        private paymentMethodsService: PaymentMethodsService
    ) {
        this.formGroup = this.fb.group({
            note: ['', []],
            amount: [convertNumberToMaskedStringWithRequireDecimals(0), []],
            paid_by__payment_method_fk_id: [null, [Validators.required]]
            // sender_velocity: [null, []],
            // restrict_downgrade: [false, []],
        });

        this.newCardGroup = this.fb.group({
            cardNumber: ['', Validators.required],
            expMonth: ['', Validators.required],
            expYear: ['', Validators.required],
            cardCode: ['', Validators.required],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            address: ['', Validators.required],
            city: ['', Validators.required],
            zipCode: [null, Validators.required],
            state: ['', Validators.required],
            country: ['', Validators.required],
            use_as_default: [false, []]
        });
    }

    ngOnInit() {
        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.isTppCreditCardFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:tpp_credit_card'
                );
                this.isVelocityFeatureFlagEnabled = this.featureFlagsService.isFeatureEnabled(
                    'marketplace:addons:zipi_financial:velocity'
                );
            });

        this.invoice = this.data.invoice;
        this.title = this.data.title;
        if (this.invoice) {
            this.loadSettings();

            this.formGroup.controls.amount.setValue(
                convertNumberToMaskedStringWithRequireDecimals(Number(this.invoice.pending_balance))
            );
            const methodIds = []; // TODO: dirty hack to implement new payment method fields. Should be refactored
            if (this.invoice.pay_to_card__payment_method_fk_id) {
                methodIds.push(this.invoice.pay_to_card__payment_method_fk_id);
            }

            this.invoicePublicService
                .getInvoiceMethods(methodIds)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((methods) => {
                    this.invoiceMethods = methods;
                    const authMerchantMethod = this.invoiceMethods.find(
                        (invoiceMethod) =>
                            invoiceMethod.payment_gateway &&
                            invoiceMethod.payment_gateway.type === 'authorize_net_merchant'
                    );
                    if (authMerchantMethod) {
                        this.authMerchantGateway = authMerchantMethod.payment_gateway;
                    }
                    this.isAbleToPayByPayloadCoCard = this.invoiceMethods.some(
                        (invoiceMethod) =>
                            invoiceMethod.payment_gateway && invoiceMethod.payment_gateway.driver_type === 'payload'
                    );
                });
        }

        this.loadContact();
    }

    loadContact() {
        if (this.invoice && this.invoice.money_sender__contact_fk_id) {
            this.invoicePublicService
                .getPublicContact(this.invoice.money_sender__contact_fk_id)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((contact) => {
                    this.moneySenderContact = contact;
                    if (this.moneySenderContact.contact_persons && this.moneySenderContact.contact_persons.length > 0) {
                        const mainPerson = this.moneySenderContact.contact_persons.find(
                            (person) => person.type === 'main_person'
                        );
                        if (mainPerson) {
                            this.newCardGroup.controls.firstName.setValue(mainPerson.first_name);
                            this.newCardGroup.controls.lastName.setValue(mainPerson.last_name);
                        }
                    }
                    if (
                        this.moneySenderContact.contact_locations &&
                        this.moneySenderContact.contact_locations.length > 0
                    ) {
                        const location = this.moneySenderContact.contact_locations[0];
                        this.newCardGroup.controls.address.setValue(
                            `${location.street_number} ${location.street_address}`
                        );
                        this.newCardGroup.controls.city.setValue(location.city);
                        this.newCardGroup.controls.zipCode.setValue(location.zip);
                        this.newCardGroup.controls.state.setValue(location.state);
                    }

                    if (
                        this.moneySenderContact &&
                        this.moneySenderContact.contact_payment_gateways &&
                        this.moneySenderContact.contact_payment_gateways.length > 0
                    ) {
                        this.contactAuthGateway =
                            this.moneySenderContact.contact_payment_gateways.find(
                                (gateway) => gateway.type === 'authorize_net_customer'
                            ) || null;
                        this.contactZipiFinCardGateway =
                            this.moneySenderContact.contact_payment_gateways.find(
                                (gateway) =>
                                    gateway.type === 'zipi_financial_receive_only' && gateway.driver_type === 'payload'
                            ) || null;
                        if (
                            this.contactZipiFinCardGateway &&
                            this.contactZipiFinCardGateway.payment_methods &&
                            this.contactZipiFinCardGateway.payment_methods.length > 0
                        ) {
                            this.contactZipiFinCardGateway.payment_methods =
                                this.contactZipiFinCardGateway.payment_methods.filter(
                                    (method) => method.type === 'zipi_financial_credit_card'
                                );
                        }
                    }
                    this.isContactLoad = true;
                });
        } else {
            this.isContactLoad = true;
        }
    }

    async loadSettings() {
        if (this.invoice && this.invoice.money_sender__contact_fk_id) {
            this.contactOwnerZipiFinancialSettings = await firstValueFrom(
                this.invoicePublicService.getPublicOwnerContactZipiFinancialSettingsByContactId(
                    this.invoice.money_sender__contact_fk_id
                )
            );
        }
    }

    isAbleToDisplay(method: IPaymentMethod, type: 'zipi_fin' | 'auth') {
        if (!this.invoiceMethods || this.invoiceMethods.length === 0) {
            return false;
        } else {
            switch (type) {
                case 'auth':
                    return (
                        method.payment_gateway &&
                        method.payment_gateway.type === 'authorize_net_customer' &&
                        this.invoiceMethods.some(
                            (invoiceMethod) =>
                                invoiceMethod.payment_gateway &&
                                invoiceMethod.payment_gateway.type === 'authorize_net_merchant'
                        )
                    );
                case 'zipi_fin':
                    return (
                        method.type === 'zipi_financial_credit_card' &&
                        this.invoiceMethods.some(
                            (invoiceMethod) =>
                                invoiceMethod.payment_gateway && invoiceMethod.payment_gateway.driver_type === 'payload'
                        )
                    );
            }
        }
    }

    tabChanged($event: MatTabChangeEvent) {
        this.formGroup.controls.paid_by__payment_method_fk_id.setValue(null);
    }

    async setupZipiFinancialForContact(methodType: 'contact_bank' | 'contact_card') {
        let mainPerson = null;
        if (
            this.moneySenderContact &&
            this.moneySenderContact.contact_persons &&
            this.moneySenderContact.contact_persons.length > 0
        ) {
            mainPerson = this.moneySenderContact.contact_persons.find((person) => person.type === 'main_person');
        }
        const contactData = {
            company_name: this.moneySenderContact?.company_name ? this.moneySenderContact?.company_name : '',
            email: mainPerson && mainPerson.email ? mainPerson.email : '',
            first_name: mainPerson && mainPerson.first_name ? mainPerson.first_name : '',
            last_name: mainPerson && mainPerson.last_name ? mainPerson.last_name : ''
        };

        const dialogRef = this.dialog.open<ZipiFinancialIframeDialogComponent, IZipiFinancialIframeDialogData>(
            ZipiFinancialIframeDialogComponent,
            {
                disableClose: true,
                maxHeight: '80vh',
                width: '650px',
                panelClass: 'custom-dialog-container',
                data: {
                    contactId: this.contactOwnerZipiFinancialSettings?.target_contact_id
                        ? this.contactOwnerZipiFinancialSettings?.target_contact_id
                        : null,
                    gateway: this.contactZipiFinCardGateway,
                    paymentMethod: null,
                    methodType: methodType,
                    driverType: 'payload',
                    accessMethod: 'internal',
                    isUniversal: true,
                    customerId: this.contactOwnerZipiFinancialSettings?.customer_ref
                        ? this.contactOwnerZipiFinancialSettings?.customer_ref
                        : null,
                    prefillData: contactData,
                    creationSource: 'current_company',
                    storeAccountMethod: 'required'
                }
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter((pn) => !!pn),
                takeUntil(this.unsubscribe)
            )
            .subscribe((result) => {
                if (result.isError) {
                    this.notificationServiceZipi.addError(result.message);
                } else if (result.success) {
                    this.loadContact();
                }
            });
    }

    validateAmount($event: any) {
        const inputValue = Number(cleanCurrencyString($event.target.value));
        let availableAmount = 0;
        if (this.invoice && this.invoice.pending_balance) {
            availableAmount = this.invoice.pending_balance;
        }

        if (availableAmount < inputValue || inputValue < 0.01) {
            this.formGroup.controls.amount.setValidators([Validators.email]);
            this.formGroup.controls.amount.updateValueAndValidity();
            this.formGroup.controls.amount.markAsTouched();
        } else {
            this.formGroup.controls.amount.clearValidators();
            this.formGroup.controls.amount.updateValueAndValidity();
        }
    }

    async chargeNewCard() {
        if (this.newCardGroup.invalid) {
            this.newCardGroup.markAllAsTouched();
        } else {
            this.isContinueButtonDisabled = true;

            let successResponse = null;

            const gateway = this.authMerchantGateway;
            if (!gateway || !gateway.payment_gateway_id) {
                return null;
            }
            const publicKey = await this.getPublicKey(gateway.payment_gateway_id);

            const authData: {[key: string]: any} = {};
            authData['clientKey'] = publicKey;
            authData['apiLoginID'] = gateway.settings.api_login;

            const cardInfo = this.newCardGroup.getRawValue();

            const cardData: {[key: string]: any} = {};
            cardData['cardNumber'] = cardInfo.cardNumber;
            cardData['month'] = cardInfo.expMonth;
            cardData['year'] = cardInfo.expYear;
            cardData['cardCode'] = cardInfo.cardCode;

            const billingData: {[key: string]: any} = {};
            billingData['firstName'] = cardInfo.firstName;
            billingData['lastName'] = cardInfo.lastName;
            billingData['address'] = cardInfo.address;
            billingData['city'] = cardInfo.city;
            billingData['zipCode'] = cardInfo.zipCode;
            billingData['state'] = cardInfo.state;
            billingData['country'] = cardInfo.country;
            billingData['use_as_default'] = cardInfo.use_as_default;

            const secureData: {[key: string]: any} = {};
            secureData['authData'] = authData;
            secureData['cardData'] = cardData;

            const cardDataForSaving = {
                card_number: cardInfo.cardNumber,
                month: cardInfo.expMonth,
                year: cardInfo.expYear,
                cardCode: cardInfo.cardCode,
                zipCode: cardInfo.zipCode
            };

            const cardBillingDataForSaving = {
                firstName: cardInfo.firstName,
                lastName: cardInfo.lastName,
                address: cardInfo.address,
                city: cardInfo.city,
                zipCode: cardInfo.zipCode,
                state: cardInfo.state,
                country: cardInfo.country
            };

            Accept.dispatchData(secureData, (response: any) => {
                if (response.messages.resultCode === 'Error') {
                    let i = 0;
                    while (i < response.messages.message.length) {
                        this.notificationServiceZipi.addError(response.messages.message[i].text);
                        console.log(response.messages.message[i].code + ': ' + response.messages.message[i].text);
                        i = i + 1;
                    }
                } else {
                    successResponse = response;

                    let gatewayId = null;
                    let payToPaymentId = null;

                    if (this.invoiceMethods) {
                        const method = this.invoiceMethods.find(
                            (im) => im.payment_gateway!.type === 'authorize_net_merchant'
                        );
                        if (method && method.payment_method_id) {
                            payToPaymentId = method.payment_method_id;
                            if (method.payment_gateway && method.payment_gateway.payment_gateway_id) {
                                gatewayId = method.payment_gateway.payment_gateway_id;
                            }
                        }
                    }
                    const paymentObject: IPublicAuthorizePaymentObj = {
                        gatewayId: gatewayId,
                        payToPaymentId: payToPaymentId,
                        opaqueData: successResponse.opaqueData,
                        title: this.cardTitleBuild(),
                        billingData: billingData,
                        invoiceId: this.invoice && this.invoice.invoice_id ? this.invoice.invoice_id : null,
                        amount: null,
                        expirationDate: this.expirationDateBuild()
                    };

                    if (paymentObject.billingData.use_as_default) {
                        this.invoicePublicService
                            .publicAuthorizePayWithSave(paymentObject)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((result) => {
                                this.isContinueButtonDisabled = false;
                                this.dialogRef.close(result);
                            });
                    } else {
                        this.invoicePublicService
                            .publicAuthorizeJustPay(paymentObject)
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((result) => {
                                this.isContinueButtonDisabled = false;
                                this.dialogRef.close(result);
                            });
                    }
                }
            });
        }
    }

    cardTitleBuild() {
        return 'XXXX XXXX XXXX ' + this.newCardGroup.getRawValue().cardNumber.slice(-4);
    }

    expirationDateBuild() {
        return `${this.newCardGroup.getRawValue().expMonth}/${this.newCardGroup.getRawValue().expYear}`;
    }

    async getPublicKey(gatewayId: number) {
        return await this.invoicePublicService.getPublicKey(gatewayId).toPromise();
    }

    chargeInvoice() {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            return;
        }

        const data = this.formGroup.getRawValue();
        data.amount = Number(cleanCurrencyString(data.amount));

        if (this.invoice && this.invoice.invoice_id) {
            this.isContinueButtonDisabled = true;
            this.invoicesService
                .updateAndChargeCustomer(this.invoice.invoice_id, data)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((result) => {
                    this.isContinueButtonDisabled = false;
                    this.dialogRef.close(result);
                });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

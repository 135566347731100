<div class="row content">
    <div class="col-xl-4 left-side">
        <mat-button-toggle-group vertical="true" [value]="activePanel" (change)="onSelectOptions($event)">
            <mat-button-toggle
                *ngFor="let item of reportOptions"
                [value]="item.value"
                (change)="onSelectOptions($event)"
            >
                {{ item.title }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="col-xl-8 right-side">
        <ng-container *ngIf="reportSettings">
            <div [hidden]="activePanel !== 'report_period'">
                <app-report-edit-bar-report-period
                    [value]="{
                        time_period: reportSettings.time_period,
                        time_period_start: reportSettings.time_period_start,
                        time_period_end: reportSettings.time_period_end,
                        date_type: reportSettings.date_type,
                        compare_to_periods: reportSettings.compare_to_periods
                    }"
                    (valueChanges)="onReportPeriodValueChanges($event)"
                    [isCompareToPeriodPossible]="true"
                    [isCompareToPeriodAllowed]="reportSettings.compare_results === 'not_compare'"
                >
                </app-report-edit-bar-report-period>
            </div>

            <div [hidden]="activePanel !== 'report_basis'">
                <app-report-edit-bar-button-list
                    [list]="listOfReportBasis"
                    [value]="reportSettings.report_basis"
                    (valueChanges)="onValueChanges('report_basis', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>

            <div [hidden]="activePanel !== 'report_tagging'" class="content-item">
                <h4>Select tags</h4>
                <app-report-autocomplete
                    [list]="allTags"
                    [value]="reportSettings.tags"
                    (valueChanges)="onValueChanges('tags', $event)"
                    titleAttribute="title"
                    valueAttribute="tag_id"
                >
                </app-report-autocomplete>
            </div>

            <div [hidden]="activePanel !== 'include_zero_balance'">
                <app-report-edit-bar-button-list
                    [list]="listOfYesNoAnswer"
                    [value]="reportSettings.is_include_zero_balance"
                    (valueChanges)="onValueChanges('is_include_zero_balance', $event)"
                >
                </app-report-edit-bar-button-list>
            </div>

            <div [hidden]="activePanel !== 'financial_deals'">
                <app-report-edit-bar-deal-picker
                    [value]="reportSettings.financial_deals"
                    (valueChanges)="onValueChanges('financial_deals', $event)"
                >
                </app-report-edit-bar-deal-picker>
            </div>

            <div [hidden]="activePanel !== 'division'" class="content-item">
                <h4>Divisions</h4>
                <div class="no-division-section">
                    <span class="title">Include Non-Division Entries</span>
                    <div class="element">
                        <mat-button-toggle-group
                            multiple="false"
                            [formControl]="includeDivisionFormControl"
                            aria-label="Font Style"
                        >
                            <mat-button-toggle *ngFor="let item of listOfYesNoAnswer" [value]="item.value">
                                <div class="flex-row">
                                    <div class="but-toggle-item icon">
                                        <div>
                                            <span
                                                [ngClass]="{
                                                    hidden: reportSettings.is_include_no_division !== item.value
                                                }"
                                                class="material-icons"
                                                >done</span
                                            >
                                        </div>
                                    </div>
                                    <div class="but-toggle-item title">
                                        <span>{{ item.title }}</span>
                                    </div>
                                </div>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>

                <h4 class="mt-2">Select Division</h4>
                <app-report-edit-bar-entity
                    [value]="reportSettings.divisions"
                    [entityTypeList]="['company_group']"
                    [onlyDivisions]="true"
                    [initialGroupIds]="initialGroupIds"
                    (valueChanges)="onValueChanges('divisions', $event)"
                >
                </app-report-edit-bar-entity>
            </div>

            <div [hidden]="activePanel !== 'compare_results'">
                <app-report-edit-bar-button-list
                    *ngIf="
                        !reportSettings.compare_to_periods?.periods ||
                        reportSettings.compare_to_periods.periods.length === 0
                    "
                    [list]="listOfCompareResults"
                    [value]="reportSettings.compare_results"
                    (valueChanges)="onValueChanges('compare_results', $event)"
                >
                </app-report-edit-bar-button-list>
                <div
                    *ngIf="
                        reportSettings.compare_to_periods?.periods &&
                        reportSettings.compare_to_periods.periods.length > 0
                    "
                >
                    If you would like to group your report by period or division, please remove any comparisons from the
                    Report Period.
                </div>
            </div>
        </ng-container>

        <div *ngIf="report">
            <app-report-edit-bar-action [reportId]="report.id" [isShowNext]="isShowNext" (action)="onAction($event)">
            </app-report-edit-bar-action>
        </div>
    </div>
</div>

import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {listOfCompensationProfileType, listOfBooleanCondition} from '../../../../constants';
import {getNextActivePanel, isLastPanelItem} from '../../helper';
import {IReport, IReportSetting, IReportSettingKeys} from '@cyberco-nodejs/zipi-typings';

@Component({
    selector: 'app-report-edit-bar-cap',
    templateUrl: './cap.component.html',
    styleUrls: ['./cap.component.scss']
})
export class CapBarComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Output() action: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Input() report: IReport | null = null;

    private defaultValues: {[key: string]: any} = {
        is_include_cap_condition: false
    };

    public listOfCompensationProfileType: Array<{title: string; value: string}> = listOfCompensationProfileType;
    public listOfBooleanCondition: Array<{title: string; value: boolean}> = listOfBooleanCondition;
    public activePanel: string = 'entity';
    public reportSettings: IReportSetting | null = null;
    public isShowNext: boolean = true;
    public reportOptions = [
        {
            value: 'entity',
            title: 'Customers'
        },
        {
            value: 'compensation_profile_type',
            title: 'Deal Element'
        },
        {
            value: 'is_include_cap_condition',
            title: 'Include Condition'
        }
    ];

    ngOnInit() {
        this.initData();
    }

    initData() {
        if (this.report) {
            this.reportSettings = {...this.defaultValues, ...this.report.settings};
        }
    }

    onSelectOptions(event: {value: string}) {
        this.activePanel = event.value;
        this.isShowNext = !isLastPanelItem(this.activePanel, this.reportOptions);
    }

    onValueChanges(property: IReportSettingKeys, data: any) {
        if (this.reportSettings) {
            this.reportSettings[property] = data as never;
        }
    }

    onAction(event: string) {
        const response: {
            type: string;
            data: IReportSetting | null;
        } = {
            type: event,
            data: null
        };

        switch (event) {
            case 'save':
            case 'run':
                response['data'] = this.reportSettings;
                this.action.emit(response);
                break;
            case 'cancel':
            case 'delete':
                this.action.emit(response);
                break;
            case 'next':
                const nextData = getNextActivePanel(this.activePanel, this.reportOptions);
                this.activePanel = nextData.name;
                this.isShowNext = !nextData.isLast;
                break;
            default:
        }
    }

    ngOnDestroy() {
        this.action.complete();
    }
}

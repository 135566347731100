import {GenericEntity} from './generic.entity';
import {DealsSummary} from '../models/deals-summary';
import {MultipleTargetsDealsQueryModel} from '../modules/deals/components/deal/common/deal.models';

export class OpeningPerformanceValuesEntity extends GenericEntity {
    virtual_values: DealsSummary = new DealsSummary();
    parameters: MultipleTargetsDealsQueryModel = new MultipleTargetsDealsQueryModel();

    setParameters(parameters: MultipleTargetsDealsQueryModel): OpeningPerformanceValuesEntity {
        this.parameters = parameters;
        return this;
    }
}

import {Injectable} from '@angular/core';
import {ServiceRequester} from '../../../services/service.requester';
import {Observable} from 'rxjs';
import {
    IAccountingLog,
    IPaymentMade,
    IPaymentReceivedMadeRequestObject,
    ITransactionExternal
} from '@cyberco-nodejs/zipi-typings';

@Injectable()
export class PaymentsMadeService {
    private url = '/finance/paymentmade/';

    constructor(protected requester: ServiceRequester) {}

    getPaymentsMade(params = {}): Observable<{result: IPaymentMade[]; _meta: {total: number}}> {
        return this.requester.makeMsCall$(this.url, 'GET', 'shipp', params);
    }

    getContactOverpaymentPaymentsMade(contactId: number): Observable<IPaymentMade[]> {
        return this.requester.makeMsCall$(this.url + `${contactId}` + '/overpayment', 'GET', 'shipp');
    }

    getPaymentMadeById(paymentId: number): Observable<IPaymentMade> {
        return this.requester.makeMsCall$(this.url + `${paymentId}`, 'GET', 'shipp');
    }

    createPaymentMade(
        data: Partial<IPaymentReceivedMadeRequestObject> & {external_transactions?: ITransactionExternal[]}
    ): Observable<IPaymentMade> {
        return this.requester.makeMsCall$(this.url, 'POST', 'shipp', {data: data});
    }

    editPaymentMade(paymentId: number, data: IPaymentReceivedMadeRequestObject): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${paymentId}`, 'PUT', 'shipp', {data: data});
    }

    applyToBills(paymentMadeId: number, data: Array<{bill_id: number; amount_to_credit: number}>): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + paymentMadeId + '/apply', 'PUT', 'shipp', data);
    }

    recheckCompanyMadePaymentsStatuses() {
        return this.requester.makeMsCall$(this.url + 'recheck', 'GET', 'shipp');
    }

    recheckPaymentMadeStatusById(paymentMadeId: number): Observable<string> {
        return this.requester.makeMsCall$(this.url + `${paymentMadeId}/recheck`, 'GET', 'shipp');
    }

    downloadCheckPdf(
        paymentId: number,
        data: {check_margins: {top: number; left: number} | boolean | null; show_background: boolean}
    ): Observable<{data: Array<number>; type: string}> {
        return this.requester.makeMsCall$(this.url + `${paymentId}/check`, 'POST', 'shipp', data);
    }

    downloadChecksPdf(data: {
        check_margins: {top: number; left: number} | boolean | null;
        show_background: boolean;
        payments_made: {payment_made_id: number; check_number: string}[];
    }): Observable<{data: Array<number>; type: string}> {
        return this.requester.makeMsCall$(this.url + `checks`, 'POST', 'shipp', data);
    }

    setCheckPrinted(paymentId: number, data: {check_number: number; is_printed: boolean}): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${paymentId}/printed`, 'PUT', 'shipp', data);
    }

    setChecksPrinted(data: {payment_made_id: number; check_number: string}[]): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `checks/printed`, 'PUT', 'shipp', data);
    }

    unlockPaymentMade(paymentMadeId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${paymentMadeId}/unlock`, 'GET', 'shipp');
    }

    cancelPaymentMade(paymentMadeId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${paymentMadeId}/cancel`, 'GET', 'shipp');
    }

    voidPaymentMade(paymentMadeId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${paymentMadeId}/void`, 'GET', 'shipp');
    }

    deletePaymentById(paymentMadeId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + `${paymentMadeId}`, 'DELETE', 'shipp');
    }

    bulkDeletePaymentsMade(paymentsMadeIds: Array<number>): Observable<{
        not_deleted_payments: number;
        deleted_payments: number;
    }> {
        return this.requester.makeMsCall$(this.url + `bulk/delete`, 'PUT', 'shipp', paymentsMadeIds);
    }

    getLastUsedAccount(): Observable<number> {
        return this.requester.makeMsCall$(this.url + `lastusedaccount`, 'GET', 'shipp');
    }

    retryPaymentMade(paymentMadeId: number): Observable<boolean> {
        return this.requester.makeMsCall$(this.url + paymentMadeId + '/retry', 'GET', 'shipp');
    }

    orderPayStubGeneration(paymentMadeIds: Array<number>): Observable<boolean> {
        return this.requester.makeMsCall$(`/pay-stub/order-generation/payments-made`, 'POST', 'zipi', {
            payment_made_ids: paymentMadeIds
        });
    }

    createPayStub(paymentMadeId: number): Observable<any> {
        return this.requester.makeMsCall$(`/pay-stub/${paymentMadeId}/create`, 'GET');
    }

    getPaymentMadeHistory(paymentMadeId: number): Observable<IAccountingLog[]> {
        return this.requester.makeMsCall$(this.url + `${paymentMadeId}/log`, 'GET', 'shipp');
    }
}

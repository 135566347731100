import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {PAGE_SIZE_OPTIONS} from '@app/local-typings';
import {IScrollData} from '@app/models/scroll-data';
import {Subject, takeUntil} from 'rxjs';
import {IInvoice} from 'typings';

@Component({
    selector: 'app-statement-invoices-sidenav',
    templateUrl: './statement-invoices-sidenav.component.html',
    styleUrls: ['./statement-invoices-sidenav.component.scss']
})
export class StatementInvoicesSidenavComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    dataSource: MatTableDataSource<any>;
    private _invoices: IInvoice[] = [];
    @Input()
    set invoices(invoices: IInvoice[]) {
        this._invoices = invoices;
        this.scrollData.total = invoices.length;
        this.scrollData.offset = 0;
        this.handlePageChange();
    }

    displayedColumns: Array<keyof IInvoice> = [
        'invoice_date',
        'invoice_number',
        'summary_status',
        'status_of_payment',
        'total_amount',
        'pending_balance'
    ];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined;
    pageSizeOptions = PAGE_SIZE_OPTIONS;
    private static defaultPageSize = PAGE_SIZE_OPTIONS[1];
    scrollData: IScrollData = {
        sort_column: 'invoice_date',
        sort_direction: 'asc',
        offset: 0,
        limit: StatementInvoicesSidenavComponent.defaultPageSize,
        total: 1
    };

    constructor() {
        this.dataSource = new MatTableDataSource<any>([]);
    }

    ngOnInit(): void {
        if (!this.paginator) return;

        this.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.scrollData.limit = data.pageSize;
            this.scrollData.offset = data.pageSize * data.pageIndex;
            this.handlePageChange();
        });
    }

    private handlePageChange(): void {
        const offset = this.scrollData.offset ?? 0;
        const limit = this.scrollData.limit ?? StatementInvoicesSidenavComponent.defaultPageSize;
        this.dataSource.data = this._invoices.slice(offset, offset + limit);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

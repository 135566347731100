import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {timePeriodObj, listOfYesNoAnswer, listOfReportBasis} from '../../../../constants';
import {getNextActivePanel, isLastPanelItem} from '../../helper';
import {SessionService} from '../../../../../../services/session.service';
import {UntypedFormControl} from '@angular/forms';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IReport, IReportSetting, IReportSettingKeys, ITag} from '@cyberco-nodejs/zipi-typings';
import {TagsService} from '../../../../../../services/api/tags.service';
import {IReportPeriodValue} from '../sub-component/report-period/report-period.component';

@Component({
    selector: 'app-report-edit-bar-income-statement',
    templateUrl: './income-statement.component.html',
    styleUrls: ['./income-statement.component.scss']
})
export class IncomeStatementBarComponent implements OnInit, OnDestroy {
    @Output() valueChanges: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Output() action: EventEmitter<{[key: string]: any}> = new EventEmitter<{[key: string]: string}>();
    @Input() report: IReport | null = null;

    private defaultValues: {[key: string]: any} = {
        time_period: timePeriodObj.this_year.value,
        date_type: 'journal_date',
        compare_to_periods: null,
        report_basis: 'accrual',
        is_include_zero_balance: true,
        is_include_no_division: true,
        compare_results: 'not_compare'
    };
    private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);

    public activePanel: string = 'report_period';
    public allTags: ITag[] = [];
    public listOfYesNoAnswer: Array<{title: string; value: boolean}> = listOfYesNoAnswer;
    public listOfReportBasis: Array<{title: string; value: string}> = listOfReportBasis;
    public reportSettings: Partial<IReportSetting> | null = null;
    public isShowNext: boolean = true;
    public currentCompanyGroupId: number | null = null;
    public initialGroupIds: number[] = [];
    public reportOptions = [
        {
            value: 'report_period',
            title: 'Report Period'
        },
        {
            value: 'compare_results',
            title: 'Grouping by'
        },
        {
            value: 'report_basis',
            title: 'Report Basis'
        },
        {
            value: 'report_tagging',
            title: 'Report Tagging'
        },
        {
            value: 'include_zero_balance',
            title: 'Include $0.00 Balance'
        },
        {
            value: 'financial_deals',
            title: 'Deal'
        },
        {
            value: 'division',
            title: 'Division'
        }
    ];

    public listOfCompareResults: Array<{title: string; value: string}> = [
        {
            value: 'not_compare',
            title: '- Nothing -'
        },
        {
            value: 'division',
            title: 'By Division'
        },
        {
            value: 'month',
            title: 'By Month'
        },
        {
            value: 'quarter',
            title: 'By Quarter'
        },
        {
            value: 'year',
            title: 'By Year'
        }
    ];

    public includeDivisionFormControl: UntypedFormControl = new UntypedFormControl([]);

    constructor(
        private tagsService: TagsService,
        private sessionService: SessionService
    ) {}

    ngOnInit() {
        this.beforeDataInit();
        this.initData();
        this.initSubscription();
        this.includeDivisionFormControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            if (this.reportSettings) {
                this.reportSettings.is_include_no_division = data;
            }
        });
    }

    initData() {
        const settings = this.report!.settings;
        this.reportSettings = {...this.defaultValues, ...settings};
        this.includeDivisionFormControl.setValue(this.reportSettings['is_include_no_division']);
        this.allTags =
            this.sessionService.companyTags && this.sessionService.companyTags.length
                ? this.sessionService.companyTags
                : [];
    }

    beforeDataInit() {
        const settings = this.report!.settings || {};
        this.currentCompanyGroupId = Number(localStorage.getItem('current_company_group_id'));
        if (this.currentCompanyGroupId) {
            this.defaultValues.is_include_no_division = false;
            if (!settings.hasOwnProperty('divisions')) {
                this.initialGroupIds = [this.currentCompanyGroupId];
            }
        }
    }

    initSubscription() {
        this.tagsService
            .getCompanyTags()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.allTags = data.result;
            });
    }

    onSelectOptions(event: {value: string}) {
        this.activePanel = event.value;
        this.isShowNext = !isLastPanelItem(this.activePanel, this.reportOptions);
    }

    onValueChanges(property: string, data: any) {
        if (!this.reportSettings) return;

        switch (property) {
            case 'compare_results':
                this.reportSettings.compare_results = data;

                // reset synchronized setting to default value
                if (this.reportSettings.compare_results !== 'not_compare') {
                    this.reportSettings.compare_to_periods = {
                        periods: [],
                        display_options: {
                            show_amount_change: false,
                            show_percent_change: false
                        }
                    };
                }

                break;
            default:
                // @ts-ignore
                this.reportSettings[property] = data as never;
        }
    }

    onReportPeriodValueChanges(data: IReportPeriodValue) {
        if (!this.reportSettings) return;

        this.reportSettings.time_period = data.time_period;
        this.reportSettings.time_period_start = data.time_period_start;
        this.reportSettings.time_period_end = data.time_period_end;

        this.reportSettings.compare_to_periods = data.compare_to_periods;

        // reset synchronized setting to default value
        if (this.reportSettings.compare_to_periods?.periods.length > 0) {
            this.reportSettings.compare_results = 'not_compare';
        }
    }

    onAction(event: string) {
        const response: {
            type: string;
            data: IReportSetting | null | {};
        } = {
            type: event,
            data: null
        };

        switch (event) {
            case 'save':
            case 'run':
                response['data'] = this.reportSettings;
                this.action.emit(response);
                break;
            case 'cancel':
            case 'delete':
                this.action.emit(response);
                break;
            case 'next':
                const nextData = getNextActivePanel(this.activePanel, this.reportOptions);
                this.activePanel = nextData.name;
                this.isShowNext = !nextData.isLast;
                break;
            default:
        }
    }

    ngOnDestroy() {
        this.action.complete();
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {GenericFormGroup} from '../../../../../entites/generic.entity';
import {Deal, DEAL_SYSTEM_STATUS} from '../../../../../models/deal';
import {Profile} from '../../../../../models/profile';
import {Subject} from 'rxjs';
import {SalesEntity} from '../../../../../models/sales-entity';
import {CurrentProfileSource} from '../../../../../services/sources/current-profile.source';
import {RbacService} from '../../../../rbac/rbac.service';
// import {DealService} from '../../../services/deal.service';
import {DealApproveApiService} from '../deal-approve.api.service';
import {MatDialog} from '@angular/material/dialog';
import {DisbursementTextDialogComponent} from './disbursement-text.dialog';
import {ConfirmComponent} from '../../../../../layouts/confirm/confirm.component';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {EditDealSalesEntitiesComponent} from './sales-entities.component';
import {CompanyExpensesComponent} from './company-expenses/company-expenses.component';
import {DealExpensesComponent} from './deal-expenses/deal-expenses.component';
import {NotificationsService} from 'angular2-notifications';
import {DealProcessingService} from '../deal-processing.service';
import {AffectedDealsDialogComponent} from './affected-deals-dialog/affected-deals-dialog.component';
import {ManageSalesEntitiesDialogComponent} from './manage-sales-entities-dialog/manage-sales-entities-dialog.component';
import {NotificationsServiceZipi} from '../../../../notifications/notifications.service';
// import {EditDealCommunicationComponent} from './communication.component';
import {Router} from '@angular/router';
import {FinancialTransferEntity} from '../../../../account-info/compensation/models/financial-transfer.entity';
import {EmailDisbursementPdfDialogComponent} from './email-disbursement-pdf-dialog/email-disbursement-pdf-dialog.component';
import {FeatureFlagsService} from '../../../../feature-flags/feature-flags.service';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-deal-disbursement',
    templateUrl: 'disbursement.component.html',
    styleUrls: ['deal-creation.scss']
})
export class DealDisbursementComponent implements OnInit, OnDestroy, OnChanges {
    DEAL_SYSTEM_STATUS = DEAL_SYSTEM_STATUS;
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('dealExpenses') dealExpenses: DealExpensesComponent | undefined;
    @ViewChild('companyExpenses') companyExpenses: CompanyExpensesComponent | undefined;
    @ViewChild('salesEntities') salesEntities: EditDealSalesEntitiesComponent | undefined;

    expandGroupFC: UntypedFormControl | null = null;
    isExpandActive: boolean = true;

    @Input() dealId: string | null = null;
    @Input() showRefreshButton: boolean = false;
    @Input() pdfOutdated: boolean = false;
    @Input() dealFormGroup: GenericFormGroup<Deal> = new GenericFormGroup<Deal>(new Deal(), 'change');
    @Input() showDepositButtonNeed: boolean = true;
    @Input() dealSnapshot: Deal | undefined;
    @Input() afterDealSaving$: Subject<string> = new Subject<string>();
    // @Input() communication: EditDealCommunicationComponent|undefined;
    @Input() isDealSaveInProgress: boolean = false;
    @Input() showSideCountAndSalesVolumeFlag: boolean = false;
    @Input() isDealConnectedToSkyslope: boolean = false;

    @Output() saveDealChanges = new EventEmitter<string>();
    @Output() saveWithReload = new EventEmitter<void>();
    @Output() onRefresh = new EventEmitter<void>();
    @Output() openDepositSection = new EventEmitter<boolean>();
    @Output() isDepositButtonNeed = new EventEmitter<boolean>();
    @Output() checkCommissionPayerEmitter = new EventEmitter<void>();
    @Output() openSideBar = new EventEmitter<void>();

    public currentProfile: Profile = new Profile();

    currentProfileLoaded: Promise<any> | null = null;

    public isPrimarySalesEntity: boolean = false;
    public canEditOwnDeals: boolean = false;
    public canCreateOwnDeals: boolean = false;
    public canEditAnyDeals: boolean = false;
    public canCreateAnyDeals: boolean = false;
    public canViewAnyContacts: boolean = false;

    public approveDisabled: boolean = false;
    public requestApprovalDisabled: boolean = false;

    isConnectedFinEntitiesExist: boolean;

    // feature flag
    public emailPDFenabledFlag = false;
    public downloadPDFenabledFlag = false;
    public isBrokerTradesheetEnabled: boolean = false;

    constructor(
        protected currentProfileSource: CurrentProfileSource,
        protected rbacService: RbacService,
        protected dealApproval: DealApproveApiService,
        // protected dealsService: DealService,
        public dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private notificationsService: NotificationsService,
        public dealProcessing: DealProcessingService,
        protected notificationService: NotificationsServiceZipi,
        private router: Router,
        protected featureFlagsService: FeatureFlagsService
    ) {
        this.currentProfileLoaded = new Promise((resolve) => {
            this.currentProfileSource.changeProfileEvent.pipe(takeUntil(this.unsubscribe)).subscribe((profile) => {
                this.currentProfile = profile;
                resolve(null);
            });
        });

        this.rbacService.isAllowed({deals__create_any: true}).then((can) => (this.canCreateAnyDeals = can));

        this.rbacService.isAllowed({deals__edit_any: true}).then((can) => (this.canEditAnyDeals = can));

        this.rbacService.isAllowed({deals__edit_own: true}).then((can) => (this.canEditOwnDeals = can));

        this.rbacService.isAllowed({deals__create_own: true}).then((can) => (this.canCreateOwnDeals = can));

        this.rbacService.isAllowed({contacts__view_any_contacts: true}).then((can) => (this.canViewAnyContacts = can));

        this.expandGroupFC = this.fb.control('collapse', []);
        this.isConnectedFinEntitiesExist = false;

        this.featureFlagsService
            .onFlagsChange()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((allFlags) => {
                this.emailPDFenabledFlag = this.featureFlagsService.isFeatureEnabled('deals:send_da_pdf_by_email');
                this.downloadPDFenabledFlag = this.featureFlagsService.isFeatureEnabled(
                    'deals:download_disbursement_pdf'
                );
                this.isBrokerTradesheetEnabled = this.featureFlagsService.isFeatureEnabled('trade_sheet:broker');
            });
    }

    ngOnInit() {
        this.currentProfileLoaded!.then(() => {
            this.isPrimarySalesEntity = this.isPrimary(new SalesEntity().setProfile(this.currentProfile));
        });

        this.expandGroupFC!.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((change) => {
            if (change === 'collapse') {
                this.dealExpenses!.panel!.close();
                this.companyExpenses!.panel!.close();
                this.salesEntities!.accordion!.closeAll();
            }

            if (change === 'expand') {
                this.dealExpenses!.panel!.open();
                this.companyExpenses!.panel!.open();
                this.salesEntities!.accordion!.openAll();
            }
        });
        this.findConnectedFinEntities();

        this.afterDealSaving$.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe((approvalType: string) => {
            if (approvalType === 'request_approve') {
                this.requestDisbursementApproval(true);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dealSnapshot && changes.dealSnapshot.currentValue) {
            this.dealSnapshot = changes.dealSnapshot.currentValue;
        }
    }

    findConnectedFinEntities() {
        this.dealProcessing.dealFG.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((cha) => {
            const deal = this.dealProcessing.dealFG.getRawValue();
            const payouts = deal.remaining_payouts;
            const transfers = deal.financial_transfers;
            if (payouts) {
                if (Array.isArray(payouts) && payouts.length > 0) {
                    this.isConnectedFinEntitiesExist = false;
                    for (const payout of payouts) {
                        const sourceTransfer = transfers.find(
                            (tr) => tr.payout_to_hash === payout.unique_hash && tr.type === 'associate_transfer'
                        );
                        if ((payout.connected__bill_fk_id || payout.connected__invoice_fk_id) && !sourceTransfer) {
                            this.isConnectedFinEntitiesExist = true;
                        }
                    }
                } else {
                    this.isConnectedFinEntitiesExist = false;
                }
            }
        });
    }

    deselectExpand() {
        this.isExpandActive = false;
    }

    handleOpenSideBar() {
        this.openSideBar.emit();
    }

    setExpand(value: 'expand' | 'collapse') {
        if (!this.isExpandActive) {
            this.isExpandActive = true;
            return;
        }

        this.expandGroupFC!.patchValue(value);
    }

    doRefresh(event: Event) {
        this.onRefresh.emit();
    }

    openDisbursementTextEdit(event: Event) {
        event.stopPropagation();
        const dialogRef = this.dialog.open(DisbursementTextDialogComponent);
        if (this.dealFormGroup.controls.disbursement_text_template) {
            dialogRef.componentInstance.disbursementTextFG = this.dealFormGroup.controls.disbursement_text_template;
        } else {
            dialogRef.componentInstance.disbursementTextFG = this.dealFormGroup.controls.disbursement_text!;
        }
    }

    showRestoreButton() {
        return this.dealFormGroup.controls.financial_transfers!.controls.some(
            (ftFg) =>
                (!ftFg.controls.is_enforced!.value || ftFg.controls.unique_hash!.value === 'NO-HASH') &&
                ftFg.controls.is_modified_by_user!.value
        );
    }

    approveDisbursement(forceApprove: boolean = false, ignoreUnsavedChanges: boolean = false) {
        if (this.approveDisabled) {
            return;
        }

        // set required fields for transfers - label and product
        this.dealProcessing.setValidatorsToTransfers(true);
        // firstly check required fields
        this.checkCommissionPayerEmitter.emit();
        if (!this.dealProcessing.isDealFormValid()) {
            this.approveDisabled = false;
            return false;
        } else if (!this.dealFormGroup.get('deal_participants')!.get('commission_payer')!.value) {
            this.notificationService.addError('Please fill all the required fields');
            this.approveDisabled = false;
            return false;
        }

        // const needSaveDeal: boolean = !this.dealProcessing.isDealObjectsEqual(this.dealSnapshot!, this.dealFormGroup.getRawValue());
        const needSaveDeal: boolean = this.dealFormGroup.dirty;

        if (!forceApprove) {
            // forbid approving if deal was changed
            if (!ignoreUnsavedChanges && needSaveDeal) {
                return this.confirmSavingChanges('approve');
            }

            const confirmDialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: 320,
                minHeight: 160,
                data: {
                    dealId: this.dealId,
                    showAddonsFlatCommission: true,
                    buttonOkMessage: 'Continue',
                    title: `Deal Disbursement Approval`,
                    messages: [
                        `Approval will lock all values. Currently displayed details will not be updated by other changes within the system.`,
                        `In order to modify approved disbursement values, Deal must be reverted to Unapproved status.`,
                        `Do you wish to continue?`
                    ]
                }
            });

            confirmDialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((confirmed: boolean) => {
                    if (!confirmed) {
                        return;
                    }

                    this.approveDeal(forceApprove, needSaveDeal);
                });
        } else {
            this.approveDeal(forceApprove, needSaveDeal);
        }
    }

    approveDeal(forceApprove: boolean = false, saveDeal: boolean = true) {
        this.approveDisabled = true;

        // trim tne name
        if (this.dealFormGroup.controls.name!.value) {
            this.dealFormGroup.controls.name!.patchValue(this.dealFormGroup.controls.name!.value.trim());
        }

        const deal: Deal = this.dealFormGroup.getRawValue();
        deal.flat_fee_commissions = this.dealFormGroup.controls.flat_fee_commissions!.getEnabledValues();

        this.dealApproval
            .putApproveDeal(deal, forceApprove, saveDeal)
            .then(async (result) => {
                // if (this.communication && this.communication.dealNotesContainer!.currentNoteFG.controls.text!.value) {
                //     this.communication.dealNotesContainer!.saveNote();
                // }

                this.approveDisabled = false;

                // try fix flow. need review later.
                if (result.wasDealApproved) {
                    this.dealFormGroup.controls.disbursement_approved!.patchValue(true, {emitEvent: false});
                    // Alex: we need to update system_status because users can modify it manually (Draft -> Open) and we update it during Deal general saving process.
                    // So we can not exclude this property from the object to save.
                    this.dealFormGroup.controls.system_status!.patchValue(DEAL_SYSTEM_STATUS.approved, {
                        emitEvent: false
                    });
                    this.dealFormGroup.markAsPristine();
                    return this.router.navigate([`/deals/edit/${deal.id}`], {fragment: (+new Date()).toString(32)});
                } else {
                    const warningDialogRef = this.dialog.open(AffectedDealsDialogComponent, {
                        minWidth: 320,
                        minHeight: 160,
                        data: {
                            close_of_escrow: this.dealFormGroup.get('close_of_escrow')!.value,
                            currentDeal: {
                                id: this.dealFormGroup.get('id')!.value,
                                name: this.dealFormGroup.get('name')!.value,
                                close_of_escrow: this.dealFormGroup.get('close_of_escrow')!.value
                            },
                            approvedDeals: result.approvedDealsInFuture,
                            approvedDealsCount: result.approvedDealsInFutureCount,
                            unapprovedDealsInPast: result.unapprovedDealsInPast,
                            unapprovedDealsCount: result.unapprovedDealsInPastCount
                        }
                    });

                    warningDialogRef
                        .afterClosed()
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe((continueApprove) => {
                            if (continueApprove) {
                                this.approveDisbursement(true);
                            }
                        });
                }
            })
            .catch(() => {
                this.approveDisabled = false;
            });
    }

    revertDisbursementApproval(forceDisapprove: boolean = false) {
        if (!forceDisapprove) {
            const dialogRef = this.dialog.open(ConfirmComponent, {
                minWidth: 320,
                data: {
                    title: 'Update Disbursement',
                    message: `Approval status will be reverted to "Draft" and need to resubmitted to approval.`,
                    buttonOkMessage: 'Revert'
                }
            });

            dialogRef
                .afterClosed()
                .pipe(
                    filter((isOk) => !!isOk),
                    takeUntil(this.unsubscribe)
                )
                .subscribe(() => {
                    this.revertDealApproval(forceDisapprove);
                });
        } else {
            this.revertDealApproval(forceDisapprove);
        }
    }

    revertDealApproval(forceDisapprove: boolean = false) {
        const {id} = this.dealFormGroup.getRawValue();
        if (!id) {
            throw new Error('No DealId available');
        }
        this.dealApproval.revertDisbursementApproval(id, forceDisapprove).then((result) => {
            if (result.wasDealUnapproved) {
                // try fix flow. need review later.
                this.dealFormGroup.controls.disbursement_approved!.patchValue(false, {emitEvent: false});
                this.dealFormGroup.controls.affected_by!.patchValue([], {emitEvent: false});
                // Alex: we need to update system_status because users can modify it manually (Draft -> Open) and we update it during Deal general saving process.
                // So we can not exclude this property from the object to save.
                this.dealFormGroup.controls.system_status!.patchValue(DEAL_SYSTEM_STATUS.open, {emitEvent: false});
                return this.router.navigate([`/deals/edit/${id}`], {fragment: (+new Date()).toString(32)});
            } else {
                const warningDialogRef = this.dialog.open(AffectedDealsDialogComponent, {
                    minWidth: 320,
                    minHeight: 160,
                    data: {
                        currentDeal: {
                            id: this.dealFormGroup.get('id')!.value,
                            name: this.dealFormGroup.get('name')!.value,
                            close_of_escrow: this.dealFormGroup.get('close_of_escrow')!.value
                        },
                        approvedDeals: result.approvedDealsInFuture,
                        approvedDealsCount: result.approvedDealsInFutureCount,
                        unapprovedDealsInPast: [],
                        unapprovedDealsCount: 0
                    }
                });

                warningDialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((continueApprove) => {
                        if (continueApprove) {
                            this.revertDisbursementApproval(true);
                        }
                    });
            }
        });
    }

    requestDisbursementApproval(ignoreUnsavedChanges: boolean = false) {
        if (!this.requestApprovalDisabled) {
            this.approveDisabled = true;

            // forbid approving if deal was changed
            // if (!ignoreUnsavedChanges && !this.dealProcessing.isDealObjectsEqual(this.dealSnapshot!, this.dealFormGroup.getRawValue())) {
            if (!ignoreUnsavedChanges && this.dealFormGroup.dirty) {
                this.confirmSavingChanges('request_approve');
            }

            this.dealApproval
                .postRequestDealApproval(this.dealFormGroup.getRawValue())
                .then(() => {
                    this.requestApprovalDisabled = false;
                    this.dealFormGroup.controls.disbursement_approve_requested!.patchValue(true, {emitEvent: false});

                    // try fix flow. need review later.
                    // this.dealFormGroup.controls.disbursement_approved.patchValue(true, { emitEvent: false });
                    // this.dealsService.getOne(this.dealFormGroup.controls.id.value)
                    //     .then(loaded => {
                    //         this.dealFormGroup.patchValue(loaded, { emitEvent: false });
                    //     });
                })
                .catch(() => {
                    this.requestApprovalDisabled = false;
                });
        }
    }

    isPrimary(salesEntity: SalesEntity): boolean {
        return (
            this.dealFormGroup.controls.sales_entities!.controls.filter((item) => {
                switch (item.controls.type!.value) {
                    case SalesEntity.type_SET.profile:
                        if (salesEntity.type === SalesEntity.type_SET.profile) {
                            return (
                                item.controls.profile!.controls.id!.value === salesEntity.profile.id &&
                                item.controls.is_primary!.value
                            );
                        } else {
                            return false;
                        }
                    case SalesEntity.type_SET.company_group:
                        if (salesEntity.type === SalesEntity.type_SET.company_group) {
                            return (
                                item.controls.company_group!.controls.id!.value === salesEntity.company_group.id &&
                                item.controls.is_primary!.value
                            );
                        } else {
                            return false;
                        }
                }
            }).length > 0
        );
    }

    exportToPdf(format: 'condensed' | 'expanded' | 'expanded-with-notes' | 'broker') {
        if (!this.dealId) {
            return;
        }
        this.dealApproval.getTradeSheetPdf(this.dealId, format).then((response) => {
            if (response.error) {
                this.notificationsService.error(response.error);
                return;
            }

            if (response.data) {
                const fileBlob = new Blob([new Uint8Array(response.data)], {type: 'application/pdf'});
                window.open(URL.createObjectURL(fileBlob), '_blank');
                return;
            }
        });
    }

    downloadDisbursementPDF() {
        const deal = this.dealFormGroup.getRawValue();
        if (!deal.id) return;

        const dealUpdatedDate = (deal as any).updated_at.slice(0, 10);
        const {street_number, address: street_name} = deal;
        const dealAddress = `${street_number ? street_number : ''}${street_name ? ' ' + street_name : ''}`;
        const fileName = `${dealAddress}${dealAddress.length ? ' ' : ''}Disbursement ${dealUpdatedDate}.pdf`;

        this.dealApproval.getDisbursementPdf(deal.id).then((response) => {
            if (response.error) {
                this.notificationsService.error(response.error);
                return;
            }

            if (response.data) {
                const fileBlob = new Blob([new Uint8Array(response.data)], {type: 'application/pdf'});
                FileSaver.saveAs(fileBlob, fileName);
                return;
            }
        });
    }

    openDisbursementPDFinNewTab() {
        // "true ||" was added by AntonV as temporary workaround. In some reason this popup shows always and now way
        // to get PDF
        if (true || this.dealFormGroup.pristine) {
            const deal = this.dealFormGroup.getRawValue();
            this.dealApproval.getDisbursementPdf(deal.id!).then((response) => {
                if (response.error) {
                    this.notificationsService.error(response.error);
                    return;
                }

                if (response.data) {
                    const fileBlob = new Blob([new Uint8Array(response.data)], {type: 'application/pdf'});
                    window.open(URL.createObjectURL(fileBlob), '_blank');
                    return;
                }
            });
            return;
        }

        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            data: {
                title: 'Preview Disburesment PDF',
                message: `Deal form has some changes. Do you want to save it?`,
                buttonOkMessage: 'Save'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isOk) => {
                if (!isOk) {
                    return;
                }
                this.saveDealChanges.emit('');
            });
    }

    openDualDealDisbursementPDFinNewTab() {
        // "true ||" was added by AntonV as temporary workaround. In some reason this popup shows always and now way
        // to get PDF
        if (true || this.dealFormGroup.pristine) {
            const deal = this.dealFormGroup.getRawValue();
            this.dealApproval.getDualDisbursementPdf(deal.id!).then((response) => {
                if (response.error) {
                    this.notificationsService.error(response.error);
                    return;
                }

                if (response.data) {
                    const fileBlob = new Blob([new Uint8Array(response.data)], {type: 'application/pdf'});
                    window.open(URL.createObjectURL(fileBlob), '_blank');
                    return;
                }
            });
            return;
        }

        const dialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            data: {
                title: 'Preview Dual Deal Disburesment PDF',
                message: `Deal form has some changes. Do you want to save it?`,
                buttonOkMessage: 'Save'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isOk) => {
                if (!isOk) {
                    return;
                }
                this.saveDealChanges.emit();
            });
    }

    showDeposit() {
        if (this.dealFormGroup.controls.sales_entities?.disabled) {
            return;
        }
        this.openDepositSection.emit(true);
        this.isDepositButtonNeed.emit(false);
    }

    confirmSavingChanges(actionType: 'approve' | 'request_approve') {
        const confirmDialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            minHeight: 160,
            data: {
                title: `Deal has unsaved changes`,
                messages: [`All changes will be saved automatically`, `Do you wish to continue?`],
                buttonOkMessage: `Continue`
            }
        });

        return confirmDialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((confirmed: boolean) => {
                // save and approve
                if (confirmed) {
                    switch (actionType) {
                        case 'approve':
                            this.approveDisbursement(false, true);
                            break;
                        case 'request_approve':
                            this.saveDealChanges.emit('request_approve');
                            break;
                    }
                } else {
                    return;
                }
            });
    }

    manageSalesEntities() {
        const confirmDialogRef = this.dialog.open(ManageSalesEntitiesDialogComponent, {
            minWidth: 700,
            minHeight: 160,
            data: {
                salesEntities: this.dealFormGroup.controls.sales_entities!.controls,
                showSideCountAndSalesVolumeFlag: this.showSideCountAndSalesVolumeFlag
            },
            disableClose: true,
            autoFocus: false
        });

        confirmDialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {});
    }

    emailDisbursementPDF() {
        const confirmDialogRef = this.dialog.open(EmailDisbursementPdfDialogComponent, {
            minWidth: '700px',
            minHeight: 160,
            data: {
                dual: this.dealFormGroup.controls.dual_deal_id?.value
            }
        });

        confirmDialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((result) => {
                if (result) {
                    const deal = this.dealFormGroup.getRawValue();

                    this.dealApproval.emailDisbursementPdf(deal.id!, result).then((response) => {
                        if (response.error) {
                            this.notificationsService.error(response.error);
                        } else {
                            this.notificationsService.success('Email was successfully sent to selected Recipient');
                        }
                    });
                }
            });
    }

    sendDisbursementToSkyslope() {
        const deal = this.dealFormGroup.getRawValue();
        if (!deal || !deal.id) {
            return;
        }
        this.dealApproval.sendDisbursementPdfToSkyslope(deal.id).then((response) => {
            if (response) {
                this.notificationsService.success(
                    '',
                    'CDA was sent to SkySlope and will appear in the transaction documents.'
                );
            } else {
                this.notificationsService.error('Something went wrong.');
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.saveDealChanges.complete();
        this.saveWithReload.complete();
        this.onRefresh.complete();
        this.openDepositSection.complete();
        this.isDepositButtonNeed.complete();
        this.afterDealSaving$.complete();
        this.checkCommissionPayerEmitter.complete();
    }

    async doResetToDefaults() {
        const confirmDialogRef = this.dialog.open(ConfirmComponent, {
            minWidth: 320,
            minHeight: 160,
            data: {
                dealId: this.dealId,
                showAddonsFlatCommission: true,
                buttonOkMessage: 'Restore',
                title: `Restore to Rules`,
                messages: [
                    `All financial transfers, created from the Not Enforced compensation profiles`,
                    `will be restored to the default values.`,
                    `Do you wish to restore?`
                ]
            }
        });

        confirmDialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((confirmed: boolean) => {
                if (!confirmed) {
                    return;
                }

                this.dealFormGroup.controls.financial_transfers!.controls =
                    this.dealFormGroup.controls.financial_transfers!.controls.filter(
                        (ftFg) => ftFg.controls.is_enforced!.value
                    );
                this.dealFormGroup.controls
                    .financial_transfers!.controls.filter((ftFg) => ftFg.controls.unique_hash!.value === 'NO-HASH')
                    .forEach((ftFg) => {
                        ftFg.controls.label!.patchValue(null, {emitEvent: false});
                        ftFg.controls.percent!.patchValue(null, {emitEvent: false});
                        ftFg.controls.amount!.patchValue(null, {emitEvent: false});
                        ftFg.controls.product_fk_id!.patchValue(null, {emitEvent: false});
                        if (ftFg.controls.type!.value === FinancialTransferEntity.type_SET.company_split_individual) {
                            ftFg.controls.value_type!.patchValue(FinancialTransferEntity.value_type_SET.remaining, {
                                emitEvent: false
                            });
                        } else {
                            ftFg.controls.value_type!.patchValue(FinancialTransferEntity.value_type_SET.flat_fee, {
                                emitEvent: false
                            });
                        }
                        ftFg.controls.is_modified_by_user!.patchValue(false, {emitEvent: false});
                    });
                this.onRefresh.emit();
            });
    }
}

import {Injectable} from '@angular/core';
import {
    SystemNotification,
    UserNotification,
    UserNotificationInterface,
    SpannerNotificationInterface
} from 'app/models/user-notification';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {AuthService} from '../../services/auth.service';
import {SystemNotificationSource} from './system-notification.source';
import {environment} from '../../../environments/environment';
import {SessionService} from '../../services/session.service';
import {Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {MessagesService} from '../messages/messages.service';
import {ContactTypeService} from '../../services/api/contact-type.service';
import {CurrentProfileSource} from '../../services/sources/current-profile.source';
import {AppInitSource} from '../../services/sources/app-init.source';
import {NotificationsServiceZipi} from '../notifications/notifications.service';
import {ContactClassService} from '../../services/api/contact-class.service';
import firebase from 'firebase/compat/app';
import {PermissionsSource} from '../../services/sources/permissions.source';
import {AvailableRolesSource} from '../../services/sources/available-roles.source';
import {CompanyPermissionsSource} from '../../services/sources/companyPermissions.source';
import {Router} from '@angular/router';
import {NotificationsService} from 'angular2-notifications';

import DataSnapshot = firebase.database.DataSnapshot;
import {DealPayoutsSource} from '../../services/sources/deal-payouts.source';
import {QBSyncEventsSource} from '../../services/sources/qb-sync-events.source';

@Injectable()
export class UserNotificationService {
    public MAX_NOTIFICATIONS_LIMIT = 999;
    public NOTIFICATIONS_LIMIT = 40;
    public isHasUnread: boolean = false;
    public unreadCount: number = 0;
    public unreadNotifications: UserNotification[] = [];

    private storage: string = `user-notifications/${(<any>environment).namespace}`;
    private path: string =
        '/' + this.storage + '/' + (this.sessionService.user ? this.sessionService.user.firebase_id : '');
    // private eventNotificationsPath = `/notification-events/${(<any>environment).namespace}/company/${this.sessionService.profile.company_fk_id}/events`;
    // private systemEventNotificationsPath = `/notification-events/${(<any>environment).namespace}/company/${this.sessionService.profile.company_fk_id}/system-events`;

    asyncNotificationsUnreadCount = 0;
    asyncNotificationsUnreadCountChange: Subject<number> = new Subject<number>();

    asyncNotificationsSpinnerNeeded = false;
    asyncNotificationsSpinnerNeededChange: Subject<boolean> = new Subject<boolean>();

    bellDisabled = false;
    bellDisabledChange: Subject<boolean> = new Subject<boolean>();

    spannerNotifications: SpannerNotificationInterface[] = [];
    spannerNotificationsChange: Subject<SpannerNotificationInterface[]> = new Subject<SpannerNotificationInterface[]>();

    notificationsCategoryFilter = 'all';
    notificationsCategoryFilterChange: Subject<string> = new Subject<string>();

    processedNotifications: string[] = [];

    // private updateDealNotification$ = new Subject();
    private updateDealNotification$: any = this.db
        .list(this.path, (ref) => {
            return ref.limitToLast(1).orderByChild('timestamp');
        })
        .valueChanges()
        .pipe(
            // @ts-ignore
            map((msgx) => msgx[0]),
            filter((msg) => !!msg),
            filter((msg: any) => msg.type === 'updatedeal')
        );

    // private updateEventNotification$ = this.db.list(this.eventNotificationsPath, (ref) => {
    //     return ref.limitToLast(1).orderByChild('timestamp');
    // }).valueChanges().pipe(
    //     map(msgx => msgx[0]),
    //     filter(msg => !!msg)
    // );

    constructor(
        public db: AngularFireDatabase,
        public auth: AuthService,
        private sessionService: SessionService,
        public systemNotifications: SystemNotificationSource,
        protected messagesService: MessagesService,
        private contactTypeService: ContactTypeService,
        public currentProfileSource: CurrentProfileSource,
        protected appInitSource: AppInitSource,
        protected notificationService: NotificationsServiceZipi,
        private contactClassService: ContactClassService,
        private authService: AuthService,
        protected permissionsSource: PermissionsSource,
        private companyPermissionsSource: CompanyPermissionsSource,
        private availableRolesSource: AvailableRolesSource,
        protected router: Router,
        public ntfs: NotificationsService,
        public dealPayoutsSource: DealPayoutsSource,
        public qbSyncEventsSource: QBSyncEventsSource
    ) {
        // use this if you want get notifications of Admin user(LoginAs)
        // this.path = '/' + this.storage + '/' + this.auth.currentFirebaseUser.uid;

        // use this if you want get notifications of current user(LoginAs)
        this.db.database.ref(this.path).on('child_added', (childSnapshot: DataSnapshot) => {
            const item = <UserNotificationInterface>childSnapshot.toJSON(); // @TODO: MAKE TYPE GUARD

            item['$key'] = childSnapshot.key;
            if (item.system) {
                if (item.timestamp && Date.now() - item.timestamp > 1000 * 60 * 10) {
                    // 10 minutes
                    if (!this.auth.currentFirebaseUser) {
                        return;
                    }
                    this.db.database
                        .ref('/' + this.storage + '/' + this.auth.currentFirebaseUser.uid + '/' + childSnapshot.key)
                        .remove();
                    return;
                }
            }

            if (item.status === UserNotification.provideStatuses().new) {
                switch (item.type) {
                    case 'updatedeal':
                        break;

                    case 'system-reload-ui-mods':
                        this.systemNotifications.triggers.reloadUiModsSystemNotification.next(
                            new SystemNotification().createFrom(item)
                        );
                        break;

                    case 'system-reload-data-list':
                        this.systemNotifications.triggers.reloadDataListNotification.next(
                            new SystemNotification().createFrom(item)
                        );
                        break;

                    default:
                        this.doAddUserNotification(item);
                        this.isHasUnread = true;
                        this.unreadCount++;
                }
            }
        });

        this.db.database.ref(`system-reload-notify`).on('child_added', (childSnapshot: DataSnapshot) => {
            this.notificationService.addReload();
        });

        this.systemNotifications.dismissSystemNotification.subscribe((notification: SystemNotification) => {
            const key = notification['$key'];
            delete notification['$key'];
            notification.status = UserNotification.provideStatuses().seen;
            if (!this.auth.currentFirebaseUser) {
                return;
            }
            this.db.database
                .ref('/' + this.storage + '/' + this.auth.currentFirebaseUser.uid + '/' + key)
                .set(notification);
        });

        this.asyncNotificationsUnreadCountChange.subscribe((value) => {
            this.asyncNotificationsUnreadCount = value;
        });
        this.asyncNotificationsSpinnerNeededChange.subscribe((value) => {
            this.asyncNotificationsSpinnerNeeded = value;
        });
        this.spannerNotificationsChange.subscribe((notifications: any[]) => {
            this.spannerNotifications = notifications;
        });
        this.bellDisabledChange.subscribe((bool) => {
            this.bellDisabled = bool;
        });
        this.notificationsCategoryFilterChange.subscribe((nextFilter) => {
            this.notificationsCategoryFilter = nextFilter;
        });

        // subscribe to appInit changes
        this.appInitSource.appInit.subscribe((appInitResponse) => {
            // unsubscribe eventNotifications of previous company
            this.unsubscribeToEventNotifications();

            // get unreaded notifications counter for new company
            this.getNotificationsCounterAndSpinner();

            // subscribe to eventNotifications for new company
            this.subscribeToEventNotifications();

            // after appinit we need to load/reload the first 40 bell notifications
            this.bellDisabledChange.next(true);
            this.messagesService
                .getAsyncNotificationsSpanner(this.NOTIFICATIONS_LIMIT, 0, this.notificationsCategoryFilter)
                .then((notificationsResponse) => {
                    this.bellDisabledChange.next(false);
                    this.spannerNotificationsChange.next(notificationsResponse);
                })
                .catch(() => {
                    this.bellDisabledChange.next(false);
                });
        });

        this.clearCompanySystemNotifications();
    }

    get eventNotificationsPath() {
        if (!this.sessionService.profile) {
            return '';
        }
        return `/notification-events/${(<any>environment).namespace}/company/${this.sessionService.profile.company_fk_id}/events`;
    }
    get systemEventNotificationsPath() {
        if (!this.sessionService.profile) {
            return '';
        }
        return `/notification-events/${(<any>environment).namespace}/company/${this.sessionService.profile.company_fk_id}/system-events`;
    }

    public unsubscribeToEventNotifications() {
        this.db.database.ref(this.eventNotificationsPath).off();
        this.db.database.ref(this.systemEventNotificationsPath).off();
        this.processedNotifications = [];
    }

    public clearCompanySystemNotifications() {
        // remove OLD system-notifications COMPANY WIDE (younger than NOW)
        this.db
            .list(this.systemEventNotificationsPath)
            .query.orderByChild('timestamp')
            .endAt(Date.now())
            .once('value')
            .then((a) => a.val())
            .then((notifications) => {
                if (notifications) {
                    Object.keys(notifications).forEach((ref) => {
                        this.db.list(this.systemEventNotificationsPath).remove(ref);
                    });
                }
            });
    }

    public subscribeToEventNotifications() {
        // subscribe to notifications event
        this.db.database
            .ref(this.eventNotificationsPath)
            .limitToLast(1) // if old notifications fired - limit them to only one
            .orderByChild('timestamp')
            .startAt(Date.now()) // skip old notifications
            .on('child_added', (childSnapshot: DataSnapshot) => {
                const item = <
                    {
                        action_with_notification: 'created' | 'updated';
                        notification_id: number;
                        timestamp: number;
                        notificationBody?: SpannerNotificationInterface; // this is the whole notification form spanner notification table
                    }
                >childSnapshot.toJSON();

                // we need prevent process same notification in case user changed his pc time
                const itemId = `${item.timestamp}-${item.notification_id}`;
                if (this.processedNotifications.indexOf(itemId) !== -1) {
                    return;
                }
                this.processedNotifications.push(itemId);

                // trigger for notifications component reload
                this.systemNotifications.triggers.reloadNotificationsNotificationEvent.next(item);

                this.updateInitialSpannerNotificationsList(item);

                // get counter and spinner
                if (item && item.action_with_notification === 'created') {
                    if (
                        this.asyncNotificationsSpinnerNeeded && // already spinning
                        item &&
                        item.notificationBody &&
                        item.notificationBody.category === 'deals_calculation' &&
                        item.notificationBody.action === 'recalculate'
                    ) {
                        // no need to do /counter request because:
                        // - spinner is already spinning
                        // - no need to update counter because deals_calculation cannot be 'not readed' by default
                    } else {
                        this.getNotificationsCounterAndSpinner();
                    }
                    // else if spinner is spinning, we need to check if it should be stopped
                } else if (
                    this.asyncNotificationsSpinnerNeeded &&
                    item &&
                    item.action_with_notification === 'updated' &&
                    item.notificationBody
                ) {
                    // CASE_1 (success | error) when main spinner is spinning and notification received with status 'error' or 'success'
                    // we need to run counter request to make sure if we need to stop spinner
                    // CASE_2 (warning) when main spinner is rolling and notification received with status 'warning'
                    // this case happens when user clicks on 'dismiss' notification button
                    // we need to run counter request to make sure if we need to stop spinner
                    if (['success', 'error', 'warning'].includes(item.notificationBody.status)) {
                        this.getNotificationsCounterAndSpinner();
                    }
                }
            });

        // subscribe to system-notifications event COMPANY WIDE
        this.db.database
            .ref(this.systemEventNotificationsPath)
            .limitToLast(1) // if old notifications fired - limit them to only one
            .orderByChild('timestamp')
            .startAt(Date.now()) // skip old notifications
            .on('child_added', (childSnapshot: DataSnapshot) => {
                const item = <UserNotificationInterface>childSnapshot.toJSON();

                // we need prevent process same notification in case user changed his pc time
                const itemId = `${item.timestamp}-${item.type}`;
                if (this.processedNotifications.indexOf(itemId) !== -1) {
                    return;
                }
                this.processedNotifications.push(itemId);

                if (
                    item.type &&
                    [
                        'system-payout-processing-started',
                        'system-payout-processing-finished',
                        'system-payout-reverting-finished',
                        'system-un-posting-all-finished'
                    ].includes(item.type)
                ) {
                    if (item.payload) {
                        this.dealPayoutsSource.payoutEvents.next(item.payload);
                    }
                }

                if (item.type === 'system-qb-sync-started') {
                    if (item.payload) {
                        this.qbSyncEventsSource.qbSyncEvents.next(item.payload);
                    }
                }
                if (item.type === 'system-qb-sync-finished') {
                    if (item.payload) {
                        this.qbSyncEventsSource.qbSyncEvents.next(item.payload);
                    }
                }

                if (item.type === 'system-reload-contact-types') {
                    // if we never got contactTypes - skip this notifications
                    if (this.sessionService.contactTypes.length === 0) {
                        return;
                    }

                    // reload contact_types
                    this.contactTypeService.getContactTypeListAsPromise().then((list) => {
                        this.currentProfileSource.contactTypes.next(list);
                    });
                }

                if (item.type === 'system-reload-contact-classes') {
                    // reload contact classes
                    this.contactClassService.getContactClassListAsPromise().then((list) => {
                        this.currentProfileSource.contactClasses.next(list);
                    });
                }

                if (item.type === 'system-reload-profiles-by-role') {
                    if (
                        this.sessionService.profile &&
                        this.sessionService.profile.roles_ids &&
                        this.sessionService.profile.roles_ids.find((role_id) => Number(role_id) === Number(item.text))
                    ) {
                        // reload
                        this.appInitSource.resetLastAppInitResponse();
                        this.authService.initApp();
                    }
                }

                if (item.type === 'system-reload-profiles-by-id') {
                    if (this.sessionService.profile && this.sessionService.profile.id === Number(item.text)) {
                        // reload
                        this.appInitSource.resetLastAppInitResponse();
                        this.authService.initApp();
                    }
                }

                if (item.type === 'system-reload-all-profiles-in-company') {
                    this.appInitSource.resetLastAppInitResponse();
                    this.authService.initApp();
                }

                if (item.type === 'system-reload-company-permissions') {
                    this.companyPermissionsSource.load();
                }

                if (item.type === 'system-reload-available-roles') {
                    this.availableRolesSource.load();
                }

                if (this.sessionService.profile) {
                    if (item.type === 'merge-contacts-failed' && this.sessionService.profile.id === Number(item.text)) {
                        this.ntfs.error('Merge Contacts Failed.');
                    }

                    if (
                        item.type === 'unmerge-contacts-failed' &&
                        this.sessionService.profile.id === Number(item.text)
                    ) {
                        this.ntfs.error('Restore Contact Failed.');
                    }

                    if (
                        item.type === 'merge-contacts-completed' &&
                        this.sessionService.profile.id === Number(item.text)
                    ) {
                        this.ntfs.success('Merge Contacts Completed.');
                        window.location.href = '/contacts';
                    }

                    if (
                        item.type === 'unmerge-contacts-completed' &&
                        this.sessionService.profile.id === Number(item.text)
                    ) {
                        this.ntfs.success('Restore Contact Completed.');
                        window.location.href = '/contacts';
                    }

                    if (item.type === 'system-bulk-action-completed') {
                        if (Number(this.sessionService.profile.id) === Number(item.text)) {
                            this.ntfs.success('Bulk Action completed successfully');
                        }
                    }
                }
            });
    }

    public doAddUserNotification(payload: UserNotificationInterface) {
        const notification = new UserNotification().createFrom(payload);
        notification.message = notification.text;
        this.unreadNotifications.push(notification);
    }

    public doDismissNotification(index: number) {
        if (!this.auth.currentFirebaseUser) {
            return;
        }
        // @TODO: make nice change status
        const item = this.unreadNotifications[index];
        const key = item['$key'];
        delete item['$key'];
        item.status = UserNotification.provideStatuses().seen;
        this.db.database.ref('/' + this.storage + '/' + this.auth.currentFirebaseUser.uid + '/' + key).set(item);

        this.unreadNotifications = this.unreadNotifications.filter((v, i, a) => {
            return i !== index;
        });
        this.unreadCount = this.unreadNotifications.length;
        this.isHasUnread = this.unreadCount > 0;
    }

    getNotificationsCounterAndSpinner() {
        this.messagesService.getAsyncNotificationsCount().then((data) => {
            // console.log(data, 'data');
            // const spinnerNeeded = !!data.find(el => el.status === 'start');
            const spinnerNeeded = data.isSpinnerNeeded;
            // do not show 'Financial Verification' ('recalculate') notifications in counter
            // NOTE: we still need spinner in counter
            const unseenMessagesCounter = data.count;

            if (this.asyncNotificationsUnreadCount !== unseenMessagesCounter) {
                this.asyncNotificationsUnreadCountChange.next(unseenMessagesCounter);
            }

            if (this.asyncNotificationsSpinnerNeeded !== spinnerNeeded) {
                this.asyncNotificationsSpinnerNeededChange.next(spinnerNeeded);
            }

            // this.systemNotifications.triggers.reloadNotificationsNotificationEvent.next({
            //     eventNotification: item,
            //     spinnerNeeded: spinnerNeeded,
            //     unseenMessagesCounter
            // });
        });
    }

    updateDealNotifications() {
        return this.updateDealNotification$;
    }

    public updateSpannerNotificationsList(data: SpannerNotificationInterface[]) {
        this.spannerNotificationsChange.next(data);
    }

    public addItemsToSpannerNotificationsList(data: SpannerNotificationInterface[]) {
        this.spannerNotificationsChange.next(this.spannerNotifications.concat(data));
    }

    public updateNotificationsCategoryFilter(data: string) {
        this.notificationsCategoryFilterChange.next(data);
    }

    // FIREBASE does not support arrays
    _prepareNotificationFromFirebase(n: any) {
        if (!n) {
            return null;
        }

        return {
            ...n,
            seen_by: Array.isArray(n.seen_by) ? n.seen_by : this._firebaseObjectToArray(n.seen_by),
            flagged_by: Array.isArray(n.flagged_by) ? n.flagged_by : this._firebaseObjectToArray(n.flagged_by)
        };
    }

    _firebaseObjectToArray(obj: {[key: string]: any}) {
        if (typeof obj === 'object' && obj !== null) {
            return Object.keys(obj).reduce((total: number[], key) => {
                total.push(obj[key]);
                return total;
            }, []);
        } else {
            return [];
        }
    }

    // update initial bell notifications list
    public async updateInitialSpannerNotificationsList(item: {
        action_with_notification: 'created' | 'updated';
        notification_id: number;
        timestamp: number;
        notificationBody?: SpannerNotificationInterface; // this is the whole notification form spanner notification table
    }) {
        if (!item) {
            return;
        }
        const categoryInFilter = this.notificationsCategoryFilter;
        const notificationFromFirebase = this._prepareNotificationFromFirebase(item.notificationBody);

        switch (item.action_with_notification) {
            case 'created':
                const alreadyHasThisNotification = this.spannerNotifications.some(
                    (n) => n.notification_id === item.notification_id
                );
                if (alreadyHasThisNotification) {
                    return;
                }

                // if body of new spanner notification is already in system-notification - no need to do additional request
                if (notificationFromFirebase) {
                    // if new notification has the same category (or all category) - add it to the list
                    if (['all', notificationFromFirebase.category].includes(categoryInFilter)) {
                        this.updateSpannerNotificationsList(
                            [notificationFromFirebase].concat(this.spannerNotifications)
                        );
                    }
                } else {
                    const newNotification = await this.messagesService.getAsyncNotification(item.notification_id);
                    if (!newNotification) {
                        return;
                    }

                    // if new notification has the same category (or all category) - add it to the list
                    if (['all', newNotification.category].includes(categoryInFilter)) {
                        this.updateSpannerNotificationsList(
                            [newNotification].concat(this.spannerNotifications).slice(0, this.MAX_NOTIFICATIONS_LIMIT)
                        );
                    }
                }
                break;
            case 'updated':
                const prevNotif = this.spannerNotifications.find((n) => n.notification_id === item.notification_id);
                if (!prevNotif) {
                    return;
                }

                // if body of new spanner notification is already in system-notification - no need to do additional request
                if (notificationFromFirebase) {
                    if (['all', prevNotif.category].includes(categoryInFilter)) {
                        this.updateSpannerNotificationsList(
                            this.spannerNotifications.map((el) => {
                                if (el.notification_id !== item.notification_id) {
                                    return el;
                                }
                                // return notificationFromFirebase;
                                return {
                                    ...prevNotif,
                                    ...notificationFromFirebase,
                                    notification_id: prevNotif.notification_id,
                                    seen_by: prevNotif.seen_by, // this field not updated by system-notification
                                    flagged_by: prevNotif.flagged_by // this field not updated by system-notification
                                };
                            })
                        );
                    }
                } else {
                    const updNotification = await this.messagesService.getAsyncNotification(item.notification_id);
                    if (!updNotification) {
                        return;
                    }
                    if (['all', updNotification.category].includes(categoryInFilter)) {
                        this.updateSpannerNotificationsList(
                            this.spannerNotifications.map((el) => {
                                if (el.notification_id !== item.notification_id) {
                                    return el;
                                }
                                return updNotification;
                            })
                        );
                    }
                }
                break;
            default:
                break;
        }
    }
}

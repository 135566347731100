<div [style]="{width: recordPaymentSettingsGroup.controls.payment_mode.value === 'check_record' ? '1272px' : '917px'}">
    <!--Content Container-->
    <div style="padding: 24px 24px 0 24px">
        <!--Header-->
        <div class="d-flex">
            <h2>Record Payment</h2>
            <span class="sp-spacer"></span>
            <mat-icon class="sp-cursor-pointer" (click)="close()">close</mat-icon>
        </div>

        <!--Payment Settings-->
        <div class="d-flex" [formGroup]="recordPaymentSettingsGroup">
            <div class="w-33 mr-2">
                <app-date-picker
                    [placeholder]="'Payment Date'"
                    [dateControl]="recordPaymentSettingsGroup.controls.paid_date"
                >
                </app-date-picker>
            </div>

            <mat-form-field class="w-33 mr-2">
                <mat-select formControlName="payment_mode">
                    <ng-container *ngFor="let mode of paymentModes">
                        <mat-option [value]="mode.slug" *ngIf="mode.is_selectable">
                            <span>{{ mode.title }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
                <mat-placeholder>Payment Type</mat-placeholder>
            </mat-form-field>

            <app-ledger-account-selector
                #ledgerAccountPicker
                class="w-33"
                [ledgerAccountControl]="recordPaymentSettingsGroup.controls.ledger_account_id"
                [placeholder]="'Deposit To'"
            >
            </app-ledger-account-selector>
        </div>

        <!--Payouts List for non-check mode-->
        <div *ngIf="recordPaymentSettingsGroup.controls.payment_mode.value !== 'check_record'">
            <!--Table Header-->
            <div class="table-header">
                <div class="w-40">From</div>
                <div class="w-30">Amount Received</div>
                <div class="w-30 text-right">Remaining Amount</div>
            </div>

            <!--Items Section-->
            <div style="height: 400px; max-height: 45vh; overflow-y: auto">
                <div *ngIf="itemsArray && itemsArray.length > 0 && itemsArrayBuilt">
                    <!--Item-->
                    <div *ngFor="let payoutGroup of itemsArray.controls; index as idx">
                        <div [formGroup]="payoutGroup" class="list-item">
                            <div class="w-40">
                                {{ payoutGroup.controls.payment_from_name.value }}
                            </div>
                            <div class="w-30 d-flex align-items-center">
                                <mat-form-field class="w-80 mr-2">
                                    <input
                                        matInput
                                        formControlName="amount"
                                        placeholder="Amount"
                                        [maskito]="currencyMaskitoMask"
                                        (keyup)="validateAmount($event, payoutGroup)"
                                    />
                                </mat-form-field>

                                <mat-icon
                                    *ngIf="payoutGroup.controls.is_dual_deal.value"
                                    matTooltipPosition="after"
                                    class="dual-icon"
                                    matTooltip="Dual Agency"
                                    >link</mat-icon
                                >
                            </div>
                            <div class="w-30 text-right">
                                <span class="mr-2">{{ payoutGroup.controls.remaining_balance.value | currency }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!itemsArrayBuilt" class="w-100 text-center">Data Loading...</div>
                <div *ngIf="!itemsArray || (itemsArray.length === 0 && itemsArrayBuilt)" class="w-100 text-center mt-2">
                    Nothing to Display
                </div>
            </div>
        </div>

        <!--Payouts List for check mode-->
        <div *ngIf="recordPaymentSettingsGroup.controls.payment_mode.value === 'check_record'">
            <!--Table Header-->
            <div class="table-header">
                <div class="w-20">Payment From</div>
                <div class="w-20">Amount Received</div>
                <div class="w-20">Remaining Amount</div>
                <div class="w-25">Memo</div>
                <div class="w-15">Check Number</div>
            </div>

            <!--Items Section-->
            <div style="height: 400px; max-height: 45vh; overflow-y: auto">
                <div *ngIf="itemsArray && itemsArray.length > 0 && itemsArrayBuilt">
                    <!--Item-->
                    <div *ngFor="let payoutGroup of itemsArray.controls; index as idx">
                        <div [formGroup]="payoutGroup" class="list-item">
                            <div class="w-20">
                                {{ payoutGroup.controls.payment_from_name.value }}
                            </div>
                            <div class="w-20 d-flex align-items-center">
                                <mat-form-field class="w-80 mr-2">
                                    <input
                                        matInput
                                        formControlName="amount"
                                        placeholder="Amount"
                                        [maskito]="currencyMaskitoMask"
                                        (keyup)="validateAmount($event, payoutGroup)"
                                    />
                                </mat-form-field>
                                <mat-icon
                                    *ngIf="payoutGroup.controls.is_dual_deal.value"
                                    matTooltipPosition="after"
                                    class="dual-icon"
                                    matTooltip="Dual Agency"
                                    >link</mat-icon
                                >
                            </div>
                            <div class="w-20">
                                <span class="mr-2">{{ payoutGroup.controls.remaining_balance.value | currency }}</span>
                            </div>
                            <div class="w-25">
                                <mat-form-field class="w-100">
                                    <textarea matInput type="text" formControlName="memo"> </textarea>
                                    <mat-label *ngIf="!payoutGroup.controls.memo.value">Memo</mat-label>
                                </mat-form-field>
                            </div>
                            <div class="w-15">
                                <mat-form-field class="w-90">
                                    <input matInput autocomplete="off" type="search" formControlName="check_number" />
                                    <mat-label *ngIf="!payoutGroup.controls.check_number.value">Check Number</mat-label>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!itemsArrayBuilt" class="w-100 text-center mt-2">Data Loading...</div>
                <div *ngIf="!itemsArray || (itemsArray.length === 0 && itemsArrayBuilt)" class="w-100 text-center mt-2">
                    Nothing to Display
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <div class="action-section">
        <span class="sp-spacer"></span>
        <button mat-button color="primary" class="ml-2" (click)="dialogRef.close(false)" tabindex="-1">Back</button>
        <button
            mat-raised-button
            class="ml-1"
            [disabled]="!itemsArrayBuilt"
            color="primary"
            (click)="continue()"
            tabindex="1"
        >
            Confirm Payment Received
        </button>
    </div>
</div>

import {of as observableOf, Observable} from 'rxjs';

import {withLatestFrom, switchMap, catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
import {IContactInvitesState} from './contact-invites.reducers';
import {
    CONTACT_INVITES_ACTION_TYPES,
    ContactInviteReceivedAction,
    FetchContactInviteAction,
    InviteAcceptEndedAction,
    LogoutAndGoToAction,
    SubmitAcceptInviteFormAction
} from './contact-invites.actions';
import {ContactInvitesService} from '../services/contact-invites.service';
import {NotificationsService} from 'angular2-notifications';
import {AuthService} from '../../../services/auth.service';
import {selectContactInvite} from './contact-invites.selectors';
import {Router} from '@angular/router';
import {SessionService} from '../../../services/session.service';
import {FetchProfileSettingsSuccess} from '../../account-info/store/settings.actions';

@Injectable()
export class ContactInvitesEffectsService {
    constructor(
        private actions$: Actions,
        protected contactInvitesService: ContactInvitesService,
        protected notificationsService: NotificationsService,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected store: Store<IContactInvitesState>,
        protected router: Router
    ) {}

    @Effect()
    fetchContactInvite$ = this.actions$.pipe(
        ofType<FetchContactInviteAction>(CONTACT_INVITES_ACTION_TYPES.FETCH_CONTACT_INVITE),
        switchMap((action) => {
            return this.contactInvitesService.getContactInviteByHash(action.payload!).pipe(
                catchError((err) => {
                    // this.notificationsService.error(err);

                    return observableOf(null);
                }),
                map((contactInvite) => {
                    if (contactInvite) {
                        return new ContactInviteReceivedAction(contactInvite);
                    }
                })
            );
        })
    );

    @Effect({dispatch: false})
    inviteAcceptEnded$ = this.actions$.pipe(
        ofType<InviteAcceptEndedAction>(CONTACT_INVITES_ACTION_TYPES.INVITE_ACCEPT_ENDED),
        switchMap((action) => {
            if (action.payload! === true) {
                return this.router.navigate(['/default-page']);
            }

            return observableOf(null);
        })
    );

    @Effect()
    submitAcceptInviteFormAction$ = this.actions$.pipe(
        ofType<SubmitAcceptInviteFormAction>(CONTACT_INVITES_ACTION_TYPES.SUBMIT_ACCEPT_INVITE_FORM),
        withLatestFrom(this.store.pipe(select(selectContactInvite))),
        switchMap(([action, contactInvite]) => {
            return this.contactInvitesService.submitAcceptInvite(contactInvite!.invite_hash!, action.payload!).pipe(
                catchError((err) => {
                    this.notificationsService.error(err.message);

                    return observableOf(null);
                }),
                map((success) => {
                    console.log('success', success);

                    return new InviteAcceptEndedAction(!!success);
                })
            );
        })
    );

    @Effect({dispatch: false})
    logoutAndGoToAction$ = this.actions$.pipe(
        ofType<LogoutAndGoToAction>(CONTACT_INVITES_ACTION_TYPES.LOGOUT_AND_GO_TO),
        withLatestFrom(this.store.pipe(select(selectContactInvite))),
        switchMap(async ([action, contactInvite]) => {
            await this.authService.logout();

            this.sessionService.setRedirectParams(
                ['/contact-invites', contactInvite!.invite_hash!, 'accept'],
                contactInvite!.invited_email!,
                action.payload!.authInfoMessage
            );

            return this.router.navigate(action.payload!.goTo);
        })
    );

    @Effect({dispatch: false})
    logoutAndGoToInvoiceAction$ = this.actions$.pipe(
        ofType<LogoutAndGoToAction>(CONTACT_INVITES_ACTION_TYPES.LOGOUT_AND_GO_TO_INVOICE),
        switchMap(async (action) => {
            await this.authService.logout();
            return this.router.navigate(action.payload!.goTo);
        })
    );

    @Effect({dispatch: false})
    LogoutAndGoToWithoutRedirectAction = this.actions$.pipe(
        ofType<LogoutAndGoToAction>(CONTACT_INVITES_ACTION_TYPES.LOGOUT_AND_GO_TO_WITHOUT_REDIRECT_ACTION),
        withLatestFrom(this.store.pipe(select(selectContactInvite))),
        switchMap(async ([action, contactInvite]) => {
            await this.authService.logout();

            this.sessionService.setRedirectParams(
                ['/default-page'],
                contactInvite!.invited_email!,
                action.payload!.authInfoMessage
            );

            return this.router.navigate(action.payload!.goTo);
        })
    );
}

import {Component, ErrorHandler, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {IInvoice, IPaymentGateway, IPaymentMethod} from '@cyberco-nodejs/zipi-typings';
import {Subject} from 'rxjs';
import {forOwn} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';
import {PaymentMethodsService} from '../../../modules/profile/services/payment-methods.service';
import {InvoicePublicService} from '../../../modules/finance/services/invoice-public.service';
import {NotificationsService} from 'angular2-notifications';
import {GatewayService} from '../../../modules/profile/services/gateway.service';
import {NotificationsServiceZipi} from '../../../modules/notifications/notifications.service';
import {LoggingApiService} from '../../../services/api/logging-api.service';

const win: {[key: string]: any} = window;
const Accept = win.Accept;

@Component({
    selector: 'app-add-credit-card-dialog',
    templateUrl: './add-credit-card-dialog.component.html',
    styleUrls: ['./add-credit-card-dialog.component.scss']
})
export class AddCreditCardDialogComponent implements OnInit, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    countryList: Array<{[key: string]: any}> = [
        {label: 'Belgium', slug: 'Belgium'},
        {label: 'Canada', slug: 'Canada'},
        {label: 'France', slug: 'France'},
        {label: 'Germany', slug: 'Germany'},
        {label: 'Mexico', slug: 'Mexico'},
        {label: 'USA', slug: 'USA'}
    ];

    cardForm: UntypedFormGroup | undefined;
    accountCustomerForm: UntypedFormGroup | undefined;
    accountFundingSourceForm: UntypedFormGroup | undefined;

    companyGateways: Array<IPaymentGateway> = [];

    authorizeGateway: UntypedFormControl | undefined;

    invoiceMethods: IPaymentMethod[] = [];

    invoiceAuthorizeAvailable: boolean;
    invoiceDwollaAvailable: boolean;

    moneySenderProfileId: number | undefined;
    invoice: IInvoice | undefined;
    moneySenderContactId: number | undefined;
    contactId: number | undefined;

    isPublicPage: boolean | undefined;

    showAccordion = false;

    linkHandler: any;

    agree: boolean;
    onDemMessage: string | undefined;

    isLoading: boolean;

    hasPendingRequest: boolean;

    defaultReceiving: boolean;
    agreeContinueButtonDisabled: boolean;

    redirect: string | undefined;

    constructor(
        public dialogRef: MatDialogRef<AddCreditCardDialogComponent>,
        private invoicePublicService: InvoicePublicService,
        private paymentMethodsService: PaymentMethodsService,
        private gatewayService: GatewayService,
        private notificationsService: NotificationsService,
        protected notificationServiceZipi: NotificationsServiceZipi,
        private fb: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected dialog: MatDialog,
        private errorHandler: ErrorHandler,
        private loggingApiService: LoggingApiService
    ) {
        this.invoiceAuthorizeAvailable = false;
        this.invoiceDwollaAvailable = false;
        this.agree = false;
        this.isLoading = false;
        this.hasPendingRequest = false;
        this.defaultReceiving = false;
        this.agreeContinueButtonDisabled = false;
    }

    ngOnInit() {
        this.moneySenderProfileId = this.data.moneySenderProfileId;
        this.invoice = this.data.invoice;
        this.isPublicPage = this.data.isPublicPage;
        this.moneySenderContactId = this.data.contactId;
        this.contactId = this.data.contactId;
        this.redirect = this.data.redirect;

        if (this.invoice) {
            this.invoicePublicService
                .getInvoiceMethods([this.invoice.pay_to_card__payment_method_fk_id])
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((methods) => {
                    this.invoiceMethods = methods;
                    methods.forEach((method) => {
                        if (method.payment_gateway?.type === 'authorize_net_merchant') {
                            this.invoiceAuthorizeAvailable = true;
                            this.authorizeGateway = this.fb.control(method.payment_gateway, Validators.required);
                        }
                    });
                });
        } else {
            this.gatewayService
                .getCompanyGateways()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((gateways) => {
                    this.companyGateways = gateways;
                    // this.currentGateway = this.fb.control(this.companyGateways[0], Validators.required);
                    this.authorizeGateway = this.fb.control(
                        this.companyGateways.filter((gateway) => gateway.type === 'authorize_net_merchant')[0],
                        Validators.required
                    );

                    this.isLoading = true;
                    if (this.redirect) {
                        this.agree = true;
                        this.agreeContinueButtonDisabled = false;
                        this.defaultReceiving = JSON.parse(localStorage.getItem('zipi_pg_redirect_default') as string);
                    }
                });
        }

        this.initForm();

        // dirty hack to prevent expand mat-accordion panels
        setTimeout(() => {
            this.showAccordion = true;
        }, 10);
    }

    initForm() {
        this.cardForm = this.fb.group({
            cardNumber: ['', Validators.required],
            expMonth: ['', Validators.required],
            expYear: ['', Validators.required],
            cardCode: ['', Validators.required],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            address: ['', Validators.required],
            city: ['', Validators.required],
            zipCode: [null, Validators.required],
            state: ['', Validators.required],
            country: ['', Validators.required],
            use_as_default: [false, Validators.required]
        });

        this.accountCustomerForm = this.fb.group({
            firstName: [null, [Validators.required]],
            lastName: [null, [Validators.required]],
            email: [null, [Validators.required]],
            title: [null, [Validators.required]]
        });

        this.accountFundingSourceForm = this.fb.group({
            routingNumber: [null, [Validators.required]],
            accountNumber: [null, [Validators.required]],
            type: [null, [Validators.required]],
            name: [null, [Validators.required]]
        });
    }

    close(value?: boolean) {
        localStorage.removeItem('zipi_pl_link_token');
        localStorage.removeItem('zipi_pg_redirect_id');
        localStorage.removeItem('zipi_pg_redirect_default');
        this.dialogRef.close(value);
    }

    cardTitleBuild(): string {
        if (typeof this.cardForm !== 'undefined') {
            return 'XXXX XXXX XXXX ' + this.cardForm.getRawValue().cardNumber.slice(-4);
        }
        return '';
    }

    expirationDateBuild(): string {
        if (typeof this.cardForm !== 'undefined') {
            return `${this.cardForm.getRawValue().expMonth}/${this.cardForm.getRawValue().expYear}`;
        }
        return '';
    }

    markFormGroupTouched(FormControls: {[key: string]: AbstractControl} | AbstractControl[]): void {
        const markFormGroupTouchedRecursive = (
            controls: {[key: string]: AbstractControl} | AbstractControl[]
        ): void => {
            forOwn(controls, (c: any, controlKey: any) => {
                c.markAsTouched();
                if (c instanceof UntypedFormGroup || c instanceof UntypedFormArray) {
                    markFormGroupTouchedRecursive(c.controls);
                }
            });
        };
        markFormGroupTouchedRecursive(FormControls);
    }

    async saveCard() {
        if (typeof this.cardForm !== 'undefined' && this.cardForm.invalid) {
            this.markFormGroupTouched(this.cardForm.controls);
        } else {
            if (typeof this.authorizeGateway !== 'undefined' && this.authorizeGateway.invalid) {
                this.markFormGroupTouched([this.authorizeGateway]);
            } else {
                let successResponse: any = null;

                const gateway = this.authorizeGateway?.value;
                const publicKey = await this.getPublicKey(gateway.id);

                const authData: {[key: string]: any} = {};
                authData['clientKey'] = publicKey;
                authData['apiLoginID'] = gateway.settings.api_login;

                const cardFormData = this.cardForm?.getRawValue();

                const cardData: {[key: string]: any} = {};
                cardData['cardNumber'] = cardFormData.cardNumber;
                cardData['month'] = cardFormData.expMonth;
                cardData['year'] = cardFormData.expYear;
                cardData['cardCode'] = cardFormData.cardCode;
                cardData['use_as_default'] = cardFormData.use_as_default;

                const billingData: {[key: string]: any} = {};
                billingData['firstName'] = cardFormData.firstName;
                billingData['lastName'] = cardFormData.lastName;
                billingData['address'] = cardFormData.address;
                billingData['city'] = cardFormData.city;
                billingData['zipCode'] = cardFormData.zipCode;
                billingData['state'] = cardFormData.state;
                billingData['country'] = cardFormData.country;
                billingData['use_as_default'] = cardFormData.use_as_default;

                const secureData: {[key: string]: any} = {};
                secureData['authData'] = authData;
                secureData['cardData'] = cardData;

                const cardDataForSaving = {
                    card_number: cardFormData.cardNumber,
                    month: cardFormData.expMonth,
                    year: cardFormData.expYear,
                    cardCode: cardFormData.cardCode,
                    zipCode: cardFormData.zipCode
                };

                const cardBillingDataForSaving = {
                    firstName: cardFormData.firstName,
                    lastName: cardFormData.lastName,
                    address: cardFormData.address,
                    city: cardFormData.city,
                    zipCode: cardFormData.zipCode,
                    state: cardFormData.state,
                    country: cardFormData.country
                };

                Accept.dispatchData(secureData, (response: any) => {
                    if (!successResponse) {
                        if (response.messages.resultCode === 'Error') {
                            let i = 0;
                            while (i < response.messages.message.length) {
                                this.notificationServiceZipi.addError(response.messages.message[i].text);
                                console.log(
                                    response.messages.message[i].code + ': ' + response.messages.message[i].text
                                );
                                i = i + 1;
                            }
                        } else {
                            successResponse = response;

                            if (!this.invoice) {
                                const paymentObject = {
                                    gatewayId: this.authorizeGateway?.value.id,
                                    opaqueData: successResponse.opaqueData,
                                    cardTitle: this.cardTitleBuild(),
                                    billingData: billingData,
                                    invoiceId: null,
                                    expirationDate: this.expirationDateBuild()
                                };

                                // create credit card associated only with contact
                                this.hasPendingRequest = true;
                                if (this.contactId) {
                                    this.paymentMethodsService
                                        .addCardForContact(paymentObject, this.contactId)
                                        .pipe(takeUntil(this.unsubscribe))
                                        .subscribe((res) => {
                                            if (res) {
                                                this.hasPendingRequest = false;
                                                this.close(true);
                                            }
                                        });
                                }
                            } else {
                                const paymentObject = {
                                    gatewayId: this.invoiceMethods.find(
                                        (im) => im.payment_gateway?.type === 'authorize_net_merchant'
                                    )?.payment_gateway?.payment_gateway_id,
                                    payToPaymentId: this.invoiceMethods.find(
                                        (im) => im.payment_gateway?.type === 'authorize_net_merchant'
                                    )?.payment_method_id,
                                    opaqueData: successResponse.opaqueData,
                                    cardTitle: this.cardTitleBuild(),
                                    billingData: billingData,
                                    invoiceId: this.invoice ? this.invoice.id : null,
                                    expirationDate: this.expirationDateBuild()
                                };

                                if (!this.data.ownerCompany) {
                                    // public pay
                                    // now public pay unavailable in this component
                                    // this.hasPendingRequest = true;
                                    // this.invoicePublicService.publicAuthorizeMethod(paymentObject).subscribe(res => {
                                    //     if (res) {
                                    //         this.hasPendingRequest = false;
                                    //         this.close(res);
                                    //     }
                                    // });
                                } else {
                                    // create credit card associated only with company
                                    this.hasPendingRequest = true;
                                    if (this.contactId) {
                                        this.paymentMethodsService
                                            .addCardForCompany(paymentObject, this.contactId)
                                            .pipe(takeUntil(this.unsubscribe))
                                            .subscribe((res) => {
                                                if (res) {
                                                    this.hasPendingRequest = false;
                                                    this.close(true);
                                                }
                                            });
                                    }
                                }
                            }
                        }
                    } else {
                        if (!this.invoice) {
                            const paymentObject = {
                                gatewayId: this.authorizeGateway?.value.id,
                                opaqueData: successResponse.opaqueData,
                                cardTitle: this.cardTitleBuild(),
                                billingData: billingData,
                                invoiceId: null,
                                expirationDate: this.expirationDateBuild()
                            };

                            // create credit card associated only with contact
                            this.hasPendingRequest = true;
                            if (this.moneySenderContactId) {
                                this.paymentMethodsService
                                    .addCardForContact(paymentObject, this.moneySenderContactId)
                                    .pipe(takeUntil(this.unsubscribe))
                                    .subscribe((res) => {
                                        if (res) {
                                            this.hasPendingRequest = false;
                                            this.close(true);
                                        }
                                    });
                            }
                        } else {
                            const paymentObject = {
                                gatewayId: this.invoiceMethods.find(
                                    (im) => im.payment_gateway?.type === 'authorize_net_merchant'
                                )?.payment_gateway?.payment_gateway_id,
                                payToPaymentId: this.invoiceMethods.find(
                                    (im) => im.payment_gateway?.type === 'authorize_net_merchant'
                                )?.payment_method_id,
                                opaqueData: successResponse.opaqueData,
                                cardTitle: this.cardTitleBuild(),
                                billingData: billingData,
                                invoiceId: this.invoice ? this.invoice.id : null,
                                expirationDate: this.expirationDateBuild()
                            };

                            if (!this.data.ownerCompany) {
                                // public pay
                                // now public pay unavailable in this component
                                // this.invoicePublicService.publicAuthorizeMethod(paymentObject).subscribe(res => {
                                //     if (res) {
                                //         this.close(res);
                                //     }
                                // });
                            } else {
                                // create credit card associated only with company
                                this.hasPendingRequest = true;
                                if (this.contactId) {
                                    this.paymentMethodsService
                                        .addCardForCompany(paymentObject, this.contactId)
                                        .pipe(takeUntil(this.unsubscribe))
                                        .subscribe((res) => {
                                            if (res) {
                                                this.hasPendingRequest = false;
                                                this.close(true);
                                            }
                                        });
                                }
                            }
                        }
                    }
                });
            }
        }
    }

    // savePaymentMethodByPlaid(paymentObjectForPlaid) {
    //     if (this.moneySenderProfileId) {
    //         this.paymentMethodsService.createDwollaPaymentMethodForProfileByPlaid(paymentObjectForPlaid, this.moneySenderProfileId)
    //             .pipe(takeUntil(this.unsubscribe))
    //             .subscribe(response => {
    //                 if (response) {
    //                     this.notificationsService.success(`Settings saved`);
    //                     this.close(true);
    //                 }
    //             });
    //     } else if (this.moneySenderContactId) {
    //         this.paymentMethodsService.createDwollaPaymentMethodForContactByPlaid(paymentObjectForPlaid, this.moneySenderContactId)
    //             .pipe(takeUntil(this.unsubscribe))
    //             .subscribe(response => {
    //                 if (response) {
    //                     this.notificationsService.success(`Settings saved`);
    //                     this.close(true);
    //                 }
    //             });
    //     }
    // }

    async getPublicKey(gatewayId: number) {
        return await this.invoicePublicService.getPublicKey(gatewayId).toPromise();
    }

    async getPlaidLinkToken(
        gatewayId: number,
        config: {products: Array<string>; linkCustomizationName: string; account_type: 'trust' | 'operating' | null}
    ) {
        return await this.gatewayService.getPlaidLinkToken(gatewayId, config).toPromise();
    }

    // async exchangePlaidToken(plaidToken, accountId) {
    //     return await this.paymentMethodsService.exchangePlaidToken(plaidToken, accountId).toPromise();
    // }

    // async createOrGetDwollaCustomerForProfile(customerData, profileId) {
    //     return await this.paymentMethodsService.createOrGetDwollaCustomerForProfile(customerData, profileId).toPromise();
    // }
    //
    // async createOrGetDwollaCustomerForContact(customerData, contactId) {
    //     return await this.paymentMethodsService.createOrGetDwollaCustomerForContact(customerData, contactId).toPromise();
    // }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

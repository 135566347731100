<mat-sidenav-container class="h-100" style="background-color: unset">
    <mat-sidenav
        #sidebarTransactions
        mode="over"
        position="end"
        style="background-color: #fff"
        [(opened)]="isSidenavOpen"
    >
        <app-statement-invoices-sidenav
            [invoices]="invoicesByContactId ? invoicesByContactId[activeContactId!] : []"
        ></app-statement-invoices-sidenav>
    </mat-sidenav>

    <section class="bg-white">
        <!------- Filters ------->
        <header class="d-flex align-items-center px-3 list-header">
            <h3 class="m-0 mr-1 d-flex align-items-end">Invoice Statements</h3>

            <i class="sp-spacer"></i>

            <app-date-range-picker
                class="mr-2 mt-2"
                [floatLabel]="'never'"
                (click)="$event.stopPropagation()"
                (dateEmitter)="setRange($event)"
                [maxDate]="range.controls.end_date.value"
                [minDate]="range.controls.start_date.value"
                [initialDateFrom]="initialDateFrom"
                [initialDateTo]="initialDateTo"
            ></app-date-range-picker>

            <app-company-compensation-combined-picker
                class="mr-2 mt-2"
                style="width: 180px"
                [availableTypes]="['contact', 'company_group']"
                [floatLabel]="'never'"
                [title]="'Customer / Group'"
                [only_compensation_groups]="false"
                [singleSelected]="true"
                [bold_style]="false"
                [nodesFA]="contactFilterCtrlArr"
            >
            </app-company-compensation-combined-picker>
        </header>

        <!------- Multi-selection toolbar ------->
        <app-multi-selection-toolbar
            [numberSelected]="selection.selected.length"
            toolbarMinWidth="800px"
            selectionName="Customer"
            *ngIf="selection.selected.length > 0"
            [hideSelectionDivider]="true"
        >
            <button class="mat-button" (click)="sendSummaries()">
                <span style="width: 18px; height: 18px" class="material-symbols-outlined action-button-icon">send</span>
                Send {{ selection.selected.length > 1 ? 'Statements' : 'Statement' }}
            </button>
        </app-multi-selection-toolbar>

        <!------- Table of invoices ------->
        <app-invoice-statement-table
            [dataSource]="dataSource"
            [selection]="selection"
            (sort)="changeSort($event)"
            (rowClick)="handleClickRow($event)"
        ></app-invoice-statement-table>
    </section>
</mat-sidenav-container>

import {Component, Input} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FocusAreaFormGroup, FocusAreaFormModel, GoalFormGroup} from '../../goals/common/model';

export class PickableGoalFormModel {
    pick?: string;
    id?: number;
    dynamic?: string;
    period?: string;
    title?: string;
    units?: string;
    type?: string;
    mandatory_type?: boolean;
    amount?: number;
    baseline_value?: number;
    start_tracking_date?: Date;
}

export class PickableGoalFormGroup extends UntypedFormGroup {
    public controls: {
        pick: UntypedFormControl;
        id: UntypedFormControl;
        dynamic: UntypedFormControl;
        period: UntypedFormControl;
        title: UntypedFormControl;
        units: UntypedFormControl;
        type: UntypedFormControl;
        amount: UntypedFormControl;
        mandatory_type: UntypedFormControl;
        baseline_value: UntypedFormControl;
        start_tracking_date: UntypedFormControl;
    };

    constructor() {
        const controls = {
            pick: new UntypedFormControl(),
            id: new UntypedFormControl(),
            dynamic: new UntypedFormControl(),
            period: new UntypedFormControl(),
            title: new UntypedFormControl(),
            units: new UntypedFormControl(),
            type: new UntypedFormControl('progress'),
            amount: new UntypedFormControl(),
            mandatory_type: new UntypedFormControl(),
            baseline_value: new UntypedFormControl(),
            start_tracking_date: new UntypedFormControl()
        };
        super(controls);
        this.controls = controls;

        this.controls.id.disable();
        this.controls.dynamic.disable();
        this.controls.period.disable();
        this.controls.title.disable();
        this.controls.units.disable();
        this.controls.type.disable();
        this.controls.amount.disable();
        this.controls.baseline_value.disable();
        this.controls.start_tracking_date.disable();
        this.applyEvents();
    }

    applyEvents() {
        this.controls.mandatory_type.valueChanges.subscribe((mandatory) => {
            if (mandatory === 'mandatory' && this.controls.pick.value) {
                this.controls.pick.disable();
            } else {
                this.controls.pick.enable();
            }
        });
    }

    getRawValue(): PickableGoalFormModel {
        return <PickableGoalFormModel>super.getRawValue();
    }

    patchValue(value: PickableGoalFormModel) {
        super.patchValue(value);
    }
}

export class PickableGoalsFormArray extends UntypedFormArray {
    public controls: PickableGoalFormGroup[];

    constructor(pickableGoal?: PickableGoalFormGroup[]) {
        super(pickableGoal || []);
        this.controls = pickableGoal || [];
    }
}

export class PickableGoalsListFormGroupArray extends UntypedFormArray {
    public controls: PickableGoalsListFormGroup[];

    constructor(pickableGoalsList?: PickableGoalsListFormGroup[]) {
        super(pickableGoalsList || []);
        this.controls = pickableGoalsList || [];
    }
}

export class PickableGoalsListModel {
    title?: string;
    id?: number;
    enumerable?: PickableGoalFormModel[];
}

export class PickableGoalsListFormGroup extends UntypedFormGroup {
    public controls: {
        title: UntypedFormControl;
        id: UntypedFormControl;
        enumerable: PickableGoalsFormArray;
    };

    constructor() {
        const controls = {
            title: new UntypedFormControl(),
            id: new UntypedFormControl(),
            enumerable: new PickableGoalsFormArray([])
        };
        super(controls);
        this.controls = controls;
    }

    applyEvents() {}

    getRawValue(): PickableGoalsListModel {
        const resp = <PickableGoalsListModel>super.getRawValue();
        resp.enumerable = resp.enumerable?.filter(
            (item) => item.period || item.amount || item.title || item.units || item.dynamic
        );
        return resp;
    }

    patchValue(value: PickableGoalsListModel) {
        // console.log(this.value);
        if (value.enumerable && Array.isArray(value.enumerable)) {
            this.controls.enumerable = new PickableGoalsFormArray(
                value.enumerable.map((item) => {
                    const resp = new PickableGoalFormGroup();
                    resp.patchValue(item);
                    return resp;
                })
            );
        }
        this.applyEvents();
        super.patchValue(value);
    }
}

export class UserSetupFormModel {
    personal_goals?: FocusAreaFormModel;
    by_group?: PickableGoalsListModel[];
    submit?: boolean;
}

export class UserSetupFormGroup extends UntypedFormGroup {
    public controls: {
        by_group: PickableGoalsListFormGroupArray;
        personal_goals: FocusAreaFormGroup;
        submit: UntypedFormControl;
    };

    constructor() {
        const controls = {
            personal_goals: new FocusAreaFormGroup(),
            by_group: new PickableGoalsListFormGroupArray(),
            submit: new UntypedFormControl()
        };
        super(controls);
        this.controls = controls;
    }

    applyEvents() {}

    getRawValue(): UserSetupFormModel {
        return <UserSetupFormModel>super.getRawValue();
    }

    patchValue(value: UserSetupFormModel) {
        if (value.personal_goals) {
            this.controls.personal_goals = new FocusAreaFormGroup();
            this.controls.personal_goals.patchValue(value.personal_goals);
        }

        if (value.by_group && Array.isArray(value.by_group)) {
            this.controls.by_group = new PickableGoalsListFormGroupArray(
                value.by_group.map((item) => {
                    // console.log(item);
                    const resp = new PickableGoalsListFormGroup();
                    resp.patchValue(item);
                    return resp;
                })
            );
        }

        this.applyEvents();
        super.patchValue(value);
    }
}

@Component({
    selector: 'app-user-goals',
    styleUrls: ['used-goals.component.scss'],
    templateUrl: './user-goals.component.html'
})
export class UserGoalsComponent {
    @Input() formGroup: UserSetupFormGroup = new UserSetupFormGroup();

    cloneCompanyGoal(goal: any) {
        const newGoal = new GoalFormGroup();
        newGoal.patchValue(goal.getRawValue());
        newGoal.enable();
        newGoal.controls.id.patchValue(undefined);
        newGoal.controls.id.disable();
        this.formGroup.controls.personal_goals.controls.enumerable.controls.push(newGoal);
    }

    addProgressGoal(focusArea: FocusAreaFormGroup, dynamic: string = 'increase') {
        const goalFormGroup = new GoalFormGroup();
        goalFormGroup.patchValue({
            dynamic: dynamic,
            type: 'progress'
        });

        focusArea.controls.enumerable.push(goalFormGroup);
    }

    addGeneralGoal(focusArea: FocusAreaFormGroup, dynamic: string = '') {
        const goalFormGroup = new GoalFormGroup();
        goalFormGroup.patchValue({type: 'general'});
        focusArea.controls.enumerable.push(goalFormGroup);
    }

    deleteGoal(focusArea?: FocusAreaFormGroup, goalIndex?: number) {
        if (focusArea && goalIndex) {
            focusArea.controls.enumerable.controls.splice(goalIndex, 1);
        }
    }

    splitGoal() {
        console.log('SPLIT GOAL');
    }

    createProject() {
        console.log('CREATE PROJECT');
    }

    makeGeneralGoal(goal: GoalFormGroup) {
        goal.controls.type.patchValue('general');
    }

    makeProgressGoal(goal: GoalFormGroup) {
        goal.controls.type.patchValue('progress');
    }
}

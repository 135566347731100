import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GoogleChartDirective} from '../../components/GoogleChart.directive';
import {DealsViewPickerComponent} from './components/deals-view-picker/deals-view-picker.component';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {NgxChartsModule} from '@swimlane/ngx-charts';

import {MomentModule} from 'ngx-moment';

import {ProfilesPickerComponent} from './components/profiles-picker/profiles-picker.component';
import {RbacModule} from '../rbac/rbac.module';
import {DealsViewPickerSelectComponent} from './components/deals-view-picker/deals-view-picker-select.component';
import {DealsViewPickerContainerComponent} from './components/deals-view-picker-container/deals-view-picker-container.component';
import {DealsViewPickerContainerDialogComponent} from './components/deals-view-picker-container/deals-view-picker-container.dialog';
import {
    ExpandablePickerComponent,
    ExpandablePickerRowComponent
} from './components/expandable-picker/expandable-picker.component';
import {ListPickerComponent, ListPickerItemComponent} from './components/list-picker/list-picker.component';
import {FromSnakeCasePipe} from '../../pipes/from-snake-case.pipe';
import {FromCamelCasePipe} from '../../pipes/from-camel-case.pipe';
import {DateFromNumberPipe} from '../../pipes/date-from-number.pipe';
import {NgForInDirective} from '../../directives/ng-for-in.directive';
import {ShipperContactsService} from '../../services/api/shipper.contacts.service';
import {ContactTypeService} from '../../services/api/contact-type.service';
import {CompanySettingsService} from '../../services/api/companies/company-settings.service';
import {ValuesGroupsService} from '../../services/api/analytics/values-groups.service';
import {CompanyLocationsService} from '../../services/api/companies/company-locations.service';
import {ConfirmComponent} from 'app/layouts/confirm/confirm.component';
import {AddressPickerDialogComponent} from 'app/layouts/address-picker-dialog';
import {InputDialogComponent} from 'app/layouts/input-dialog/input-dialog.component';
import {CompaniesPickerComponent} from 'app/layouts/companies-picker';
// import { SharedPickerComponent } from 'app/layouts/shared-picker';
import {FinancialNodeComponent} from './components/financial-node/financial-node.component';
import {MoneyCollectionComponent} from './components/money-collection/money-collection.component';
import {DateTimePickerComponent} from '../../components/date-time-picker/date-time-picker.component';
import {RootDialogService} from 'app/services/root-dialog.service';
import {CompaniesService} from 'app/services/api/companies.service';
import {TimeRangePickerComponent} from '../../components/time-range-picker/time-range-picker.component';
import {ExpensesCollectionComponent} from './components/money-collection/expenses-collection.component';
import {RecurringInvoicesService} from 'app/services/api/finance/recurring-invoices.service';
import {InvoicesService} from 'app/services/api/finance/invoices.service';
import {TransactionsService} from 'app/services/api/finance/transactions.service';
import {TransactionsExternalService} from 'app/services/api/finance/transactions-external.service';
import {ProductsService} from 'app/services/api/finance/products.service';
import {BankingService} from 'app/services/api/finance/banking.service';
import {LedgerAccountService} from 'app/services/api/finance/ledger-accounts.service';
import {JournalsService} from 'app/services/api/finance/journals.service';
import {JournalsTemplatesService} from '../../services/api/finance/journal-templates.service';
import {RecurringJournalsService} from '../../services/api/finance/recurring-journals.service';
import {BillsService} from 'app/services/api/finance/bills.service';
import {AddCreditCardDialogComponent} from '../../layouts/cards-dialogs/add-credit-card-dialog/add-credit-card-dialog.component';
import {FilterForNgForPipe} from '../../pipes/filter-for-ng-for.pipe';
import {FinancialTransferComponent} from './components/money-collection/financial-transfer.component';
import {TransfersCollectionComponent} from './components/money-collection/transfers-collection.component';
import {TransfersReferralCollectionComponent} from './components/money-collection/transfers-referral-collection.component';
import {DealParticipantsComponent} from './components/deal-participants/deal-participants.component';
import {CompanyInnerNavComponent} from 'app/components/company-inner-nav.component';
import {AuditLogRowComponent} from '../deals/components/deal/common/audit-log/audit-log-row-component/audit-log-row.component';
import {CombinedCompensationProfilePickerComponent} from '../account-info/compensation/components/combined-compensation-profile-picker/combined-compensation-profile-picker.component';
import {DialogConfirmationComponent} from './components/dialog-confirmation/dialog-confirmation.dialog';
import {DeductedPaymentConfirmationComponent} from './components/calculations/deducted-payment-confirmation/deducted-payment-confirmation.component';
import {DeductedLoanPaymentConfirmationComponent} from './components/calculations/deducted-loan-payment-confirmation/deducted-loan-payment-confirmation.component';
import {LedgerAccountSelectorComponent} from './components/ledger-account-selector/ledger-account-selector.component';
import {ProductServiceSelectorComponent} from './components/product-service-selector/product-service-selector.component';
import {NotificationsComponent} from '../notifications/notifications.component';
import {ConfirmNotificationDialogComponent} from '../notifications/kinds/confirm.notification';
import {ReactionNotificationCardComponent} from '../notifications/kinds/reaction.notification';
import {NotificationsServiceZipi} from '../notifications/notifications.service';
import {DeleteAccountComponent} from '../../layouts/delete-account/delete-account.component';
import {DatePickerComponent} from '../../components/date-picker/date-picker.component';
import {ApplyCreditToInvoicesDialogComponent} from './components/credit-apply/apply-credit-to-invoices-dialog/apply-credit-to-invoices-dialog.component';
import {ApplyCreditToBillsDialogComponent} from './components/credit-apply/apply-credit-to-bills-dialog/apply-credit-to-bills-dialog.component';
import {OverrideDialogComponent} from './components/money-collection/override.dialog.component';
import {CheckPrintConfirmDialogComponent} from './components/check-print-confirm-dialog/check-print-confirm.dialog';
import {DealPickerComponent} from './components/deal-picker/deal-picker.component';
import {DealMultiPickerComponent} from './components/deal-multi-picker/deal-multi-picker.component';
import {AddMyCreditCardForContactDialogComponent} from '../../layouts/cards-dialogs/add-my-credit-card-for-contact-dialog/add-my-credit-card-for-contact-dialog.component';
import {DocumentsService} from 'app/services/documents.service';
import {EditUserRolesComponent} from '../account-info/users-licenses/edit-user/edit-user-roles.component';
import {SettingsInnerNavComponent} from '../../components/settings-inner-nav.component';
import {LicensesService} from '../../services/licenses.service';
import {ValuePickerComponent} from './components/value-picker/value-picker.component';
import {SubCompaniesPanelComponent} from './components/sub-companies-panel/sub-companies-panel.component';
import {DealFilterComponent} from './components/deal-filter/deal-filter.component';
import {UnsavedChangesComponent} from '../../layouts/unsaved-changes/unsaved-changes.component';
import {ToggleButtonComponent} from './components/toggle-button.component';
import {LicenseReloadService} from '../../services/license-reload.service';
import {InfiniteScrollComponent} from '../../components/infinite-scroll/infinite-scroll.component';
import {DealCommissionCategorizationNotesComponent} from '../ui-mods/components/deal-commission-categorization-notes.component';
import {ReloadNotificationComponent} from '../notifications/kinds/reload.notification';
import {LazyLoadingComponent} from '../../components/lazy-loading/lazy-loading.component';
import {SelectPayToMethodsComponent} from './components/finance/select-pay-to-methods/select-pay-to-methods.component';
import {EntityPickerAdvancedComponent} from '../../components/entity-picker-advanced/entity-picker-advanced.component';
import {CompensationProfilePickerComponent} from '../../components/compensation-profile-picker/compensation-profile-picker.component';
import {SearchEntitiesSource} from '../../services/sources/search-entities.source';
import {ContactClassService} from '../../services/api/contact-class.service';
import {IsNumberPipe} from '../../pipes/is-number.pipe';
import {ReduceDoubledStringPipe} from '../../pipes/reduce-doubled-Strings.pipe';
import {WildcardPickerComponent} from './components/wildcard-picker/wildcard-picker.component';
import {PhoneMaskPipe} from '../../pipes/phone-mask.pipe';
import {EditTitleComponent, EditTitlePopupComponent} from './components/edit-title-popup/edit-title-popup.component';
import {PayAtClosingPickerComponent} from './components/pay-at-closing-picker/pay-at-closing-picker.component';
import {RecurringBillsService} from '../../services/api/finance/recurring-bills.service';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DateRangePickerComponent} from '../../components/date-range-picker/date-range-picker.component';
import {PromptComponent} from '../../layouts/prompt/prompt.component';
import {FindItemByIdInArrayPipe} from '../../pipes/find-item-by-id-In-array.pipe';
import {EffectsModule} from '@ngrx/effects';
import {CompanyWideEffectsService} from '../../store/company-wide/company-wide.effects';
import {StoreModule} from '@ngrx/store';
import {companyWideReducer} from '../../store/company-wide/company-wide.reducer';
import {RoundToDecimalPipe} from '../../pipes/round-to-decimal.pipe';
import {EntityPickerChipsComponent} from '../../components/entity-picker-advanced/entity-picker-chips.component';
import {FinancialDetailsComponent} from './components/financial-node/financial-details.component';
import {AutomationService} from '../../services/automation.service';
import {AlertComponent} from '../../layouts/alert/alert.component';
import {DivisionPickerComponent} from '../../components/division-picker/division-picker.component';
import {UnsavedChangesNewDialogComponent} from '../../layouts/unsaved-changes-new-dialog/unsaved-changes-new-dialog.component';
import {TrimWhitespaceDirective} from 'app/directives/trim.directive';
import {MultiSelectionToolbarComponent} from '../../components/multi-selection-toolbar/multi-selection-toolbar.component';
import {CustomPopoverComponent} from '../../components/custom-popover/custom-popover.component';
import {QuickBooksService} from '../../services/api/addon/quickbooks/quickbooks.service';
import {MaskitoModule} from '@maskito/angular';
import {PdfViewerModule} from 'ng2-pdf-viewer';

@NgModule({
    imports: [
        CommonModule,
        // BrowserModule,
        FormsModule,
        // MaterialModule,
        ReactiveFormsModule,
        RbacModule,
        RouterModule,
        MatRadioModule,
        MatSelectModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatMenuModule,
        MatGridListModule,
        MatChipsModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatCardModule,
        MatProgressBarModule,
        MatListModule,
        MatFormFieldModule,
        MatIconModule,
        MatToolbarModule,
        MatTooltipModule,
        MatButtonModule,
        MatSidenavModule,
        MatExpansionModule,
        MatStepperModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatButtonToggleModule,
        MaskitoModule,
        MomentModule,
        ScrollingModule,
        ClipboardModule,
        StoreModule.forFeature('companyWide', companyWideReducer),
        EffectsModule.forFeature([CompanyWideEffectsService]),
        PdfViewerModule
    ],
    exports: [
        DateRangePickerComponent,
        ToggleButtonComponent,
        OverrideDialogComponent,
        DealsViewPickerComponent,
        DealsViewPickerSelectComponent,
        ListPickerComponent,
        ListPickerItemComponent,
        GoogleChartDirective,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MomentModule,
        MaskitoModule,
        ClipboardModule,
        CompanyInnerNavComponent,
        SettingsInnerNavComponent,
        TransfersReferralCollectionComponent,
        TransfersCollectionComponent,
        FinancialTransferComponent,
        ProfilesPickerComponent,
        ExpandablePickerComponent,
        FinancialNodeComponent,
        FinancialDetailsComponent,
        MoneyCollectionComponent,
        ExpensesCollectionComponent,
        DealParticipantsComponent,
        HttpClientModule,
        DateFromNumberPipe,
        FromSnakeCasePipe,
        FromCamelCasePipe,
        FilterForNgForPipe,
        FindItemByIdInArrayPipe,
        IsNumberPipe,
        ReduceDoubledStringPipe,
        PhoneMaskPipe,
        NgForInDirective,
        TrimWhitespaceDirective,
        RoundToDecimalPipe,
        LedgerAccountSelectorComponent,
        ProductServiceSelectorComponent,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatInputModule,
        MatCardModule,
        MatSidenavModule,
        MatListModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatGridListModule,
        MatTabsModule,
        MatTableModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatSelectModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatMenuModule,
        MatDialogModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        MatProgressBarModule,
        MatPaginatorModule,
        MatSortModule,
        MatButtonToggleModule,
        MatBadgeModule,
        ScrollingModule,
        DragDropModule,
        FromSnakeCasePipe,
        DateFromNumberPipe,
        FilterForNgForPipe,
        IsNumberPipe,
        NgForInDirective,
        MomentModule,
        NotificationsComponent,
        ConfirmNotificationDialogComponent,
        ReactionNotificationCardComponent,
        ReloadNotificationComponent,
        NgxChartsModule,
        DatePickerComponent,
        DateTimePickerComponent,
        TimeRangePickerComponent,
        AddCreditCardDialogComponent,
        AddMyCreditCardForContactDialogComponent,
        RbacModule,
        AuditLogRowComponent,
        CombinedCompensationProfilePickerComponent,
        EntityPickerAdvancedComponent,
        EntityPickerChipsComponent,
        CompensationProfilePickerComponent,
        DialogConfirmationComponent,
        EditUserRolesComponent,
        ApplyCreditToInvoicesDialogComponent,
        ApplyCreditToBillsDialogComponent,
        CheckPrintConfirmDialogComponent,
        DealPickerComponent,
        DealMultiPickerComponent,
        SubCompaniesPanelComponent,
        DealFilterComponent,
        InfiniteScrollComponent,
        DealCommissionCategorizationNotesComponent,
        LazyLoadingComponent,
        SelectPayToMethodsComponent,
        ValuePickerComponent,
        WildcardPickerComponent,
        EditTitleComponent,
        EditTitlePopupComponent,
        PayAtClosingPickerComponent,
        DivisionPickerComponent,
        MultiSelectionToolbarComponent,
        CustomPopoverComponent,
        PdfViewerModule
    ],
    declarations: [
        DateRangePickerComponent,
        ToggleButtonComponent,
        OverrideDialogComponent,
        DealsViewPickerComponent,
        DealsViewPickerSelectComponent,
        DealsViewPickerContainerComponent,
        DealsViewPickerContainerDialogComponent,
        ListPickerComponent,
        ListPickerItemComponent,
        GoogleChartDirective,
        ProfilesPickerComponent,
        ExpandablePickerComponent,
        ExpandablePickerRowComponent,
        TrimWhitespaceDirective,
        DealParticipantsComponent,
        TransfersCollectionComponent,
        TransfersReferralCollectionComponent,
        FinancialTransferComponent,
        FinancialNodeComponent,
        FinancialDetailsComponent,
        MoneyCollectionComponent,
        ExpensesCollectionComponent,
        CompaniesPickerComponent,
        ConfirmComponent,
        AlertComponent,
        PromptComponent,
        UnsavedChangesComponent,
        UnsavedChangesNewDialogComponent,
        DeleteAccountComponent,
        // SharedPickerComponent,
        AddressPickerDialogComponent,
        InputDialogComponent,
        DatePickerComponent,
        DateTimePickerComponent,
        TimeRangePickerComponent,
        AddCreditCardDialogComponent,
        AddMyCreditCardForContactDialogComponent,
        NotificationsComponent,
        ConfirmNotificationDialogComponent,
        ReactionNotificationCardComponent,
        ReloadNotificationComponent,
        CompanyInnerNavComponent,
        SettingsInnerNavComponent,
        FromSnakeCasePipe,
        FromCamelCasePipe,
        DateFromNumberPipe,
        FilterForNgForPipe,
        FindItemByIdInArrayPipe,
        IsNumberPipe,
        ReduceDoubledStringPipe,
        PhoneMaskPipe,
        RoundToDecimalPipe,
        NgForInDirective,
        AuditLogRowComponent,
        CombinedCompensationProfilePickerComponent,
        EntityPickerAdvancedComponent,
        EntityPickerChipsComponent,
        CompensationProfilePickerComponent,
        DialogConfirmationComponent,
        DeductedPaymentConfirmationComponent,
        DeductedLoanPaymentConfirmationComponent,
        LedgerAccountSelectorComponent,
        ProductServiceSelectorComponent,
        ApplyCreditToInvoicesDialogComponent,
        ApplyCreditToBillsDialogComponent,
        EditUserRolesComponent,
        CheckPrintConfirmDialogComponent,
        DealPickerComponent,
        DealMultiPickerComponent,
        ValuePickerComponent,
        WildcardPickerComponent,
        SubCompaniesPanelComponent,
        DealFilterComponent,
        InfiniteScrollComponent,
        DealCommissionCategorizationNotesComponent,
        LazyLoadingComponent,
        SelectPayToMethodsComponent,
        EditTitleComponent,
        EditTitlePopupComponent,
        PayAtClosingPickerComponent,
        DivisionPickerComponent,
        MultiSelectionToolbarComponent,
        CustomPopoverComponent
    ],
    providers: [
        ShipperContactsService,
        ContactTypeService,
        CompanySettingsService,
        ValuesGroupsService,
        // DashboardsService,
        CompanyLocationsService,
        CompaniesService,
        RootDialogService,
        RecurringInvoicesService,
        RecurringBillsService,
        InvoicesService,
        BillsService,
        ProductsService,
        LedgerAccountService,
        JournalsService,
        JournalsTemplatesService,
        RecurringJournalsService,
        CurrencyPipe,
        TransactionsService,
        TransactionsExternalService,
        BankingService,
        NotificationsServiceZipi,
        DocumentsService,
        LicensesService,
        LicenseReloadService,
        SearchEntitiesSource,
        ContactClassService,
        AutomationService,
        QuickBooksService
    ],
    bootstrap: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}

import {FinancialElementModel} from '../account-info/compensation/models/financial-element.model';
import {IGroupReportsTypes, IReportSetting} from '@cyberco-nodejs/zipi-typings';

export const availableReportType: {[key: string]: string} = {
    tracking: 'tracking',
    product: 'product',
    contact: 'contact',
    cap: 'cap',
    ten99: 'ten99',
    ten99_nec: 'ten99_nec',
    ten99_int: 'ten99_int',
    ten99_misc: 'ten99_misc',
    summary: 'summary',
    detailed: 'detailed',
    sob: 'sob',
    commission: 'commission',
    profit_loss: 'profit_loss',
    balance_sheet: 'balance_sheet',
    account_transactions: 'account_transactions',
    journal: 'journal',
    general_ledger: 'general_ledger',
    account_type: 'account_type',
    trial_balance: 'trial_balance', // accounting trial balance
    summary_trial_balance: 'summary_trial_balance',
    cash_flow: 'cash_flow',
    trust_reconciliation: 'trust_reconciliation',
    recurring_invoice: 'recurring_invoice',
    ap_aging: 'ap_aging',
    ar_aging: 'ar_aging',
    'avg-sales': 'avg-sales',
    'agent-performance': 'agent-performance',
    membership: 'membership',
    // user level
    summary_user: 'summary_user'
};

export const generalReportTypes: Array<{title: string; value: string}> = [
    {
        title: 'Progress Reports',
        value: availableReportType.tracking
    },
    {
        title: 'Product',
        value: availableReportType.product
    },
    {
        title: 'Contact',
        value: availableReportType.contact
    },
    {
        title: 'Cap',
        value: availableReportType.cap
    },
    {
        title: 'Membership',
        value: availableReportType.membership
    }
];

export const dealReportTypes: Array<{title: string; value: string}> = [
    {
        title: 'Summary Transactions',
        value: availableReportType.summary
    },
    {
        title: 'Detailed Transactions',
        value: availableReportType.detailed
    },
    {
        title: 'Source of Business',
        value: availableReportType.sob
    },
    {
        title: 'Commission Expense',
        value: availableReportType.commission
    },
    {
        title: 'Average Sales',
        value: availableReportType['avg-sales']
    },
    {
        title: 'Agent Performance',
        value: availableReportType['agent-performance']
    }
];

export const dealUserReportTypes: Array<{title: string; value: string}> = [
    {
        title: 'Transactions',
        value: availableReportType.summary_user
    }
];

export const financialReportsTypes: Array<{title: string; value: string; tooltip?: string}> = [
    {
        title: 'Income Statement',
        value: availableReportType.profit_loss
    },
    {
        title: 'Balance Sheet',
        value: availableReportType.balance_sheet
    },
    {
        title: 'Account Transactions',
        value: availableReportType.account_transactions
    },
    {
        title: 'Journal',
        value: availableReportType.journal
    },
    {
        title: 'General Ledger',
        value: availableReportType.general_ledger
    },
    {
        title: 'Account Type Summary',
        value: availableReportType.account_type
    },
    {
        title: 'Accounting Trial Balance',
        value: availableReportType.trial_balance
    },
    {
        title: 'Summary Trial Balance',
        value: availableReportType.summary_trial_balance
    },
    {
        title: 'Trust Reconciliation',
        value: availableReportType.trust_reconciliation
    },
    {
        title: 'Recurring Invoice',
        value: availableReportType.recurring_invoice
    },
    {
        title: 'Cash Flow Statement',
        value: availableReportType.cash_flow
    },
    {
        title: '1099-NEC',
        value: availableReportType.ten99
    },
    {
        title: '1099-NEC',
        value: availableReportType.ten99_nec,
        tooltip: 'Freelancer or contractor income'
    },
    {
        title: '1099-INT',
        value: availableReportType.ten99_int,
        tooltip: 'Interest received by a person or entity'
    },
    {
        title: '1099-MISC',
        value: availableReportType.ten99_misc,
        tooltip: 'Rent, prizes, legal settlements, and other awards'
    },
    {
        title: 'A/P Aging',
        value: availableReportType.ap_aging
    },
    {
        title: 'A/R Aging',
        value: availableReportType.ar_aging
    }
];

export const reportsTypes: Array<{title: string; value: string}> = generalReportTypes.concat(
    dealReportTypes,
    financialReportsTypes,
    dealUserReportTypes
);

export const reportLevelBasedOnReportType: {[key: string]: string} = (() => {
    const result: {[key: string]: string} = {};

    generalReportTypes.concat(dealReportTypes, financialReportsTypes).forEach((item) => {
        result[item.value] = 'company';
    });

    dealUserReportTypes.forEach((item) => {
        result[item.value] = 'user';
    });
    return result;
})();

export const groupReportsTypes: IGroupReportsTypes[] = [
    {
        name: 'General Reports',
        value: 'general_company',
        group: 'company',
        reports: generalReportTypes,
        permissionRule: {reports__view_general_reports: true}
    },
    {
        name: 'Deal Reports',
        value: 'deal_company',
        group: 'company',
        reports: dealReportTypes,
        permissionRule: {reports__view_deal_reports: true}
    },
    {
        name: 'Financial Reports',
        value: 'financial',
        group: 'company',
        reports: financialReportsTypes,
        permissionRule: {reports__view_financial_reports: true}
    },
    {
        name: 'General Reports',
        value: 'general_user',
        group: 'user',
        reports: [],
        permissionRule: {reports__view_general_reports__user: true}
    },
    {
        name: 'Deal Reports',
        value: 'deal_user',
        group: 'user',
        reports: dealUserReportTypes,
        permissionRule: {reports__view_deal_reports__user: true}
    }
];

type TimePeriod = IReportSetting['time_period'];

export const timePeriodObj = {
    today: {
        title: 'Today',
        value: 'today' as const
    },
    yesterday: {
        title: 'Yesterday',
        value: 'yesterday' as const
    },
    this_week: {
        title: 'Current Week',
        value: 'this_week' as const
    },
    this_month: {
        title: 'Current Month',
        value: 'this_month' as const
    },
    this_quarter: {
        title: 'Current Quarter',
        value: 'this_quarter' as const
    },
    this_year: {
        title: 'Current Year',
        value: 'this_year' as const
    },
    last_week: {
        title: 'Last Week',
        value: 'last_week' as const
    },
    last_month: {
        title: 'Last Month',
        value: 'last_month' as const
    },
    last_quarter: {
        title: 'Last Quarter',
        value: 'last_quarter' as const
    },
    last_year: {
        title: 'Last Year',
        value: 'last_year' as const
    },
    date_range: {
        title: 'Date Range',
        value: 'date_range' as const
    }
};

export const timePeriods: Array<{title: string; value: TimePeriod}> = [
    timePeriodObj.today,
    timePeriodObj.yesterday,
    timePeriodObj.this_week,
    timePeriodObj.this_month,
    timePeriodObj.this_quarter,
    timePeriodObj.this_year,
    timePeriodObj.last_week,
    timePeriodObj.last_month,
    timePeriodObj.last_quarter,
    timePeriodObj.last_year,
    timePeriodObj.date_range
];

export const financialTimePeriods: Array<{title: string; value: TimePeriod}> = [
    timePeriodObj.today,
    timePeriodObj.yesterday,
    timePeriodObj.this_week,
    timePeriodObj.this_month,
    timePeriodObj.this_quarter,
    timePeriodObj.this_year,
    timePeriodObj.last_week,
    timePeriodObj.last_month,
    timePeriodObj.last_quarter,
    timePeriodObj.last_year,
    timePeriodObj.date_range
];

export const ten99TimePeriods: Array<{title: string; value: TimePeriod}> = [
    timePeriodObj.this_year,
    timePeriodObj.last_year,
    timePeriodObj.date_range
];

export const rangedTimePeriods: Array<{title: string; value: TimePeriod}> = [
    timePeriodObj.this_week,
    timePeriodObj.this_month,
    timePeriodObj.this_quarter,
    timePeriodObj.this_year,
    timePeriodObj.last_week,
    timePeriodObj.last_month,
    timePeriodObj.last_quarter,
    timePeriodObj.last_year,
    timePeriodObj.date_range
];

export const dateTypesObj = {
    closing_date: {
        title: 'Closing Date',
        value: 'closing_date'
    },
    paid_date_for_company: {
        title: 'Paid Date for Company',
        value: 'paid_date_for_company'
    },
    approval_date: {
        title: 'Approval Date',
        value: 'approval_date'
    },
    acceptance_date: {
        title: 'Acceptance Date',
        value: 'acceptance_date'
    },
    listing_date: {
        title: 'Listing Date',
        value: 'listing_date'
    },
    contract_date: {
        title: 'Contract Date',
        value: 'contract_date'
    },
    expiration_date: {
        title: 'Expiration Date',
        value: 'expiration_date'
    }
    // paid_date_for_agent: {
    //     title: 'Paid Date for Agent',
    //     value: 'paid_date_for_agent'
    // },
};
export const dateTypesArr: Array<{title: string; value: string}> = Object.values(dateTypesObj);

export const compareToPeriodObj = {
    previous_period: {
        title: 'Previous Period',
        value: 'previous_period'
    },
    similar_period_of_previous_year: {
        title: 'Similar Period of Previous Year',
        value: 'similar_period_of_previous_year'
    },
    previous_year: {
        title: 'Previous Year',
        value: 'previous_year'
    },
    year_to_date: {
        title: 'Year-to-date',
        value: 'year_to_date'
    },
    previous_year_to_date: {
        title: 'Previous Year-to-date',
        value: 'previous_year_to_date'
    }
};
export const compareToPeriodArr: Array<{title: string; value: string}> = Object.values(compareToPeriodObj);

export const transactionTypeList: Array<{title: string; value: string}> = [
    {
        title: 'Listing (Sale)',
        value: 'seller'
    },
    {
        title: 'Buyer (Sale)',
        value: 'buyer'
    },
    {
        title: 'Landlord (Rental)',
        value: 'rental_owner'
    },
    {
        title: 'Tenant (Rental)',
        value: 'rental_tenant'
    },
    {
        title: 'Referral',
        value: 'referral'
    },
    {
        title: 'Broker Price Opinion',
        value: 'broker_price_opinion'
    }
];

export const transactionTypeListByName: {[key: string]: any} = {
    buyer: 'buyer',
    seller: 'seller',
    referral: 'referral',
    rental_tenant: 'rental_tenant',
    rental_owner: 'rental_owner'
};

export const transactionStatusListByName: {[key: string]: any} = {
    active: 'active',
    pending: 'pending',
    closed: 'closed',
    processed: 'processed',
    opportunity: 'opportunity',
    cancelled: 'cancelled'
};

export const listOfTransactionStatus: Array<{title: string; value: string; order: number}> = [
    {
        title: 'Opportunity',
        value: 'opportunity',
        order: 1
    },
    {
        title: 'Active',
        value: 'active',
        order: 2
    },
    {
        title: 'Pending',
        value: 'pending',
        order: 3
    },
    {
        title: 'Closed',
        value: 'closed',
        order: 4
    },
    {
        title: 'Processed',
        value: 'processed',
        order: 5
    },
    {
        title: 'Cancelled',
        value: 'cancelled',
        order: 6
    }
];

export const listOfPropertyClass: Array<{title: string; value: string; order: number}> = [
    {
        title: 'Residential',
        value: 'residential',
        order: 1
    },
    {
        title: 'Commercial',
        value: 'commercial',
        order: 2
    }
];

export const listOfLayout: Array<{title: string; value: string; icon: string}> = [
    {
        title: 'Table View',
        value: 'table',
        icon: 'table_chart'
    },
    {
        title: 'Pie Chart',
        value: 'pie',
        icon: 'pie_chart'
    },
    {
        title: 'Line Chart',
        value: 'line',
        icon: 'timeline'
    },
    {
        title: 'Bar Chart',
        value: 'bar',
        icon: 'bar_chart'
    }
];

export const listOfLayoutValue: object = {
    table: 'Table View',
    pie: 'Pie Chart',
    line: 'Line Chart',
    bar: 'Bar Chart'
};

export const listOfSummarized: Array<{title: string; value: string}> = [
    {
        title: 'Daily',
        value: 'daily'
    },
    {
        title: 'Weekly',
        value: 'weekly'
    },
    {
        title: 'Monthly',
        value: 'monthly'
    },
    {
        title: 'Quarterly',
        value: 'quarterly'
    },
    {
        title: 'Annually',
        value: 'annually'
    }
];

export const listOfGrouping: Array<{title: string; value: string}> = [
    {
        title: 'By Type',
        value: 'type'
    },
    {
        title: 'By Status',
        value: 'status'
    },
    {
        title: 'By Type and Status',
        value: 'type_status'
    }
];

export const listOfReportBasis: Array<{title: string; value: string}> = [
    {
        title: 'Accrual',
        value: 'accrual'
    },
    {
        title: 'Cash',
        value: 'cash'
    }
];

export const listOfExportOptions: Array<{title: string; value: string}> = [
    {
        title: 'Export Detailed Report',
        value: 'detailed'
    },
    {
        title: 'Export Summary Report',
        value: 'summary'
    }
];

export const listOfInDirectoryOptions: Array<{title: string; value: string}> = [
    {
        title: 'Directory Only',
        value: 'directory_only'
    },
    {
        title: 'Contacts Only',
        value: 'contacts_only'
    },
    {
        title: 'Any',
        value: 'any'
    }
];

export const listOfStatusInDirectoryOptions: Array<{title: string; value: string}> = [
    {
        title: 'Active',
        value: 'active'
    },
    {
        title: 'Invited',
        value: 'invited'
    },
    {
        title: 'Deleted',
        value: 'deleted'
    },
    {
        title: 'Suspended',
        value: 'suspended'
    },
    {
        title: 'Not Invited',
        value: 'no_access'
    }
];

export const listOfStatusInDirectoryOptionsInactive: Array<{title: string; value: string}> = [
    {
        title: 'Not Applicable',
        value: 'not_applicable'
    }
];

export const listOfAccountTransactionGroupBy: Array<{title: string; value: string}> = [
    {
        title: 'Date',
        value: 'date'
    },
    {
        title: 'Account',
        value: 'account'
    },
    {
        title: 'Transaction Type',
        value: 'transaction_type'
    },
    {
        title: 'Account Type',
        value: 'account_type'
    },
    {
        title: 'Account Group',
        value: 'account_group'
    },
    {
        title: 'Contact',
        value: 'contact'
    }
];

export const listOfDealApproval: Array<{title: string; value: string}> = [
    {
        title: 'Approved',
        value: 'approved'
    },
    {
        title: 'Unapproved',
        value: 'unapproved'
    }
];

export const listOfZipiPayOptions: Array<{title: string; value: string}> = [
    {
        title: 'Yes',
        value: 'yes'
    },
    {
        title: 'No',
        value: 'no'
    }
];

export const listOfBooleanCondition: Array<{title: string; value: boolean}> = [
    {
        title: 'Yes',
        value: true
    },
    {
        title: 'No',
        value: false
    }
];

export const listOfCompensationProfileType: Array<{title: string; value: string}> = [
    {
        title: FinancialElementModel.type_LABELS.agent,
        value: FinancialElementModel.type_set.agent
    },
    {
        title: FinancialElementModel.type_LABELS.royalty,
        value: FinancialElementModel.type_set.royalty
    },
    {
        title: FinancialElementModel.type_LABELS.user_royalty,
        value: FinancialElementModel.type_set.user_royalty
    },
    {
        title: FinancialElementModel.type_LABELS.compensation_expense,
        value: FinancialElementModel.type_set.compensation_expense
    },
    {
        title: FinancialElementModel.type_LABELS.company,
        value: FinancialElementModel.type_set.company
    },
    {
        title: FinancialElementModel.type_LABELS.company_income_expense,
        value: FinancialElementModel.type_set.company_income_expense
    },
    {
        title: FinancialElementModel.type_LABELS.company_partner,
        value: FinancialElementModel.type_set.company_partner
    },
    {
        title: FinancialElementModel.type_LABELS.referral,
        value: FinancialElementModel.type_set.referral
    },
    {
        title: FinancialElementModel.type_LABELS.disbursement_template,
        value: FinancialElementModel.type_set.disbursement_template
    },
    {
        title: FinancialElementModel.type_LABELS.user_referral,
        value: FinancialElementModel.type_set.user_referral
    },
    {
        title: FinancialElementModel.type_LABELS.participant,
        value: FinancialElementModel.type_set.participant
    },
    {
        title: FinancialElementModel.type_LABELS.sales,
        value: FinancialElementModel.type_set.sales
    },
    {
        title: FinancialElementModel.type_LABELS.commission_categorization,
        value: FinancialElementModel.type_set.commission_categorization
    },
    {
        title: FinancialElementModel.type_LABELS.disbursement_instructions,
        value: FinancialElementModel.type_set.disbursement_instructions
    }
];

export const listOfTen99Forms = {
    ten99_int: [
        {
            title: '-- Select All --',
            value: 'all'
        },
        {
            title: 'Copy A',
            value: 'copy_a'
        },
        {
            title: 'Copy 1',
            value: 'copy_1'
        },
        {
            title: 'Copy B',
            value: 'copy_b'
        },
        {
            title: 'Instructions for Recipient',
            value: 'recipient_instruction'
        },
        {
            title: 'Copy 2',
            value: 'copy_2'
        },
        {
            title: 'Copy C',
            value: 'copy_c'
        },
        {
            title: 'Instructions for Payer',
            value: 'payer_instruction'
        }
    ],
    ten99_misc: [
        {
            title: '-- Select All --',
            value: 'all'
        },
        {
            title: 'Copy A',
            value: 'copy_a'
        },
        {
            title: 'Copy 1',
            value: 'copy_1'
        },
        {
            title: 'Copy B',
            value: 'copy_b'
        },
        {
            title: 'Instructions for Recipient',
            value: 'recipient_instruction'
        },
        {
            title: 'Copy 2',
            value: 'copy_2'
        }
    ],
    ten99_nec: [
        {
            title: '-- Select All --',
            value: 'all'
        },
        {
            title: 'Copy A',
            value: 'copy_a'
        },
        {
            title: 'Copy 1',
            value: 'copy_1'
        },
        {
            title: 'Copy B',
            value: 'copy_b'
        },
        {
            title: 'Instructions for Recipient',
            value: 'recipient_instruction'
        },
        {
            title: 'Copy 2',
            value: 'copy_2'
        }
    ]
};

export const listOfYesNoAnswer: Array<{title: string; value: boolean}> = [
    {
        title: 'Yes',
        value: true
    },
    {
        title: 'No',
        value: false
    }
];

export const entityRouterListByJournalType: {[key: string]: string | null} = {
    invoice: '/sales/invoices',
    payment_received: '/sales/paymentsreceived',
    payment_made: '/purchases/payments',
    expense: '/purchases/expenses',
    bill: '/purchases/bills',
    credit_note: '/sales/creditnotes',
    vendor_credit: '/purchases/vendorcredits',
    payment: null,
    deposit_release: null
};

export const ledgerTypeGroups: Array<{[key: string]: any}> = [
    {
        group: 'Asset',
        order: 1,
        list: [
            {
                value: 'cash',
                label: 'Cash',
                order: 1
            },
            {
                value: 'bank',
                label: 'Bank',
                order: 2
            },
            {
                value: 'trust',
                label: 'Trust',
                order: 3
            },
            {
                value: 'payment_clearing',
                label: 'Payment Clearing',
                order: 4
            },
            {
                value: 'other_current_asset_accounts_receivable',
                label: 'Other Current Asset (Accounts Receivable)',
                order: 5
            },
            {
                value: 'other_current_asset',
                label: 'Other Current Asset',
                order: 6
            },
            {
                value: 'fixed_asset',
                label: 'Fixed Asset',
                order: 7
            },
            {
                value: 'inventory',
                label: 'Inventory',
                order: 8
            },
            {
                value: 'investment',
                label: 'Investment',
                order: 9
            },
            {
                value: 'other_asset',
                label: 'Other Asset',
                order: 10
            }
        ]
    },
    {
        group: 'Liability',
        order: 2,
        list: [
            {
                value: 'current_liability',
                label: 'Current Liability',
                order: 11
            },
            {
                value: 'credit_card',
                label: 'Credit Card',
                order: 12
            },
            {
                value: 'other_current_liability',
                label: 'Other Current Liability',
                order: 13
            },
            {
                value: 'long_term_liability',
                label: 'Long Term Liability',
                order: 14
            },
            {
                value: 'trust_liability',
                label: 'Trust Liability',
                order: 15
            },
            {
                value: 'other_liability',
                label: 'Other Liability',
                order: 16
            }
        ]
    },
    {
        group: 'Equity',
        order: 3,
        list: [
            {
                value: 'equity',
                label: 'Equity',
                order: 17
            },
            {
                value: 'common_stock',
                label: 'Common Stock',
                order: 18
            },
            {
                value: 'preferred_stock',
                label: 'Preferred Stock',
                order: 19
            },
            {
                value: 'treasury_stock',
                label: 'Treasury Stock',
                order: 20
            },
            {
                value: 'additional_paid_in_capital',
                label: 'Additional Paid in Capital',
                order: 21
            },
            {
                value: 'partner_contributions',
                label: 'Partner Contributions',
                order: 22
            },
            {
                value: 'partner_distributions',
                label: 'Partner Distributions',
                order: 23
            },
            {
                value: 'retained_earnings',
                label: 'Retained Earnings',
                order: 24
            }
        ]
    },
    {
        group: 'Income',
        order: 4,
        list: [
            {
                value: 'income',
                label: 'Income',
                order: 25
            }
        ]
    },
    {
        group: 'Cost of Goods Sold',
        order: 5,
        list: [
            {
                value: 'cost_of_goods_sold',
                label: 'Cost of Goods Sold',
                order: 26
            }
        ]
    },
    {
        group: 'Expense',
        order: 6,
        list: [
            {
                value: 'expense',
                label: 'Expense',
                order: 27
            }
        ]
    },
    {
        group: 'Other Income/Expense',
        order: 7,
        list: [
            {
                value: 'other_income',
                label: 'Other Income',
                order: 28
            },
            {
                value: 'other_expense',
                label: 'Other Expense',
                order: 29
            }
        ]
    }
];

const listOfReportPermissions = {
    reports__view_general_reports: 'reports__view_general_reports',
    reports__view_general_reports__user: 'reports__view_general_reports__user',
    reports__view_deal_reports: 'reports__view_deal_reports',
    reports__view_deal_reports__user: 'reports__view_deal_reports__user',
    reports__view_financial_reports: 'reports__view_financial_reports'
};

export const listOfReportTypeByPermissions = ((): {[key: string]: string} => {
    const result: {[key: string]: string} = {};

    generalReportTypes.forEach((item) => {
        result[item.value] = listOfReportPermissions.reports__view_general_reports;
    });

    dealReportTypes.forEach((item) => {
        result[item.value] = listOfReportPermissions.reports__view_deal_reports;
    });

    financialReportsTypes.forEach((item) => {
        result[item.value] = listOfReportPermissions.reports__view_financial_reports;
    });

    dealUserReportTypes.forEach((item) => {
        result[item.value] = listOfReportPermissions.reports__view_deal_reports__user;
    });

    return result;
})();

export const payrollFilingStatuses: Array<{label: string; value: string}> = [
    {
        value: 'individual',
        label: 'Individual'
    },
    {
        value: 'joint',
        label: 'Joint'
    },
    {
        value: 'head_of_household',
        label: 'Head of Household (with dependents)'
    }
];

export const productReportTypes = {
    company_income: {
        title: 'Company Income',
        value: 'company_income'
    },
    company_expense: {
        title: 'Company Expense',
        value: 'company_expense'
    },
    agent_income: {
        title: 'Agent Income',
        value: 'agent_income'
    },
    agent_expense: {
        title: 'Agent Expense',
        value: 'agent_expense'
    }
};

export const productReportTypesArr: Array<{title: string; value: string}> = Object.values(productReportTypes);

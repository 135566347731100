import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {IWildcard} from '@cyberco-nodejs/zipi-typings';
import {FinancialTransferEntity} from '../../../account-info/compensation/models/financial-transfer.entity';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {Wildcard} from 'app/models/wildcard';
import {takeUntil} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {selectWildcards} from 'app/store/company-wide/company-wide.selectors';
import {ICompanyWideState} from 'app/store/company-wide/company-wide.reducer';
import {currencyMaskitoOptions, percentMaskitoOptions} from '../../../../utilities/maskito';

@Component({
    selector: 'app-value-picker',
    templateUrl: './value-picker.component.html',
    styleUrls: ['./value-picker.component.scss']
})
export class ValuePickerComponent implements OnInit, OnDestroy, OnChanges {
    unsubscribe: Subject<void> = new Subject<void>();

    @ViewChild('inputPercent', {static: false}) inputPercent: ElementRef | undefined;

    @Input() readonly wildcardType: 'numeric' | 'text' = 'numeric';
    @Input() allowPercents: boolean = false;
    @Input() isPercent: boolean = false;
    @Input() disabled: boolean = false;
    @Input() hideRequiredMarker: boolean = false;

    @Input() valueFC: UntypedFormControl = new UntypedFormControl(null);
    @Input() percentFC: UntypedFormControl = new UntypedFormControl(null);
    @Input() valueTypeFC: UntypedFormControl = new UntypedFormControl(null);
    @Input() valueWildcardIdFC: UntypedFormControl = new UntypedFormControl(null);

    currentInputType: 'numeric' | 'text' | 'wildcard' = 'numeric';
    wildcardTypeFilter$: Subject<string> = new BehaviorSubject('');
    currencyMaskitoMask = currencyMaskitoOptions;
    percentMaskitoMask = percentMaskitoOptions;
    editPercent: boolean = false;
    wildcardList: IWildcard[] = [];

    protected lastValue: any;

    constructor(private store: Store<ICompanyWideState>) {}

    ngOnInit() {
        this.lastValue = this.valueFC.value;
        if (this.disabled) {
            this.valueFC.disable({emitEvent: false});
            this.percentFC.disable({emitEvent: false});
            this.valueWildcardIdFC.disable({emitEvent: false});
        }
        this.currentInputType = this.wildcardType;
        if (this.wildcardType === Wildcard.type_SET.numeric) {
            if (
                this.valueTypeFC.value === FinancialTransferEntity.value_type_SET.flat_fee ||
                this.valueTypeFC.value === FinancialTransferEntity.value_type_SET.flat_fee_sales_net
            ) {
                this.wildcardTypeFilter$.next(Wildcard.type_SET.numeric);
            }

            this.valueTypeFC.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
                switch (value) {
                    case FinancialTransferEntity.value_type_SET.flat_fee:
                    case FinancialTransferEntity.value_type_SET.flat_fee_sales_net:
                        this.wildcardTypeFilter$.next(Wildcard.type_SET.numeric);
                        break;
                    default:
                        this.wildcardTypeFilter$.next('');
                        this.changeType(this.wildcardType);
                        break;
                }
            });
        }

        if (this.wildcardType === Wildcard.type_SET.text) {
            this.wildcardTypeFilter$.next(Wildcard.type_SET.text);
        }

        combineLatest([this.store.pipe(select(selectWildcards)), this.wildcardTypeFilter$])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([wildcards, wildcardFilter]) => {
                if (wildcardFilter) {
                    this.wildcardList = wildcards.filter((wildcard) => wildcard.wildcard_type_sk === wildcardFilter);
                    if (this.valueWildcardIdFC.value) {
                        const currentWildcard = this.wildcardList.find(
                            (wildcard) => wildcard.wildcard_id === this.valueWildcardIdFC.value
                        );
                        if (typeof currentWildcard !== 'undefined') {
                            // this.currentInputType = 'wildcard';
                            this.changeType('wildcard');
                            this.valueWildcardIdFC.setValue(currentWildcard.wildcard_id);
                        }
                    }
                } else {
                    this.wildcardList = [];
                }
            });

        this.valueWildcardIdFC.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((wildcardValue) => {
            if (this.wildcardType === 'numeric') {
                if (wildcardValue === null && this.valueFC.value !== this.lastValue) {
                    if (this.currentInputType !== 'wildcard') {
                        switch (this.valueTypeFC.value) {
                            case FinancialTransferEntity.value_type_SET.flat_fee:
                            case FinancialTransferEntity.value_type_SET.flat_fee_sales_net:
                                this.changeType('wildcard');
                                break;
                            default:
                                this.changeType(this.wildcardType);
                                break;
                        }
                    } else {
                        this.changeType(this.wildcardType);
                    }
                }
            } else {
                if (wildcardValue === null && this.currentInputType === 'wildcard') {
                    this.changeType(this.wildcardType);
                } else if (wildcardValue === null && this.currentInputType !== 'wildcard') {
                    this.changeType('wildcard');
                }
            }
        });

        this.valueFC.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((newValue) => {
            this.lastValue = newValue;
        });

        if (this.currentInputType === 'numeric' && (!this.isPercent || !this.allowPercents)) {
            this.valueFC.setValidators([Validators.required, Validators.min(0.01)]);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isPercent && changes.isPercent.currentValue) {
            this.valueFC.clearValidators();
            this.valueFC.setValue(null, {emitEvent: false});
        } else if (changes.isPercent && !changes.isPercent.currentValue && this.currentInputType === 'numeric') {
            this.valueFC.setValidators([Validators.required, Validators.min(0.01)]);
        }

        if (changes.disabled && changes.disabled.currentValue) {
            this.valueFC.disable({emitEvent: false});
            this.percentFC.disable({emitEvent: false});
            this.valueWildcardIdFC.disable({emitEvent: false});
        }
    }

    changeType(toType: 'numeric' | 'wildcard' | 'text') {
        if (this.disabled) {
            return;
        }
        if (toType === 'wildcard') {
            this.valueFC.setValue(null, {emitEvent: false});
            this.valueFC.clearValidators();
            this.currentInputType = 'wildcard';
        } else {
            this.valueWildcardIdFC.setValue(null, {emitEvent: false});
            this.currentInputType = 'numeric';

            if (!this.isPercent || !this.allowPercents) {
                this.valueFC.setValidators([Validators.required, Validators.min(0.01)]);
            } else {
                this.valueFC.setValue(null, {emitEvent: false});
                this.valueFC.clearValidators();
            }
        }
    }

    togglePercentFC() {
        this.editPercent = !this.editPercent;
        setTimeout(() => {
            if (typeof this.inputPercent !== 'undefined') {
                this.inputPercent.nativeElement.focus();
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        this.wildcardTypeFilter$.complete();
    }
}
